import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

 import "../../Style/paginavista/familiamemorial.css" 
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";

import axios from "axios";
import { Helmet } from 'react-helmet';
import { CircularProgress} from "@mui/material";
import { Navbar } from "../nav-fotter/navbar";
import { MdGroups } from "react-icons/md";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

/* imagenes */
import herramientas from '../../Image/generales/memorial-en-proceso.png'



function GrupoFamiliar({setShowNavbarFooter}) {
  const { grupofamiliarId } = useParams();
  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [link, setLink] = useState(null); 
  const [data, setData] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [funerariaId, setFunerariaId] = useState(null);
  const [funerarias, setFunerarias] = useState(null);
  const [loading, setLoading] = useState(true);
  const [marcas, setMarcas] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(''); 


  useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
        setShowNavbarFooter(true);
    };
}, [setShowNavbarFooter]);

useEffect(() => {
  // Si hay una imagen de fondo, se carga la versión de baja calidad
  if (marcas?.fondo_memorial) {
    const lowQualityImage = `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`;
    setBackgroundImage(lowQualityImage); // Mostrar imagen de baja calidad primero

    // Crear una nueva instancia de imagen para cargar la versión completa
    const img = new Image();
    img.src = `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`;
    img.onload = () => {
      setBackgroundImage(img.src); // Cambia a la imagen completa cuando se haya cargado
    };
  }
}, [marcas]);

  useEffect(() => {
    
    const fetchGrupoFamiliar = async () => {
      setLoading(true);
      if (!grupofamiliarId) {
        navigate('/Registro');
        return;
      }

      try {
        const response = await axios.get(`${backendURL}/api/get/GrupoFamiliar/public/${grupofamiliarId}`);
        const grupoFamiliarData = response.data;
       
        // Si no existe el grupo familiar, redirigir a /Registro
        if (!grupoFamiliarData || grupoFamiliarData.estado === undefined) {
          navigate('/Registro');
          return;
        }

        setData(grupoFamiliarData);
       

        // Redirigir si el grupo familiar está inactivo
        if (grupoFamiliarData.estado === '0') {
          navigate('/Registro');
          return;
        }


        
         // Redirigir si el tipo_memorial es 2 y obtener el id del único memorial
      if (grupoFamiliarData.tipo_memorial === '2' && Array.isArray(grupoFamiliarData.memorial) && grupoFamiliarData.memorial.length === 1) {
        const memorialId = grupoFamiliarData.memorial[0].id; // Obtén el id del único memorial
        navigate(`/familiar/memorial/${memorialId}`); // Redirige al memorial
        return; // Sal del efecto después de la redirección
      }
        // Obtener el id_usuario y hacer una solicitud para obtener el estado del usuario
        const userId = grupoFamiliarData.id_usuario;

        // Validar si existe `id_usuario` y `nombre_familia` es null
        if (grupoFamiliarData?.id_usuario && !grupoFamiliarData?.nombre_familia) {
          const ruta = `/empresa/${grupoFamiliarData.id_empresa}/login/usuario`;
          navigate(ruta);
          return;
        }

        fetchUserState(userId, grupoFamiliarData);

        if (response.data?.id_empresa) {
          const idEmpresa = response.data.id_empresa;
          setEmpresa(idEmpresa)
          // Realiza la solicitud con la nueva ruta y el id_empresa
          const empresaResponse = await axios.get(`${backendURL}/api/marcaempresa/especifica/${idEmpresa}`, {
              headers: {
                  'Content-Type': 'application/json'
              }
          });
          setMarcas(empresaResponse.data.data[0]);
           setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching grupo familiar:', error);
        setLoading(false);
        navigate('/Registro');
      }
      finally{
        setLoading(false);
      }
    };

 
    // Función para obtener el estado del usuario
    const fetchUserState = async (userId, grupoFamiliarData) => {
      try {
        const response = await axios.get(`${backendURL}/api/getGrupoUserpublic/${userId}`);
        const userData = response.data;

        if (userData && userData.length > 0) {
          const user = userData[0].user[0];
         

          // Redirigir si el usuario está inactivo
          if (user.estado === '0') {
           
            navigate('/user-renew', {
              state: {
                user: user,
                familia: grupoFamiliarData,
              },
            });
          }
        } else {
         
        }
      } catch (error) {
        console.error('Error fetching user state:', error);
      }
    };

    fetchGrupoFamiliar();
  }, [backendURL, grupofamiliarId, navigate]);

  useEffect(() => {
    if (data && data.id_funeraria) {
      setFunerariaId(data.id_funeraria);
    }
  }, [data]);

  useEffect(() => {
    const fetchFuneraria = async () => {
      if (!funerariaId) {
        return;
      }

      try {
        const response = await axios.get(`${backendURL}/api/funeraria/${funerariaId}`);
        const funerariaData = response.data;
        setLink(funerariaData);

        if (funerariaData ) {
          if (funerariaData.nombre === 'smart memorial') {
            setLogoUrl(null); // No mostrar logo
          } else if (funerariaData.logo) {
            setFunerarias(funerariaData);
          setLogoUrl(funerariaData.logo);
          } else {
            setLogoUrl(null); // No hay logo disponible
          }
      
        }
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };

    fetchFuneraria();
  }, [backendURL, funerariaId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  if (loading) {
    return  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Pantalla completa
      flexDirection: 'column',
    }}
  >
    <CircularProgress
      size={100}
      thickness={4}
      sx={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}
    />
  </Box>;
  }

  const StyleFondo = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: 'auto',
    transition: 'filter 0.3s ease', // Transición suave
    filter: backgroundImage.includes('_low') ? 'blur(5px)' : 'none', // Aplica desenfoque mientras sea de baja calidad
  };

  const Styleboton = {
    background: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81',
    fontFamily:'Poppins',
    fontSize:'1rem',
    color:'#fff',
    padding:'10px 20px',
    borderRadius:'50px',
    cursor:'pointer'        // Ajustar la altura de la sección
      
  }

  const handlebotonregistroempresaexterna = () => {
    const ruta = `/empresa/${empresa}/registro/${marcas.id}/usuario`;
    
    // Navegar a la ruta construida
    navigate(ruta);
  }

  const handlebortoniniciarseccion = () => {
    const ruta = `/empresa/${empresa}/login/usuario`;
    
    // Navegar a la ruta construida
    navigate(ruta);
  }


  return (
    <section className="contienenpaginainiciousuario" style={StyleFondo}>
        {marcas ? (
            <Navbar
            marcas={marcas}
            color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
            color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
            logomarca={`${backendURL}/${marcas?.logo}`}
            />
          ) : (
            <Navbar
            />
          )}
       <Helmet>
       <title>Familia {data && data.nombre_familia ? data.nombre_familia : 'Smart Memorial'} </title>
        <meta name="description" content=" Conozca sus
historias de vida" />
        
      </Helmet>
      {logoUrl && (
          <Link to={link.web} className="logofunerariadosmemorial " target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}

       <Container component="main" maxWidth="xs" >
      <Box
        sx={{
          marginTop: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',

        }}
      >
        <div>
          
            
        {data && data.nombre_familia ? (
  <div className="titulogrupofamilia">
    <h3 style={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }}>Grupo Familiar</h3>
    <div className="segundotitulofinal">
      <h1 style={{ color: (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : '#D26999' }}>{data?.nombre_familia}</h1>
      <MdGroups style={{ fontSize: '3.5rem', color: (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : '#D26999' }} />
    </div>
  </div>
) : <></>}
           
        
        </div>
      </Box>

      <div className="rowmemorialesfamiliapagina">
      {data && data.memorial && data.memorial.length > 0 && (
         <div className="titulomemorialestodo">
        <h1 style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Conozca sus  </h1>
        <h1 style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}> historias de vida</h1>
        <span>haga click  sobre la foto  para visitar el memorial</span>
      </div>
    )}

      <div className="contienentodoslosmemorialesfamilia">
      <Box sx={{width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      {data && data.memorial && data.memorial.length > 0 ? (
          data.memorial.map((item, index) => (
          <Grid container  key={item.id} sx={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
         <Link 
         style={{width:"100%"}}
      to={{
        pathname: `/familiar/memorial/${item?.id}`,
        state: { funeraria: funerarias }
      }}
    >
              <div className="grupofamiliacard">
                <Grid item md={12} sx={{ width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' } } >
              
            <div className="grupofamiliacircle" style={{zIndex:'1'}}>
            <img
    src={`${backendURL}/imagenes/memoriales/${item?.carpeta_perfil}/${item?.imagen_perfil}`}
    alt="smart memorial"
    className="grupofamiliaprofileimg"
  
    style={{
      borderColor: (() => {
        switch (item?.fondo) {
          case 'naturalezaAustral':
            return '#E1EDDD';
          case 'atardecerVerano':
            return '#F9E7D7';
          default:
            return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 : '#E1DBE6';
        }
      })()
    }}
  /> </div>
              </Grid>
              <Grid item md={12} sx={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"-82px"}}>
              <div
                className="grupofamiliacuadrado"
                style={{
                  background: (() => {
                    switch (item?.fondo) {
                      case 'naturalezaAustral':
                        return '#E1EDDD';
                      case 'atardecerVerano':
                        return '#F9E7D7';
                      default:
                        return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :'#E1DBE6';
                    }
                  })()
                }}
              >
                <div
                  className="grupofamiliacontent"
                  style={{
                    color: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :'#D26999';
                      }
                    })()
                  }}
                >
                  <h2 className="name"style={{
                    color: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :'#D26999';
                      }
                    })()
                  }}>{item?.nombre}</h2>
                  <p className="dates"  style={{
                     color: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#68A354';
                        case 'atardecerVerano':
                          return '#E28939';
                        default:
                          return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
                      }
                    })(),
                  }}>
                    {formatDate(item?.fecha_nacimiento)} • {formatDate(item?.fecha_defuncion)}
                  </p>
                </div>
              </div>
           
          
               
             </Grid>
             </div>
         </Link>
         </Grid>
        ))):(

          <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '80%',
            background: (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : "#F0EDF3",
            borderRadius: "10px",
            padding: "30px 30px",
            '@media (max-width: 375px)': {
              padding: "30px 10px",
              width: '95%',
            },
          }}
        >
          {data?.id_usuario && data?.nombre_familia ? (
            // Caso 3: Mostrar mensaje de proceso de creación del grupo
            <div className="memorialfamiliavacio">
              <h1 style={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }}>
                Este grupo familiar <strong>{data.nombre_familia}</strong> se encuentra
              </h1>
              <h1 style={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }}>
                en proceso de creación
              </h1>
              
              <div className="contienenimagenvaciomemorial" style={{ width: '200px', height: '100px' }}>
                {marcas ? (
                  <LazyLoadImage
                    src={`${backendURL}/${marcas.icono_registro}`}
                    placeholderSrc={`${backendURL}/${marcas.icono_registro}`}
                    alt="smart memorial"
                    title="smart memorial"
                    effect="blur"
                  />
                ) : (
                  <LazyLoadImage
                    src={herramientas}
                    placeholderSrc={`${herramientas}`}
                    alt="smart memorial"
                    title="smart memorial"
                    effect="blur"
                  />
                )}
              </div>
            </div>
          ) : data?.id_usuario ? (
            // Caso 2: Mostrar botón "Iniciar sesión"
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}>
              <div className="memorialfamiliavacio">
              <h1 style={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }}>
                Este grupo familiar se encuentra
              </h1>
              <h1 style={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }}>
                en proceso de creación
              </h1>
              
              <div className="contienenimagenvaciomemorial" style={{ width: '200px', height: '100px' }}>
                {marcas ? (
                  <LazyLoadImage
                    src={`${backendURL}/${marcas.icono_registro}`}
                    placeholderSrc={`${backendURL}/${marcas.icono_registro}`}
                    alt="smart memorial"
                    title="smart memorial"
                    effect="blur"
                  />
                ) : (
                  <LazyLoadImage
                    src={herramientas}
                    placeholderSrc={`${herramientas}`}
                    alt="smart memorial"
                    title="smart memorial"
                    effect="blur"
                  />
                )}
              </div>
                <div className="titulosecindariovaciomemorial" style={{
                  marginBottom: '-4px',
                  fontSize: '0.8rem',
                  color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81',
                }}>
                  Si ya tienes una cuenta, haz clic aquí para iniciar sesión
                </div>
              </div>
              <button
                className=""
                style={Styleboton}
                onClick={handlebortoniniciarseccion}
              >
                Iniciar sesión
              </button>
            </div>
          ) : (
            // Caso 1: Mostrar botón "Registrarse"
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}>
              <div className="memorialfamiliavacio">
              <h1 style={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }}>
                Este grupo familiar se encuentra
              </h1>
              <h1 style={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }}>
                en proceso de creación
              </h1>
              
              <div className="contienenimagenvaciomemorial" style={{ width: '200px', height: '100px' }}>
                {marcas ? (
                  <LazyLoadImage
                    src={`${backendURL}/${marcas.icono_registro}`}
                    placeholderSrc={`${backendURL}/${marcas.icono_registro}`}
                    alt="smart memorial"
                    title="smart memorial"
                    effect="blur"
                  />
                ) : (
                  <LazyLoadImage
                    src={herramientas}
                    placeholderSrc={`${herramientas}`}
                    alt="smart memorial"
                    title="smart memorial"
                    effect="blur"
                  />
                )}
              </div>
                <div className="titulosecindariovaciomemorial" style={{
                  marginBottom: '-4px',
                  fontSize: '0.8rem',
                  color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81',
                }}>
                  Si no te haz registrado, haz clic aquí
                </div>
              </div>
              <button
                className=""
                style={Styleboton}
                onClick={handlebotonregistroempresaexterna}
              >
                Registrarse
              </button>
            </div>
          )}
        </Box>
          
          

        )}
        </Box>
      </div>
    </div> 
    </Container>
  </section>
  );
}

export default GrupoFamiliar;
