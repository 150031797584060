import React, { useEffect} from "react";

import { ReporteFuncion } from "../../FuncionesEmpresaAdmi/Tareas/Reportefuncion";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import EmpresaLayout from "../../../../../../EmpresaLayout"
/* MATERIAL UI */
import { Container} from '@mui/material';

export const IndexTareaBaseDatos = ({setShowNavbarFooter}) => {
    const { Token, Empresa } = useUserEmpresa();

    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);
    return (
        <EmpresaLayout>
        <Container maxWidth="lg"> 
            <ReporteFuncion  empresa={Empresa}  Token={Token}  />
        </Container>
        </EmpresaLayout>

    )
}