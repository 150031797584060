import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import "../../Style/paginavista/empredemo.css"
import { Navbar } from '../nav-fotter/navbar';
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import ReactPlayer from 'react-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { motion } from "framer-motion";
/*  libreria UI */
import { Container, Grid, Box, CircularProgress, Modal, Snackbar} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import PersonPinIcon from "@mui/icons-material/PersonPin";


/* REACT ICONS */
import { BiErrorCircle } from "react-icons/bi";
import { PiScrollFill } from "react-icons/pi";
import { FaPhotoVideo } from "react-icons/fa";
import { IoList } from "react-icons/io5";
import { BsEnvelopePaperHeartFill } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { IoPlayCircleOutline } from 'react-icons/io5';
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { FaRegCircleCheck } from "react-icons/fa6";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

/* imagenes */
import fondo from "../../Image/generales/fondo-memorial.jpg";
import fotovideo from "../../Image/video/foto.jpg"
import herramientas from '../../Image/generales/memorial-en-proceso.png' 

export const PublicMemorialHistorico = ({ setShowNavbarFooter }) => {
    const { nombre_empresa, id, id_qr } = useParams();
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [marcas, setMarcas] = useState(null);
    const [empresaData, setEmpresaData] = useState(null);
    const [usuarioData, setusuarioData] = useState(null);
    const [memorialData, setMemorialData] = useState(null);
    const [memorialunicoData, setMemorialDataunico] = useState(null);
    const [grupoFamiliar, setGrupoFamiliar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(true);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [error, setError] = useState(false);
    const messagesSectionRef = useRef(null);
    const [backgroundImage, setBackgroundImage] = useState(''); 
    const [isButtonVisible, setIsButtonVisible] = useState(false);
     const buttonRef = useRef(null);
    const ref = useRef(null);
     /* galeria */
  const [selectedIcon, setSelectedIcon] = useState('grid');
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleIconClick = (icon) => setSelectedIcon(icon);
 
  /* video */
  const videoRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isPreviewImageLoaded, setIsPreviewImageLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /* ubicacion */
  const [selectedPoint, setSelectedPoint] = useState({ top: 0, left:0 });
  const [mapImage, setMapImage] = useState(null);

  useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
        setShowNavbarFooter(true);
    };
}, [setShowNavbarFooter]);
/* animacion scroll */
useEffect(() => {
    AOS.init({ duration: 3000});  // Asegúrate de que las animaciones solo se ejecuten una vez
  
  }, []);

const handleScroll = () => {
    if (ref.current) {
    const rect = ref.current.getBoundingClientRect();
    if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
    }
    }
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
}, []);

/* trae informacion */

  useEffect(() => {
    // Detectar espacios en nombre_empresa
    if (nombre_empresa.includes(" ")) {
        // Transformar espacios en %20
        const fixedNombreEmpresa = encodeURIComponent(nombre_empresa);
        // Redirigir a la URL corregida
        navigate(`/empresa/memorial/historico/${fixedNombreEmpresa}/${id}/${id_qr}`, { replace: true });
    }
}, [nombre_empresa, id, id_qr, navigate]);;

useEffect(() => {
    // Si hay una imagen de fondo, se carga la versión de baja calidad
    if (marcas?.fondo_memorial) {
      const lowQualityImage = `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`;
      setBackgroundImage(lowQualityImage); // Mostrar imagen de baja calidad primero
  
      // Crear una nueva instancia de imagen para cargar la versión completa
      const img = new Image();
      img.src = `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`;
      img.onload = () => {
        setBackgroundImage(img.src); // Cambia a la imagen completa cuando se haya cargado
      };
    }
  }, [marcas]);
  
    useEffect(() => {
      const fetchEmpresaData = async () => {
        try {
          const empresaResponse = await axios.get(`${backendURL}/api/empresa/especifica/${id}`);
          setEmpresaData(empresaResponse.data.empresa);
          setusuarioData(empresaResponse.data.empresa.usuario);
        } catch (error) {
          console.error("Error al obtener datos de la empresa:", error);
        } finally {
          setLoading(false);
        }
      };
    
      fetchEmpresaData();
    }, [id, backendURL]);
    
    useEffect(() => {
      const fetchMarcasData = async () => {
        try {
          const marcasResponse = await axios.get(`${backendURL}/api/marcaempresa/especifica/${id}`);
          const marcaData = marcasResponse.data.data[0];
    
          if (marcaData) {
            setMarcas(marcaData); // Guardar la marca si existe
          }
        } catch (error) {
          console.error("Error al obtener datos de marcas:", error);
        }
      };
    
      fetchMarcasData();
    }, [id, backendURL]);
    
    useEffect(() => { 
      setLoadings(true);
      const fetchGrupoFamiliar = async () => {
        if (id_qr) { // Verifica que id_qr esté presente
          try {
            const grupoFamiliarResponse = await axios.get(`${backendURL}/api/empresa/demoqr/${id_qr}`);
            const grupoFamiliarData = grupoFamiliarResponse.data.grupo_familiar;
            
            // Verifica si hay un grupo familiar y si tiene memoriales
            if (grupoFamiliarData && grupoFamiliarData[0]?.memorial?.length > 0) {
              // Extrae el primer memorial
              const primerMemorial = grupoFamiliarData[0].memorial[0];
              setGrupoFamiliar(grupoFamiliarData); // Guarda todo el grupo familiar
              setMemorialDataunico(primerMemorial); // Guarda solo el primer memorial
            }
            
            setLoadings(false);
          } catch (error) {
            console.error("Error al obtener el grupo familiar:", error);
            setLoadings(false);
          }
        } else {
            console.warn("id_qr no está definido");
            setLoadings(false); // Detiene la carga si id_qr no está presente
        }
      };
    
      fetchGrupoFamiliar();
    }, [id_qr, backendURL]); 
    
    // Maneja la carga de los datos
    useEffect(() => {
      if (grupoFamiliar && grupoFamiliar[0]?.memoriales?.length > 0) {
        setLoadings(false); // Deja de cargar cuando ya tienes los datos
      }
    }, [grupoFamiliar]);
  
    const fetchDatamemorial = async () => {
      setLoadings(true);
      try {
        // Verificar si el memorial existe
        const memorialResponse = await axios.get(`${backendURL}/api/verificarmemorial/${memorialunicoData.id}`);
        if (!memorialResponse.data.exists) {
          setLoadings(false);
          // Redirigir si el memorial no existe
          navigate('/Registro');
          return;
        }
  
        // Obtener los datos del memorial
        const memorialDataResponse = await axios.get(`${backendURL}/api/getMemorialesPorGrupoFamiliarpublic/${id_qr}`);
       
        setMemorialData(memorialDataResponse.data[0]); 
        const memorial = memorialDataResponse.data[0];
        if (memorial.top && memorial.left) {
          setSelectedPoint({ top: memorial.top, left: memorial.left });
        }
        if (memorial.map_image) {
          setMapImage(memorial.map_image); // Asume que la respuesta tiene la URL de la imagen
        }
        setError(false);
  
  
      } catch (error) {
        console.error('Error fetching memorial data:', error);
        setError(true);
      } finally {
        setLoadings(false);
      }
    };
  
    useEffect(() => {
      if (memorialunicoData && memorialunicoData.id) {
        fetchDatamemorial();
      }
    }, [memorialunicoData]);

    
  if (loading || loadings) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Pantalla completa
          flexDirection: 'column',
        }}
      >
        <CircularProgress
          size={100}
          thickness={4}
          sx={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}
        />
      </Box>
    );
  }
   /* COLORES MEMORIAL */

   const getColorByFondo = () => {
    return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
};

const getColorByborder = () => {
    return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81';
};

const getColorByborderperfil = () => {
    return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
};

const backborderColor = () =>{
    return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
} 
  const borderColor = getColorByFondo(memorialData?.fondo);
  const backgroundColor = getColorByFondo(memorialData?.fondo);

  const StyleFondo = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: 'auto',
    transition: 'filter 0.3s ease', // Transición suave
    filter: backgroundImage.includes('_low') ? 'blur(5px)' : 'none', // Aplica desenfoque mientras sea de baja calidad
  };

  const styles = {
    titleStyle: {
        color: marcas?.color_2 || '#694B81'
    },
    fondomensajeStyle: {
        background: marcas?.color_2 || '#694B81'
    },
    fondosStyle: {
        background: marcas?.color_3 || '#F0EDF3'
    },
    titulodosStyle: {
        color: marcas?.color_3 || '#F0EDF3'
    },
    titulooscuroStyle: {
        color: marcas?.color_1 || '#D26999'
    },
    botonoscuroStyle: {
        backgroundColor: marcas?.color_1 || '#D26999'
    },
    texto: {
      color: "#fff"
    },
    selectedIconStyle: {

      cursor: "pointer",
      width: '40px',
      height: '40px',
      color: '#fff',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background:  (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'
    },
    iconStyle: {
      cursor: "pointer",
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      background: 'transparent',  // Aquí está el error corregido
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'
        
    },
    modalContent: {
      maxHeight: '80vh', // Limitar la altura máxima
      overflowY: 'auto', // Hacer el contenido desplazable si es necesario
      position: 'relative',
    },
    modalImage: {
      width: '100%',
      maxHeight: '60vh',
      objectFit: 'contain',
    },
    buttonmas: {
      color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81',  
      border: `1px solid ${marcas?.color_2 && marcas.color_2 !== 'undefined' ? marcas.color_2 :  '#694B81'}`,
      '--button-bg-hover': backborderColor,
      '--button-border-hover': borderColor,
    },
    styleborder: {
      border: `8px solid ${marcas?.color_2 && marcas.color_2 !== 'undefined' ? marcas.color_2 :  '#694B81'}`,

    },
    styleiconoblose: {
      color: (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6',
       

    }
  };
  const styleHrinicio = {
    width:'100%',
    border:'1px solid rgb(255 255 255 / 16%)',
    background:'rgb(255 255 255 / 50%)',
    height:'1px',
    borderRadius:'60px',
    margin:'0px',
    animation: isVisible ? 'growHr 1s ease-out' : '',
};


     /* formato texto */   
        const capitalizeFirstLetter = (string) => {
            if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        /* fechas */
        function formatFecha(fecha) {
            // Verifica si la fecha es nula o indefinida
            if (!fecha) {
            return '';
            }
            
            // Convierte la fecha a un objeto Date
            const date = new Date(fecha);
            
            // Verifica si la fecha es válida
            if (isNaN(date.getTime())) {
            return '';
            }
            
            // Obtiene el día, el mes y el año
            const day = date.getDate();
            const month = date.toLocaleString('es-ES', { month: 'long' });
            const year = date.getFullYear();
            
            // Devuelve la fecha formateada
            return `${day} ${month} ${year}`;
        }

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = date.getUTCDate().toString().padStart(2, '0');
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = date.getUTCFullYear();
            return `${day}/${month}/${year}`;
        };



  const handleShare = () => {
    // Codifica el nombre de la empresa para la URL
    const nombreEmpresaCodificado = encodeURIComponent(empresaData.nombre_empresa);
    
    // Construye la URL para compartir
    const shareURL = `/empresa/memorial/historico/${nombreEmpresaCodificado}/${empresaData.id}/${id_qr}`;
    
    // Usa la API de compartir del navegador
    window.navigator.share({ url: shareURL })
        .then(() => {
           
        })
        .catch((error) => {
            console.error('Error al compartir:', error);
        });
};
    return(
        <>
         <section style={StyleFondo}>
           {marcas ? (
  <Navbar
  marcas={marcas}
  color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
  color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
  logomarca={`${backendURL}/${marcas?.logo}`}
  />
) : (
  <Navbar
  />
)}
            
          <Helmet>
       <title> Empresa {empresaData.id_tipo_empresa == 1 ? 'Cementerio' : 'Funeraria' || ""} {empresaData.nombre_empresa || "Empresa"}  </title>
        <meta name="description" content="Empresa asociada con smart memorial para mejorar el servicio de nuestros clientes" />
        
      </Helmet>
      </section>
      <Container component="main" maxWidth="sm" sx={{paddingLeft:'0px', paddingRight:'0px'}} >
      {grupoFamiliar && grupoFamiliar.length > 0 ? (
      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: (() => {
              switch (memorialData?.fondo) {
                case 'naturalezaAustral':
                  return '#E1EDDD';
                case 'atardecerVerano':
                  return '#F9E7D7';
                default:
                  return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 :  '#E1DBE6';
              }
            })(),
          }}
        >

          <section className="contienehistoriademo" ref={messagesSectionRef} >

          

            <div className="vistamemorial">
            <div className='marcologoempresademo' style={{
                       width:'100%',
                       display:'flex',
                       alignItems:'center',
                       justifyContent:'center',
                        position:'absolute',
                        zIndex:'11111',
                        top:'0%',
                        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%)',

                      }}>
                      {marcas?.logo ? (
                        <div  className="logoempresamemorialhistorico" >
                            <img src={`${backendURL}/${marcas.logo}`} className='marcoimglogoempresademo' alt='Smart Memorial' title='SmartMemorial' />
                        </div>) : <>
                        </>}  
                      </div>  
            <Box
               sx={{
                 width: "100%",
                 height: '100%',
                 position: "relative",
                 overflow: "hidden",
               }}
             >
             {memorialData && (
             
             <img src={`${backendURL}/imagenes/memoriales/${memorialData?.carpeta_perfil}/${memorialData?.imagen_perfil}`} alt="Recuerdo" className="imagen-obituario"
                  />
             
             )}
               
               <Box
                 sx={{
                   position: "absolute",
                   bottom: 15,
                   left: 0,
                   width: "100%",
                   height: "100px",
                   backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo negro con transparencia
                   display: "flex",
                   flexDirection: "column",
                   justifyContent: "center",
                   alignItems: "center",
                   color: "#fff", // Letras blancas
                  
                   textAlign: "center",
                 }}
               >
                 <div style={{ width:'100%',display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                     <hr   
                         ref={ref}
                         style={styleHrinicio}
                         className={`scrollFade ${isVisible ? 'scrollFadeVisible' : ''}`}
                     />
                     <h3  
                     className={`animateFadeInUp ${isVisible ? 'scrollFadeVisible' : ''}`} 
                     style={{color:'#ffff',  marginBottom:'2px', fontStyle:'italic', fontWeight:'600', marginTop:'10px'}} >
                         {memorialData?.nombre}
                     </h3>
                 <div  className={`animateFadeInUp ${isVisible ? 'scrollFadeVisible' : ''}`} style={{ marginBottom:'10px', color:'#ffff',  fontStyle:'italic'}}> {formatDate(memorialData?.fecha_nacimiento)} • {formatDate(memorialData?.fecha_defuncion)} </div>
                 <hr  ref={ref}
                       style={styleHrinicio}
                       className={`scrollFade ${isVisible ? 'scrollFadeVisible' : ''}`}/>
                 </div>
             
               </Box>
               </Box>

           

                
              <div className="titulodemomerorial" style={{width:'95%', position:'relative', top:'0%'}}>
              <Box
        sx={{
       
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
       
          
        }}
      >
        
      </Box>
            
              </div>
              
            </div>
            {memorialData?.historia && (
            <div className="texto-historia" style={{ borderRadius:'0px', width: '95%', padding:'0px ', marginTop:'-4px' }} data-aos="fade-down">
              
              <p className="textohistoriademo" style={{
                fontStyle:'italic',
                
                padding:'10px 10px',
                textAlign:'center',
                lineHeight:'1.2',
                color: marcas?.color_2 && marcas.color_2 !== 'undefined' ? marcas.color_2 :  '#E1DBE6',
                
              }}>
                {memorialData?.historia ? (
                  <DangerouslySetHTMLContent html={capitalizeFirstLetter(memorialData.historia)} />
                ) : (
                  <span></span>
                )}
              </p>
            </div>)}


          </section>
          {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
            <section className="demogaleriarecuerdo" style={{...styles.fondosStyle, marginTop:'0px', padding:'30px 0px'}}>
              {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
                <div className="titulogaleriabonito" style={{...styles.titleStyle, fontWeight:'500', width:'90%', justifyContent:'flex-start', paddingLeft:'6px'}} >
                <h1 style={{ fontWeight:'500'}}>Memoria de {memorialData?.nombre}</h1>
                </div>
              )}
              <section className="contienensliderdemogaleria" >
              
                <div className="contiendedemogaleria">
                  {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
                   
                   <Timeline position="alternate"  sx={{padding:'6px 0px'}} >
                   {memorialData.galeria.map((imagen, index) => (
                     <TimelineItem 
                     sx={{ height:'335px',  "@media (max-width: 370px)": {
                        height:'300px',
                      },}}
                     key={index}
                     data-aos="fade-up"  // Aquí puedes cambiar el tipo de animación
                     data-aos-delay={index * 300}>
                       {/* Contenido a la izquierda */}
                       <TimelineOppositeContent color="text.secondary" sx={{fontWeight:'600', fontFamily:'Poppins'}}>
                         {/* Mostrar año */}
                         {new Date(imagen.fecha).getFullYear()}
                            <div >
                            <h3 className="timeline-title" style={{color:marcas?.color_2 && marcas.color_2 !== 'undefined' ? marcas.color_2 :  '#694B81'}}>{imagen.titulo}</h3>
                            <p className="timeline-description">{imagen.descripcion}</p>
                            </div>
                       </TimelineOppositeContent>
             
                       {/* Separador entre los elementos de la línea de tiempo */}
                       <TimelineSeparator >
                         <TimelineDot  sx={{backgroundColor:marcas?.color_2 && marcas.color_2 !== 'undefined' ? marcas.color_2 :  '#694B81'}} />
                         <TimelineConnector   sx={{backgroundColor:marcas?.color_2 && marcas.color_2 !== 'undefined' ? marcas.color_2 :  '#694B81'}}/>
                       </TimelineSeparator>
             
                       {/* Contenido principal */}
                       <TimelineContent sx={{display:'flex', alignContent:'center', alignItems:'center'}}>
                       
             
                         {/* Imagen */}
                         <div className="timeline-image">
                           <LazyLoadImage
                             src={`${backendURL}/${imagen.imagen}`}
                             alt="smart memorial"
                             effect="blur"
                             className="image"
                           />
                         </div>
                       </TimelineContent>
                     </TimelineItem>
                   ))}
                 </Timeline>
                    
                  )}
                </div>
              </section>
            </section>)}
            {memorialData && memorialData.video && (
  <section className="contienenvideosdemo" style={{ padding:'20px 0px'}}  data-aos="fade-up"  // Aquí puedes cambiar el tipo de animación
  >
    <div className="videotitulodemo" style={styles.titleStyle}> Homenaje {memorialData.nombre}</div>

    <div className="video-container">
     

      {isLoading && (
        <div className="loader">
          <div className="in"></div>
          <div className="out-1"></div>
          <div className="out-2"></div>
          <div className="out-3"></div>
        </div>
      )}

<ReactPlayer
  url={`${backendURL}/video/${memorialData.video}?t=${new Date().getTime()}`}
  playing={isPlaying}
  controls={true}
  width="100%"
  height="200px"
  light={isPreviewImageLoaded ? previewImage : (marcas.fondo_video ? `${backendURL}/${marcas.fondo_video}` : fotovideo)}
  onPlay={() => setIsPlaying(true)}
  onPause={() => setIsPlaying(false)}
  onReady={() => setIsLoading(false)}
  onError={() => {
    setIsLoading(false);
    setIsPlaying(false);
  }}
/>
    </div>
  </section>
)}
{memorialData?.mapa &&(
<section>
<Box sx={{ position: "relative", display: "inline-block", mt: 2 }}>
          <img
            src={`${backendURL}/${memorialData.mapa}`}
            alt="Mapa"
            style={{ width: "100%", maxWidth: "400px",  }}
           
            
          />
           {/* Icono de PersonPin con animación */}
           <motion.div
            animate={{ top: `${selectedPoint.top}%`, left: `${selectedPoint.left}%` }}
            transition={{ type: "spring", stiffness: 100 }}
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              
            }}
            
          >
            <PersonPinIcon sx={{ fontSize: 50, color: marcas?.color_2 || '#F0EDF3'  }} />
            {memorialData?.imagen_perfil && (
              <img
                src={`${backendURL}/imagenes/memoriales/${memorialData.carpeta_perfil}/${memorialData.imagen_perfil}`}
                alt="Memorial"
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  border: "2px solid white",
                }}
              />
            )}
          </motion.div>
        </Box>
</section>
)
}
 {memorialData?.cementerio && (         
<section className="contienencementerio">
    

        <section className="titulocementerio" style={styles.titleStyle}>
           
            <div className="titulocementerio"> <h1>Donde lo puedes encontrar</h1></div> 
        </section>
   
    <section className="contieneninformacioncementerio">
     
        <p>{capitalizeFirstLetter(memorialData.cementerio)}</p>

    </section>

</section>  )}

<section
           className={`enviarmensajememorialpaginainicial ${isButtonVisible ? 'enviarmensajememorialpaginainicial-visible' : 'enviarmensajememorialpaginainicial-hidden'}`}
          style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection:"column",
            border: `1px solid ${borderColor}`,
            background: backgroundColor,
          }}
          ref={buttonRef}
        >

          <button onClick={handleShare}   className="butoncompartirmemorial" style={styles.fondomensajeStyle}   > <FaShareAlt /> Compartir memorial</button>
        </section>
  
        </Box>) : (
    // Contenido cuando NO hay un grupo familiar
    <Box
      sx={{
        marginTop: '20px',
       
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '80%',
        background: (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : "#F0EDF3",
        borderRadius: "10px",
        padding: "30px 30px",
        '@media (max-width: 375px)': {
          padding: "30px 10px",
          width: '95%',
        },
      }}
    >
      <div className="memorialfamiliavacio">
        <h1 style={{
          color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81'
        }}>
          Este grupo familiar se encuentra
        </h1>
        <h1 style={{
          color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81'
        }}>
          en proceso de creación
        </h1>
      </div>
      <div className="contienenimagenvaciomemorial" style={{ width: '200px', height: '100px' }}>
        {marcas?.icono_registro ? (
          <LazyLoadImage
            src={`${backendURL}/${marcas.icono_registro}`}
            placeholderSrc={`${backendURL}/${marcas.icono_registro}`}
            alt="smart memorial"
            title="smart memorial"
            effect="blur"
          />
        ) : (
          <LazyLoadImage
            src={herramientas}
            placeholderSrc={herramientas}
            alt="smart memorial"
            title="smart memorial"
            effect="blur"
          />
        )}
      </div>
    </Box>
  )}
    
      </Container>
        </>
    )
}