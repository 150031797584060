import React, { useState, useEffect, useRef  } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import ReactQuill from 'react-quill';
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";

/* material UI */
import { Box, TextField, IconButton, Button, Modal, CircularProgress, Grid, Snackbar,  Accordion, AccordionSummary, AccordionDetails, Typography, AppBar, Toolbar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
/* react icons */
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { Padding } from "@mui/icons-material";
import { TbHomeEdit } from "react-icons/tb";

import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

export const AgregarSucursalAdmin = ({ sucursal,formCloseAdminModal }) => {
    const {  Token, UserDataMarca, Empresa } = useUserEmpresa();
    const [emailExistsError, setEmailExistsError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
    const theme = useTheme();
     /* procesadores */
     const [errors, setErrors] = useState({});
     const [error, setError] = useState('');
     const [successAlertOpen, setSuccessAlertOpen] = useState(false);
     const [errorAlertOpen, setErrorAlertOpen] = useState(false);
     const [enviandoAlert, setenviandoAlert] = useState(false);
     const [alertMessage, setAlertMessage] = useState('');

    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        id_sucursal:sucursal.id,
        id_empresa:Empresa.id,
        telefono: '',
        correo: '',
        email: '',
        password: '',
        password_confirmation: '',
      });

      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        setFormData(prevData => {
            let newData = { ...prevData, [name]: value };
            
            // Sincroniza 'correo' y 'email' si uno de ellos cambia
            if (name === 'correo') {
                newData.email = value;
                setEmailExistsError(false);
            } else if (name === 'email') {
                newData.correo = value;
            }
            return newData;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = {};
            // Validaciones de campos requeridos
        if (!formData.nombre) newErrors.nombre = 'El nombre es obligatorio.';
        if (!formData.apellido) newErrors.apellido = 'El apellido es obligatorio.';
        if (!formData.telefono) newErrors.telefono = 'El teléfono es obligatorio.';
        if (!formData.correo) newErrors.correo = 'El correo es obligatorio.';
        if (!formData.password) newErrors.password = 'La contraseña es obligatoria.';
        if (!formData.password_confirmation) newErrors.password_confirmation = 'Confirma tu contraseña.';

        // Validación de longitud mínima de la contraseña
        if (formData.password && formData.password.length < 8) {
        newErrors.password = 'La contraseña debe tener al menos 8 caracteres.';
        }

        // Validación de coincidencia de contraseñas
        if (formData.password && formData.password_confirmation && formData.password !== formData.password_confirmation) {
        newErrors.password_confirmation = 'Las contraseñas no coinciden.';
        }
         
        if (formData.password !== formData.password_confirmation) {
            newErrors.password_confirmation = 'La confirmación de la contraseña no coincide.';
            setErrors(newErrors);
            return; // Detener el envío
        }
          // Actualizar el estado de errores
          setErrors(newErrors);
    
    
        const formDataToSend = new FormData();
        setenviandoAlert(true); 
        // Agrega todos los datos del estado formData al FormData
            for (const [key, value] of Object.entries(formData)) {
            formDataToSend.append(key, value);
            }
    
            try {
          const response = await fetch(`${backendURL}/api/store/admin/sucursal`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${Token}`
            },
            body: formDataToSend,
          });
 
          const result = await response.json();
          if (response.ok) {
            setenviandoAlert(false);
            setAlertMessage('Administrador de Sucursal creada con éxito.');
            setSuccessAlertOpen(true);
            setTimeout(() => {
              setSuccessAlertOpen(false);
              formCloseAdminModal();
            }, 3000);
          } else {
            // Verifica si el error es por correo electrónico ya registrado
            if (result.error === 'El correo electrónico ya está registrado') {
              setFormData(prevState => ({
                ...prevState,
                emailExistsError: true
              }));
              setError('El correo electrónico ya está registrado');
            } else {
              setAlertMessage(result.message || 'Error al crear el administrador.');
              setErrorAlertOpen(true);
            }
            setenviandoAlert(false);
          }
        } catch (error) {
          // Error de red: Ocultar la alerta de envío y mostrar la de error
            setenviandoAlert(false);
            setAlertMessage('  Error de red. Por favor, inténtalo de nuevo más tarde.');
            setErrorAlertOpen(true);
        }
    };

    const backendURL = process.env.REACT_APP_BACKEND_URL;
    return (
    <Box sx={formStyle}>
        <form style={{width:'100%'}} onSubmit={handleSubmit}>
          <h5 className="tituloeditarsucempresa">Agregar Administrador a Sucursal: {sucursal.nombre_sucursal}</h5>
          <div className="input-crearadminsmartempresa">
            <label htmlFor='nombre'>Nombre</label>
            <div className='contieneinputempresaregister'>
            <input value={formData.nombre}  style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} onChange={handleInputChange} className="input-crear-empresa-adminsmart" name="nombre" id="nombre" />
            </div>
            {errors.nombre &&<div className="error-message"><BiErrorCircle style={{ color:"#D26999", fontSize:"1rem"}}/>{errors.nombre}</div>}
          </div>
          <div className="input-crearadminsmartempresa">
            <label htmlFor='apellido'>Apellido</label>
            <div className='contieneinputempresaregister'>
            <input value={formData.apellido}  style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} onChange={handleInputChange} className="input-crear-empresa-adminsmart" name="apellido" id="apellido" />
            </div>
            {errors.apellido && <div className="error-message"><BiErrorCircle style={{ color:"#D26999", fontSize:"1rem"}}/>{errors.apellido}</div>}
           </div>
           <div className="input-crearadminsmartempresa">
                <label htmlFor='telefono'>Teléfono</label>
                <div className='contieneinputempresaregister'>
                <input className="input-crear-empresa-adminsmart"  style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} value={formData.telefono} onChange={handleInputChange} name="telefono" id="telefono" />
                </div>
                {errors.telefono && <div className="error-message"><BiErrorCircle style={{ color:"#D26999", fontSize:"1rem"}}/>{errors.telefono}</div>}
            </div>
            <div className="input-crearadminsmartempresa">
                <label htmlFor='email'>Correo electrónico</label>
                <div className='contieneinputempresaregister'>
                <input type="email"  style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} className={`input-crear-empresa-adminsmart ${emailExistsError && 'input-error'}`} name="correo" id="correo" value={formData.correo} onChange={handleInputChange} />
                </div>
                {error && <div className="error-message"><BiErrorCircle style={{ color:"#D26999", fontSize:"1rem"}}/>{error}</div>}
            </div>
            <div className="input-crearadminsmartempresa">
                <label htmlFor='password'>Contraseña</label>
                <div className='contieneinputempresaregister'>
                    <input
                      style={{
                        background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                      }}
                        type={showPassword ? 'text' : 'password'}
                        className="input-crear-empresa-adminsmart"
                        value={formData.password}
                        onChange={handleInputChange}
                        name="password"
                        id="password"
                    />
                    <span className="toggleempresapassword" onClick={togglePasswordVisibility}>
                        {showPassword ?<FaRegEyeSlash  style={{
                      color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5'
                    }} /> : <FaRegEye   style={{
                        color: (UserDataMarca?.color_3 && UserDataMarca?.color_3 !== 'undefined') ? UserDataMarca?.color_3 : '#3f51b5'
                      }} />}
                    </span>
                </div>
                  {errors.password && <div className="error-message"><BiErrorCircle style={{ color:"#D26999", fontSize:"1rem"}}/>{errors.password}</div>}
            </div>
            <div className="input-crearadminsmartempresa">
                <label htmlFor='password_confirmation'>Confirmar Contraseña</label>
                <div className='contieneinputempresaregister'>
                    <input
                     style={{
                        background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                      }}
                        type={showPassword ? 'text' : 'password'}
                        className="input-crear-empresa-adminsmart"
                        value={formData.password_confirmation}
                        onChange={handleInputChange}
                        name="password_confirmation"
                        id="password_confirmation"
                    />
                    <span className="toggleempresapassword" onClick={togglePasswordVisibility}>
                        {showPassword ? <FaRegEyeSlash  style={{
                      color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5'
                    }} /> : <FaRegEye   style={{
                        color: (UserDataMarca?.color_3 && UserDataMarca?.color_3 !== 'undefined') ? UserDataMarca?.color_3 : '#3f51b5'
                      }} />}
                    </span>
                </div>
                {errors.password_confirmation &&<div className="error-message"><BiErrorCircle style={{ color:"#D26999", fontSize:"1rem"}}/>{errors.password_confirmation}</div>}
            </div>
            <div className="buttonsiguientecrearempresa">
                <button type="submit"  style={{
                      background: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5'
                    }}>Crear Administrador</button>
            </div>
        </form>
        <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
            <Box
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                height: 'auto',
                bgcolor: '#ffff',
                boxShadow: 24,
                p: 1,
                borderRadius: '10px',
                '@media (max-width: 500px)': {
                    width: '90%',
                },
                }}
            >
                <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
                <IoClose />
                </section>
                <section style={{ textAlign: 'center' }}>
                <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
                <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
                    {alertMessage}
                </p>
                </section>
            </Box>
        </Modal>
  
        {/* Popup de error */}
        <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
            <Box
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                height: 'auto',
                bgcolor: '#ffff',
                boxShadow: 24,
                p: 1,
                borderRadius: '10px',
                '@media (max-width: 500px)': {
                    width: '90%',
                },
                }}
            >
                <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
                <IoClose />
                </section>
                <section style={{ textAlign: 'center' }}>
                <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
                <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                    {alertMessage}
                </p>
                </section>
            </Box>
        </Modal>
        {/* Popup de carga */}
        <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
            <Box
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                height: 'auto',
                bgcolor: '#ffff',
                boxShadow: 24,
                p: 1,
                borderRadius: '10px',
                '@media (max-width: 500px)': {
                    width: '90%',
                },
                }}
            >
                <section style={{ textAlign: 'center', padding: '1rem' }}>
                <CircularProgress style={{ color: '#694B81' }} />
                <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                    Procesando solicitud...
                </p>
                </section>
            </Box>
        </Modal>
    </Box>
    );
  };

const formStyle = {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
fontFamily:'Poppins',
    marginTop:'0px'
};


const StyleAcordeon = {
    width:"100%",
}

const StyleBox ={
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
    flexDirection:'row',
    width:'100%',
}
const StyleBoxcontienelogo = {
     width:'100%',
     Padding:'0px 30px',
     display:'flex',
    
}
const StyleTitulo ={
    fontFamily:'Poppins',
    color: '#585857',
    fontWeight:'600'
}
const StyleBoxcontienecolor = {
    width:'100%',
    Padding:'0px 30px',
    display:'flex',
    flexDirection:'column',

}
