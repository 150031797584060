import React, { useEffect, useState, useRef  } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import { useNavigate, useParams, useLocation} from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { motion } from "framer-motion";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

/* matrial ui */
import {Container, Box, Button,Grid, CircularProgress,Modal, ListItemText, MenuItem, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

/* react icons */
import { FaRegPlayCircle } from "react-icons/fa";
import { ImSearch } from "react-icons/im";
import { PiScrollFill } from "react-icons/pi";
import { RxCalendar } from "react-icons/rx";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { RiFileEditFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { FiPlusCircle } from "react-icons/fi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { BsTrash3Fill } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";


const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 3000 });

export const IndexEditarMemorialHistorico = ({ selectedMemorial, onUpdateMemorial }) =>{
    const {Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const [memoriales, setMemoriales] = useState([]);
    const theme = useTheme();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [loading, setLoading] = useState(true); // Para manejar la carga de datos
    const [loadings, setLoadings] = useState(false); 
    const [expanded, setExpanded] = useState(false);
    const [openModalseliminar, setOpenModalseliminar] = useState(false);
    const [imagenPreview, setImagenPreview] = useState([]);
    /* edit nombre */
    const [nombreFamilia, setNombreFamilia] = useState('');
    /* galeria */
    const [imagenesData, setImagenesData] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [selectedImageId, setSelectedImageId] = useState(null); 
    const [modalOpen, setModalOpen] = useState(false);
    const [showCreateOnlyImageConfirmation, setShowCreateOnlyImageConfirmation] = useState(false);
    
         /* video */
    const [isPlaying, setIsPlaying] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // Controla el modo de edición
    const [videoLinkInput, setVideoLinkInput] = useState('');  // Input del video
    const [video, setVideo] = useState('');  // Video actual
    /* ubicacion */
    const isDragging = useRef(false);
    const [selectedPoint, setSelectedPoint] = useState({ top: 0, left:0 });
    const [mapImage, setMapImage] = useState(null);
    const [mapUpdated, setMapUpdated] = useState(false);
    /* modales */
    const [modalOpens, setModalOpens] = useState(false);
    const [progress, setProgress] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const[enviandoAlertprogress, setenviandoAlertprogress] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [formData, setFormData] = useState({
        nombre: '',
        titulo: '',
        descripcion: '',
        imagen: null
    });
    const [memorialData, setMemorialData] = useState({
        id: '',
        nombre: '',
        fecha_nacimiento: '', // Valor inicial vacío o cualquier otro valor válido
        fecha_defuncion: '',
        cementerio: '',
        carpeta_perfil: '',
        imagen_perfil: '',
        historia: '',
        video:'',
        mapa:'',
        top:'',
        left:'',
        galeria: []
        
    });


   /* editar memorial historico */
   const fetchMemoriales = async () => {
    if (!selectedMemorial?.id || !Token) return; 
    setLoading(true); // Puedes establecer esto aquí si deseas mostrar un indicador de carga.
    try {
      const response = await http.get(`${backendURL}/api/getMemorialesPorGrupoFamiliar/${selectedMemorial.id}`, {
        headers: {
          Authorization: `Bearer ${Token}`, // Incluir el token de autenticación
        },
      });
      const memorial = response.data[0];
      
      setMemoriales(response.data); // Guardar los memoriales en el estado
      setMapImage(memorial.mapa); // Asignar mapa
      
      if (memorial.top && memorial.left) {
        setSelectedPoint({ top: memorial.top, left: memorial.left });
      }
      if (memorial.map_image) {
        setMapImage(memorial.map_image); // Asume que la respuesta tiene la URL de la imagen
      }
      setMemorialData(response.data);
    } catch (error) {
      console.error('Error al obtener los memoriales', error);
    } finally {
      setLoading(false); // Finalizar la carga
    }
  };

  useEffect(() => {
    fetchMemoriales(); // Llamar a fetchMemoriales al cargar el componente
    
  }, [Token, selectedMemorial.id, backendURL]);

  useEffect(() => {
    if (memoriales && memoriales.length > 0) {
      // Puedes llamar a fetchMemoriales directamente si es necesario,
      // o crear otra función para usar con memoriale
    }
  }, [memoriales]);

  useEffect(() => {
    if (memoriales.length > 0) {
      const nuevasPreviews = memoriales.map((memorial) => 
        memorial.imagen_perfil
          ? `${backendURL}/imagenes/memoriales/${memorial.carpeta_perfil}/${memorial.imagen_perfil}`
          : null
      );
      setImagenPreview(nuevasPreviews);
    }
  }, [memoriales]);
  
  const handleFotoPerfilChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Actualiza la imagen en el estado de previsualización
        setImagenPreview(prevState => {
          const newState = [...prevState];
          newState[index] = reader.result; // Asigna la imagen al índice correspondiente
          return newState;
        });
  
        // Actualiza la imagen en el memorial seleccionado
        setMemoriales(prevMemoriales => {
          const updatedMemoriales = [...prevMemoriales];
          updatedMemoriales[index] = {
            ...updatedMemoriales[index],
            imagen_perfil: file // Guarda el archivo para enviar en formData
          };
          return updatedMemoriales;
        });
      };
      reader.readAsDataURL(file); // Carga la imagen como URL de datos
    }
  };
    // Función para manejar los cambios en los inputs del formulario
    const handleInputChanges = (e, index) => {
      const { name, value } = e.target;
      setMemoriales((prevMemoriales) =>
        prevMemoriales.map((memorial, i) =>
          i === index ? { ...memorial, [name]: value } : memorial
        )
      );
    };

    const handleQuillChange = (value, index) => {
      setMemoriales((prevMemoriales) =>
        prevMemoriales.map((memorial, i) =>
          i === index ? { ...memorial, historia: value } : memorial
        )
      );
    };

   
// Función para manejar la actualización de los datos cuando se presiona el botón
const handleUpdate = async (index) => {
  if (!memoriales || memoriales.length === 0 || !memoriales[index]) {
    setErrorAlertOpen(true);
    setAlertMessage('No se encontraron memoriales para actualizar.');
    return;
  }

  const memorialData = memoriales[index]; // Obtener los datos del memorial específico
  const formData = new FormData();
  
  const formatDate = (dateValue) => {
    const date = new Date(dateValue);
    // Si dateValue no es válida, retorna el valor original
    if (isNaN(date.getTime())) return dateValue;
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  // Itera sobre los datos del memorial y agrega los valores al formData
  Object.entries(memorialData).forEach(([key, val]) => {
    if (val !== null && val !== undefined) {
      if (key === 'fecha_nacimiento' || key === 'fecha_defuncion') {
        // Formatea el valor de la fecha antes de agregarlo
        formData.append(key, formatDate(val));
      } else {
        formData.append(key, val);
      }
    }
  });

  // Envío de la actualización
  try {
    setLoading(true);
    await axios.post(`${backendURL}/api/memorialhistoricoUpdatedemo/${memorialData.id}`, formData, {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'multipart/form-data', // Indicar que es multipart
      },
    });
    setLoading(false);
    setSuccessAlertOpen(true);
    setAlertMessage('Información actualizada correctamente.');
    await fetchMemoriales(memorialData.id); // Recarga los datos del memorial
  } catch (error) {
    setErrorAlertOpen(true);
    setAlertMessage('Error al actualizar el memorial. Intenta nuevamente.');
    setLoading(false);
  }
};

/* galeria */

  

const handleInputChange = (index, field, value) => {
    setImagenesData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: value,
      };
      return updatedData;
    });
  };
  const handleDescriptionButtonClick = (imagenId, index) => {
   
    const memorialData = memoriales[index]; 
    const selectedImageData = memorialData.galeria.find(img => img.id === imagenId); // Buscar la imagen por ID
   
    if (selectedImageData) { // Verificar si se encontró la imagen
      setSelectedImageId(imagenId); // Cambié a ID
      setImagenesData([{
        titulo: selectedImageData.titulo || '',
        descripcion: selectedImageData.descripcion || '',
        fecha: selectedImageData.fecha || ''
      }]);
      setModalOpen(true);
    } else {
      console.error('No se encontró la imagen con ID:', imagenId);
      // Aquí podrías mostrar un mensaje al usuario si lo deseas
    }
 
  };
  const handleInputChangesedit = (name, value) => {
    const updatedData = { ...imagenesData[0], [name]: value }; // Actualiza el primer elemento
    setImagenesData([updatedData]); // Asegúrate de que sea un array con un solo elemento
  };

  const handleEditImage = async () => {
    setLoading(true);
    try {
      setenviandoAlert(true);
      const updatedImageData = {
        id: selectedImageId,
        titulo: imagenesData[0]?.titulo || '',
        descripcion: imagenesData[0]?.descripcion || '',
        fecha: imagenesData[0]?.fecha || ''
      };
  
      const response = await axios.post(
        `${backendURL}/api/galeriaimagenUpdatedemo`,
        updatedImageData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`
          }
        }
      );
  
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue editada con éxito.');
      await fetchMemoriales(); // Actualizar memoriales después de editar
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al editar la imagen, vuelva a intentar.');
    } finally {
      setLoading(false);
      setenviandoAlert(false);
      setTimeout(() => {
        handleModalClose();
      }, 3000);
    }
  };
  

const handleImageChange = (e) => {
const { files } = e.target;
const imagenData = {
  titulo: '',
  descripcion: '',
  fecha: '',
  imagen: files[0]
};
setImagenesData([imagenData]);
setSelectedImageIndex(0);
setShowCreateOnlyImageConfirmation(true);
};

const handleAddImage = () => {
setImagenesData([...imagenesData, { titulo: '', descripcion: '', fecha: '', imagen: null }]);
};



const handleRemoveImage = async (imageId) => {
if (!imageId) {
  console.error("ID de imagen no proporcionado.");
  return; // Salir si no hay un ID válido
}

try {
    setenviandoAlert(true);
    
  setLoading(true)
    // Realiza la solicitud al backend para eliminar la imagen
    const response = await axios.post(
        `${backendURL}/api/galeriaDeletedemo`,
        { id: imageId },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Token}`
            }
        }
    );
    setLoading(false)
    setSuccessAlertOpen(true);
    setAlertMessage('Su imagen en galería fue eliminada con éxito.');
    await fetchMemoriales(); // Actualizar memoriales después de eliminar
} catch (error) {
    console.error('Error al eliminar la imagen:', error);
    setErrorAlertOpen(true);
    setAlertMessage('Error al eliminar la imagen, vuelva a intentar.');
} finally {
    setenviandoAlert(false);
    setTimeout(() => {
        handleCloseModal();
    }, 3000);
}
};



const handleCreateOnlyImageConfirmation = async (index) => {
if (!memoriales || memoriales.length === 0 || !memoriales[index]) {
  setErrorAlertOpen(true);
  setAlertMessage('No se encontraron memoriales para actualizar.');
  return;
}

const memorialData = memoriales[index]; 

const formData = new FormData();
formData.append('id_memorial', memorialData.id);

if (selectedImageIndex !== null) {
    const imagenData = imagenesData[selectedImageIndex];
    const formattedFecha = imagenData.fecha ? dayjs(imagenData.fecha, 'YYYY-MM-DD').format('YYYY/MM/DD') : '';
    formData.append('imagenes[0][imagen]', imagenData.imagen);
    formData.append('imagenes[0][titulo]', imagenData.titulo);
    formData.append('imagenes[0][descripcion]', imagenData.descripcion);
    formData.append('imagenes[0][fecha]', formattedFecha);
}

try {
    setenviandoAlert(true);
    await axios.post(`${backendURL}/api/galeriaStoreempresademo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${Token}`
        },
    });

    setSuccessAlertOpen(true);
    setAlertMessage('Su imagen en galeria fue creada con éxito.');
    await fetchMemoriales(); // Actualizar memoriales después de crear
} catch (error) {
    setErrorAlertOpen(true);
    setAlertMessage('Error al crear la imagen para galeria, vuelva a intentar.');
} finally {
    setenviandoAlert(false);
    setTimeout(() => {
        handleCloseModal();
    }, 3000);
}
};

const handleModalClose = () => {
  setModalOpen(false);
  setShowCreateOnlyImageConfirmation(false);
  setImagenesData([]);
  setSelectedImageIndex(null);
};

/* video  */
const handleAddOrEditVideo = async (index) => {
    // Asegúrate de que el index es válido
    if (typeof index === 'undefined') {
      console.error('No se recibió un index válido.');
      return;
    }
  
    // Obtener los datos del memorial correspondiente
    const memorialData = memoriales[index];
  
    if (!memorialData) {
      console.error('No se encontró el memorial para el index proporcionado.');
      return;
    }
  
    if (!videoLinkInput) {
      alert('Por favor ingrese un enlace de video.');
      return;
    }
  

  
    setLoading(true);
    try {
      const response = await axios.post(
        `${backendURL}/api/updateMemorialdemo/${memorialData.id}`,  // Usa el memorialId aquí
        { videoLink: videoLinkInput },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.video) {
        setVideo(response.data.video);
        setLoading(false);
        setSuccessAlertOpen(true);
        setAlertMessage('Video actualizado correctamente.');
        await fetchMemoriales(); // Asegúrate de actualizar los memoriales correctamente
      } else {
        alert('No se recibió una URL de video desde el servidor.');
      }
    } catch (error) {
      console.error('Error al actualizar el video:', error);
      setErrorAlertOpen(true);
      setAlertMessage('Hubo un error al actualizar el video.');
      setLoading(false);
    }
  }
/* ubicacion */
const handleMouseDown = (e) => {
  e.preventDefault();
  isDragging.current = true;
};

const handleMouseMove = (e) => {
  if (!isDragging.current || !mapImage) return;

  const imgRect = e.target.closest("img").getBoundingClientRect();
  const newLeft = ((e.clientX - imgRect.left) / imgRect.width) * 100;
  const newTop = ((e.clientY - imgRect.top) / imgRect.height) * 100;

  // Limitar valores entre 0 y 100
  setSelectedPoint({
    top: Math.min(100, Math.max(0, newTop)),
    left: Math.min(100, Math.max(0, newLeft)),
  });
};

const handleMouseUp = () => {
  if (!isDragging.current) return;
  isDragging.current = false;

  const memorialData = memoriales[0];
  // Guardar nueva ubicación en la base de datos
  axios
    .post(
      `${backendURL}/api/posicion/mapa/memorialhistorico/${memorialData.id}`,
      { top: selectedPoint.top, left: selectedPoint.left },
      { headers: { Authorization: `Bearer ${Token}` } }
    )
    .then(() => console.log(""))
    .catch((error) => console.error("Error al guardar ubicación:", error));
};

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const memorialData = memoriales[0];
    const formData = new FormData();
    formData.append("mapa", file);
    setLoading(true);
    try {
      const response = await axios.post(
        `${backendURL}/api/agregar/mapa/mwemorial/historico/${memorialData.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setLoading(false);
      setSuccessAlertOpen(true);
      setAlertMessage('el mapa se subio exitosamente.')
      handleModalClose();
      setMapImage(URL.createObjectURL(file)); // Mostrar la imagen inmediatamente
      setMapUpdated(true); // Actualizar el estado para refrescar los datos del memorial
    } catch (error) {
      setLoading(false);
      setErrorAlertOpen(true);
      setAlertMessage('Error al subir la imagen.',error);
    }
    finally {
      setLoading(false); // Ocultar el CircularProgress al finalizar el guardado de la imagen
      setenviandoAlert(false);
    }
  };

 

  
/* funciones de botones  */
    const handleCloseModal = () => {
        setOpenModal(false);
        setenviandoAlert(false);
        setenviandoAlertprogress(false);
    };

    const handleCloseModalseliminar = () => {
        setOpenModalseliminar(false);
    };

    const handleEliminarMemorial = async () => {
        try {
        
          setLoadings(true);
          setenviandoAlert(true);
          
          // Realizar la solicitud de eliminación con los encabezados correctamente configurados
          const response = await axios.post(
            `${backendURL}/api/eliminarMemorial/empresa/${memorialData.id}`, 
            {}, // Enviar un objeto vacío en lugar de datos en el cuerpo si no se requiere enviar datos
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Token}`, // Asegúrate de que sea Bearer {Token}
              }
            }
          );
      
          // Si la solicitud es exitosa
          setenviandoAlert(false);
          setLoadings(false);
          setOpenModalseliminar(false);
          setSuccessAlertOpen(true);
          setAlertMessage('El memorial fue eliminado con éxito.');
          window.location.reload();
      
          
        } catch (error) {
          // Manejo de errores
          setLoadings(false);
          setErrorAlertOpen(true);
          
          if (error.response && error.response.status === 401) {
            setAlertMessage('No autorizado. Por favor, verifica tu token de autenticación.');
          } else {
            setAlertMessage('Error al eliminar el memorial, vuelva a intentar.');
          }
      
          console.error('Error al eliminar el memorial:', error.response ? error.response.data : error.message);
        } finally {
          // Asegurarse de desactivar la alerta de envío en el bloque finally
          setenviandoAlert(false);
        }
      };
      
      useEffect(() => {
      
      // Aquí podrías hacer la llamada a la API para obtener la información de memoriales
        setTimeout(() => {
          setLoading(false);
        }, 2000); // Simulación de 2 segundos de carga
      }, [memoriales]);

      const handleEditClick = () => {
        setModalOpens(true); // Abre el modal después de hacer clic en editar
      };
  
      const handleCloseModaledit = () => {
        setModalOpens(false); // Cierra el modal
      };
      const handleNombreChange = (event) => {
        setNombreFamilia(event.target.value);
      };
      
      const handleGuardarNombre = async (event) => {
        event.preventDefault();
    
        try {
            setenviandoAlert(true);
            const response = await axios.post(
                `${backendURL}/api/updatedFamiliarNameEmpresaDemo/${selectedMemorial.id}`,
                { nombre_familia: nombreFamilia },
                {
                    headers: {
                        Authorization: `Bearer ${Token}` // Encabezado de autorización
                    }
                }
            );
            const updatedMemorial = { 
                ...selectedMemorial, 
                nombre_familia: nombreFamilia 
              };
          
              // Llama a la función onUpdateMemorial para actualizar el estado en el componente padre
              onUpdateMemorial(updatedMemorial);
            // Cierra el modal después de editar el nombre de la familia
            handleCloseModaledit();
            setenviandoAlert(false);
            setSuccessAlertOpen(true);
            setAlertMessage('Editado nombre familiar con éxito.');

            // Retardo antes de navegar
            
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al editar nombre familiar, vuelva a intentar.');
        } finally {
            // Cierra las alertas después de un pequeño retardo para permitir que se vean
            setTimeout(() => {
                setErrorAlertOpen(false);
                setSuccessAlertOpen(false);
            }, 2000); // 2000 ms = 2 segundos
        }
    };
  
  /* cargas */
if (loading) {
    // Mostrar CircularProgress mientras se carga la información
    return  (<Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" sx={{ flexDirection:'column', marginTop:'40px'}}>
     <Skeleton variant="rectangular" width="80%" height="100px" />
    <Skeleton variant="text" width="80%" height="100px" />
    <Skeleton variant="rectangular" width="80%" height="400px" />
  </Box>)
  }
  
    return(
        <Box>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" sx={{ flexDirection:'column'}}>
          <CircularProgress/>
      </Box> 
        ) : (
          <Box sx={{ marginTop: "0px",marginBottom:'100px' }}>
            {/* Mapear los memoriales y crear un Accordion para cada uno */}
            {memoriales.map((memorial, index) => (
               <>  
                <Typography sx={{
                    fontSize:'1.2rem',
                    fontFamily:'Poppins',
                    fontWeight:'500',
                                color:
                                  UserDataMarca?.color_1 &&
                                  UserDataMarca.color_1 !== "undefined"
                                    ? UserDataMarca.color_1
                                    : "#694B81",
                              }}>
                    {memorial.nombre} <FiEdit3 style={{cursor:'pointer'}} onClick={handleEditClick} />
                  </Typography>
 
                  <form className="formularioeditarperfilmemorialdemo">
                    <div className="tituloeditmemorialdemo">
                      <h2>Información del Memorial {memorial.nombre}</h2>
                    </div>
                    <Grid container><Grid item xs={12}>
                        <section className="contiene-input-imagen-perfil">
                          <label htmlFor={`file-input-${index}`} className="input-subir">
                            <input
                              id={`file-input-${index}`}
                              name="imagen_perfil"
                              type="file"
                              onChange={(e) => handleFotoPerfilChange(e, index)} // Maneja el cambio de imagen
                            />
                            {imagenPreview[index] ? (
                              <img
                                src={imagenPreview[index]} // Mostrar la vista previa de la nueva imagen seleccionada
                                alt={`Vista previa de la imagen de ${memorial.nombre}`}
                                style={{ maxWidth: '200px', maxHeight: '200px' }} // Ajusta el tamaño según sea necesario
                              />
                            ) : (
                              memorial.imagen_perfil && (
                                <img
                                  src={`${backendURL}/imagenes/memoriales/${memorial.carpeta_perfil}/${memorial.imagen_perfil}`} // Mostrar la imagen actual si no hay vista previa
                                  alt={`Imagen actual de ${memorial.nombre}`}
                                  style={{ maxWidth: '200px', maxHeight: '200px' }} // Ajusta el tamaño según sea necesario
                                />
                              )
                            )}
                            <div className="cambiar-foto">
                              <span className="cambiarfotoedit">
                                <p>Cambiar</p><p>foto</p>
                              </span>
                            </div>
                          </label>
                        </section>
                      </Grid>
                      <Grid item xs={12}>
                      <div className="inputregistorform">
                <label>Nombre del Memorial</label>
                <div className="inputnombreeditfallecido" style={{width:'100%'}}>
                  <input className="inputeditregistro " name="nombre"
                    value={memorial.nombre}
                    onChange={(e) => handleInputChanges(e, index)}
                    id="name" />
                </div>
              </div>
                      </Grid>
                      <Grid item xs={12}>
                         <div className="inputregistorform">
                                        <label htmlFor="editfecha">Fecha de Nacimiento</label>
                                        <div className="contienencalendarioperfil">
                                          <input
                                          style={{color: (UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined') ? UserDataMarca.color_2 :  '#694B81'}}
                                            type="date"
                                            className="inputeditregistro "
                                            name='fecha_nacimiento'
                                            value={memorial.fecha_nacimiento}
                                            onChange={(e) => handleInputChanges(e, index)}
                                            
                                          />
                         <div className="calendarioicono">
                                                    <RxCalendar className="iconoperfilcalendario"
                                                     style={{color: (UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined') ? UserDataMarca.color_2 :  '#694B81'}}/>
                                                    </div>
                                        </div>
                        
                                      </div>
                                      <div className="inputregistorform">
                                        <label htmlFor="editfecha">Fecha de Fallecimiento</label>
                                        <div className="contienencalendarioperfil">
                                          <input
                                            type="date"
                                            style={{color: (UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined') ? UserDataMarca.color_2 :  '#694B81'}}
                                            className="inputeditregistro "
                                            name='fecha_defuncion'
                                            value={memorial.fecha_defuncion}
                                            onChange={(e) => handleInputChanges(e, index)}
                                           
                                          />
                                          <div className="calendarioicono">
                                                    <RxCalendar className="iconoperfilcalendario"
                                                    style={{color: (UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined') ? UserDataMarca.color_2 :  '#694B81'}} />
                                                    </div>
                                        </div>
                        
                         </div>
                        <div className="inputregistorform">
                          <label>Cementerio </label>
                          <div className="inputeditcementerio" style={{width:'100%'}}>

                            <div>
                              <input
                                type='text'
                                className="inputeditregistro "
                                name="cementerio"
                                id="cementerio"
                                placeholder="Buscar cementerios"
                                value={memorial.cementerio}
                                onChange={(e) => handleInputChanges(e, index)}
                              />
                              <span className="buscadoredit" style={{right:'1%'}}>
                                <ImSearch />
                              </span>

                            </div>
                          </div>

                        </div>
                      </Grid>
                      
                      <Grid item xs={12}>
                        <section className="historiaeditcrear">
                            <div className="titulohitoriacrear">
                              <PiScrollFill />
                              <h1>Historia de {memorial.nombre}</h1>
                            </div>
                            <ReactQuill
                            className="inputedithistoria" 
                            placeholder="Escriba hechos importantes en la vida de su ser querido..."
                              value={memorial.historia}
                              onChange={(value) => handleQuillChange(value, index)}
                              modules={{
                                toolbar: [
                                  [],
                                  [],
                                  ['link'],
                                
                                ],
                              }}
                              
                            />
                          </section>
                      </Grid>
                    </Grid>
                    <div className="contienebotoneditarmemorialdemo">
                    <button type="button" className="BotonQragregarmemorial"  onClick={() => handleUpdate(index)}>
                      Actualizar Memorial
                    </button>
                    </div>
                  </form>
                  <Box>
        <form className="formularioeditarperfilmemorialdemo">
                    <div className="tituloeditmemorialdemo">
                      <h2>Multimedia del Memorial {memorial.nombre}</h2>
                    </div>
                    <Container maxWidth='sm'>
                        <section className="galeria-crear">
                          <Grid container className="cardgaleriatodo">
                          {memorial.galeria && memorial.galeria.length > 0 ? (
                                memorial.galeria.map((imagen, imagenindex) => {
                                  
                                  return (
                                    <Grid key={imagenindex} item xs={4} className="contienecardgaleria">
                                      <div className="cardfoto">
                                        <div className="inputsubir">
                                          {/* Muestra la imagen */}
                                          <img 
                                            src={`${backendURL}/${imagen.imagen}`} 
                                            alt={`Imagen ${imagenindex + 1}`} 
                                            className="imagen-seelcion-galeria" 
                                          />
                                          <div className="contieneicono">
                                            <FaArrowUpFromBracket className="imgicono" />
                                            <span>Subir Foto</span>
                                          </div>

                                          <div className="contienebotoneliminarfoto">
                                            <button 
                                              className="button-cambiar-close" 
                                              type="button" 
                                              onClick={() => handleRemoveImage(imagen.id)} 
                                            >
                                              <IoClose />
                                            </button>
                                          </div>
                                        </div>

                                        <button 
                                          type="button" 
                                          className="editar-imagen" 
                                          onClick={() => handleDescriptionButtonClick(imagen.id, index)}
                                        >
                                          <RiFileEditFill /> <p>Descripción</p> 
                                        </button>
                                      </div>
                                    </Grid>
                                  );
                                })
                                ) : (
                                  <Grid item xs={12} className="no-galeria">
                                    <p>No hay imágenes en la galería.</p>
                                  </Grid>
                                )}

                                <Grid item xs={4} className="contienecardgaleria">
                                  <div className="cardfoto">
                                    <div className="inputsubir">
                                      <input 
                                        type="file" 
                                        accept="image/*" 
                                        className="cardinputgaleria" 
                                        onChange={handleImageChange} 
                                      />
                                      <div className="contieneicono">
                                        <FaArrowUpFromBracket className="imgicono" />
                                        <span>Subir Foto</span>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>

                               

                            <Grid item xs={4} className="botonagregar" sx={{ marginBottom: '50px' }}>
                              <div className="cardnuevafoto">
                                <div className="inputnuevosubir">
                                  <button 
                                    type="button" 
                                    className="cardnuevafoto" 
                                    onClick={handleAddImage}
                                  > 
                                    <div className="agregaricono">
                                      <FiPlusCircle />
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </section>

                        {modalOpen && selectedImageId && (
                            <div className="modal">
                              <div className="modal-content">
                                <span className="close" onClick={handleModalClose}><IoClose style={{
                                    color:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",
                                }} /></span>
                                <div className="titulo-galeriadescripcion"> 
                                  <RiFileEditFill style={{
                                     color:
                                     UserDataMarca?.color_1 &&
                                     UserDataMarca.color_1 !== "undefined"
                                       ? UserDataMarca.color_1
                                       : "#694B81",
                                  }} />
                                  <h2 style={{
                                     color:
                                     UserDataMarca?.color_1 &&
                                     UserDataMarca.color_1 !== "undefined"
                                       ? UserDataMarca.color_1
                                       : "#694B81",
                                  }} >Agregar Descripción</h2>
                                </div>
                                <section className="aviso"> 
                                  <span> Si no desea agregar descripción, </span>
                                  <span> haga click en continuar.</span>
                                </section>
                                <section className="formdescripciongaleriaimagen">
                                  <div className="inputimagenespecifico">
                                    <label className="tituloinputfecha">Título</label>
                                    <input
                                    style={{width:'100%', background:'#ffff',
                                      color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",
                                    }}
                                      type="text"
                                      name="titulo"
                                      className="inputdescripimag"
                                      value={imagenesData[0]?.titulo || ''} // Accede al primer elemento
                                      onChange={(e) => handleInputChangesedit(e.target.name, e.target.value)}
                                    />
                                  </div>
                                  <div className="inputimagenespecifico">
                                    <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                      <label>Fecha de la foto</label>
                                    </div>
                                    <div className="inputfotocontainer">
                                      <input
                                       style={{width:'100%', color:
                                        UserDataMarca?.color_1 &&
                                        UserDataMarca.color_1 !== "undefined"
                                          ? UserDataMarca.color_1
                                          : "#694B81",}}
                                        type="date"
                                        className="inputefotofecha"
                                        name='fecha'
                                        value={imagenesData[0]?.fecha || ''} // Accede al primer elemento
                                        onChange={(e) => handleInputChangesedit(e.target.name, e.target.value)}
                                      />
                                      <div className="calendarioediticono">
                                        <RxCalendar className="iconoeditcalendario" style={{   color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="inputimagenespecifico">
                                    <label className="tituloinputfecha">Descripción</label>
                                    <textarea
                                     style={{width:'100%', background:'#ffff',  color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}}
                                      name="descripcion"
                                      className="inputdescripitodo"
                                      value={imagenesData[0]?.descripcion || ''} // Accede al primer elemento
                                      onChange={(e) => handleInputChangesedit(e.target.name, e.target.value)}
                                    />
                                  </div>
                                </section>
                                <section className="botonguardarimagen">
                                  <button 
                                  style={{
                                    background:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",
                                  }}
                                    className="guardarimagen" 
                                    type="button" 
                                    onClick={handleEditImage}
                                  >
                                    {loading ? (<CircularProgress color="inherit"/>) : ("Continuar")}
                                  </button>
                                </section>
                              </div>
                            </div>
                          )}

                        {showCreateOnlyImageConfirmation && (
                          <div className="modal">
                            <div className="modal-content">
                              <span className="close" onClick={handleModalClose}><IoClose style={{   color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}} /></span>
                              <div className="titulo-galeriadescripcion"> 
                                <RiFileEditFill  style={{   color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}}/>
                                <h2 style={{   color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}}>Agregar Descripción</h2>
                              </div>
                              <section className="aviso"> 
                                <span> Si no desea agregar descripción, </span>
                                <span> haga click en continuar.</span>
                              </section>
                              <section className="formdescripciongaleriaimagen">
                                <div className="inputimagenespecifico">
                                  <label className="tituloinputfecha">Título</label>
                                  <input
                                  style={{ 
                                    width:'100%',
                                    background:'#ffff',
                                    color:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",}}
                                    type="text"
                                    name="titulo"
                                    className="inputdescripimag"
                                    value={imagenesData[selectedImageIndex]?.titulo || ''}
                                    onChange={(e) => handleInputChange(selectedImageIndex, 'titulo', e.target.value)}
                                  />
                                </div>
                                <div className="inputimagenespecifico">
                                  <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <label>Fecha de la foto</label>
                                  </div>
                                  <div className="inputfotocontainer">
                                    <input
                                     style={{ 
                                      color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}}
                                      type="date"
                                      className="inputefotofecha"
                                      name='fecha'
                                      value={imagenesData[selectedImageIndex]?.fecha || ''}
                                      onChange={(e) => handleInputChange(selectedImageIndex, 'fecha', e.target.value)}
                                    />
                                    <div className="calendarioediticono">
                                      <RxCalendar className="iconoeditcalendario"  style={{ 
                                    color:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",}} />
                                    </div>
                                  </div>
                                </div>
                                <div className="inputimagenespecifico">
                                  <label className="tituloinputfecha">Descripción</label>
                                  <textarea
                                   style={{ 
                                    width:'100%',
                                    background:'#ffff',
                                    color:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",}}
                                    name="descripcion"
                                    className="inputdescripitodo"
                                    value={imagenesData[selectedImageIndex]?.descripcion || ''}
                                    onChange={(e) => handleInputChange(selectedImageIndex, 'descripcion', e.target.value)}
                                  />
                                </div>
                              </section>
                              <section className="botonguardarimagen">
                                <button 
                                 style={{ 
                                  background:
                                  UserDataMarca?.color_1 &&
                                  UserDataMarca.color_1 !== "undefined"
                                    ? UserDataMarca.color_1
                                    : "#694B81",}}
                                  className="guardarimagen" 
                                  type="button" 
                                  onClick={() => handleCreateOnlyImageConfirmation(index)}
                                >
                                  {loading ? (<CircularProgress color="inherit"/>) : ("Continuar")}
                                </button>
                              </section>
                            </div>
                          </div>
                        )}
                      </Container>
                      <Container maxWidth='md' sx={{ marginTop: '30px' }}>
                      <section className="seccion-video-crear">
                        <div className="videocontiene">
                        {memorial.video && !isEditing ? (
                              <div>
                                <ReactPlayer
                                  url={memorial.video}
                                  playing={false}
                                  controls={true}
                                  width="100%"
                                  height="600px"
                                  playIcon={<FaRegPlayCircle />}
                                />
                                <Button
                                  onClick={() => {
                                    setIsEditing(true);
                                    setVideoLinkInput('');  // Limpiar el campo de input al entrar en modo edición
                                  }}
                                >
                                  Editar video
                                </Button>
                              </div>
                            ) : (
                              <div className="card-video">
                                <div className="no-video-message">
                                  <div className="inputsubir">
                                    <input
                                      id="videoLinkInput"
                                      name="videoLink"
                                      className="cardinputgaleria" 
                                      type="text"
                                      placeholder="Ingresa el enlace del video"
                                      value={videoLinkInput}
                                      onChange={(e) => setVideoLinkInput(e.target.value)} // Actualiza el valor del input
                                    />
                                    <div className="contienevideoicono" onClick={() => handleAddOrEditVideo(index)}>
                                      <FaArrowUpFromBracket className="imgicono" />
                                      <span>Pega el link del video</span>
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  sx={{ marginTop: '10px' }}
                                  onClick={() => {
                                    handleAddOrEditVideo(index);  // Asegúrate de pasar el index aquí también
                                    setIsEditing(false);  // Vuelve al modo de visualización
                                  }}
                                >
                                  {video ? 'Actualizar video' : 'Agregar video'}
                                </Button>
                              </div>
                            )}
                        </div>
                      </section>
                    </Container>
                    
                    </form>
                    <form className="formularioeditarperfilmemorialdemo">
                    {loading ? (
        <CircularProgress />
      ) : memorial?.mapa ? (
        <Box sx={{ position: "relative", display: "inline-block", mt: 2 }}>
    <img
      src={`${backendURL}/${memorial.mapa}`}
      alt="Mapa"
      style={{ width: "100%", maxWidth: "400px", cursor: "pointer" }}
      onMouseMove={handleMouseMove}
    />
    {/* Icono de PersonPin con animación */}
    <motion.div
      animate={{ top: `${selectedPoint.top}%`, left: `${selectedPoint.left}%` }}
      transition={{ type: "spring", stiffness: 100 }}
      style={{
        position: "absolute",
        transform: "translate(-50%, -50%)",
        cursor: "grab",
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={() => (isDragging.current = false)}
    >
      <PersonPinIcon sx={{ fontSize: 50, color: "#3f9bdb" }} />
      {memorial?.imagen_perfil && (
        <img
          src={`${backendURL}/imagenes/memoriales/${memorial.carpeta_perfil}/${memorial.imagen_perfil}`}
          alt="Memorial"
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            border: "2px solid white",
          }}
        />
      )}
    </motion.div>
  </Box>
      ) : (
        <Box
                    width='100%'
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      height: 200,
                      border: "1px dashed rgba(0, 0, 0, 0.2)",
                      borderRadius: 8,
                      padding: 2,
                      backgroundColor: "#fefefe",
                    }}
                  >
                    <WarningAmberIcon
                      sx={{
                        fontSize: 40,
                        color: "#FF9800",
                        marginBottom: 1,
                      }}
                    />
                    <Typography variant="h6" color="textSecondary">
                      No hay mapa seleccionado
                    </Typography>
                  </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="map-upload"
        />
        <label htmlFor="map-upload">
          <Button variant="contained" component="span"
            sx={{
                                    ...botonStyle,
                                    backgroundColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : '#694B81',
                                    '&:hover': {
                                        backgroundColor: UserDataMarca?.color_2 && UserDataMarca.color_2 !== "undefined"
                                            ? UserDataMarca.color_2
                                            : '#694B81',
                                    }
                                }}>
            Subir Mapa
          </Button>
        </label>
      </Box>

      <Box sx={{ mt: 2,mb:3 }}>
        <Typography variant="body2" sx={textStyle}>
        Posición seleccionada: Top {isNaN(selectedPoint.top) ? 0 : parseFloat(selectedPoint.top).toFixed(2)}%, Left {isNaN(selectedPoint.left) ? 0 : parseFloat(selectedPoint.left).toFixed(2)}%
        </Typography>
      </Box>

                    </form>
        </Box>
               </>  

                  ))}
          </Box>
                  )}
             <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
      <Modal open={enviandoAlertprogress} onClose={() => setenviandoAlertprogress(false)}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '350px',
      height: 'auto',
      bgcolor: '#ffff',
      boxShadow: 24,
      p: 1,
      borderRadius: '10px',
      '@media (max-width: 500px)': {
        width: '90%',
      },
    }}
  >
    <section style={{ textAlign: 'center', padding: '1rem' }}>
      <CircularProgressWithLabel value={progress} style={{ color: '#694B81' }} />
      <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
        Procesando solicitud...
      </p>
    </section>
  </Box>
</Modal>

      <Modal
        open={modalOpens}
        onClose={handleCloseModaledit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: '#ffff', border: '2px solid #585857', borderRadius: "5px", boxShadow: 24, p: 0,  '@media (max-width: 500px)': {
                width: '90%',
                
              },
              '@media (max-width: 325px)': {
                width: '90%',
               

              } }}>
          <div className="botoncerrar">  <Button onClick={handleCloseModaledit}><IoClose /></Button></div>
          <div className="cardeditnombrefamilia">
            <div className="tituloeditarfamilia">
            
              <RiFileEditFill style={{ color: '#694B81' }} className="iconoeditarnombrefamilia" />
           
            <h2 className="labeleditarnombre">Editar Nombre</h2></div>
            <div className="inputlabelfamilia">
              <form  className="formularioeditarnombre">
              <label>Nombre Empresa</label>
              
                <input  style={{width:'100%',
                                background:theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                              }} type="text" name="nombre" placeholder="ej: Lon Cristos" value={nombreFamilia} onChange={handleNombreChange} />
                <button className="buttonguardareditarfamilia" onClick={handleGuardarNombre}> Guardar</button>
              </form>

            </div>
          </div> 
        </Box>
      </Modal>
      <Modal
        open={openModalseliminar}
        onClose={handleCloseModalseliminar}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius: "10px", '@media (max-width: 500px)': {
            width:'80%', p:2
          } }}>
          <div className="tituloeliminarmensaje">
            <h2 id="modal-title">¿Eliminar memorial?</h2>
          </div>
          <div className="informacioneliminarmemorial"> <span>Se eliminará toda la información
de este memorial</span></div>
          <div className="contienetodoslosbotonesediteliminar">
            
            <button className="sieli"  onClick={handleEliminarMemorial}>{loadings ?( <CircularProgress color="inherit"/>):("Si eliminar")}</button>
          <button className="noeli" onClick={handleCloseModalseliminar}>No eliminar</button>
          </div>
        </Box>
      </Modal>
       
    </Box>
    )
}
function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };
  
  export default function CircularWithValueLabel() {
    const [progress, setProgress] = useState(10);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);
  
    return <CircularProgressWithLabel value={progress} />;
  }
  
  
const textStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'1rem',
    fontWeight:'600'
}


const textFieldStyle = {
    fontFamily: 'Poppins',
    color: '#585857' // Cambia el color del texto ingresado
};

const labelStyle = {
    color: '#585857', // Cambia el color del label
    fontWeight: '600'
};

const StyleBox ={
    width:'100%',
    border:'1px solid #F0EDF3',
    marginTop:'30px',
    borderRadius:'10px',
    p:2,
    background:'#F0EDF3'
}

const StyleBoximagen = {
    width:"20%",
   display:'flex',
   alignItems:'center',
   justifyContent:'center',
  
}
const StyleBoxtitulo = {
   display:'flex',
   alignItems:'center',
   justifyContent:'flex-start',

}
const StyleBoxtitulos = {
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
    flexDirection:'column'
 
 }

 const StyleBoxbotones = {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'row'
 
 }
 const StyleAcordeon = {
    width:"100%",
    
}
const StyleTitulo ={
    fontFamily:'Poppins',
    color: '#585857',
    fontWeight:'600'
}
const StyleButton = {
  background:'#D26999',
  fontFamily:'Poppins',
  color:'#fff',
  fontSize:'1rem',
  marginTop:'10px'
}

const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
  color: "#585857",
  fontFamily: "Poppins",
  fontSize: "0.8rem",
  '& .MuiTypography-root': {
    fontSize: "0.8rem",
  }

}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '0.7rem',
  '& .MuiTypography-root': {
    fontSize: '0.8rem',
    fontFamily: 'Poppins', // Aplica el fontFamily a la raíz de la tipografía
  },
}));

const botonStyle = {
  backgroundColor: "#694B81",
  color: "#fff",
  padding: "10px 20px",
  fontSize: "14px",
  fontWeight: "bold",
  borderRadius: "30px",
  textTransform: "none",
  transition: "all 0.3s ease-in-out",
  '&:hover': {
      backgroundColor: "#563A67",
      transform: "scale(1.05)",
  },
}

