import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../../../../Style/usuario/crear-familiar.css";
import "../../../../../../Style/usuario/galeriayvideo.css";
/* elmentos */
import { FaRegPlayCircle } from "react-icons/fa";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { RxCalendar } from "react-icons/rx";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { FaPhotoVideo } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import dayjs from 'dayjs';
import { RiFileEditFill } from "react-icons/ri";
import { Container, Modal } from "@mui/material";
import { FiPlusCircle } from "react-icons/fi";
import ReactPlayer from 'react-player';
import { CircularProgress } from '@mui/material';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { Button } from '@mui/material';


/* imagenes */
import editar from "../../../../../../Image/generales/descripcion-foto.png";




export const GaleriaMemorialDemo = ({ memorialId,  grupoFamiliar, Token, onNextStep }) => {

    const [showCreateOnlyImageConfirmation, setShowCreateOnlyImageConfirmation] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // Estado para controlar la visibilidad del modal
    const [imagenesData, setImagenesData] = useState([{ titulo: '', descripcion: '', fecha: '', imagen: null }]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null); // Estado para almacenar el índice de la imagen seleccionada
    const [loading, setLoading] = useState(false);
    const [showAlertcrear, setShowAlertcrear] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [memorialData, setMemorialData] = useState({});
    const [progress, setProgress] = useState(0);
    const[enviandoAlertprogress, setenviandoAlertprogress] = useState(false);
    ;
    /* video */
    const [video, setVideo] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false); // Estado para controlar la reproducción
    const [videoLinkInput, setVideoLinkInput] = useState(''); 
    
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const handleclickcontinuar  = () => {
        setSuccessAlertOpen(false);
        if (memorialId) {
            onNextStep(memorialId); // Pasa el memorialId al siguiente paso
        } else {
            setErrorAlertOpen(true);
            setAlertMessage("No se pudo obtener el ID del memorial.");
        }
      }

    useEffect(() => {
        if (grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length === 1) {
          setShowAlertcrear(true);
        } else {
          setShowAlertcrear(false);
        }
      }, [grupoFamiliar]);

     /*  memorial */ 
  const fetchMemorialData = async (id) => {
    try {
      const response = await axios.get(`${backendURL}/api/getMemorialIddemo/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Token}`,
        }
      });
      setMemorialData(response.data);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching memorial data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemorialData(memorialId);
  }, []);
      /* galeria */

  const handleInputChange = (index, name, value) => {
    const list = [...imagenesData];
    list[index][name] = value;
    setImagenesData(list);
  };

  const handleDescriptionButtonClick = (index) => {
    setSelectedImageIndex(index);
    const selectedImageData = memorialData.galeria[index];
    setImagenesData([{
      titulo: selectedImageData.titulo || '',
      descripcion: selectedImageData.descripcion || '',
      fecha: selectedImageData.fecha || ''
    }]);
    setModalOpen(true);
  };

  const handleEditImage = async () => {
    setLoading(true);
    try {
      setenviandoAlert(true);
      // Obtiene el ID de la imagen a editar
      const imageId = memorialData.galeria[selectedImageIndex].id;

      // Construye el objeto con los datos actualizados
      const updatedImageData = {
        id: imageId,
        titulo: imagenesData[0]?.titulo || '',
        descripcion: imagenesData[0]?.descripcion || '',
        fecha: imagenesData[0]?.fecha || ''
      };
      setLoading(true);
      // Envía una solicitud POST al backend para actualizar la imagen
      await axios.post(
        `${backendURL}/api/galeriaimagenUpdatedemo`,
        updatedImageData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`
          }
        }
      );

      setLoading(false);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue editada con éxito.')
      handleModalClose();
      await fetchMemorialData(memorialId);
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al editar la imagen, vuelva a intentar.',error);
    }
    finally {
      setLoading(false); // Ocultar el CircularProgress al finalizar el guardado de la imagen
      setenviandoAlert(false);
    }
    // Simulación de tiempo de carga
 setTimeout(() => {
  handleCloseModal();
}, 3000);
// Simulación de tiempo de carga
setTimeout(() => {
  setSuccessAlertOpen(false);
  setErrorAlertOpen(false);
}, 2000);
  };

  const handleImageChange = (e) => {
    const { files } = e.target;
    const imagenData = {
      titulo: '',
      descripcion: '',
      fecha: '',
      imagen: files[0]
    };
    setImagenesData([imagenData]);
    setSelectedImageIndex(0);
    setShowCreateOnlyImageConfirmation(true);
  };

  const handleAddImage = () => {
    setImagenesData([...imagenesData, { titulo: '', descripcion: '', fecha: '', imagen: null }]);
  };

  const handleRemoveImage = async (index) => {
    try {
      setenviandoAlert(true);
      // Obtiene el ID de la imagen a eliminar
      const imageId = memorialData.galeria[index].id;

      // Envía una solicitud POST al backend para eliminar la imagen
      await axios.post(
        `${backendURL}/api/galeriaDeletedemo`,
        { id: imageId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`
          }
        }
      );
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue eliminada con éxito.')
      await fetchMemorialData(memorialId);
      // Elimina la imagen del estado imagenesData
      const list = [...imagenesData];
      list.splice(index, 1);
      setImagenesData(list);
     
    } catch (error) {
     
      setErrorAlertOpen(true);
      setAlertMessage('Error al eliminar la imagen, vuelva a intentar.',error);
    }
    finally{
      setenviandoAlert(false);
 }
  // Simulación de tiempo de carga
  setTimeout(() => {
      handleCloseModal();
  }, 3000);
  // Simulación de tiempo de carga
  setTimeout(() => {
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
  }, 2000);
  };


  const handleCreateOnlyImageConfirmation = async () => {
    
    setLoading(true);

    const formData = new FormData();
    formData.append('id_memorial', memorialId);

    // Agregar imagen al formulario
    if (selectedImageIndex !== null) {
      const imagenData = imagenesData[selectedImageIndex];
      const formattedFecha = imagenData.fecha ? dayjs(imagenData.fecha, 'YYYY-MM-DD').format('YYYY/MM/DD') : '';
      formData.append('imagenes[0][imagen]', imagenData.imagen);
      formData.append('imagenes[0][titulo]', imagenData.titulo);
      formData.append('imagenes[0][descripcion]', imagenData.descripcion);
      formData.append('imagenes[0][fecha]', formattedFecha);
    }

    try {
      setenviandoAlert(true);
      setLoading(true);
      const responseGaleria = await axios.post(`${backendURL}/api/galeriaStoreempresademo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${Token}`
        },
      });
      await fetchMemorialData(memorialId);
      setLoading(false);
      handleModalClose(true);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue creado con éxito.')
     
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al crear la imagen para galeria, vuelva a intentar.');
      setLoading(false)
    }
    finally{
      setenviandoAlert(false);
      setLoading(false)
      
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModal();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
}

  const handleModalClose = () => {
    setModalOpen(false);
    setShowCreateOnlyImageConfirmation(false);
    setImagenesData([]);
    setSelectedImageIndex(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setenviandoAlert(false);
    setenviandoAlertprogress(false);
  };

  /* video */
  const handleAddOrEditVideo = async () => {
    if (!videoLinkInput) {
      alert('Por favor ingrese un enlace de video.');
      return;
    }
    setLoading(true);
    try {
      // Enviar la URL del video en un objeto JSON
      const response = await axios.post(
        `${backendURL}/api/updateMemorialdemo/${memorialData.id}`,
        { videoLink: videoLinkInput }, // Aquí se envía la URL del video
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json' // No es necesario usar multipart si solo es un string
          }
        }
      );
  
      // Actualizar el estado del video en el frontend con la URL recibida del servidor
      if (response.data.video) {
        setVideo(response.data.video);
        setLoading(false);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Video actualizado correctamente.')
       
      } else {
        alert('No se recibió una URL de video desde el servidor.');
      }
    } catch (error) {
      console.error('Error al actualizar el video:', error);
      setErrorAlertOpen(true);
      setAlertMessage('Hubo un error al actualizar el video..',error);
    }
  };
  


    return (
        <Container className="contieneregistro" component="main" maxWidth="md">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                   
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div className="pasosmemorial">
                    <div className="titulopasos"><h1>Paso Multimedia:</h1>
                      <span>Agregue  fotos y videos en su galería del memorial</span>
                    </div>
                  </div>
                  
                 

                  {showAlertcrear && (
        <div className="alertaeditarmemorial">
          <BiErrorCircle />
          <div className="infoalertamemorial">
            <h4>Si usted no posee la información necesaria en este</h4>
            <h4>momento, puede editar el memorial más tarde</h4>
          </div>
        </div>
      )}

              <div className="titulogaleriavideofinal">
                <div className="titulohitoriacrear">
                  <FaPhotoVideo />
                  <h1>Galería</h1>
                </div>
              </div>
              <section className="contienesubtitulofoto">
              <div className="titulofotocrear">
                <h1>Fotos</h1>
              </div>
              </section>

              <section className="galeria-crear">
                <Grid container >
                  {memorialData.galeria && memorialData.galeria.map((imagen, index) => (
                    <Grid key={index} item xs={4} className="contienecardgaleria">
                      <div className="cardfoto">
                        <div className="inputsubir">
                          {/* Muestra la imagen */}
                          <img src={`${backendURL}/${imagen.imagen}`} alt={`Imagen ${index + 1}`} className="imagen-seelcion-galeria" />
                          <div className="contieneicono">
                            <FaArrowUpFromBracket className="imgicono" />
                            <span>Subir Foto</span>
                          </div>
                           <div className="contienebotoneliminarfoto">
                        <button className="button-cambiar-close" type="button" onClick={() => handleRemoveImage(index)}>
                          <IoClose />
                        </button>
                        </div>
                        </div>
                       
                        <button type="button" className="editar-imagen" onClick={() => handleDescriptionButtonClick(index)}>
                        <RiFileEditFill/>  <p>Descripción</p> 
                        </button>
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={4} className="contienecardgaleria">
                    <div className="cardfoto">
                      <div className="inputsubir">

                        <input type="file" accept="image/*" className="cardinputgaleria" onChange={handleImageChange} />


                        <div className="contieneicono">
                          <FaArrowUpFromBracket className="imgicono" />
                          <span>Subir Foto</span>
                        </div>
                      </div>


                    </div>
                  </Grid>
                  {imagenesData.map((imagenData, index) => (
                    <Grid key={index} item xs={4} className="contienecardgaleria">
                      <div className="cardfoto">
                        <div className="inputsubir">

                          <input type="file" accept="image/*" className="cardinputgaleria" onChange={handleImageChange} />

                          {imagenData.imagen && (
                            <img src={URL.createObjectURL(imagenData.imagen)} alt="Smart Memorial" className='imagen-seelcion-galeria' />
                          )}
                          <div className="contieneicono">
                            <FaArrowUpFromBracket className="imgicono" />
                            <span>Subir Foto</span>
                          </div>
                        </div>
                        {imagenData.imagen && ( // Condición para mostrar los botones solo si hay una imagen seleccionada
                          <>
                           <div className="contienebotoneliminarfoto">
                            <button className="button-cambiar-close" type="button" onClick={() => handleRemoveImage(index)}>
                              <IoClose />
                            </button></div>
                            <button type="button" className="editar-imagen" onClick={() => handleDescriptionButtonClick(index)}>
                              <p>Descripción</p> <img src={editar} alt="Smart Memorial" />
                            </button>
                          </>
                        )}

                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={4} className="botonagregar">
                    <div className="cardnuevafoto">
                      <div className="inputnuevosubir">
                        <button type="button" className="cardnuevafoto" onClick={handleAddImage}> <div className="agregaricono">
                          <FiPlusCircle />
                        </div>
                        </button>
                      </div>
                    </div></Grid>



                </Grid>
              </section>

              {modalOpen && selectedImageIndex !== null && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={handleModalClose}><IoClose /></span>
                    <div className="titulo-galeriadescripcion"> < RiFileEditFill />
                      <h2>Agregar Descripción</h2>
                    </div>
                    <section className="aviso"> <span> Si no desea agregar descripción </span> <span>haga click en continuar.</span></section>
                    <section className="formdescripciongaleriaimagen">
                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Título</label>
                        <input
                          type="text"
                          name="titulo"
                          className="inputdescripimag"
                          value={imagenesData[0]?.titulo || ''}
                          onChange={(e) => handleInputChange(0, e.target.name, e.target.value)}
                        />
                      </div>
                      <div className="inputimagenespecifico">
                        <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                          <label>Fecha de la foto</label>
                          
                        </div>
                        <div className="inputfotocontainer">
                          <input
                            type="date"
                            className="inputefotofecha"
                            name='fecha'
                            value={imagenesData[0]?.fecha || ''}
                            onChange={(e) => handleInputChange(0, e.target.name, e.target.value)}
                          />
                       <div className="calendariofotoicono">
        <RxCalendar className="iconofotolcalendario" />
        </div>
                        </div>
                      </div>
                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Descripción</label>
                        <textarea
                          name="descripcion"
                          className="inputdescripitodo"
                          value={imagenesData[0]?.descripcion || ''}
                          onChange={(e) => handleInputChange(0, e.target.name, e.target.value)}
                        />
                      </div>
                    </section>
                    <section className="botonguardarimagen">
                    <button className="guardarimagen">    {loading ? (
    <CircularProgress size={24} color="inherit" /> // Mostrar el CircularProgress si se está guardando la imagen
  ) : (
    <div onClick={handleEditImage}>Continuar</div> // Renderizar el botón "Guardar" normalmente
  )}</button>
                      
                    </section>
                  </div>
                </div>
              )}

              {showCreateOnlyImageConfirmation && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={handleModalClose}><IoClose /></span>

                    <div className="titulo-galeriadescripcion"> < RiFileEditFill />
                      <h2>Agregar Descripción</h2></div>
                      <section className="aviso"> <span> Si no desea agregar descripción </span> <span>haga click en continuar.</span></section>
                    <section className="formdescripciongaleriaimagen">
                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Título</label>
                        <input
                          type="text"
                          name="titulo"
                          className="inputdescripimag"
                          value={imagenesData[selectedImageIndex]?.titulo || ''}
                          onChange={(e) => handleInputChange(selectedImageIndex, 'titulo', e.target.value)}
                        />
                      </div>

                      <div className="inputimagenespecifico">
                        <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} ><label>Fecha de la foto</label> </div>
                        <div className="inputfotocontainer">
                          <input
                            type="date"
                            className="inputefotofecha"
                            name='fechaNacimiento'
                            value={imagenesData[selectedImageIndex]?.fecha || ''}
                            onChange={(e) => handleInputChange(selectedImageIndex, 'fecha', e.target.value)}

                          />
<div className="calendariofotoicono">
        <RxCalendar className="iconofotolcalendario" />
        </div>
                        </div>
                      </div>

                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Descripción</label>
                        <textarea
                          name="descripcion"
                          className="inputdescripitodo"
                          value={imagenesData[selectedImageIndex]?.descripcion || ''}
                          onChange={(e) => handleInputChange(selectedImageIndex, 'descripcion', e.target.value)}
                        />
                      </div>

                      <section className="botonguardarimagen">
                      <div className="guardarimagen">
  {loading ? (
    <CircularProgress size={24} color="inherit" /> // Mostrar el CircularProgress si se está guardando la imagen
  ) : (
    <div onClick={handleCreateOnlyImageConfirmation}>Continuar</div> // Renderizar el botón "Guardar" normalmente
  )}
</div>
                      </section>
                    </section>
                  </div>
                </div>
              )}
    <section className="videoeditargaleria">
  <div className="titulovideoedit">
    <h1 style={{fontSize:'1.3rem', marginBottom:'10px'}}>Videos</h1>
    <div className="avisodevideo">
    
    </div>
  </div>
  
  
  {showSuccessMessage && (
    <div className={`successmessagecorrect ${showSuccessMessage ? 'show' : ''}`}>
      <FaRegCircleCheck /><p>¡El video se ha subido correctamente!</p>
    </div>
  )}

  <Modal
    open={uploading}
    aria-labelledby="uploading-modal-title"
    aria-describedby="uploading-modal-description"
  >
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="background.paper"
        p={4}
        borderRadius={1}
        boxShadow={3}
      >
        <CircularProgress color="secondary" />
      </Box>
    </Box>
  </Modal>
  
  <section className="seccion-video-crear">
  <div className="videocontiene" >
      {video ? (
        <div>
          <ReactPlayer
            url={video}
            playing={isPlaying}
            controls={true}
            width="100%"
            height="600px"
            playIcon={<FaRegPlayCircle />}
          />
          <Button 
          onClick={() => {
            setVideo('');         // Limpia el video
            setVideoLinkInput(''); // Limpia el input
          }}
        >
          Editar video
        </Button> {/* Al hacer clic, se vuelve a mostrar el input para editar */}
        </div>
      ) : (
        <>
        <div className="card-video" >
        <div className="no-video-message">
            <div className="inputsubir">
          <input
            id="videoLinkInput"
            name="videoLink"
             className="cardinputgaleria" 
            type="text"
            placeholder="Ingresa el enlace del video"
            value={videoLinkInput}
            onChange={(e) => setVideoLinkInput(e.target.value)} // Actualiza el valor del input
          />
          <div className="contienevideoicono" onClick={handleAddOrEditVideo}>
                <FaArrowUpFromBracket className="imgicono" />
                <span>Pega el link del video</span>
              </div>
         {/* Este botón agrega o edita el video */}
        </div>
        </div>
        </div>
        
         <Button sx={StyleButton} onClick={handleAddOrEditVideo} >Agregar video</Button>
         </>
      )}
      </div>
    </section>
</section>
</Box>

<div className="botonesgaleriayvideo">
                        <div className="siguiientevideoygaleria" >
                          <button  onClick={handleclickcontinuar} >Siguiente</button>
                        </div>
                      </div>
                    
                <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

       {/* Popup de carga */}
       <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
      <Modal open={enviandoAlertprogress} onClose={() => setenviandoAlertprogress(false)}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '350px',
      height: 'auto',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 1,
      borderRadius: '10px',
      '@media (max-width: 500px)': {
        width: '90%',
      },
    }}
  >
    <section style={{ textAlign: 'center', padding: '1rem' }}>
      <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
        Procesando solicitud...
      </p>
    </section>
  </Box>
</Modal>
</Container>

                      
     
    );
}


const StyleButton = {
  background:'#D26999',
  fontFamily:'Poppins',
  color:'#fff',
  fontSize:'1rem',
  marginTop:'10px'
}