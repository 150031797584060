import React from "react";
import { useState, useEffect } from 'react'
import { useUsers } from "../../../context/userscontexts";
import { useGrupoFamiliar } from "../../../context/estadofamiliar";
import { useNavigate, useParams, Link } from "react-router-dom";
import "../../../../Style/usuario/obituario.css"
import { Iconos} from "../../../../data/dataicono";



/* elemento */
import axios from 'axios';
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Container, Modal, CircularProgress } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';
import Chip from '@mui/material/Chip';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";

/* icono */
import { RxCalendar } from "react-icons/rx";
import { LuClock2 } from "react-icons/lu";



export const Obituarioeditar = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const {ClientToken} = useUsers();
    const { grupoFamiliar } = useGrupoFamiliar();
    const[obituario, setObituario] = useState(null)
    const [logoUrl, setLogoUrl] = useState('');
    const [link, setLink] = useState(null);
    const [loading, setLoading] = useState(false);
    const [memorialData, setMemorialData] = useState({});
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const {userData} = useUsers();
    const [edad, setEdad] = useState(null);
    const [genero, setGenero] = useState('');
    const [selectedChips, setSelectedChips] = useState([]);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [formData, setFormData] = useState({
      descripcion: '',
      direccion: '',
      estado: '',
      fecha_desde: '',
      fecha_funeral: '',
      fecha_hasta: '',
      genero: '',
      hora_funeral: '',
      hora_salida: '',
      icono: '',
      id_memorial: '',
      parentesco: [],
      diseno:'2'
    });
  
    const fetchObituarios = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/obtenerObituarioid/${id}`);
            setObituario(response.data.obituario);
            setFormData(response.data.obituario);
            const idMemorial = response.data.obituario.id_memorial;
            if (idMemorial) {
                fetchMemorialData(idMemorial);
            }
        } catch (error) {
            console.error('Error fetching obituarios:', error);
        }
    };
  
    useEffect(() => {
        fetchObituarios();
    }, [id, backendURL]);
    
  const fetchMemorialData = async (idMemorial) => {
    try {
        const response = await axios.get(`${backendURL}/api/getMemorialIdGeneral/${idMemorial}`);
        setMemorialData(response.data);
       
        const fechaNacimiento = new Date(response.data.fecha_nacimiento);
        const fechaDefuncion = new Date(response.data.fecha_defuncion);
        const calculatedEdad = calcularEdad(fechaNacimiento, fechaDefuncion);
        
        setEdad(calculatedEdad);
        setLoading(false);

      
    } catch (error) {
        console.error('Error fetching memorial data:', error);
        setLoading(false);
    }
};



useEffect(() => {
    const fetchFuneraria = async () => {
        try {
            if (grupoFamiliar && grupoFamiliar.id_funeraria) {
                const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
                const funerariaData = response.data;
                
                setLink(funerariaData.web);setLogoUrl(funerariaData.logo);
                if (funerariaData.nombre === 'smart memorial') {
                    setLogoUrl(null); // No mostrar logo
                } else if (funerariaData.logo) {
                    
                } else {
                    setLogoUrl(null); // No hay logo disponible
                } 
            }
        } catch (error) {
            console.error('Error fetching funeraria data:', error);
        }
    };

    fetchFuneraria();
}, [grupoFamiliar]);

const calcularEdad = (fechaNacimiento, fechaDefuncion) => {
    let edad = fechaDefuncion.getFullYear() - fechaNacimiento.getFullYear();
    const mes = fechaDefuncion.getMonth() - fechaNacimiento.getMonth();

    if (mes < 0 || (mes === 0 && fechaDefuncion.getDate() < fechaNacimiento.getDate())) {
        edad--;
    }

    return edad;
};
  /* form editar */
  useEffect(() => {
    if (obituario) {
      const initialGenero = obituario.genero || '';
      const initialParentesco = typeof obituario.parentesco === 'string'
        ? JSON.parse(obituario.parentesco)
        : obituario.parentesco ? obituario.parentesco.split(',') : [];
  
      setGenero(initialGenero);
      setFormData({
        descripcion: obituario.descripcion || '',
        direccion: obituario.direccion || '',
        estado: obituario.estado || '',
        fecha_desde: obituario.fecha_desde || '',
        fecha_funeral: obituario.fecha_funeral || '',
        fecha_hasta: obituario.fecha_hasta || '',
        genero: initialGenero,
        hora_funeral: obituario.hora_funeral || '',
        hora_salida: obituario.hora_salida || '',
        icono: obituario.icono || '',
        id_memorial: obituario.id_memorial || '',
        parentesco: initialParentesco,
        diseno:'2'
      });
  
      // Sincronizar los chips seleccionados con el género inicial
      const initialParentescoOptions = initialGenero === 'Masculino' ? parentescoMasculino : parentescoFemenino;
      setSelectedChips(initialParentesco.filter(chip => initialParentescoOptions.includes(chip)));
    }
  }, [obituario]);
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
console.log(memorialData)
  
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Crear un nuevo objeto FormData y agregar los campos
    const formDataToSend = new FormData();
    formDataToSend.append('descripcion', formData.descripcion || '');
    formDataToSend.append('direccion', formData.direccion || '');
    formDataToSend.append('estado', formData.estado || '');
    formDataToSend.append('fecha_desde', formData.fecha_desde || '');
    formDataToSend.append('fecha_funeral', formData.fecha_funeral || '');
    formDataToSend.append('fecha_hasta', formData.fecha_hasta || '');
    formDataToSend.append('genero', formData.genero || '');
    formDataToSend.append('hora_funeral', formData.hora_funeral || '');
    formDataToSend.append('hora_salida', formData.hora_salida || '');
    formDataToSend.append('icono', formData.icono || '');
    formDataToSend.append('id_memorial', memorialData.id);
    
  
  
    // Convertir el array parentesco a una cadena JSON si no está vacío
    formDataToSend.append('parentesco', JSON.stringify(formData.parentesco) || '[]');

    setenviandoAlert(true);
   
    // Enviar la solicitud PUT usando axios
    try {
      await axios.post(`${backendURL}/api/editar-obituario/${id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${ClientToken.token}`,
        },
      });
      
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Obituario editado correctamente.');
  
    // Retardo antes de navegar
    setTimeout(() => {
      navigate('/usuariomemoriales', { state: { user: ClientToken, familia: grupoFamiliar } });
  }, 2000);
    } catch (error) {
      if (error.response) {
        setErrorAlertOpen(true);
        setAlertMessage('Error al actualizar obituario');
      } else {
       
        setErrorAlertOpen(true);
        setAlertMessage('Error al actualizar obituario');
      }
    }
    finally {
      // Cierra las alertas después de un pequeño retardo para permitir que se vean
      setTimeout(() => {
          setErrorAlertOpen(false);
          setSuccessAlertOpen(false);
      }, 2000); 
  };
}
  const handleGeneroChange = (event) => {
    const newGenero = event.target.value;
  
    // Actualiza el estado del género y limpia los chips seleccionados
    setGenero(newGenero);
    setFormData((prevData) => ({
      ...prevData,
      genero: newGenero,
      parentesco: [] // Limpiar parentesco cuando se cambia el género
    }));
  
    // Actualiza los chips seleccionados de acuerdo al nuevo género
    const newParentesco = newGenero === 'Masculino' ? parentescoMasculino : parentescoFemenino;
    setSelectedChips(newParentesco.filter((chip) => selectedChips.includes(chip)));
  };
  
  // Función para manejar el clic en un chip
  const handleChipClick = (label) => {
    // Actualiza el estado de los chips seleccionados
    const newSelectedChips = selectedChips.includes(label)
      ? selectedChips.filter((chip) => chip !== label)
      : [...selectedChips, label];
  
    // Actualiza el estado del formulario
    setSelectedChips(newSelectedChips);
    setFormData((prevData) => ({
      ...prevData,
      parentesco: newSelectedChips,
    }));
  };
  
  // Listas de parentesco según el género
  const parentescoMasculino = [
    'Papá',
    'Hermano',
    'Esposo',
    'Abuelo',
    'Tío',
    'Hijo',
    'Primo',
    'Nieto',
  ];
  
  const parentescoFemenino = [
    'Madre',
    'Hermana',
    'Esposa',
    'Abuela',
    'Tía',
    'Hija',
    'Prima',
    'Nieta',
  ];
  
  const parentesco = genero === 'Masculino' ? parentescoMasculino : parentescoFemenino;

const styles = {
titleStyle: {
  color: (() => {
    switch (memorialData?.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
    }
  })(),
},
fondomensajeStyle: {
  background: (() => {
    switch (memorialData?.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
    }
  })(),
},
fondosStyle: {
  background: (() => {
    switch (memorialData?.fondo) {
      case 'naturalezaAustral':
        return '#F3F8F1';
      case 'atardecerVerano':
        return '#FDF5EF';
      default:
        return (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :'#F0EDF3';
    }
  })(),
},
titulooscuroStyle: {
  color: (() => {
    switch (memorialData?.fondo) {
      case 'naturalezaAustral':
        return '#27632D';
      case 'atardecerVerano':
        return '#A04424';
      default:
        return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#D26999';
    }
  })(),
},
texto: {
  color: "#fff"
},
selectedIconStyle: {

  cursor: "pointer",
  width: '40px',
  height: '40px',
  color: '#fff',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: (() => {
    switch (memorialData?.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
    }
  })(),
},
iconStyle: {
  cursor: "pointer",
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  background: 'transparent',  // Aquí está el error corregido
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: (() => {
    switch (memorialData?.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
    }
  })(),
},

};



  

    return(
        <>
          <Container component="main" maxWidth="xs">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent: 'center',
            alignItems: 'center'

          }}
        >
          <section className="titulopaginacrearobituario">
            <h1 className="tituloobituariocrear" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Editar obituario</h1>
            <span className="informacionobituariocrear">Se mostrará una ventana emergente con</span>
            <span className="informacionobituariocrear">la información al ingresar en el memorial</span>
            <span className="informacionobituariocrear">de su ser querido</span>
          </section>
          <section className="formulariocrearobituario">
            <form onSubmit={handleSubmit} >
              <section className="seccionform">
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Aviso del desceso</h3>
                <div className="inputformobituario">
                  <textarea
                  style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                    placeholder="Escriba información para quienes deseen asistir al velatorio y/o funeral..."
                    className="inputdescriopcionformcrearobituario"
                     type="text"
                      name="descripcion"
                      value={formData.descripcion}
                onChange={handleChange}

                     />
                </div>
                
                <div className="inputformgenero">
          <label htmlFor='genero'>Género</label>
          <div className='contieneinputgenero'>
            <StyledSelect
              name='genero'
              id='genero'
              sx={{ textAlign: "left" }}
              value={formData.genero}
              onChange={handleGeneroChange}
              className="input-select-registro"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value="">
                <em>Seleccionar género...</em>
              </MenuItem>
              <MenuItem value={"Masculino"}>Masculino</MenuItem>
              <MenuItem value={"Femenino"}>Femenino</MenuItem>
            </StyledSelect>
          </div>
        </div>
      </section>

      {formData.genero && (
      <section className="seccionform">
        <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Título de parentesco</h3>
        <span className="infosuvtitulo">Puedes seleccionar varias opciones</span>

        <div className="contienechip">
          {parentesco.map((label) => (
            <Chip
              key={label}
              label={label}
              onClick={() => handleChipClick(label)}
              sx={{
                margin: '10px',
                fontSize: '0.8rem',
                fontWeight: '500',
                fontFamily: 'Poppins',
                backgroundColor: selectedChips.includes(label)
                ? (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81'
                : (userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 : '#E1DBE6',
              color: selectedChips.includes(label)
                ? 'white'
                : (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81',
              '&:hover': {
                backgroundColor: selectedChips.includes(label)
                  ? (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81'
                  : (userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 : '#E1DBE6',
              },
              }}
              variant={selectedChips.includes(label) ? 'default' : 'outlined'}
            />
          ))}
        </div>
      </section>
    )}
              <section  className="seccionform">
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Velatorio</h3>
                <div className="inputformobituario">
                  <label htmlFor="direccion" className="labelfromobituario"> Dirección del lugar</label>
                  <input type="text" id="direccion" 
                  name="direccion" 
                  style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                  className="inputcreaobituario"
                  value={formData.direccion}
                  onChange={handleChange} />
                </div>
               
                <Grid container  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
      flexDirection: "column",
      maxWidth:"100%"  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                  <Grid item xs={12}> 
                     <label htmlFor="direccion" className="labelfromobituario">
                       Fecha
                     </label>
                  </Grid>
                  <Grid item xs={6}  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
     maxWidth:"100%",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                    <div className="inputcrearobituario">
                    <div className="contieneninputfechaobituariocrear">
                    <input  type="date" 
                    style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                      id="fecha"
                      name="fecha_desde"
                      value={formData.fecha_desde}
                      onChange={handleChange}
                       className="inputcreaobituario" 
                        />
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario"
                      style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                    </div>
                  </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
     maxWidth:"100%",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                    <div className="inputcrearobituario">
                    <div className="contieneninputfechaobituariocrear">
                    <input
                    style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                      type="date" 
                      id="fecha" 
                     className="inputcreaobituario" 
                     name="fecha_hasta"
                     value={formData.fecha_hasta}
                     onChange={handleChange}
                    
                    />
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario"
                      style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                    </div>
                  </div>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section  className="seccionform">  
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Cortejo fúnebre</h3>
                <div>
                  <label htmlFor="direccion" className="labelfromobituario"> Hora de salida </label>
                  <div className="inputcrearobituario">
                      <div className="contienenrelojcrearobituario">
                        <input  className="inputcreahorariodosobituario"
                        style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                          placeholder="Desde.."
                          type="time"
                          name="hora_salida"
                          value={formData.hora_salida}
                          onChange={handleChange}
                        />
                        <div className="relojiobituariocono">
                          <LuClock2 className="iconoeventoobituariocalendario" 
                          style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}/>
                        </div>
                      </div>
                    </div>
                </div>

              </section>
              <section  className="seccionform">
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Funeral</h3>
               
                <Grid container sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
      flexDirection: "column",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>

                  <Grid item xs={6}  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
      maxWidth:"100%",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                    <div className="conitnenetodoobedit" >
                      <label htmlFor="direccion" className="labelfromobituario"> Fecha</label>
                      <div className="contieneninputfechaobituariodoscrear">
                    <input
                    style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                      type="date" 
                      id="fecha" 
                       className="inputcreaobituario"
                       name="fecha_funeral"
                          value={formData.fecha_funeral}
                          onChange={handleChange}
                         />
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario"
                      style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                    </div>
                  </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
     maxWidth:"100%",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                    <div   className="conitnenetodoobedit" >
                      <label htmlFor="direccion" className="labelfromobituario"> Hora</label>
                      <div className="inputcrearobituario">
                      <div className="contienenrelojcrearobituario">
                        <input  className="inputcreahorarioobituario"
                        style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                          placeholder="Desde.."
                          type="time"
                          name="hora_funeral"
                          value={formData.hora_funeral}
                          onChange={handleChange}
                        />
                        <div className="relojiobituariocono">
                          <LuClock2 className="iconoeventoobituariocalendario"
                          style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                        </div>
                      </div>
                    </div>
                    </div>
                  </Grid>
                </Grid>

              </section>

              <section  className="seccionform">
                <div className="contieneiconoobitiario">
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}> Seleccione un icono</h3>
                <SelectItem
                style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                className="seleciondefondofinal"
                IconComponent={KeyboardArrowDownIcon}
                name="icono"
                value={formData.icono}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Seleccione un icono' }}
                renderValue={(selected) => {
                  const selectedIconData = Iconos.find(icono => icono.value == selected);
                  return selectedIconData ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img
                        src={selectedIconData.src}
                        alt="Icon Preview"
                        style={{ width: '32px', height: '32px', objectFit: 'contain' }}
                      />
                    </div>
                  ) : (
                    <span>Seleccione un icono</span>
                  );
                }}
               >
          {Iconos.map((icono) => (
        
        <CustomMenu key={icono.value} value={icono.value}>
          <img
            src={icono.src}
            alt={icono.alt}
            style={{ width: '40px', height: '40px', objectFit: 'contain', marginRight: '10px' }}
          />
        </CustomMenu>
  
      ))}
         
      </SelectItem>
                </div>
        
              
              

              </section>

             
              <section className="buttonovituario">
              <button className="previsualizacion-obituario" type="submit" style={{
  border: (() => {
    switch (memorialData.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
    }
  })(),
  backgroundColor: (() => {
    switch (memorialData.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
    }
  })()
}} >
  Actualizar
          </button>
              </section>

            </form>
          </section>

        </Box>
      </Container>
    
      <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
        </>

    )
}

/* diseño material iu elemento */

  
  const SelectItem = styled(Select)(({ theme }) => ({
    width:'40%',
    marginLeft:'10px',
    borderRadius:'50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    '@media (max-width: 310px)': {
      
      width: '70%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '70%',
    },
    
  }));
  
  // Estilo personalizado para el contenedor del menú
  const CustomMenu = styled(MenuItem)(({ theme }) => ({
    
  }));
  
  
  
  const StyledSelect = styled(Select)(({ theme }) => ({
    padding: '1px 13px',
    color:'#694B81',
    position:'relative',
    fontFamily: "Poppins",
    width:'95%',
    height:'55px',
    fontSize:'0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize:'0.7rem',
      width: '100%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '100%',
    },
    
  }));