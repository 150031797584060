import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../../../../../../Style/AdministradorSuperEmpresa/adminmemorialempresapagina.css"
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { EditSucursalEMpresa } from "../../FuncionesEmpresaAdmi/editarsucursal";
import { EditSucursalInfoEmpresa } from "../../FuncionesEmpresaAdmi/editarinfosucursal";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import EmpresaLayout from "../../../../../../EmpresaLayout";
/* libreria UI */
import {
  Modal,
  Box,
  CircularProgress,
  Grid, Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { IoIosClose } from "react-icons/io";

// Configuración de axios-rate-limit
const https = rateLimit(axios.create(), {
  maxRequests: 2, // Número máximo de solicitudes permitidas
  perMilliseconds: 1000, // Tiempo en milisegundos
});


export const SucursalesEmpresaEspecifico = ({ setShowNavbarFooter }) => {
  const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [AllQr, setAllQr] = useState([])
  const { sucursales } = location.state || {};
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [usuariosPorSucursal, setUsuariosPorSucursal] = useState({});
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedSucursal, setSelectedSucursal] = useState(null);
  const [selectedUsuarios, setSelectedUsuarios] = useState([]);
  const [openEditsucuralModal, setOpenEditsucursalModal] = useState(false);
  const [selectedSucursaledit, setSelectedSucursaledit] = useState(null);
  const [ModalInactivos, setModalInactivos] = useState(false);

  useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
      setShowNavbarFooter(true);
    };
  }, [setShowNavbarFooter]);



  // Fetch usuarios y sucursales
  const fetchUserAndSucursal = async () => {
    if (!sucursales || !Token) return;
    setLoading(true); // Muestra el estado de carga
    try {
      const response = await https.get(`${backendURL}/api/getUserId/empresa/${sucursales.id}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setUsuariosPorSucursal(response.data || []); // Asegúrate de que sea un arreglo
      setUsuarios(response.data || []);
    } catch (error) {
      console.error("Error al obtener usuario y sucursal:", error);
    } finally {
      setLoading(false); // Oculta el estado de carga
    }
  };

  // Fetch grupos familiares
  const fetchGruposFamiliares = async () => {
    try {
      const response = await https.get(`${backendURL}/api/empresa/grupos/familiares/index/${Empresa.id}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setAllQr(response.data);
    } catch (error) {
      console.error("Error al obtener grupos familiares:", error);
    }
  };

  const handleToggleUserStatus = async (userId) => {
    try {
      const response = await axios.get(`${backendURL}/api/usuarios/${userId}/estado`, {
        headers: {
          Authorization: `Bearer ${Token}` // Si es necesario
        }
      });
      await fetchUserAndSucursal()
      // Actualiza la UI según sea necesario
    } catch (error) {
      console.error('Error al actualizar el estado del usuario:', error.response ? error.response.data : error.message);
      // Manejar errores según sea necesario
    }
  };
  // Efecto para obtener datos iniciales
  useEffect(() => {
    fetchUserAndSucursal();
    fetchGruposFamiliares();
  }, [sucursales?.id, Empresa?.id, Token]);

  const usuariosActivos = usuarios.filter((u) => u.estado == 1);
  const usuariosInactivos = usuarios.filter((u) => u.estado == 0);

  const handleOpenEditModal = (usuario) => {
    setSelectedSucursal(usuario);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedSucursal(null);
    setSelectedUsuarios([]);
  };
  
  const handleOpenEditsucursalModal = (sucursal) => {
    setSelectedSucursaledit(sucursal);
    setOpenEditsucursalModal(true);
  };

  const handleCloseEditsucursalModal = () => {
    setOpenEditsucursalModal(false);
    setSelectedSucursaledit(null);

  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }



  return (
    <EmpresaLayout>
      <Container maxWidth='lg'>
    

        <Box sx={{ width: '100%', marginBottom: '50px', marginTop: '50px' }} >
          <Grid container sx={{ width: '100%', display: 'flex' }}>
            <Grid item xs={6}>
              <Typography variant="h5" gutterBottom sx={{ fontSize: '1.4rem', fontWeight: '600', color: '#585857' }}>
                Sucursal {sucursales?.Sucursal}
              </Typography>
              <Typography variant="h5" gutterBottom sx={{ fontSize: '1rem', fontWeight: '500', color: '#694B81' }}>
                Total QR generados {sucursales?.QR}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <button className="botoncrearmemorilaempresa" onClick={() => handleOpenEditsucursalModal(sucursales)}> Editar Sucursal  <IconButton >
                        <EditIcon  />
                      </IconButton>
              </button>
            </Grid>
            <Grid item xs={3}>
            <button
            style={{...buttoninactivos,  background: UserDataMarca?.color_1 ? UserDataMarca.color_1 : '#D26999',
              color:'#ffff', border:'1px solid', borderColor: UserDataMarca?.color_1 ? UserDataMarca.color_1 : '#D26999'}}
              onClick={() => setModalInactivos(true)}
            >
              Administradores Inactivos
              <IconButton>
              <PersonIcon sx={{color:'#ffff'}} />
              </IconButton>
            </button>
            
            </Grid>
          </Grid>
          

        </Box>

          <Typography variant="h5" gutterBottom>
            Administradores Activos
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell>Avatar</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Teléfono</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usuariosActivos.map((usuario) => (
                  <TableRow key={usuario.id}>
                    <TableCell>
                    <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar {...stringAvatar(`${capitalizeFirstLetter(usuario.name)} ${capitalizeFirstLetter(usuario.apellido)}`)} />
                   
                  </Stack>
                    </TableCell>
                    <TableCell>
                      {usuario.name} {usuario.apellido}
                    </TableCell>
                    <TableCell>{usuario.email}</TableCell>
                    <TableCell>{usuario.telefono}</TableCell>
                    <TableCell>
                    <IOSSwitch checked={usuario.estado == 1} onChange={(event) => handleToggleUserStatus(usuario.id, event.target.checked)} />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => { handleOpenEditModal(usuario) }}>
                        <EditIcon  />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
       
  
        <Modal open={openEditModal} onClose={handleCloseEditModal} aria-labelledby="edit-sucursal-modal" aria-describedby="edit-sucursal-form">
          <Box sx={style}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <div className="editusuariosucursal">
                <IoIosClose style={{ fontSize: '2.3rem' }} onClick={handleCloseEditModal} />
              </div>
            </Box>
            <Box>
              <EditSucursalEMpresa usuario={selectedSucursal} onclose={handleCloseEditModal} onUserUpdated={fetchUserAndSucursal} />
            </Box>
          </Box>
        </Modal>
        <Modal open={openEditsucuralModal} onClose={handleCloseEditsucursalModal} aria-labelledby="edit-sucursal-modal" aria-describedby="edit-sucursal-form">
          <Box sx={style}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <div className="editusuariosucursal">
                <IoIosClose style={{ fontSize: '2.3rem' }} onClick={handleCloseEditsucursalModal} />
              </div>
            </Box>
            <Box>
              <EditSucursalInfoEmpresa sucursal={selectedSucursaledit} onclose={handleCloseEditsucursalModal} />
            </Box>
          </Box>
        </Modal>
        <Modal open={ModalInactivos} onClose={() => setModalInactivos(false)}>
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width:'60%',
          bgcolor: "#ffff",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display:'flex',
          justifyContent:'center',
          flexDirection:'column',
          alignItems:'center'
        }}>
          <Box sx={{
            width:'100%',
            display:'flex',
            justifyContent:"flex-end"
          }}>
          <div className="editusuariosucursal">
                <IoIosClose style={{ fontSize: '2.3rem' }} onClick={() => setModalInactivos(false)} />
              </div>
          </Box>
          <Typography variant="h6" gutterBottom sx={{color: UserDataMarca?.color_2 ? UserDataMarca.color_2 : '#D26999', mb:4}}>
            Administradores Inactivos
          </Typography>
          {usuariosInactivos.length > 0 ? (
                  usuariosInactivos.map((usuario) => (
                    <Box key={usuario.id} mb={2} sx={{ display:'flex', fontSize:'0.9rem'}}>
                      <TableRow key={usuario.id} sx={{color: UserDataMarca?.color_2 ? UserDataMarca.color_2 : '#D26999', borderColor: UserDataMarca?.color_2 ? UserDataMarca.color_2 : '#D26999', border:'1px solid'}}>
                    <TableCell>
                    <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar {...stringAvatar(`${capitalizeFirstLetter(usuario.name)} ${capitalizeFirstLetter(usuario.apellido)}`)} />
                   
                  </Stack>
                    </TableCell>
                    <TableCell sx={{color: UserDataMarca?.color_2 ? UserDataMarca.color_2 : '#D26999'}}>
                      {usuario.name} {usuario.apellido}
                    </TableCell>
                    <TableCell sx={{color: UserDataMarca?.color_2 ? UserDataMarca.color_2 : '#D26999'}}>{usuario.email}</TableCell>
                    <TableCell sx={{color: UserDataMarca?.color_2 ? UserDataMarca.color_2 : '#D26999'}}>{usuario.telefono}</TableCell>
                    <TableCell >
                    <IOSSwitch checked={usuario.estado == 1} onChange={(event) => handleToggleUserStatus(usuario.id, event.target.checked)} />
                    </TableCell>
                    <TableCell >
                      <IconButton onClick={() => { handleOpenEditModal(usuario) }}>
                        <EditIcon sx={{color: UserDataMarca?.color_2 ? UserDataMarca.color_2 : '#D26999'}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                    </Box>
                  ))
                ) : (
                  <Typography sx={{color: UserDataMarca?.color_1 ? UserDataMarca.color_1 : '#D26999',
                    background:'#ffff',}}>No hay administradores inactivos</Typography>
                )}
        </Box>
      </Modal>
      </Container>
    </EmpresaLayout>
  );
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}


const style = {
  fontFamily: 'Poppins',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: '#ffff',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
  height: '600px',
  overflowY: 'auto'
};
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const buttoninactivos = {
  fontFamily:'Poppins',
  fontSize:'0.8rem',
  borderRadius:'50px',
  padding:'10px 20px',
  fontWeight:'600',
  textAlign:'center',

}