import React, { useState } from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import { IoIosArrowBack } from "react-icons/io";
import { FiAlertCircle } from "react-icons/fi";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import "../../Style/generales/recetcon.css";
import { Nuevacontraseña } from "../Log/contraseña/nuevacontraseña";



export const Restaurarcontraseña = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const handleNextPage = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${backendURL}/api/resetPassword`, { email });

            

            if (response.status === 200 && response.data.message) {
                setError("");
                setCurrentPage(currentPage + 1);
            } else {
                setError("Correo no corresponde o no existe");
            }
        } catch (error) {
            setError("Correo no corresponde o no existe");
        } finally {
            setLoading(false);
        }
    };

    const handleInputFocus = () => {
        setError("");
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                }}
            >
               
                            <Box>
                                <Link to="/">
                                    <IoIosArrowBack className="arrowatras" />
                                </Link>
                            </Box>

                            <Box>
                                <section className="titulorecuperarcontraseña">
                                    <h1>Recuperar contraseña</h1>
                                </section>
                                
                                 <section className={`pagina-${currentPage}`}>
                    {currentPage === 1 && (
                        <>
                                <form onSubmit={handleNextPage} style={{width:"100%"}}>
                                    <section className="verificacioncorreorecuperacion">
                                        <div className="contieneverificar">
                                            <label className="tituloinputverificar" htmlFor="email">Ingrese su correo electrónico</label>
                                            <input
                                                type="text"
                                                className={`inuptverificarcorreo ${error ? 'error' : ''}`}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                onFocus={handleInputFocus}
                                            />
                                            <div className="errorgenerado">
                                                {error && <span className="errorverificarmessage"> <FiAlertCircle className='iconserror' />{error}</span>}
                                            </div>
                                        </div>
                                    </section>
                                    <section className="contienebottonesrecet">
                                        <button type="submit">Confirmar</button>
                                    </section>
                                </form>   </>
                    )}
                    {currentPage === 2 && (
                        <Nuevacontraseña email={email} />
                    )}
                </section>
                            </Box>
                     
            </Box>
            <Modal
                open={loading}
                aria-labelledby="loading-modal-title"
                aria-describedby="loading-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <CircularProgress />
            </Modal>
        </Container>
    );
}