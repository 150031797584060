import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet';
import { CircularProgress} from "@mui/material";
import { Navbar } from "../nav-fotter/navbar";
import { FiAlertCircle } from "react-icons/fi";
import { MdVerifiedUser } from "react-icons/md";
import { UserEmpresaMarca } from "./registropartdos";
import { Modal, Typography, Button} from "@mui/material";
import { IoIosClose } from "react-icons/io";

export const RegistroUsuarioMarca =({setShowNavbarFooter}) => {
    const { id } = useParams();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [marcas, setMarcas] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [codigo, setCodigo] = useState('');
    const [suscripcion, setSuscripcion] = useState([]);
    const [error, setError] = useState(null);
    const [step, setStep] = useState(1);
    const [modalVisible, setModalVisible] = useState(false);

  

    useEffect(() => {
        // Ocultar el navbar y footer al montar el componente
        setShowNavbarFooter(false);
        return () => {
            // Mostrar el navbar y footer al desmontar el componente
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    const handleNextStep = () => {
        setStep(step + 1);
    };

   

    const handlebortoniniciarseccion = () => {
        const ruta = `/empresa/${id}/login/usuario`;
        
        // Navegar a la ruta construida
        navigate(ruta);
      }

    useEffect(() => {
        const fetchGrupoFamiliar = async () => {
            try {
                setLoading(true);
                const empresaResponse = await axios.get(`${backendURL}/api/marcaempresa/especifica/${id}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setMarcas(empresaResponse.data.data[0]);
            } catch (error) {
                console.error('Error fetching grupo familiar:', error);
                navigate('/Registro');
            } finally {
                setLoading(false);
            }
        };

        fetchGrupoFamiliar();
    }, [id, backendURL, navigate]);
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${backendURL}/api/getCodigo`, {
                params: { codigo }
            });

            const data = response.data;
        
      if (data.estado === "ACTIVO") {
        // Mostrar modal si el usuario está registrado
        setModalVisible(true);
      } else if (data.estado === "DISPONIBLE") {
        setSuscripcion(response.data)
        handleNextStep();
      } else {
        setError("Código inválido. Por favor, verifica e inténtalo nuevamente.");
      }
           
        } catch (error) {
            setError('código de verificacion incorrecto.');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const Styleboton = {
        background: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81',
        fontFamily:'Poppins',
        fontSize:'1rem',
        color:'#fff',
        padding:'10px 20px',
        borderRadius:'50px',
        cursor:'pointer'  ,
        border:'0px solid'      // Ajustar la altura de la sección
          
      }
    if (loading) {
        return  <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Pantalla completa
          flexDirection: 'column',
        }}
      >
        <CircularProgress
          size={100}
          thickness={4}
          sx={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}
        />
      </Box>;
      }

      const StyleFondo={
        backgroundImage: `url(${marcas?.fondo_memorial ? `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}` : ''})`,
          backgroundSize: 'cover',           // Asegurar que cubra toda la sección
          backgroundPosition: 'center',      // Centrar la imagen
          width: '100%',
          height: 'auto',                   // Ajustar la altura de la sección
          
      }

 
      
    return(
        <section className="contienenpaginainiciousuario" style={StyleFondo}>
        {marcas ? (
            <Navbar
            marcas={marcas}
            color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
            color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
            logomarca={`${backendURL}/${marcas?.logo}`}
            />
          ) : (
            <Navbar
            />
          )}
           <Helmet>
       <title>Bienvenido a registro usuario  empresa </title>
        <meta name="description" content="registro del usuario en empresa" />
        
      </Helmet>
      <Container component="main" maxWidth="xs">
      {step === 1 && ( <Box sx={{
                    padding: '20px 40px',
                    '@media (max-width: 450px)': {
                        padding: '20px 10px' // Margen reducido para pantallas menores a 450px
                    }

                }}>
                    <section className="contienenverificacionpagina">
                        <div className="iconoseguridad"><MdVerifiedUser style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}} /></div>
                        <div className="tituloverificar"><h1 style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Verificación de registro</h1>
                        </div>
  <strong className="bienveindaverificacion"> ¡Bienvenido!</strong>
                        <div className="contieneninformacionverificar">

                             <span>
                             Para completar tu registro y comenzar a
                              disfrutar de todos los beneficios que ofrece
                               nuestro servicio, por favor, ingresa el código
                                que se encuetra en la carta.
                                 Este código es crucial para verificar tu identidad 
                                 y garantizar una experiencia segura y personalizada.
                                  </span>
                                  </div>

                                  <div className="inputverificacionregistro">
                            <label htmlFor="">Ingresa código</label>
                            <input
                            style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                                type="text"
                                className="verificacionregistro"
                                value={codigo}
                                onChange={(e) => setCodigo(e.target.value)}
                            />
                        </div>
                        {error && <div className="errorsmessage"> <FiAlertCircle/>{error}</div>}
                        <div className="botondeenviarverificacion">
                            <button onClick={handleSubmit} disabled={loading} style={Styleboton}>
                                {loading ? <CircularProgress size={24} /> : "Enviar"}
                            </button>
                        </div>
                       
                    </section>


                </Box>
                 )}
                    {step === 2 && (
                        <> <UserEmpresaMarca marcas={marcas} codigo={codigo} suscripcion={suscripcion}/></>
                    )}
        </Container>
        <Modal
        open={modalVisible}
        onClose={() => setModalVisible(false)} // Cerrar el modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#ffff",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column'
            ,
            '@media (max-width: 500px)': {
               width:'80%'
                
              },
          }}
        >
            <div
            style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'flex-end',
                width:'100%'
            }}>
             <Button
            onClick={() => setModalVisible(false)} // Cerrar el modal
            sx={{ marginTop: "10px", color: marcas?.color_2 || "#694B81",background:'trasparent'

             }}
          >
           <IoIosClose style={{fontSize:'2rem'}} />
          </Button>
          </div>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ color: marcas?.color_2 || "#694B81", fontFamily:'Poppins',   '@media (max-width: 500px)': {
             fontSize:'1rem',
             textAlign:'center'
                
              }, }}>
            Usuario ya registrado
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2, color: marcas?.color_2 || "#694B81", mb:2 , textAlign:'center', fontFamily:'Poppins', '@media (max-width: 500px)': {
             fontSize:'0.9rem',
             textAlign:'center'
                
              },}}>
            Por favor, inicia sesión. Este código QR ya está registrado con un usuario activo.
          </Typography>

          <button
                className=""
                style={Styleboton}
                onClick={handlebortoniniciarseccion}
              >
                Iniciar sesión
              </button>
         
        </Box>
      </Modal>
        </section>
    )
}