    import React, { useEffect, useState } from "react";
import SucursalLayout from '../../../../../../SucursalLayout'
    import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
    import axios from "axios";

    /* Material UI */
    import {
        Container,
        Box,
        Accordion,
        AccordionSummary,
        AccordionDetails,
        Typography,
        Grid,
        Link,
    } from '@mui/material';
    import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

    export const ManualConfiguracionSucursal = ({ setShowNavbarFooter }) => {
        const [expanded, setExpanded] = useState(false);

        // Función para manejar el cambio de estado del acordeón
        const handleAccordionChange = (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };

        useEffect(() => {
            setShowNavbarFooter(false);
            return () => {
                setShowNavbarFooter(true);
            };
        }, [setShowNavbarFooter]);

        const accordionData = [
            {
                title: "Crear QR",
                videos: [{ 
                    name: "Cómo crear un QR paso a paso", 
                    link: "https://drive.google.com/file/d/1HG7dJtI_gLpPAmcjA6WJSdh_V07J-Tck/preview", 
                    description: "Guía sencilla para generar códigos QR." 
                }],
            },   
            {
                title: "Asignar Usuario a Qr",
                videos: [{ 
                    name: "Cómo Asignar  un usuario a un  QR paso a paso", 
                    link: "https://drive.google.com/file/d/1DTA2XnyxOQWvFN-iOPum8q9O7ZecGccL/preview", 
                    description: "Guía sencilla para asignar un usuario a un  códigos QR." 
                }],
            },   
            {
                title: "Crear Memorial y velatorio",
                videos: [{ 
                    name: "Guía para crear un memorial y uso del velatorio virtual", 
                    link: "https://drive.google.com/file/d/1ttCb3CrInKvzi6fbOc39ZSoxg1aXwvKj/preview", 
                    description: "Pasos para crear un memorial y uso del velatorio virtual en la plataforma y manejo de los comentarios de cada memorial." 
                }
            ],
            },
            {
                title: "Obituario",
                videos: [{ 
                    name: "Guía para crear y editar obituario de un memorial", 
                    link: "https://drive.google.com/file/d/1ywqhBBGS4eXX3pSXg1-_vWj7PRiI3aL7/preview", 
                    description: "Pasos para crear y editar el obituario de  un memorial en la plataforma." 
                }
            ],
            },

            {
                title: "Reporte",
                videos: [{ 
                    name: "Cómo generar reportes", 
                    link: "https://drive.google.com/file/d/1tNgOuQ_RnwfpdkyhFxdz_BTW2AsaQjVs/preview", 
                    description: "Crea reportes detallados fácilmente." 
                }],
            },
            
            {
                title: "Asignar QR por administrador sucursal",
                videos: [{ 
                    name: "Asignación avanzada de QR", 
                    link: "https://drive.google.com/file/d/15Qa0lNqJtAGy-zLuxICoCZTeOsXClNhh/preview", 
                    description: "Asignación de QR por administradores." 
                }],
            },
           
        ];
        

      
        

        return (
            <SucursalLayout>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontSize:'1.4rem', color:'#585857', fontFamily:'Poppins', fontWeight:'600'}}>
                    Manual de Configuración
                </Typography>
                <Container maxWidth="lg">
                <Box sx={formStyle}>
                    {accordionData.map((section, index) => (
                        <Accordion
                            key={index}
                            expanded={expanded === `panel${index + 1}`}
                            onChange={handleAccordionChange(`panel${index + 1}`)}
                            sx={StyleAcordeon}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "#694B81" }} />}
                                aria-controls={`panel${index + 1}a-content`}
                                id={`panel${index + 1}a-header`}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: "500",
                                        color: "#694B81",
                                    }}
                                >
                                    {section.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={StyleBox}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box sx={StyleBoxcontienelogo}>
                                                {section.videos.length > 0 ? (
                                                    section.videos.map((video, idx) => (
                                                        <Box key={idx} sx={{ mb: 2 }}>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: "Poppins",
                                                                    color: "#585857",
                                                                }}
                                                            >
                                                                {video.name}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: "Poppins",
                                                                    color: "#585857",
                                                                    mb: 1,
                                                                }}
                                                            >
                                                                {video.description}
                                                            </Typography>
                                                            <Box sx={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden", mt: 1 }}>
                                                                <iframe
                                                                    src={video.link}
                                                                    title={video.name}
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: "100%",
                                                                        height: "100%",
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Link
                                                                href={video.link.replace("embed/", "watch?v=")}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                sx={{
                                                                    fontFamily: "Poppins",
                                                                    color: "#694B81",
                                                                    textDecoration: "underline",
                                                                }}
                                                            >
                                                                Ver video
                                                            </Link>
                                                        </Box>
                                                    ))
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Poppins",
                                                            color: "#585857",
                                                        }}
                                                    >
                                                        No hay videos disponibles para esta sección.
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
            </SucursalLayout>
        )
    }

    
// Estilos personalizados
const formStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "50px",
};

const StyleAcordeon = {
    width: "100%",
    background: "#ffff",
};

const StyleBox = {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    marginBottom: "50px",
};

const StyleBoxcontienelogo = {
    width: "100%",
    padding: "0px 30px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};