import React from "react";
import { OtorgarBotonQrAdministrador } from "../../../Index/ComponenteEstructura/OtorgarQrAdmin";
import SucursalLayout from "../../../../../../../SucursalLayout";
import { Container} from '@mui/material';
import { useEffect } from "react";

export const AsignarQrAdministradorSucursal = ({ setShowNavbarFooter }) => {

    useEffect(() => {
            setShowNavbarFooter(false);
            return () => {
                setShowNavbarFooter(true);
            };
        }, [setShowNavbarFooter]);

    return(
         <SucursalLayout>
            <Container maxWidth="lg">
                <OtorgarBotonQrAdministrador/>
            </Container>
        </SucursalLayout>
    )
}