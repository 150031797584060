import React, { useState, useEffect, useRef } from "react";
import { Button, Box, Typography, CircularProgress, Modal } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IoClose } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { motion } from "framer-motion";

export const UbicacionMemorialHistorico = ({ id, memorialId, grupoFamiliar, onNextStep }) => {
  const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [memorial, setMemorial] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mapImage, setMapImage] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState({ top: 50, left: 50 });
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false); 
  const navigate = useNavigate();
  const[enviandoAlertprogress, setenviandoAlertprogress] = useState(false);
  const isDragging = useRef(false);
  const [mapUpdated, setMapUpdated] = useState(false);
  
  useEffect(() => {
    // Función para obtener el memorial y la imagen del mapa
    const fetchMemorialData = () => {
      setLoading(true);
      axios.get(`${backendURL}/api/get/Memorial/Historico/${memorialId}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        setMemorial(response.data);
        if (response.data.top && response.data.left) {
          setSelectedPoint({ top: response.data.top, left: response.data.left });
        }
        if (response.data.map_image) {
          setMapImage(response.data.map_image); // Asume que la respuesta tiene la URL de la imagen
        }
      })
      .catch((error) => {
        console.error("Error al obtener memorial:", error);
      })
      .finally(() => {
        setLoading(false);
      });
    };

    fetchMemorialData(); // Llamada para cargar la información del memorial

  }, [memorialId, backendURL, mapUpdated]); 

  const handleMouseDown = (e) => {
    e.preventDefault();
    isDragging.current = true;
  };

  // Función para mover el botón rojo
  const handleMouseMove = (e) => {
    if (!isDragging.current || !memorial) return;

    const imgRect = e.target.closest("img").getBoundingClientRect();
    const newLeft = ((e.clientX - imgRect.left) / imgRect.width) * 100;
    const newTop = ((e.clientY - imgRect.top) / imgRect.height) * 100;

    // Limitar valores entre 0 y 100
    setSelectedPoint({
      top: Math.min(100, Math.max(0, newTop)),
      left: Math.min(100, Math.max(0, newLeft)),
    });
  };

  const handleMouseUp = () => {
    if (!isDragging.current) return;
    isDragging.current = false;

    // Guardar nueva ubicación en la base de datos
    axios
      .post(
        `${backendURL}/api/posicion/mapa/memorialhistorico/${memorialId}`,
        { top: selectedPoint.top, left: selectedPoint.left },
        { headers: { Authorization: `Bearer ${Token}` } }
      )
      .then(() => console.log(""))
      .catch((error) => console.error("Error al guardar ubicación:", error));
  };

  const handleFileChange = async (event) => { 
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("mapa", file);
    setLoading(true);
    try {
      const response = await axios.post(
        `${backendURL}/api/agregar/mapa/mwemorial/historico/${memorialId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setLoading(false);
      setSuccessAlertOpen(true);
      setAlertMessage('El mapa se subió exitosamente.');
      handleModalClose();
      setMapImage(URL.createObjectURL(file)); // Mostrar la imagen inmediatamente
      setMapUpdated(true); // Actualizar el estado para refrescar los datos del memorial
    } catch (error) {
      setLoading(false);
      setErrorAlertOpen(true);
      setAlertMessage('Error al subir la imagen.', error);
    }
    finally {
      setLoading(false);
      setenviandoAlert(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
   
  };

  


  // Función para ir a la página de memoriales
  
  const handleClickFinal = () => {
    navigate(`/memorialeshistoricos/${Usuario.id}`)
  };
  return (
    <Box sx={{ textAlign: "center", p: 2 }}>
      <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                     
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <div className="pasosmemorial">
                                      <div className="titulopasos"><h1>Paso Ubicación:</h1>
                                        <span>Agregue la imagen del mapa en donde se encuentra el memorial y seleccioné la ubicación</span>
                                      </div>
                                    </div>
                                    </Box>
 

      {loading ? (
        <CircularProgress />
      ) : memorial?.mapa ? (
        <Box sx={{ position: "relative", display: "inline-block", mt: 2 }}>
          <img
            src={`${backendURL}/${memorial.mapa}`}
            alt="Mapa"
            style={{ width: "100%", maxWidth: "400px", cursor: "pointer" }}
            onMouseMove={handleMouseMove}
            
          />
           {/* Icono de PersonPin con animación */}
           <motion.div
            animate={{ top: `${selectedPoint.top}%`, left: `${selectedPoint.left}%` }}
            transition={{ type: "spring", stiffness: 100 }}
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              cursor: "grab",
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onClick={() => (isDragging.current = false)}
          >
            <PersonPinIcon sx={{ fontSize: 50, color: '#3f9bdb'  }} />
            {memorial?.imagen_perfil && (
              <img
                src={`${backendURL}/imagenes/memoriales/${memorial.carpeta_perfil}/${memorial.imagen_perfil}`}
                alt="Memorial"
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  border: "2px solid white",
                }}
              />
            )}
          </motion.div>
        </Box>
      ) : (
        <Box
                    width='100%'
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      height: 200,
                      border: "1px dashed rgba(0, 0, 0, 0.2)",
                      borderRadius: 8,
                      padding: 2,
                      backgroundColor: "#fefefe",
                    }}
                  >
                    <WarningAmberIcon
                      sx={{
                        fontSize: 40,
                        color: "#FF9800",
                        marginBottom: 1,
                      }}
                    />
                    <Typography variant="h6" color="textSecondary">
                      No hay mapa seleccionado
                    </Typography>
                  </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="map-upload"
        />
        <label htmlFor="map-upload">
          <Button variant="contained" component="span"
            sx={{
                                    ...botonStyle,
                                    backgroundColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : '#694B81',
                                    '&:hover': {
                                        backgroundColor: UserDataMarca?.color_2 && UserDataMarca.color_2 !== "undefined"
                                            ? UserDataMarca.color_2
                                            : '#694B81',
                                    }
                                }}>
            Subir Mapa
          </Button>
        </label>
      </Box>

      <Box sx={{ mt: 2,mb:3 }}>
        <Typography variant="body2" sx={textStyle}>
        Posición seleccionada: Top {isNaN(selectedPoint.top) ? 0 : parseFloat(selectedPoint.top).toFixed(2)}%, Left {isNaN(selectedPoint.left) ? 0 : parseFloat(selectedPoint.left).toFixed(2)}%
        </Typography>
      </Box>

      <Button 
      variant="contained"  
      sx={{
         
        ...botonStyle,
        backgroundColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
       ? UserDataMarca.color_1
       : '#694B81',
       '&:hover': {
         backgroundColor: UserDataMarca?.color_2 && UserDataMarca.color_2 !== "undefined"
                          ? UserDataMarca.color_2
                          : '#694B81',
         }
         }}
          onClick={handleClickFinal}>
        Ir a los Memoriales
      </Button>
      <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

       {/* Popup de carga */}
       <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
      <Modal open={enviandoAlertprogress} onClose={() => setenviandoAlertprogress(false)}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '350px',
      height: 'auto',
      bgcolor: '#ffff',
      boxShadow: 24,
      p: 1,
      borderRadius: '10px',
      '@media (max-width: 500px)': {
        width: '90%',
      },
    }}
  >
    <section style={{ textAlign: 'center', padding: '1rem' }}>
      <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
        Procesando solicitud...
      </p>
    </section>
  </Box>
</Modal>
    </Box>
  )
}

const botoneraStyle = {
  position: "relative",
  right: "0px",
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};

const botonStyle = {
  backgroundColor: "#694B81",
  color: "#fff",
  padding: "10px 20px",
  fontSize: "14px",
  fontWeight: "bold",
  borderRadius: "30px",
  textTransform: "none",
  transition: "all 0.3s ease-in-out",
  '&:hover': {
      backgroundColor: "#563A67",
      transform: "scale(1.05)",
  },
}

const textStyle = {
  fontFamily:'Poppins',
  color:'#585857',
  fontSize:'1rem',
  fontWeight:'600'
}
