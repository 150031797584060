import React, { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useNavigate, useParams, useLocation} from "react-router-dom";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import EmpresaLayout from "../../../../../../EmpresaLayout";
/* libreria UI */
import { Container, Grid, Box, CircularProgress, Button,MenuItem,Select,ListItemIcon,ListItemText,   Modal,  Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
/* react icons */
import { FaRegPlayCircle } from "react-icons/fa";
import { ImSearch } from "react-icons/im";
import { PiScrollFill } from "react-icons/pi";
import { RxCalendar } from "react-icons/rx";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { RiFileEditFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { FiPlusCircle } from "react-icons/fi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { BsTrash3Fill } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";




const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 3000 });

export const VerQrDemoAdminEmpresa = ({setShowNavbarFooter}) => {
  const theme = useTheme();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { id } = useParams(); // Este es el id_grupo_familiar
    const location = useLocation();
    const { empresa, user, grupoFamiliar } = location.state || {};
    const navigate = useNavigate();
    const {Token, UserDataMarca, Empresa} = useUserEmpresa();
    const [memoriales, setMemoriales] = useState([]); // Estado para almacenar los memoriales
    const [loading, setLoading] = useState(true); // Para manejar la carga de datos
    const [loadings, setLoadings] = useState(false); 
    const [expanded, setExpanded] = useState(false); // Para manejar el acordeón
    const [historia, setHistoria] = useState('')
    const [openModalseliminar, setOpenModalseliminar] = useState(false);
    const [imagenPreview, setImagenPreview] = useState([]);
    /* edit nombre */
    const [nombreFamilia, setNombreFamilia] = useState('');
    /* galeria */
    const [imagenesData, setImagenesData] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [selectedImageId, setSelectedImageId] = useState(null); 
    const [modalOpen, setModalOpen] = useState(false);
    const [showCreateOnlyImageConfirmation, setShowCreateOnlyImageConfirmation] = useState(false);

     /* video */
    const [isPlaying, setIsPlaying] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // Controla el modo de edición
    const [videoLinkInput, setVideoLinkInput] = useState('');  // Input del video
    const [video, setVideo] = useState('');  // Video actual
      
    /* fondo */
    const [selectedOption, setSelectedOption] = useState('');
   
    /* marca */
    const [marcas, setMarcas] = useState([]);
    const [error, setError] = useState(null);
    const [hayColores, setHayColores] = useState(false);
    const [colores, setColores] = useState({ // Estado para almacenar los colores
      color_1: '',
      color_2: '',
      color_3: '',
      color_4: '',
    });

    
     /* mensaje */

    const [openVideoModal, setOpenVideoModal] = useState(false);
    const [videoURL, setVideoURL] = useState('')
    const [showPopup, setShowPopup] = useState(false);
    const [comentarios, setComentarios] = useState([]);
    const [openModals, setOpenModals] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const capitalizeFirstLetter = (string) => {
      if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const [mensajeData, setMensajeData] = useState({
      nombre: '',
      titulo: '',
      descripcion: '',
      imagen: null
    });
   

    /* modales */
    const [modalOpens, setModalOpens] = useState(false);
    const [progress, setProgress] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const[enviandoAlertprogress, setenviandoAlertprogress] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    titulo: '',
    descripcion: '',
    imagen: null
  });
  const [memorialData, setMemorialData] = useState({
    id: '',
    nombre: '',
    cementerio: '',
    carpeta_perfil: '',
    imagen_perfil: '',
    historia: '',
    video:'',
 galeria: []
  
  });
  
   useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
        setShowNavbarFooter(true);
    };
}, [setShowNavbarFooter]);

 

// Manejar la expansión de los acordeones
    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    
    const handleEditClick = () => {
      setModalOpens(true); // Abre el modal después de hacer clic en editar
    };

    const handleCloseModaledit = () => {
      setModalOpens(false); // Cierra el modal
    };
    const handleNombreChange = (event) => {
      setNombreFamilia(event.target.value);
    };
    
    const handleGuardarNombre = async (event) => {
      event.preventDefault();
  
      try {
          setenviandoAlert(true);
          const response = await axios.post(
              `${backendURL}/api/updatedFamiliarNameEmpresaDemo/${grupoFamiliar.id}`,
              { nombre_familia: nombreFamilia },
              {
                  headers: {
                      Authorization: `Bearer ${Token}` // Encabezado de autorización
                  }
              }
          );
  
          // Cierra el modal después de editar el nombre de la familia
          handleCloseModaledit();
          setenviandoAlert(false);
          setSuccessAlertOpen(true);
          setAlertMessage('Editado nombre familiar con éxito.');
          location.state.grupoFamiliar = { ...grupoFamiliar, nombre_familia: nombreFamilia };
      
         
  
          // Retardo antes de navegar
          
      } catch (error) {
          setErrorAlertOpen(true);
          setAlertMessage('Error al editar nombre familiar, vuelva a intentar.');
      } finally {
          // Cierra las alertas después de un pequeño retardo para permitir que se vean
          setTimeout(() => {
              setErrorAlertOpen(false);
              setSuccessAlertOpen(false);
          }, 2000); // 2000 ms = 2 segundos
      }
  };

    const fetchMemoriales = async () => {
      if (!grupoFamiliar?.id || !Token) return; 
      setLoading(true); // Puedes establecer esto aquí si deseas mostrar un indicador de carga.
      try {
        const response = await http.get(`${backendURL}/api/getMemorialesPorGrupoFamiliar/${grupoFamiliar.id}`, {
          headers: {
            Authorization: `Bearer ${Token}`, // Incluir el token de autenticación
          },
        });
        setMemoriales(response.data); // Guardar los memoriales en el estado
      } catch (error) {
        console.error('Error al obtener los memoriales', error);
      } finally {
        setLoading(false); // Finalizar la carga
      }
    };
  
    useEffect(() => {
      fetchMemoriales(); // Llamar a fetchMemoriales al cargar el componente
      
    }, [Token, grupoFamiliar.id, backendURL]);
  
    useEffect(() => {
      if (memoriales && memoriales.length > 0) {
        // Puedes llamar a fetchMemoriales directamente si es necesario,
        // o crear otra función para usar con memoriale
      }
    }, [memoriales]);
  
    useEffect(() => {
      if (memoriales.length > 0) {
        const nuevasPreviews = memoriales.map((memorial) => 
          memorial.imagen_perfil
            ? `${backendURL}/imagenes/memoriales/${memorial.carpeta_perfil}/${memorial.imagen_perfil}`
            : null
        );
        setImagenPreview(nuevasPreviews);
      }
    }, [memoriales]);
    
    const handleFotoPerfilChange = (e, index) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Actualiza la imagen en el estado de previsualización
          setImagenPreview(prevState => {
            const newState = [...prevState];
            newState[index] = reader.result; // Asigna la imagen al índice correspondiente
            return newState;
          });
    
          // Actualiza la imagen en el memorial seleccionado
          setMemoriales(prevMemoriales => {
            const updatedMemoriales = [...prevMemoriales];
            updatedMemoriales[index] = {
              ...updatedMemoriales[index],
              imagen_perfil: file // Guarda el archivo para enviar en formData
            };
            return updatedMemoriales;
          });
        };
        reader.readAsDataURL(file); // Carga la imagen como URL de datos
      }
    };
      // Función para manejar los cambios en los inputs del formulario
      const handleInputChanges = (e, index) => {
        const { name, value } = e.target;
        setMemoriales((prevMemoriales) =>
          prevMemoriales.map((memorial, i) =>
            i === index ? { ...memorial, [name]: value } : memorial
          )
        );
      };

      const handleQuillChange = (value, index) => {
        setMemoriales((prevMemoriales) =>
          prevMemoriales.map((memorial, i) =>
            i === index ? { ...memorial, historia: value } : memorial
          )
        );
      };

     
  // Función para manejar la actualización de los datos cuando se presiona el botón
  const handleUpdate = async (index) => {
    if (!memoriales || memoriales.length === 0 || !memoriales[index]) {
      setErrorAlertOpen(true);
      setAlertMessage('No se encontraron memoriales para actualizar.');
      return;
    }
  
    const memorialData = memoriales[index]; // Obtener los datos del memorial específico
    const formData = new FormData();
    
    // Itera sobre los datos del memorial y agrega los valores al formData
    Object.entries(memorialData).forEach(([key, val]) => {
      if (val !== null && val !== undefined) {
        formData.append(key, val);
      }
    });
  
    // Envío de la actualización
    try {
      setLoading(true);
      await axios.post(`${backendURL}/api/updateMemorialalldemo/${memorialData.id}`, formData, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'multipart/form-data', // Indicar que es multipart
        },
      });
      setLoading(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Información actualizada correctamente.');
      await fetchMemoriales(memorialData.id); // Recarga los datos del memorial
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al actualizar el memorial. Intenta nuevamente.');
      setLoading(false);
    }
  };
    /* galeria */

  

    const handleInputChange = (index, field, value) => {
      setImagenesData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [field]: value,
        };
        return updatedData;
      });
    };
    const handleDescriptionButtonClick = (imagenId, index) => {
     
      const memorialData = memoriales[index]; 
      const selectedImageData = memorialData.galeria.find(img => img.id === imagenId); // Buscar la imagen por ID
     
      if (selectedImageData) { // Verificar si se encontró la imagen
        setSelectedImageId(imagenId); // Cambié a ID
        setImagenesData([{
          titulo: selectedImageData.titulo || '',
          descripcion: selectedImageData.descripcion || '',
          fecha: selectedImageData.fecha || ''
        }]);
        setModalOpen(true);
      } else {
        console.error('No se encontró la imagen con ID:', imagenId);
        // Aquí podrías mostrar un mensaje al usuario si lo deseas
      }
   
    };
    const handleInputChangesedit = (name, value) => {
      const updatedData = { ...imagenesData[0], [name]: value }; // Actualiza el primer elemento
      setImagenesData([updatedData]); // Asegúrate de que sea un array con un solo elemento
    };

    const handleEditImage = async () => {
      setLoading(true);
      try {
        setenviandoAlert(true);
        const updatedImageData = {
          id: selectedImageId,
          titulo: imagenesData[0]?.titulo || '',
          descripcion: imagenesData[0]?.descripcion || '',
          fecha: imagenesData[0]?.fecha || ''
        };
    
        const response = await axios.post(
          `${backendURL}/api/galeriaimagenUpdatedemo`,
          updatedImageData,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Token}`
            }
          }
        );
    
        setSuccessAlertOpen(true);
        setAlertMessage('Su imagen en galeria fue editada con éxito.');
        await fetchMemoriales(); // Actualizar memoriales después de editar
      } catch (error) {
        setErrorAlertOpen(true);
        setAlertMessage('Error al editar la imagen, vuelva a intentar.');
      } finally {
        setLoading(false);
        setenviandoAlert(false);
        setTimeout(() => {
          handleModalClose();
        }, 3000);
      }
    };
    

const handleImageChange = (e) => {
  const { files } = e.target;
  const imagenData = {
    titulo: '',
    descripcion: '',
    fecha: '',
    imagen: files[0]
  };
  setImagenesData([imagenData]);
  setSelectedImageIndex(0);
  setShowCreateOnlyImageConfirmation(true);
};

const handleAddImage = () => {
  setImagenesData([...imagenesData, { titulo: '', descripcion: '', fecha: '', imagen: null }]);
};



const handleRemoveImage = async (imageId) => {
  if (!imageId) {
    console.error("ID de imagen no proporcionado.");
    return; // Salir si no hay un ID válido
}

  try {
      setenviandoAlert(true);
      
    setLoading(true)
      // Realiza la solicitud al backend para eliminar la imagen
      const response = await axios.post(
          `${backendURL}/api/galeriaDeletedemo`,
          { id: imageId },
          {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${Token}`
              }
          }
      );
      setLoading(false)
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galería fue eliminada con éxito.');
      await fetchMemoriales(); // Actualizar memoriales después de eliminar
  } catch (error) {
      console.error('Error al eliminar la imagen:', error);
      setErrorAlertOpen(true);
      setAlertMessage('Error al eliminar la imagen, vuelva a intentar.');
  } finally {
      setenviandoAlert(false);
      setTimeout(() => {
          handleCloseModal();
      }, 3000);
  }
};



const handleCreateOnlyImageConfirmation = async (index) => {
  if (!memoriales || memoriales.length === 0 || !memoriales[index]) {
    setErrorAlertOpen(true);
    setAlertMessage('No se encontraron memoriales para actualizar.');
    return;
  }

  const memorialData = memoriales[index]; 

  const formData = new FormData();
  formData.append('id_memorial', memorialData.id);

  if (selectedImageIndex !== null) {
      const imagenData = imagenesData[selectedImageIndex];
      const formattedFecha = imagenData.fecha ? dayjs(imagenData.fecha, 'YYYY-MM-DD').format('YYYY/MM/DD') : '';
      formData.append('imagenes[0][imagen]', imagenData.imagen);
      formData.append('imagenes[0][titulo]', imagenData.titulo);
      formData.append('imagenes[0][descripcion]', imagenData.descripcion);
      formData.append('imagenes[0][fecha]', formattedFecha);
  }

  try {
      setenviandoAlert(true);
      await axios.post(`${backendURL}/api/galeriaStoreempresademo`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${Token}`
          },
      });

      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue creada con éxito.');
      await fetchMemoriales(); // Actualizar memoriales después de crear
  } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al crear la imagen para galeria, vuelva a intentar.');
  } finally {
      setenviandoAlert(false);
      setTimeout(() => {
          handleCloseModal();
      }, 3000);
  }
};

  const handleModalClose = () => {
    setModalOpen(false);
    setShowCreateOnlyImageConfirmation(false);
    setImagenesData([]);
    setSelectedImageIndex(null);
  };

  
  /* video y fondo */
  const handleAddOrEditVideo = async (index) => {
    // Asegúrate de que el index es válido
    if (typeof index === 'undefined') {
      console.error('No se recibió un index válido.');
      return;
    }
  
    // Obtener los datos del memorial correspondiente
    const memorialData = memoriales[index];
  
    if (!memorialData) {
      console.error('No se encontró el memorial para el index proporcionado.');
      return;
    }
  
    if (!videoLinkInput) {
      alert('Por favor ingrese un enlace de video.');
      return;
    }
  

  
    setLoading(true);
    try {
      const response = await axios.post(
        `${backendURL}/api/updateMemorialdemo/${memorialData.id}`,  // Usa el memorialId aquí
        { videoLink: videoLinkInput },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.video) {
        setVideo(response.data.video);
        setLoading(false);
        setSuccessAlertOpen(true);
        setAlertMessage('Video actualizado correctamente.');
        await fetchMemoriales(); // Asegúrate de actualizar los memoriales correctamente
      } else {
        alert('No se recibió una URL de video desde el servidor.');
      }
    } catch (error) {
      console.error('Error al actualizar el video:', error);
      setErrorAlertOpen(true);
      setAlertMessage('Hubo un error al actualizar el video.');
      setLoading(false);
    }
  }
  
  useEffect(() => {
    if (memoriales?.fondo) {
      setSelectedOption(memoriales.fondo);
    }
  }, [memoriales]);

  const handleSelectfondoChange = (event, memorialId) => {
    const newFondo = event.target.value;
    updateMemorial(memorialId, newFondo);
  };
  const updateMemorial = async (memorialId, newFondo) => {
    if (!newFondo) {
      console.error('El fondo no puede estar vacío.');
      return;
    }
  
   
    setSelectedOption(newFondo);  // Actualiza la selección
  
    try {
      const response = await axios.post(
        `${backendURL}/api/updateMemorialdemofondo/${memorialId}`,
        { fondo: newFondo },
        {
          headers: {
            'Authorization': `Bearer ${Token}`
          }
        }
      );
      setMemorialData({ ...memorialData, fondo: newFondo });
    } catch (error) {
      console.error('Error al editar el memorial:', error);
    }
  };
  
  // Función para manejar el cambio en el select
  const handleSelectChange = (event, memorialId) => {
    const newFondo = event.target.value;
    updateMemorial(memorialId, newFondo);  // Pasar id del memorial y nuevo fondo
  };

  
    // Botón para agregar memorial
    const handleNavigateCreardemo = () => {
      navigate(`/crear/demo/memorial/empresa/${id}`, {
        state: { user, empresa, grupoFamiliar },
      });
    };

    const handleNavigatepaginademo = () => {
      window.open(`/empresa/${empresa.nombre_empresa}/${empresa.id}/${grupoFamiliar.id}`, '_blank');
    };
    const handleCloseModal = () => {
      setOpenModal(false);
      setenviandoAlert(false);
      setenviandoAlertprogress(false);
    };


    const fetchMarcas = async () => {
      try {
        const response = await http.get(`${backendURL}/api/marcaempresa/${empresa.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Token}`,
          },
        });
    
        const fetchedMarca = response.data.data[0];
        setMarcas(fetchedMarca);
    
        if (fetchedMarca) {
          // Establecer los colores, manejando undefined o null
          const nuevosColores = {
            color_1: fetchedMarca.color_1 === 'undefined' ? '' : fetchedMarca.color_1,
            color_2: fetchedMarca.color_2 === 'undefined' ? '' : fetchedMarca.color_2,
            color_3: fetchedMarca.color_3 === 'undefined' ? '' : fetchedMarca.color_3,
            color_4: fetchedMarca.color_4 === 'undefined' ? '' : fetchedMarca.color_4,
          };
    
          // Verificar si algún color está definido (no vacío)
          const hayColores = Object.values(nuevosColores).some(color => color !== '');
    
          setHayColores(hayColores); // true si hay al menos un color definido
          // Opcional: almacenar los colores en el estado
        }
      } catch (err) {
        setError(err.response?.data?.message || 'Error al cargar marcas');
      }
    };
    
    useEffect(() => {
      // Verificar que haya un Token y un empresa.id antes de llamar a fetchMarcas
      if (Token && empresa.id) {
        fetchMarcas();
      }
    }, [Token, empresa.id]);

  
   
/* mensaje */



  const handleVideoModalOpen = (e) => {
    e.preventDefault();
    setOpenVideoModal(true);
  };

  const handleVideoModalClose = () => {
    setOpenVideoModal(false);
  };

  const handleVideoURLChange = (e) => {
    setVideoURL(e.target.value);
  };

  const handleSaveVideoURL = () => {
    if (videoURL) {
      const videoLink = `<a href="${videoURL}" target="_blank"> <IoPlayCircleOutline /> Ver Video</a>`;
      const updatedDescription = formData.descripcion + videoLink;
      setFormData(prevFormData => ({
        ...prevFormData,
        descripcion: updatedDescription
      }));
      setVideoURL('');
      setOpenVideoModal(false);
    }
  };
  const handlemenChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
    }));
};


const handleImagemenChange = (e) => {
    setFormData(prevFormData => ({
        ...prevFormData,
        imagen: e.target.files[0]
    }));
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nombre, titulo, descripcion, imagen } = formData;

    const formDataToSend = new FormData();
    formDataToSend.append('titulo', titulo);
    formDataToSend.append('creador', nombre);
    formDataToSend.append('mensaje', descripcion);
    formDataToSend.append('imagen', imagen);
    formDataToSend.append('id_memorial', memorialData.id);

    try {
      setLoading(true);
      setenviandoAlert(true);
      const response = await axios.post(`${backendURL}/api/comentarioStoredemo`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${Token}`
        }
      });

      await axios.post(`${backendURL}/api/comentarioStatedemo`, {
        id: response.data.id, visible: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Token}`
        }
      });

      setLoading(false);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su mensaje fue creado con éxito.')
      // Actualizar comentarios en memorialData
      const updatedComentarios = [...memorialData.comentarios, response.data];
      setMemorialData({ ...memorialData, comentarios: updatedComentarios });
      setFormData({
        nombre: '',
        titulo: '',
        descripcion: '',
        imagen: null
      });
      handleCloseModaluno(true);
     await fetchMemoriales(memorialData.id);

    } catch (error) {
      setErrorAlertOpen(true);
            setAlertMessage('Error al enviar el mensaje, vuelva a intentar.');
    }
    finally{
      setenviandoAlert(false);
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModaluno();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
  };

  
  const handleDeleteComment = async (id) => {
    try {
      setenviandoAlert(true);
      setLoading(true);
      await axios.post(
        `${backendURL}/api/comentarioDeletedemo`,
        { id: id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`
          }
        }
      );
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su mensaje fue eliminado con éxito.')
      setLoading(false);
      handleCloseModals();
      const updatedComentarios = comentarios.filter(comentario => comentario.id !== id);
      setComentarios(updatedComentarios);

      setFormData({
        nombre: '',
        titulo: '',
        descripcion: '',
        imagen: null
      });
     await fetchMemoriales(memoriales.id);
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al eliminar el mensaje, vuelva a intentar.');
    }
    finally{
      setenviandoAlert(false);
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModal();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
  };

  const handleOpenModalsnew = (comentario) => {
    setCommentToDelete({ id: comentario.id, nombre: comentario.creador });
    setOpenModals(true);
};

const handleOpenModal = () => {

  setOpenModal(true);
};
  const handleCloseModaluno = () => {
    setOpenModal(false);
    setenviandoAlert(false);
    setenviandoAlertprogress(false);
  };


  const handleCloseModals = () => {
    setOpenModals(false);
    setCommentToDelete(null);
  };
  

  /* descarga */
  const downloadQR = async () => {
    try {
        const response = await axios.post(`${backendURL}/api/qr/empresa/download/demo`, {
            qr: grupoFamiliar.qr,
            id: grupoFamiliar.id,
            id_empresa: empresa.id
        }, {
            responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = grupoFamiliar.qr; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } else {
            console.error('Error:', response.status);
        }
    } catch (error) {
        console.error('Error al enviar la solicitud:', error);
    }
};

/* eliminar memorial */

const handleOpenModalseliminar = () => {
  if (memoriales.length > 0) {
    // Asegurarse de que memoriales no esté vacío
    setMemorialData({ ...memorialData, id: memoriales[0].id }); // Tomar el ID del primer elemento
  } else {
    console.error('El array memoriales está vacío.');
  }
  setOpenModalseliminar(true);
};


const handleCloseModalseliminar = () => {
  setOpenModalseliminar(false);
};
const handleEliminarMemorial = async () => {
  try {
  
    setLoadings(true);
    setenviandoAlert(true);
    
    // Realizar la solicitud de eliminación con los encabezados correctamente configurados
    const response = await axios.post(
      `${backendURL}/api/eliminarMemorial/empresa/${memorialData.id}`, 
      {}, // Enviar un objeto vacío en lugar de datos en el cuerpo si no se requiere enviar datos
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Token}`, // Asegúrate de que sea Bearer {Token}
        }
      }
    );

    // Si la solicitud es exitosa
    setenviandoAlert(false);
    setLoadings(false);
    setOpenModalseliminar(false);
    setSuccessAlertOpen(true);
    setAlertMessage('El memorial fue eliminado con éxito.');
    window.location.reload();

    
  } catch (error) {
    // Manejo de errores
    setLoadings(false);
    setErrorAlertOpen(true);
    
    if (error.response && error.response.status === 401) {
      setAlertMessage('No autorizado. Por favor, verifica tu token de autenticación.');
    } else {
      setAlertMessage('Error al eliminar el memorial, vuelva a intentar.');
    }

    console.error('Error al eliminar el memorial:', error.response ? error.response.data : error.message);
  } finally {
    // Asegurarse de desactivar la alerta de envío en el bloque finally
    setenviandoAlert(false);
  }
};

useEffect(() => {

// Aquí podrías hacer la llamada a la API para obtener la información de memoriales
  setTimeout(() => {
    setLoading(false);
  }, 2000); // Simulación de 2 segundos de carga
}, [memoriales]);

if (loading) {
  // Mostrar CircularProgress mientras se carga la información
  return  (<Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" sx={{ flexDirection:'column', marginTop:'40px'}}>
   <Skeleton variant="rectangular" width="80%" height="100px" />
  <Skeleton variant="text" width="80%" height="100px" />
  <Skeleton variant="rectangular" width="80%" height="400px" />
</Box>)
}

const handleOpenVelatorioVirtual = (memorialId) => {
  window.open(`/${Empresa.nombre_empresa}/${Empresa.id}/pagina/virtual/${memorialId}`, "_blank");

};

    return(
      <EmpresaLayout>
        <Container maxWidth='lg' sx={{
          fontFamily:'Poppins'
        }}>
         
       <Box>
        <Grid container sx={{marginTop:'50px', alignItems:'center'}}>
            <Grid item xs={5}>
                <Box sx={StyleBoxtitulo}>
                <Box sx={StyleBoximagen}>
                {empresa.logo && (
                    <img src={`${backendURL}/${empresa.logo}`} alt="smart memorial" title="smart memorial" width={'70px'} />
                    )}
                 </Box>
                 <Box sx={StyleBoxtitulos}>
                    <h2 className="titulosEmpresaQrEmpresaPagina">{empresa.id_tipo_empresa == 1 ? 'Cementerio' : 'Funeraria'}</h2>
                    <h2 className="titulosEmpresaQrEmpresaPagina" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>{grupoFamiliar.nombre_familia} <FiEdit3 style={{cursor:'pointer'}} onClick={handleEditClick} /></h2>
                 </Box>
                </Box>
            </Grid>
            <Grid item xs={7}>
                <Box sx={StyleBoxbotones}>
                    {memoriales.length === 0 ? (
                      <button className="BotonQragregarmemorial" onClick={handleNavigateCreardemo}>
                        Agregar Memorial
                      </button>
                    ) : (
                      <></>
                    )}

                    <button className="BotonQrvermemorial" onClick={handleNavigatepaginademo}>
                        Ver memorial Empresa
                    </button>
                    <button className="BotonQrDescargarrmemorial" onClick={downloadQR}>
                        Descargar QR del memorial
                    </button>
                    {memoriales.map((memorial, index) => (
                        <button 
                    className="BotonQrDescargarrmemorial" 
                    onClick={() => handleOpenVelatorioVirtual(memorial.id)}
                    style={{background:
                      UserDataMarca?.color_1 &&
                      UserDataMarca.color_1 !== "undefined"
                        ? UserDataMarca.color_1
                        : "#694B81",}} >
                        Pagina Virtual
                    </button>
                    ))}
                </Box>
            </Grid>

        </Grid>
        <Box sx={{
            marginTop:'50px'
        }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" sx={{ flexDirection:'column'}}>
          <CircularProgress/>
      </Box> 
        ) : (
          <Box sx={{ marginTop: "50px",marginBottom:'100px' }}>
            {/* Mapear los memoriales y crear un Accordion para cada uno */}
            {memoriales.map((memorial, index) => (
              <Accordion
              sx={{
                background:'#ffff'
              }}
                key={memorial.id}
                expanded={expanded === `panel${index}`}
                onChange={handleAccordionChange(`panel${index}`)}
                
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{
                    color:
                      UserDataMarca?.color_1 &&
                      UserDataMarca.color_1 !== "undefined"
                        ? UserDataMarca.color_1
                        : "#694B81",
                  }}/>}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Typography sx={{
                    fontFamily:'Poppins',
                    fontWeight:'500',
                                color:
                                  UserDataMarca?.color_1 &&
                                  UserDataMarca.color_1 !== "undefined"
                                    ? UserDataMarca.color_1
                                    : "#694B81",
                              }}>
                    {memorial.nombre} 
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form className="formularioeditarperfilmemorialdemo">
                    <div className="tituloeditmemorialdemo">
                      <h2>Información del Memorial {memorial.nombre}</h2>
                    </div>
                    <Grid container>
                      <Grid item xs={6}>
                      <div className="inputregistorform">
                <label>Nombre del Memorial</label>
                <div className="inputnombreeditfallecido">
                  <input className="inputeditregistro " name="nombre"
                    value={memorial.nombre}
                    onChange={(e) => handleInputChanges(e, index)}
                    id="name" />
                </div>
              </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="inputregistorform">
                          <label>Cementerio </label>
                          <div className="inputeditcementerio">

                            <div>
                              <input
                                type='text'
                                className="inputeditregistro "
                                name="cementerio"
                                id="cementerio"
                                placeholder="Buscar cementerios"
                                value={memorial.cementerio}
                                onChange={(e) => handleInputChanges(e, index)}
                              />
                              <span className="buscadoredit">
                                <ImSearch />
                              </span>

                            </div>
                          </div>

                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <section className="contiene-input-imagen-perfil">
                          <label htmlFor={`file-input-${index}`} className="input-subir">
                            <input
                              id={`file-input-${index}`}
                              name="imagen_perfil"
                              type="file"
                              onChange={(e) => handleFotoPerfilChange(e, index)} // Maneja el cambio de imagen
                            />
                            {imagenPreview[index] ? (
                              <img
                                src={imagenPreview[index]} // Mostrar la vista previa de la nueva imagen seleccionada
                                alt={`Vista previa de la imagen de ${memorial.nombre}`}
                                style={{ maxWidth: '200px', maxHeight: '200px' }} // Ajusta el tamaño según sea necesario
                              />
                            ) : (
                              memorial.imagen_perfil && (
                                <img
                                  src={`${backendURL}/imagenes/memoriales/${memorial.carpeta_perfil}/${memorial.imagen_perfil}`} // Mostrar la imagen actual si no hay vista previa
                                  alt={`Imagen actual de ${memorial.nombre}`}
                                  style={{ maxWidth: '200px', maxHeight: '200px' }} // Ajusta el tamaño según sea necesario
                                />
                              )
                            )}
                            <div className="cambiar-foto">
                              <span className="cambiarfotoedit">
                                <p>Cambiar</p><p>foto</p>
                              </span>
                            </div>
                          </label>
                        </section>
                      </Grid>
                      <Grid item xs={6}>
                        <section className="historiaeditcrear">
                            <div className="titulohitoriacrear">
                              <PiScrollFill />
                              <h1>Su historia</h1>
                            </div>
                            <ReactQuill
                            className="inputedithistoria" 
                            placeholder="Escriba hechos importantes en la vida de su ser querido..."
                              value={memorial.historia}
                              onChange={(value) => handleQuillChange(value, index)}
                              modules={{
                                toolbar: [
                                  [],
                                  [],
                                  ['link'],
                                
                                ],
                              }}
                              
                            />
                          </section>
                      </Grid>
                    </Grid>
                    <div className="contienebotoneditarmemorialdemo">
                    <button type="button" className="BotonQragregarmemorial"  onClick={() => handleUpdate(index)}>
                      Actualizar Memorial
                    </button>
                    </div>
                  </form>
                  <form className="formularioeditarperfilmemorialdemo">
                    <div className="tituloeditmemorialdemo">
                      <h2>Multimedia del Memorial {memorial.nombre}</h2>
                    </div>
                    <Container maxWidth='sm'>
                        <section className="galeria-crear">
                          <Grid container className="cardgaleriatodo">
                          {memorial.galeria && memorial.galeria.length > 0 ? (
                                memorial.galeria.map((imagen, imagenindex) => {
                                  
                                  return (
                                    <Grid key={imagenindex} item xs={4} className="contienecardgaleria">
                                      <div className="cardfoto">
                                        <div className="inputsubir">
                                          {/* Muestra la imagen */}
                                          <img 
                                            src={`${backendURL}/${imagen.imagen}`} 
                                            alt={`Imagen ${imagenindex + 1}`} 
                                            className="imagen-seelcion-galeria" 
                                          />
                                          <div className="contieneicono">
                                            <FaArrowUpFromBracket className="imgicono" />
                                            <span>Subir Foto</span>
                                          </div>

                                          <div className="contienebotoneliminarfoto">
                                            <button 
                                              className="button-cambiar-close" 
                                              type="button" 
                                              onClick={() => handleRemoveImage(imagen.id)} 
                                            >
                                              <IoClose />
                                            </button>
                                          </div>
                                        </div>

                                        <button 
                                          type="button" 
                                          className="editar-imagen" 
                                          onClick={() => handleDescriptionButtonClick(imagen.id, index)}
                                        >
                                          <RiFileEditFill /> <p>Descripción</p> 
                                        </button>
                                      </div>
                                    </Grid>
                                  );
                                })
                                ) : (
                                  <Grid item xs={12} className="no-galeria">
                                    <p>No hay imágenes en la galería.</p>
                                  </Grid>
                                )}

                                <Grid item xs={4} className="contienecardgaleria">
                                  <div className="cardfoto">
                                    <div className="inputsubir">
                                      <input 
                                        type="file" 
                                        accept="image/*" 
                                        className="cardinputgaleria" 
                                        onChange={handleImageChange} 
                                      />
                                      <div className="contieneicono">
                                        <FaArrowUpFromBracket className="imgicono" />
                                        <span>Subir Foto</span>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>

                               

                            <Grid item xs={4} className="botonagregar" sx={{ marginBottom: '50px' }}>
                              <div className="cardnuevafoto">
                                <div className="inputnuevosubir">
                                  <button 
                                    type="button" 
                                    className="cardnuevafoto" 
                                    onClick={handleAddImage}
                                  > 
                                    <div className="agregaricono">
                                      <FiPlusCircle />
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </section>

                        {modalOpen && selectedImageId && (
                            <div className="modal">
                              <div className="modal-content">
                                <span className="close" onClick={handleModalClose}><IoClose style={{
                                    color:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",
                                }} /></span>
                                <div className="titulo-galeriadescripcion"> 
                                  <RiFileEditFill style={{
                                     color:
                                     UserDataMarca?.color_1 &&
                                     UserDataMarca.color_1 !== "undefined"
                                       ? UserDataMarca.color_1
                                       : "#694B81",
                                  }} />
                                  <h2 style={{
                                     color:
                                     UserDataMarca?.color_1 &&
                                     UserDataMarca.color_1 !== "undefined"
                                       ? UserDataMarca.color_1
                                       : "#694B81",
                                  }} >Agregar Descripción</h2>
                                </div>
                                <section className="aviso"> 
                                  <span> Si no desea agregar descripción, </span>
                                  <span> haga click en continuar.</span>
                                </section>
                                <section className="formdescripciongaleriaimagen">
                                  <div className="inputimagenespecifico">
                                    <label className="tituloinputfecha">Título</label>
                                    <input
                                    style={{width:'100%', background:'#ffff',
                                      color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",
                                    }}
                                      type="text"
                                      name="titulo"
                                      className="inputdescripimag"
                                      value={imagenesData[0]?.titulo || ''} // Accede al primer elemento
                                      onChange={(e) => handleInputChangesedit(e.target.name, e.target.value)}
                                    />
                                  </div>
                                  <div className="inputimagenespecifico">
                                    <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                      <label>Fecha de la foto</label>
                                    </div>
                                    <div className="inputfotocontainer">
                                      <input
                                       style={{width:'100%', color:
                                        UserDataMarca?.color_1 &&
                                        UserDataMarca.color_1 !== "undefined"
                                          ? UserDataMarca.color_1
                                          : "#694B81",}}
                                        type="date"
                                        className="inputefotofecha"
                                        name='fecha'
                                        value={imagenesData[0]?.fecha || ''} // Accede al primer elemento
                                        onChange={(e) => handleInputChangesedit(e.target.name, e.target.value)}
                                      />
                                      <div className="calendarioediticono">
                                        <RxCalendar className="iconoeditcalendario" style={{   color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="inputimagenespecifico">
                                    <label className="tituloinputfecha">Descripción</label>
                                    <textarea
                                     style={{width:'100%', background:'#ffff',  color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}}
                                      name="descripcion"
                                      className="inputdescripitodo"
                                      value={imagenesData[0]?.descripcion || ''} // Accede al primer elemento
                                      onChange={(e) => handleInputChangesedit(e.target.name, e.target.value)}
                                    />
                                  </div>
                                </section>
                                <section className="botonguardarimagen">
                                  <button 
                                  style={{
                                    background:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",
                                  }}
                                    className="guardarimagen" 
                                    type="button" 
                                    onClick={handleEditImage}
                                  >
                                    {loading ? (<CircularProgress color="inherit"/>) : ("Continuar")}
                                  </button>
                                </section>
                              </div>
                            </div>
                          )}

                        {showCreateOnlyImageConfirmation && (
                          <div className="modal">
                            <div className="modal-content">
                              <span className="close" onClick={handleModalClose}><IoClose style={{   color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}} /></span>
                              <div className="titulo-galeriadescripcion"> 
                                <RiFileEditFill  style={{   color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}}/>
                                <h2 style={{   color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}}>Agregar Descripción</h2>
                              </div>
                              <section className="aviso"> 
                                <span> Si no desea agregar descripción, </span>
                                <span> haga click en continuar.</span>
                              </section>
                              <section className="formdescripciongaleriaimagen">
                                <div className="inputimagenespecifico">
                                  <label className="tituloinputfecha">Título</label>
                                  <input
                                  style={{ 
                                    width:'100%',
                                    background:'#ffff',
                                    color:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",}}
                                    type="text"
                                    name="titulo"
                                    className="inputdescripimag"
                                    value={imagenesData[selectedImageIndex]?.titulo || ''}
                                    onChange={(e) => handleInputChange(selectedImageIndex, 'titulo', e.target.value)}
                                  />
                                </div>
                                <div className="inputimagenespecifico">
                                  <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <label>Fecha de la foto</label>
                                  </div>
                                  <div className="inputfotocontainer">
                                    <input
                                     style={{ 
                                      color:
                                      UserDataMarca?.color_1 &&
                                      UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : "#694B81",}}
                                      type="date"
                                      className="inputefotofecha"
                                      name='fecha'
                                      value={imagenesData[selectedImageIndex]?.fecha || ''}
                                      onChange={(e) => handleInputChange(selectedImageIndex, 'fecha', e.target.value)}
                                    />
                                    <div className="calendarioediticono">
                                      <RxCalendar className="iconoeditcalendario"  style={{ 
                                    color:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",}} />
                                    </div>
                                  </div>
                                </div>
                                <div className="inputimagenespecifico">
                                  <label className="tituloinputfecha">Descripción</label>
                                  <textarea
                                   style={{ 
                                    width:'100%',
                                    background:'#ffff',
                                    color:
                                    UserDataMarca?.color_1 &&
                                    UserDataMarca.color_1 !== "undefined"
                                      ? UserDataMarca.color_1
                                      : "#694B81",}}
                                    name="descripcion"
                                    className="inputdescripitodo"
                                    value={imagenesData[selectedImageIndex]?.descripcion || ''}
                                    onChange={(e) => handleInputChange(selectedImageIndex, 'descripcion', e.target.value)}
                                  />
                                </div>
                              </section>
                              <section className="botonguardarimagen">
                                <button 
                                 style={{ 
                                  background:
                                  UserDataMarca?.color_1 &&
                                  UserDataMarca.color_1 !== "undefined"
                                    ? UserDataMarca.color_1
                                    : "#694B81",}}
                                  className="guardarimagen" 
                                  type="button" 
                                  onClick={() => handleCreateOnlyImageConfirmation(index)}
                                >
                                  {loading ? (<CircularProgress color="inherit"/>) : ("Continuar")}
                                </button>
                              </section>
                            </div>
                          </div>
                        )}
                      </Container>
                      <Container maxWidth='md' sx={{ marginTop: '30px' }}>
                      <section className="seccion-video-crear">
                        <div className="videocontiene">
                        {memorial.video && !isEditing ? (
                              <div>
                                <ReactPlayer
                                  url={memorial.video}
                                  playing={false}
                                  controls={true}
                                  width="100%"
                                  height="600px"
                                  playIcon={<FaRegPlayCircle />}
                                />
                                <Button
                                  onClick={() => {
                                    setIsEditing(true);
                                    setVideoLinkInput('');  // Limpiar el campo de input al entrar en modo edición
                                  }}
                                >
                                  Editar video
                                </Button>
                              </div>
                            ) : (
                              <div className="card-video">
                                <div className="no-video-message">
                                  <div className="inputsubir">
                                    <input
                                      id="videoLinkInput"
                                      name="videoLink"
                                      className="cardinputgaleria" 
                                      type="text"
                                      placeholder="Ingresa el enlace del video"
                                      value={videoLinkInput}
                                      onChange={(e) => setVideoLinkInput(e.target.value)} // Actualiza el valor del input
                                    />
                                    <div className="contienevideoicono" onClick={() => handleAddOrEditVideo(index)}>
                                      <FaArrowUpFromBracket className="imgicono" />
                                      <span>Pega el link del video</span>
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  sx={{ marginTop: '10px' }}
                                  onClick={() => {
                                    handleAddOrEditVideo(index);  // Asegúrate de pasar el index aquí también
                                    setIsEditing(false);  // Vuelve al modo de visualización
                                  }}
                                >
                                  {video ? 'Actualizar video' : 'Agregar video'}
                                </Button>
                              </div>
                            )}
                        </div>
                      </section>
                    </Container>
                    <Container maxWidth='sm'>
                    <section className="fondomemorial">
                          <div className="titulofondomemorial">
                            <h1>Fondo {memorial.fondo == "coloresEmpresa" ? "Colores Empresa" :
              memorial.fondo == "atardecerVerano" ? "Atardecer en verano" :
              memorial.fondo == "naturalezaAustral" ? "Naturaleza austral" :
              "Clásico Smart Memorial"} </h1>
                          </div>
                        </section>
                        <section style={{marginTop:'130px'}} >  
                          <Select
                            className="seleciondefondofinal"
                            value={selectedOption}
                            onChange={(event) => handleSelectfondoChange(event, memorial.id)}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                              background:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                    ? UserDataMarca.color_1 
                                    : '#694B81',
                              width: '100%',
                              borderRadius: '50px',
                              display: 'flex',
                              fontSize: "0.8rem",
                              alignItems: 'flex-start',
                              color: '#585857',
                              fontFamily: 'Poppins',
                              '& .MuiTypography-root': { // Estilos para MuiTypography-root
                                fontSize: '0.8rem', // Aquí estableces el tamaño de fuente deseado
                              }
                            }}
                          >
                            <StyledMenuItem value="atardecerVerano">
                              <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                <ListItemIcon>
                                  <div style={{ backgroundColor: '#E28939', borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
                                </ListItemIcon>
                                <StyledListItemText primary="Atardecer en verano" x={{ width: '200px', fontFamily: "Poppins", fontSize: "0.7rem", '& .MuiTypography-root': { fontSize: "0.8rem", fontFamily: "Poppins" }, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />
                              </div>
                            </StyledMenuItem>
                            <StyledMenuItem value="naturalezaAustral">
                              <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                <ListItemIcon>
                                  <div style={{ backgroundColor: '#68A354', borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
                                </ListItemIcon>
                                <StyledListItemText primary="Naturaleza austral" x={{ width: '200px', fontFamily: "Poppins", fontSize: "0.7rem", '& .MuiTypography-root': { fontSize: "0.8rem", fontFamily: "Poppins" }, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />
                              </div>
                            </StyledMenuItem>
                            {hayColores ? (
                    <StyledMenuItem value="coloresEmpresa">
                      <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                        <ListItemIcon>
                          <div style={{ backgroundColor: colores.color_2, borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
                        </ListItemIcon>
                        <StyledListItemText primary="Colores Empresa" x={{ width: '200px', fontFamily: "Poppins", fontSize: "0.7rem" }} />
                      </div>
                    </StyledMenuItem>
                  ) : (
                    <StyledMenuItem value="clasicoSmartMemorial">
                      <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                        <ListItemIcon>
                          <div style={{ backgroundColor: '#694B81', borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
                        </ListItemIcon>
                        <StyledListItemText primary="Clásico Smart Memorial" x={{ width: '200px', fontFamily: "Poppins", fontSize: "0.7rem" }} />
                      </div>
                    </StyledMenuItem>
                  )}
                          </Select>
                        </section>
                    </Container>
                    </form>
                    <form className="formularioeditarperfilmemorialdemo">
                    <div className="tituloeditmemorialdemo">
                      <h2>Mensajes Memorial {memorial.nombre}</h2>
                    </div>
                    <Container maxWidth='sm'>

                    <section className="editcomentarios">
  {memorial.comentarios && memorial.comentarios.length > 0 ? (
    // Invertir el orden de los comentarios
    memorial.comentarios.slice().reverse().map((comentario, index) => {
      return (
        <div style={{ width: "100%" }} key={index}>
          {comentario.visible === "1" && (
            <div className="cardmensajeeditaparicion">
              <div className="basuraeditcomt">
                <div
                  onClick={() => {
                    handleOpenModalsnew(comentario);
                  }}
                >
                  Eliminar <BsTrash3Fill />
                </div>
              </div>
              <div className="toocomt">
                <div className="titulogeneradoeditcoment">
                  <h1>{capitalizeFirstLetter(comentario.titulo)}</h1>
                </div>
                <div className="comentarioeditgeneradocomt">
                  <p>
                    {comentario.mensaje ? (
                      <DangerouslySetHTMLContent
                        html={capitalizeFirstLetter(comentario.mensaje)}
                      />
                    ) : (
                      <span></span>
                    )}
                  </p>
                </div>
                <div className="quienteditlorealizohora">
                  <p>{comentario.creador}</p> •
                  <p>{new Date(comentario.fecha).toLocaleDateString('es-ES')}</p>
                </div>
                <div className="imagencomentario">
                  {comentario.imagen && (
                    <img
                      src={`${backendURL}/${comentario.imagen}`}
                      alt="smart memorial"
                      className="imagencomentariofinal"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    })
  ) : (
    <Grid item xs={12} className="no-galeria">
      <p>No hay mensajes.</p>
    </Grid>
  )}
</section>
              
              
               </Container>
                    </form>

                    <Box style={{
          width:'100%',
          marginBottom:'100px'
        }}>
        {memoriales.length === 0 ? (
                      <></>
                    ) : (
                      <button onClick={handleOpenModalseliminar} className="BotonQragregarmemorial">
                        Eliminar Memorial
                      </button>
                    )}

        </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
        </Box>
       
       </Box>
       
       <Modal
                open={openModals}
                onClose={handleCloseModals}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius: "10px", '@media (max-width: 500px)': {
            width:'80%', p:2
          } }}>
                  <div className="tituloeliminarmensaje">
                    <h2 id="modal-title">¿Desea eliminar este mensaje de {capitalizeFirstLetter(commentToDelete?.nombre)}?</h2></div>
                  <div className="contienetodoslosbotonesediteliminar"> <button className="noeli" onClick={handleCloseModals}>No eliminar</button>
                    <button className="sieli" onClick={() =>handleDeleteComment(commentToDelete?.id)} >{loading ?( <CircularProgress color="inherit"/>):("Si eliminar")}</button></div>
                </Box>
              </Modal>
       <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
      <Modal open={enviandoAlertprogress} onClose={() => setenviandoAlertprogress(false)}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '350px',
      height: 'auto',
      bgcolor: '#ffff',
      boxShadow: 24,
      p: 1,
      borderRadius: '10px',
      '@media (max-width: 500px)': {
        width: '90%',
      },
    }}
  >
    <section style={{ textAlign: 'center', padding: '1rem' }}>
      <CircularProgressWithLabel value={progress} style={{ color: '#694B81' }} />
      <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
        Procesando solicitud...
      </p>
    </section>
  </Box>
</Modal>
<Modal
        open={openModals}
        onClose={handleCloseModals}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius: "10px", '@media (max-width: 500px)': {
            width: '80%', p: 2
          }
        }}>
          <div className="tituloeliminarmensaje">
            <h2 id="modal-title">¿Desea eliminar este mensaje de {capitalizeFirstLetter(commentToDelete?.nombre)}?</h2></div>
          <div className="contienetodoslosbotonesediteliminar"> <button className="noeli" onClick={handleCloseModals}>No eliminar</button>
            <button className="sieli" onClick={() => handleDeleteComment(commentToDelete?.id)} >{loading ? (<CircularProgress color="inherit" />) : ("Si eliminar")}</button></div>
        </Box>
      </Modal>
      <Modal
        open={modalOpens}
        onClose={handleCloseModaledit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: '#ffff', border: '2px solid #585857', borderRadius: "5px", boxShadow: 24, p: 0,  '@media (max-width: 500px)': {
                width: '90%',
                
              },
              '@media (max-width: 325px)': {
                width: '90%',
               

              } }}>
          <div className="botoncerrar">  <Button onClick={handleCloseModaledit}><IoClose /></Button></div>
          <div className="cardeditnombrefamilia">
            <div className="tituloeditarfamilia">
            
              <RiFileEditFill style={{ color: '#694B81' }} className="iconoeditarnombrefamilia" />
           
            <h2 className="labeleditarnombre">Editar Nombre</h2></div>
            <div className="inputlabelfamilia">
              <form  className="formularioeditarnombre">
              <label>Nombre Empresa</label>
              
                <input  style={{width:'100%',
                                background:theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                              }} type="text" name="nombre" placeholder="ej: Lon Cristos" value={nombreFamilia} onChange={handleNombreChange} />
                <button className="buttonguardareditarfamilia" onClick={handleGuardarNombre}> Guardar</button>
              </form>

            </div>
          </div> 
        </Box>
      </Modal>
      <Modal
        open={openModalseliminar}
        onClose={handleCloseModalseliminar}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius: "10px", '@media (max-width: 500px)': {
            width:'80%', p:2
          } }}>
          <div className="tituloeliminarmensaje">
            <h2 id="modal-title">¿Eliminar memorial?</h2>
          </div>
          <div className="informacioneliminarmemorial"> <span>Se eliminará toda la información
de este memorial</span></div>
          <div className="contienetodoslosbotonesediteliminar">
            
            <button className="sieli"  onClick={handleEliminarMemorial}>{loadings ?( <CircularProgress color="inherit"/>):("Si eliminar")}</button>
          <button className="noeli" onClick={handleCloseModalseliminar}>No eliminar</button>
          </div>
        </Box>
      </Modal>
        </Container>
      </EmpresaLayout>
    )
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel() {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}




const StyleBox ={
    width:'100%',
    border:'1px solid #F0EDF3',
    marginTop:'30px',
    borderRadius:'10px',
    p:2,
    background:'#F0EDF3'
}

const StyleBoximagen = {
    width:"20%",
   display:'flex',
   alignItems:'center',
   justifyContent:'center',
  
}
const StyleBoxtitulo = {
   display:'flex',
   alignItems:'center',
   justifyContent:'flex-start',

}
const StyleBoxtitulos = {
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
    flexDirection:'column'
 
 }

 const StyleBoxbotones = {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'row'
 
 }
 const StyleAcordeon = {
    width:"100%",
    
}
const StyleTitulo ={
    fontFamily:'Poppins',
    color: '#585857',
    fontWeight:'600'
}
const StyleButton = {
  background:'#D26999',
  fontFamily:'Poppins',
  color:'#fff',
  fontSize:'1rem',
  marginTop:'10px'
}

const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
  color: "#585857",
  fontFamily: "Poppins",
  fontSize: "0.8rem",
  '& .MuiTypography-root': {
    fontSize: "0.8rem",
  }

}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '0.7rem',
  '& .MuiTypography-root': {
    fontSize: '0.8rem',
    fontFamily: 'Poppins', // Aplica el fontFamily a la raíz de la tipografía
  },
}));