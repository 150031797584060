import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams} from 'react-router-dom';
import { Navbar } from "../nav-fotter/navbar";
import PollIcon from '@mui/icons-material/Poll'; //
import { FaRegCircleCheck } from "react-icons/fa6";

import { FiAlertCircle } from "react-icons/fi";
import "../../Style/generales/EncuestaPublic.css"
import { TbHeartHandshake } from "react-icons/tb";
import {
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  FormControlLabel,
  TextField,
  Container,
  CircularProgress,
  Snackbar,
  Modal,
   Alert
} from "@mui/material";

export const EncuestaPublicaUsuario = ({setShowNavbarFooter}) => {
    const { id_empresa, id_encuesta, id_usuario } = useParams();
    const [EncuestaId, setEncuestaId] = useState({});
    const [Empresa, setEmpresa] = useState({});
    const [marcas, setUserMarcaData] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [questions, setQuestions] = useState([]); // Preguntas válidas
    const [answers, setAnswers] = useState([]); 
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [avisoAlertOpen, setAvisoAlertOpen] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [alreadyResponded, setAlreadyResponded] = useState(false); 
    const [step, setStep] = useState(1);
 
    
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    const fetchData = async () => {
      setLoadings(true); // Activamos el estado de carga
  
      try {
          const [encuestaResp, empresaResp, marcaResp] = await Promise.all([
              axios.get(`${backendURL}/api/encuestasseleccion/publica/${id_encuesta}`),
              axios.get(`${backendURL}/api/empresa/especifica/${id_empresa}`),
              axios.get(`${backendURL}/api/marcaempresa/especifica/${id_empresa}`)
          ]);
  
          // Intentar obtener la respuesta del usuario
          try {
              const respuestaUsuarioResp = await axios.get(`${backendURL}/api/encuestas/repsuesta/usuario/${id_usuario}/${id_encuesta}`);
             
              // Si hay respuestas, marcamos que ya respondió
              if (respuestaUsuarioResp.data.respondido) {
                  setAlreadyResponded(true);
              }
          } catch (error) {
              if (error.response && error.response.status === 404) {
                  console.log("El usuario no ha respondido ninguna encuesta.");
                  setAlreadyResponded(false); // Marcar que no hay respuestas
              } else {
                  console.error("Error al obtener la respuesta del usuario:", error);
              }
          }
  
          // Establecer los resultados de los demás fetch
          setEncuestaId(encuestaResp.data.data);
          setEmpresa(empresaResp.data.empresa);
          setUserMarcaData(marcaResp.data.data[0]);
      } catch (error) {
          console.error("Error al obtener los datos:", error);
      } finally {
          setLoadings(false); // Desactivamos el estado de carga cuando todo termine
      }
  };

    useEffect(() => {
        fetchData();
    }, [id_encuesta, id_empresa, id_usuario]);

    useEffect(() => {
        const validQuestions = Object.keys(EncuestaId).filter(
          (key) => key.startsWith("pregunta_") && EncuestaId[key]
        );
        setQuestions(
          validQuestions.map((key) => ({
            key,
            value: EncuestaId[key], // Esto se usa para mostrar el texto de la pregunta
          }))
        );
      }, [EncuestaId]);
    
      // Inicializar las respuestas con `null` para todas las preguntas
      useEffect(() => {
        setAnswers(Array(questions.length).fill(null));
        setLoading(false); // Terminamos de cargar la encuesta
      }, [questions]);
    
      // Manejo de las respuestas
      const handleAnswer = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = value; // Asignar la respuesta seleccionada
        setAnswers(updatedAnswers); // Actualiza el estado de las respuestas
      };
    
      // Manejo de retroceso en el Stepper
      const handleBack = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
      };
    
      // Enviar las respuestas de la encuesta
      const handleSubmit = async () => {
        if (answers.some((answer) => answer === null || answer === "")) {
          alert("Por favor responde todas las preguntas antes de enviar.");
          return;
        }
    
        const payload = {};
        // Usamos el 'key' real de la pregunta para generar el payload correcto
        questions.forEach((question, index) => {
          const answer = answers[index];
          if (answer !== null) {
            payload[`respuesta_${question.key.split("_")[1]}`] =
              answer === "Sí" ? "1" : answer === "No" ? "0" : answer;
          }
        });
    
        payload.id_empresa = id_empresa;
        payload.id_usuario = id_usuario;
        payload.id_encuesta = id_encuesta;
    
       
        try {
            setenviandoAlert(true);

          const response = await axios.post(
            `${backendURL}/api/encuesta/${id_empresa}/${id_usuario}/${id_encuesta}`,
            payload
          );
         
          setSuccessAlertOpen(true);
          setAlertMessage('¡Encuesta respondida exitosamente!');
          setTimeout(() => {
            setSuccessAlertOpen(false);
            handleNextStep()
        }, 5000);
          
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Hubo un error al guardar las respuestas. Intenta nuevamente.');
        
        }
        finally {
            setenviandoAlert(false);
        }
        setTimeout(() => {
            setSuccessAlertOpen(false);
            setErrorAlertOpen(false);
        }, 5000);
      };
    
      if (loadings) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <CircularProgress size={80} thickness={6}  /> {/* Hacemos el CircularProgress más grande */}
                    <PollIcon style={{ fontSize: 80, marginTop: 20, color: '#3f51b5' }} /> {/* Ícono de encuesta */}
                    <div style={{ marginTop: 10, fontSize: '20px', fontWeight: 'bold' }}>Cargando Encuesta...</div>
                </Box>
            </div>
        );
    }

     const SvgRedes = {
      color:
      marcas?.color_2 && marcas.color_2 !== "undefined"
        ? marcas.color_2
        : "#694B81",
        fontSize:'2rem'
    }

     // Vista si ya respondió
    if (alreadyResponded) {
      return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: marcas?.color_4 || "#E1DBE6",
               
                
               
            }}
        >
            {/* Navbar personalizado con el logotipo y colores */}
            {marcas ? (
                <Navbar
                    marcas={marcas}
                    color1={marcas?.color_1 || ""}
                    color2={marcas?.color_2 || ""}
                    logomarca={`${backendURL}/${marcas?.logo}`}
                />
            ) : (
                <Navbar />
            )}

<div className="encuesta-gracias" style={{
            background: marcas?.color_1 && marcas.color_1 !== "undefined"
            ? marcas.color_1
            : "#694B81"
          }}>
          <div className="barra-degradada" 
           style={{
            background: `linear-gradient(to right, ${marcas?.color_2 || '#ff6d00'}, ${marcas?.color_3 || '#ff0080'}, ${marcas?.color_4 || '#ff6d00'})`,
          }}></div>
                <FiAlertCircle
                    style={{ fontSize: 64, color: "#ffff", marginBottom: 2 }}
                />
                <div className="contenidoencuestafinal">
            <div className="iconofinalencuesta" style={{justifyContent:'center'}}>
              
            
            <h1 className="tituloencuestafnal" style={{textAlign:'center'}}>ya ha respondido esta encuesta.</h1>
            </div>
            <p className="textoencuestafnal">
              No puede volver a respondfer esta encuesta. Si necesita más inofrmación, puede escribirnos a: {Empresa.correo}
            </p>
            <h2 className="segundotituloencuestafnal">¡Gracias por preferir {Empresa.nombre_empresa}!</h2>
            <div className="logoencuestafinal">
            {marcas?.logo ? (
              <img src={`${backendURL}/${marcas.logo}`} width='100%' height='100%' alt='Smart Memorial' title='SmartMemorial' />
              ) : <>
              </>} 
            </div>
          </div>
            </div>
        </Box>
      );
  }
    
      const handleNextStep = () => {
        setStep(step + 1);
    };

   
    return (
        <>
      <Box sx={{ display: "flex", flexDirection: "column",backgroundColor: marcas?.color_4 || "#ffff", justifyContent: "center", alignItems: "center", backgroundColor: marcas?.color_4 || "#E1DBE6",  padding: 0 }}>
         
        {marcas ? (
          <Navbar
            marcas={marcas}
            color1={marcas?.color_1 || ""}
            color2={marcas?.color_2 || ""}
            logomarca={`${backendURL}/${marcas?.logo}`}
          />
        ) : (
          <Navbar />
        )}
        <Container maxWidth='md' sx={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        {/* Información de la encuesta */}
        {step === 1 && (
        <Box>
            
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            <div style={{ width: "70px" }}>
              {marcas?.logo ? (
                <img
                  src={`${backendURL}/${marcas.logo}`}
                  className="marcoimglogoempresademo"
                  alt="Smart Memorial"
                  title="SmartMemorial"
                />
              ) : (
                <></>
              )}
            </div>
            <Typography
              variant="h4"
              sx={{
                color:
                  marcas?.color_2 && marcas.color_2 !== "undefined"
                    ? marcas.color_2
                    : "#694B81",
                fontFamily: "Poppins",
                textTransform: "capitalize",
                fontWeight: "600",
                fontSize: "1.3rem",
              }}
            >
              {Empresa?.nombre_empresa || "Nombre Empresa"}
            </Typography>
          </Box>

          <Typography
            variant="h5"
            sx={{
              color:
                marcas?.color_2 && marcas.color_2 !== "undefined"
                  ? marcas.color_2
                  : "#694B81",
              fontFamily: "Poppins",
              textTransform: "capitalize",
              fontWeight: "600",
              fontSize: "1.1rem",
            }}
          >
            {EncuestaId.nombre_encuesta}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize:'0.8rem',
              marginTop:'20px',
              color:
                marcas?.color_2 && marcas.color_2 !== "undefined"
                  ? marcas.color_2
                  : "#694B81",
              fontFamily: "Poppins",
              textTransform: "capitalize",
              fontWeight: "500",
            }}
          >
            {EncuestaId.descripcion}
          </Typography>
        </Box>
        
        <Box sx={{
           mt: 2,
           mb: 2,
           p:2,
          transition: "opacity 500ms ease-in-out, transform 500ms ease-in-out",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Sin sombra si no es actual
          borderRadius: 2, }}>
        <Stepper activeStep={activeStep} orientation="vertical">
  {questions.map((question, index) => {
    const isCompleted = answers[index] !== null;
    const isCurrent = index === activeStep;
    const isFuture = index > activeStep;

    const handleAnswer = (answer) => {
      const updatedAnswers = [...answers];
      updatedAnswers[index] = answer;
      setAnswers(updatedAnswers);

      // Avanzar al siguiente paso si no es la última pregunta
      if (activeStep < questions.length - 1) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    };

    return (
      <Step key={question.key}>
        <StepLabel
          sx={{
            "& .Mui-active": {
              color:
                marcas?.color_2 && marcas.color_2 !== "undefined"
                  ? marcas.color_2
                  : "#694B81",
            },
            "& .MuiStepLabel-label": {
              color: isCurrent
                ? marcas?.color_2 || "#694B81"
                : isCompleted || isFuture
                ? marcas?.color_3 || "text.secondary"
                : "text.secondary",
              fontWeight: isCurrent ? "bold" : "normal",
            },
            "& .MuiStepIcon-root": {
              color:
                marcas?.color_3 && marcas.color_3 !== "undefined"
                  ? marcas.color_3
                  : "#694B81",
            },
            "& .MuiStepIcon-text": {
              fill: marcas?.color_2 || "#694B81",
            },
          }}
        >
          {question.value}
        </StepLabel>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            opacity: isCurrent ? 1 : 0.5,
            transform: isCurrent ? "translateY(0)" : "translateY(20px)",
            transition: "opacity 500ms ease-in-out, transform 500ms ease-in-out",
            visibility: isCompleted || isFuture || isCurrent ? "visible" : "hidden",
          }}
        >
          {question.key === "pregunta_10" ? (
            <TextField
              fullWidth
              sx={{
                marginTop: "-10px",
                color:
                  marcas?.color_2 && marcas.color_2 !== "undefined"
                    ? marcas.color_2
                    : "#694B81",
                "& .MuiOutlinedInput-input": {
                  color:
                    marcas?.color_2 && marcas.color_2 !== "undefined"
                      ? marcas.color_2
                      : "#694B81",
                },
                "& .MuiOutlinedInput-root": {
                  border: "1px solid",
                  borderColor:
                    marcas?.color_2 && marcas.color_2 !== "undefined"
                      ? marcas.color_2
                      : "#694B81",
                },
              }}
              variant="outlined"
              value={answers[index] || ""}
              onChange={(event) => handleAnswer(event.target.value)}
              placeholder="Escribe tu respuesta aquí..."
              disabled={!isCurrent}
            />
          ) : (
            <div style={{ marginTop: "-10px", marginBottom: "0px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color:
                        marcas?.color_2 && marcas.color_2 !== "undefined"
                          ? marcas.color_2
                          : "#694B81",
                    }}
                    checked={answers[index] === "Sí"}
                    onChange={() => handleAnswer("Sí")}
                    disabled={!isCurrent}
                  />
                }
                label="Sí"
                sx={{
                  color:
                    marcas?.color_2 && marcas.color_2 !== "undefined"
                      ? marcas.color_2
                      : "#694B81",
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color:
                        marcas?.color_2 && marcas.color_2 !== "undefined"
                          ? marcas.color_2
                          : "#694B81",
                    }}
                    checked={answers[index] === "No"}
                    onChange={() => handleAnswer("No")}
                    disabled={!isCurrent}
                  />
                }
                label="No"
                sx={{
                  color:
                    marcas?.color_2 && marcas.color_2 !== "undefined"
                      ? marcas.color_2
                      : "#694B81",
                }}
              />
            </div>
          )}
        </Box>
      </Step>
    );
  })}
</Stepper>



     

      {/* Botones para navegar entre preguntas */}
      <Box
  sx={{
    mt: 4,
    display: "flex",
    justifyContent: "space-between",
  }}
>
        {activeStep > 0 && <Button sx={{
      backgroundColor: marcas?.color_3 || "#694B81",
      color: "#fff",
      '&:disabled': {
        backgroundColor: "#ccc",
      },
       textTransform:'capitalize'
    }}onClick={handleBack}>Atrás</Button>}
        {activeStep === questions.length - 1 ? (
          <Button onClick={handleSubmit} sx={{
            backgroundColor: marcas?.color_2 || "#68A354",
            color: "#fff",
            '&:disabled': {
              backgroundColor: "#ccc",
            },
             textTransform:'capitalize'
          }}>Enviar</Button>
        ) : (
          <Button
          sx={{
            backgroundColor: marcas?.color_2 || "#68A354",
            color: "#fff",
            '&:disabled': {
              backgroundColor: "#ccc",
            },
             textTransform:'capitalize'
          }}
            onClick={() => {
              if (answers[activeStep] === null || answers[activeStep] === "") {
                alert("Por favor, responde esta pregunta antes de continuar.");
              } else {
                setActiveStep((prev) => prev + 1); // Avanzar a la siguiente pregunta solo si se ha respondido
              }
            }}
          >
            Siguiente
          </Button>
        )}
      </Box>
    </Box>
    
      </Box>)}
      {step === 2 && (
          <div className="encuesta-gracias" style={{
            background: marcas?.color_1 && marcas.color_1 !== "undefined"
            ? marcas.color_1
            : "#694B81"
          }}>
          <div className="barra-degradada" 
           style={{
            background: `linear-gradient(to right, ${marcas?.color_2 || '#ff6d00'}, ${marcas?.color_3 || '#ff0080'}, ${marcas?.color_4 || '#ff6d00'})`,
          }}></div>
          <div className="contenidoencuestafinal">
            <div className="iconofinalencuesta">
              <span role="img" aria-label="manos con corazón" className="iconoselecionencuesta"><TbHeartHandshake /></span>
            
            <h1 className="tituloencuestafnal">¡Muchas gracias por responder nuestra encuesta!</h1>
            </div>
            <p className="textoencuestafnal">
              Tu opinión es muy valiosa para nosotros y nos ayuda a mejorar para ofrecerte siempre el mejor servicio.
            </p>
            <h2 className="segundotituloencuestafnal">¡Gracias por preferir {Empresa.nombre_empresa}!</h2>
            <div className="logoencuestafinal">
            {marcas?.logo ? (
              <img src={`${backendURL}/${marcas.logo}`} width='100%' height='100%' alt='Smart Memorial' title='SmartMemorial' />
              ) : <>
              </>} 
            </div>
          </div>
        </div>
      )}
        </Container>
      </Box>

      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
      <Modal open={successAlertOpen} >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>
      <Snackbar 
    open={errorAlertOpen} 
    autoHideDuration={5000} 
    onClose={() => setErrorAlertOpen(false)} 
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
    <Alert 
        onClose={() => setErrorAlertOpen(false)} 
        severity="error" 
        sx={{ width: '100%' }}
    >
        {alertMessage}
    </Alert>
</Snackbar>


<Snackbar 
    open={avisoAlertOpen} 
    autoHideDuration={5000} 
    onClose={() => setAvisoAlertOpen(false)} 
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
    <Alert 
        onClose={() => setAvisoAlertOpen(false)} 
        severity="warning"
        sx={{ width: '100%' }}
    >
        {alertMessage}
    </Alert>
</Snackbar>
    </>
    );
}

