import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useNavigate, useParams } from "react-router-dom";
import { Container, Grid, Box, CircularProgress, Card, CardHeader, CardMedia, CardContent, CardActions, Avatar, IconButton, Typography } from '@mui/material';
import { BiErrorCircle } from "react-icons/bi";
import "../../Style/velatorio/Velatorio.css"
/* card de material ui */

import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/* swiper carrusel de comentarios */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const images = [
  'https://i.pinimg.com/736x/6a/fd/10/6afd108b9a2ef2e9d06bcbfae5e15df9.jpg',
  'https://i.pinimg.com/736x/10/12/d2/1012d2e1578d475ef18881e0f3a1421c.jpg',
  'https://i.pinimg.com/736x/b2/60/71/b260714c1e7370ed51f89d93d1ee208b.jpg',
];

const https = rateLimit(axios.create(), {
  maxRequests: 1, // Máximo de solicitudes
  perMilliseconds: 100, // Cada segundo
});

export const VelatorioVirtualIndex = ({ setShowNavbarFooter }) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [loadings, setLoadings] = useState(true);
  const [loading, setLoading] = useState(true);
  const [memorialData, setMemorialData] = useState(null);
  const [data, setData] = useState(null);
  const [marcas, setMarcas] = useState(null);
  const [empresaData, setEmpresaData] = useState(null);
  const [comentarios, setComentarios] = useState([]);
  const {nombre_empresa, id, id_empresa } = useParams();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const GrupoFamiliarId = memorialData?.id_grupo_familiar;

  
  useEffect(() => {
    // Ocultar el navbar y footer al montar el componente
    setShowNavbarFooter(false);
    return () => {
      // Mostrar el navbar y footer al desmontar el componente
      setShowNavbarFooter(true);
    };
  }, [setShowNavbarFooter]);

  useEffect(() => {
        // Detectar espacios en nombre_empresa
        if (nombre_empresa.includes(" ")) {
            // Transformar espacios en %20
            const fixedNombreEmpresa = encodeURIComponent(nombre_empresa);
            // Redirigir a la URL corregida
            navigate(`/${fixedNombreEmpresa}/${id_empresa}/Velatorio/virtual/${id}`, { replace: true });
        }
    }, [nombre_empresa, id, id_empresa, navigate]);

  useEffect(() => {
    if (!memorialData) return;

    const fetchGrupoFamiliar = async () => {
      
      setLoading(true);

      try {
        const response = await axios.get(`${backendURL}/api/get/GrupoFamiliar/public/${GrupoFamiliarId}`);
        const grupoFamiliarData = response.data;

        setData(grupoFamiliarData);
      
      } catch (error) {  // <-- Aquí estaba mal posicionado
        console.error('Error fetching grupo familiar:', error);
        navigate('/Registro');
      } finally {
        setLoading(false); // <-- Se ejecutará siempre, incluso en caso de error
      }
    };

    fetchGrupoFamiliar();
  }, [GrupoFamiliarId, backendURL, navigate]);
  useEffect(() => {
    const fetchData = async () => {
      setLoadings(true);
      try {

        // Obtener los datos del memorial
        const memorialDataResponse = await https.get(`${backendURL}/api/getMemorialIdGeneral/${id}`);
        const memorialData = memorialDataResponse.data;
        setMemorialData(memorialData);
        setError(false);
        setLoadings(false);

      } catch (error) {
        console.error('Error fetching memorial data:', error);
        setError(true);
      } finally {
        setLoadings(false);
      }
    };

    fetchData();
  }, [id, navigate, backendURL]);

  useEffect(() => {
    const fetchEmpresaData = async () => {
      try {
        const empresaResponse = await axios.get(`${backendURL}/api/empresa/especifica/${id_empresa}`);
        setEmpresaData(empresaResponse.data.empresa);

      } catch (error) {
        console.error("Error al obtener datos de la empresa:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmpresaData();
  }, [id_empresa, backendURL]);

  useEffect(() => {
    const fetchMarcasData = async () => {
      try {
        const marcasResponse = await axios.get(`${backendURL}/api/marcaempresa/especifica/${id_empresa}`);
        const marcaData = marcasResponse.data.data[0];

        if (marcaData) {
          setMarcas(marcaData); // Guardar la marca si existe
        }
      } catch (error) {
        console.error("Error al obtener datos de marcas:", error);
      }
    };

    fetchMarcasData();
  }, [id_empresa, backendURL]);

  /* comentarios memorial */
  const fetchComentarios = async () => {
    try {
        const response = await axios.get(`${backendURL}/api/get/comentariosmemorial/${id}`);
       
        
        // Ordena los comentarios por fecha de manera descendente
        const sortedComentarios = response.data
        .sort((a, b) => new Date(b.fecha) - new Date(a.fecha)) // Ordena por fecha descendente
        .reverse(); // Invierte el orden para que el último agregado esté primero
    
        
        setComentarios(sortedComentarios);
 
    } catch (error) {
        console.error('Error al obtener comentarios:', error);
    }
};

// Llama a esta función en el useEffect para obtener comentarios al cargar la página
useEffect(() => {
  // Llamamos a la función para obtener comentarios al cargar la página
  fetchComentarios();

  // Configuramos un intervalo para actualizar los comentarios cada 5 segundos
  const interval = setInterval(() => {
      fetchComentarios();
  }, 5000); // Actualiza cada 5 segundos

  // Limpiamos el intervalo cuando el componente se desmonta
  return () => clearInterval(interval);
}, [id]); 

  if (loading || loadings) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Pantalla completa
          flexDirection: 'column',
        }}
      >
        <CircularProgress
          size={100}
          thickness={4}
          sx={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }}
        />
      </Box>
    );
  }
  if (error) {
    return (
      <div style={{ position: 'fixed', zIndex: 9999, width: "100%", height: "100%", backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: '100%', textAlign: 'center' }}>
          <BiErrorCircle style={{ color: "red", fontSize: "1.8rem" }} />
          <p>Error en cargar la página memorial</p>
        </Box>
      </div>
    );
  }
  /* grupo familiar para traer qr */

  /*estilos con colores de marca  */
  const getColorByFondo = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return '#E1EDDD';
      case 'atardecerVerano':
        return '#F9E7D7';
      default:
        return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
    }
  };
  const getColorByborder = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81';
    }
  };

  const getColorByborderperfil = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return '#E1EDDD';
      case 'atardecerVerano':
        return '#F9E7D7';
      default:
        return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
    }
  };

  const borderColor = getColorByFondo(memorialData?.fondo);


  const getLightBackgroundByFondo = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return '#F3F8F1';
      case 'atardecerVerano':
        return '#FDF5EF';
      default:
        return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
    }
  };

  const backborderColor = getLightBackgroundByFondo(memorialData?.fondo);


  const styles = {
    titleStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#694B81';
        }
      })(),
    },
    titulooscuroStyleuno: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#27632D';
          case 'atardecerVerano':
            return '#A04424';
          default:
            return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#D26999';
        }
      })(),
    },
    fondomensajeStyle: {
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : '#694B81';
        }
      })(),
    },
    fondosStyle: {
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#F3F8F1';
          case 'atardecerVerano':
            return '#FDF5EF';
          default:
            return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 : '#F0EDF3';
        }
      })(),
    },
    titulodosStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#F3F8F1';
          case 'atardecerVerano':
            return '#FDF5EF';
          default:
            return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 : '#F0EDF3';
        }
      })(),
    },
    titulooscuroStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#27632D';
          case 'atardecerVerano':
            return '#A04424';
          default:
            return (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : '#D26999';
        }
      })(),
    },
    titulooscuroDosStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#27632D';
          case 'atardecerVerano':
            return '#A04424';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#D26999';
        }
      })(),
    },
    botonoscuroStyle: {
      backgroundColor: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#27632D';
          case 'atardecerVerano':
            return '#A04424';
          default:
            return (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : '#D26999';
        }
      })(),
    },
    texto: {
      color: "#fff"
    },
    selectedIconStyle: {

      cursor: "pointer",
      width: '40px',
      height: '40px',
      color: '#fff',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81';
        }
      })(),
    },
    iconStyle: {
      cursor: "pointer",
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      background: 'transparent',  // Aquí está el error corregido
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81';
        }
      })(),
    },
    modalContent: {
      maxHeight: '80vh', // Limitar la altura máxima
      overflowY: 'auto', // Hacer el contenido desplazable si es necesario
      position: 'relative',
    },
    modalImage: {
      width: '100%',
      maxHeight: '60vh',
      objectFit: 'contain',
    },
    buttonmas: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81';
        }
      })(),
      border: `1px solid ${getColorByborder(memorialData?.fondo)}`,
      '--button-bg-hover': backborderColor,
      '--button-border-hover': borderColor,
    },
    styleborder: {
      border: `8px solid ${getColorByborderperfil(memorialData?.fondo)}`,

    },
    styleiconoblose: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#E1EDDD';
          case 'atardecerVerano':
            return '#F9E7D7';
          default:
            return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
        }
      })(),

    }
  };


  /* final de diseño y colores */

  /* transformacion de fechas */
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };
  return (
    <section>
      <Grid container>
        <Grid item xs={12}sm={6} md={4} sx={{
          ...styles.fondomensajeStyle,
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',

        }}>
          <Box sx={StyleBoxLogo}>
            <div className='marcologovelatorioempresa'>
              {marcas?.logo ? (
                <img src={`${backendURL}/${marcas.logo}`} className='marcoimglogoempresademo' alt='Smart Memorial' title='SmartMemorial' />
              ) : <>
              </>
              }
            </div>
          </Box>
          <Box sx={StyleBoxmemorialimagen}>
            <div className="memorialimagenvelatorio" style={styles.styleborder}>
              {memorialData && (
                <img src={`${backendURL}/imagenes/memoriales/${memorialData.carpeta_perfil}/${memorialData.imagen_perfil}`} alt="smart memorial" style={styles.fondosStyle} />
              )}
            </div>
            <div className="TituloNombreVelatorio">
              <h1 className="nombre" style={styles.titulodosStyle}>{memorialData.nombre}</h1>
              <h3 className="fechadefusion" style={styles.titulodosStyle}>{formatDate(memorialData.fecha_nacimiento)} • {formatDate(memorialData.fecha_defuncion)}</h3>
            </div>
          </Box>
            <Box sx={StyleBoxQrvelatorio}>
                      <Box sx={StyleBoxCardQr}>
                        <Grid container>
                          <Grid item xs={12} sm={3} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Box sx={{width:'100px'}}>
                            {data&&(    
                              <img src={`${backendURL}/imagenes/qr/empresa/${empresaData.nombre_empresa}/${data.qr}`} className="imagenqrmemorial" alt={`${empresaData.nombre_empresa}`}/>
                            )}
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={9} sx={{ display:'flex', justifyContent:'center',  flexDirection:'column'}}>
                            <Box className="contieneFootervelatorioVirtualEmpresa" > 
                              <Typography className="TituloSecundarioPaginaVirtualEmpresa"  sx={{...styles.titulooscuroDosStyle,  fontWeight:'600', fontFamily:'Poppins'}} >
                              Comparte un mensaje en nuestro memorial
                              </Typography>
                              <Typography className="TextoSecundarioPaginaVirtualEmpresa"  sx={{fontSize:'0.9rem', fontWeight:'500', color:'#585857', fontFamily:'Poppins'}}>
                              Escanea este código con la cámara de tu celular y comparte tu mensaje.
                                </Typography>
          
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
          
                    </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8} sx={StyleContainerSwipper}>
          <Container sx={{height:'100vh', mt:1, display: 'flex',
              alignItems: 'center',
              justifyContent: 'center', }} >
          {comentarios.length === 0 ? (
            <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding:'20px'
            }}
          > <Grid container>
          <Grid item xs={12} >
            <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
              <div style={{width:'300px'}}>
                {data&&(    
                  <img src={`${backendURL}/imagenes/qr/empresa/${empresaData.nombre_empresa}/${data.qr}`} className="imagenqrmemorial" alt={`${empresaData.nombre_empresa}`}/>
                )}
            </div>
            </Box>
          </Grid>
          <Grid item xs={12}  sx={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
            <Box sx={{width:'100%', p:2, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column' }}> 
              <Typography variant="h3" sx={{...styles.titulooscuroDosStyle, fontSize:'1.2rem', fontWeight:'600', fontFamily:'Poppins'}} >
              Comparte un mensaje en su memoria
              </Typography>
              <Typography variant="body1" sx={{fontSize:'0.9rem', fontWeight:'500', color:'#585857', fontFamily:'Poppins', width:'400px', textAlign:'center'}}>
                  Escanéa con la  cámara de tu celular este código y comparta  con familiares y amigos.
                </Typography>

            </Box>
          </Grid>
        </Grid>
      </Box>
    ) : (
     
            <Swiper
              className="Swiper-Wrapper-Velatoriouno"
              modules={[Autoplay, Navigation, Pagination]}
              spaceBetween={20}
              slidesPerView={1}
              autoplay={{ delay: 4000, disableOnInteraction: false }}
              navigation
              loop={true}
              style={{ 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center", 
                padding: '20px', 
                width: '100%', 
                height: '100%'  
              }}

            >
              {/* Carrusel con varias tarjetas */}
              <Container 
                sx={{
                 
                  display: "flex",
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '20px',
                  
                }}
              >
            <Swiper
             className="Swiper-Wrapper-Velatorio"
              modules={[Autoplay, Navigation, Pagination]}
              spaceBetween={20}
              slidesPerView={1}
              autoplay={{ delay: 9000, disableOnInteraction: false }}
              navigation
              loop={true}
              style={{ 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center", 
                padding: '20px', 
                width: '100%', 
                height: '100%'  
              }}
              
            >
              {/* Carrusel con varias tarjetas */}
              <div className="swiper-wrapper" > 
              {comentarios.map((comentario, index) => (
                <SwiperSlide
                 key={index} 
                style={{ 
                  display: "flex", 
                  alignItems: "center", 
                  justifyContent: "center", 
                  height: "100%",
                }}
                >
                  <Card sx={{ width: 500,  }}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : '#694B81'[500] }} aria-label="recipe">
                          {comentario.creador ? comentario.creador[0].toUpperCase() : "?"}
                        </Avatar>
                      }

                      title={
                        <Box sx={{  width: "100%" }}>
                          <Typography variant="body2" sx={{ ...styles.titulooscuroDosStyle, fontWeight: "600", fontFamily:'Poppins',textTransform:'capitalize' }}>
                          {comentario.titulo}
                          </Typography>
                        </Box>
                        }
                      subheader={
                        <Box sx={{ fontFamily:'Poppins', display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <Typography variant="body2" sx={{  fontWeight: "600", fontFamily:'Poppins',textTransform:'capitalize' }}>
                            {comentario.creador}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "gray", fontFamily:'Poppins', }}>
                            {new Date(comentario.fecha).toLocaleDateString("es-ES", {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      }
                    />
                     {comentario.imagen && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CardMedia
                            component="img"
                            sx={{
                              width: '300px',
                              height: '300px',
                              objectFit: 'cover'
                            }}
                            image={`${backendURL}/${comentario.imagen}`} // Asegúrate de usar la URL correcta
                            alt={comentario.titulo}
                          />
                        </Box>
                      )}
                    <CardContent>
                      <Typography variant="body2" sx={{ color: 'text.secondary', fontFamily:'Poppins' }}>
                      {comentario.mensaje}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing sx={{ justifyContent: "flex-end" }}>
                      <IconButton aria-label="add to favorites">
                        <section className="corazonmemorialdenmo">
                          <div className="containerheart">
                            <div className="heart">
                              <svg width="50" height="50" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z" />
                              </svg>
                            </div>
                            <div className="heart">
                              <svg width="40" height="40" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z" />
                              </svg>
                            </div>
                            <div className="heart">
                              <svg width="35" height="35" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z" />
                              </svg>
                            </div>
                          </div>
                        </section>
                      </IconButton>

                    </CardActions>
                  </Card>
                </SwiperSlide>
              ))}
              </div>
            </Swiper>
          
          </Container>
            </Swiper>
           
 )}
          </Container>
          
        </Grid>

      </Grid>

    </section>
  )
}


/* estilos material ui */

const StyleBoxLogo = {
  padding: ' 5px '
}

const StyleBoxmemorialimagen = {
  padding: '20px',
  marginTop: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}

const StyleContainerSwipper = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop:'0px'
}

const StyleBoxQrvelatorio = {
  width: '100%',
  background: '#f4f3f2',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',

}

const StyleBoxCardQr = {
  width: '100%',
  padding: '20px 0px'
}
