import React, { useEffect } from "react";
import EmpresaLayout from "../../../../../../../EmpresaLayout";
import { IndexEstructuraEstadistica } from "../../../FuncionesEmpresaAdmi/Tareas/EstadisticaPaginaEmpresa/EstructuraIndexEstadistica";

export const EstadisticaEmpresa = ({ setShowNavbarFooter }) => {

    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);
    return (

        <EmpresaLayout>
            <IndexEstructuraEstadistica />
        </EmpresaLayout>
    )
}