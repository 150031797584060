import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from 'jspdf';
import rateLimit from 'axios-rate-limit';
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import { useNavigate, useParams, useLocation} from "react-router-dom";
import "../../../../../Style/AdministradorSuperEmpresa/adminmemorialempresapagina.css"
import cartacondolencias from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/cartacondolencias.png"

import SucursalLayout from "../../../../../SucursalLayout"
import { TbUserHeart } from "react-icons/tb";


/* componenetes */
import { EditarMemorialSistemaSucursal } from "../FuncionesSucursalAdmin/MemorialEditar/EditarMemorial";
import { VelatorioComentarioSucursal } from "../FuncionesSucursalAdmin/VelatorioComentarios/ComentariosVelatorios";
import { CrearObituarioSucursal } from "../FuncionesSucursalAdmin/ObituarioEmpresa/CrearObituario";
import { EditarObituarioSucursal } from "../FuncionesSucursalAdmin/ObituarioEmpresa/EditarObituario";

/* libreria UI */
import { Container, Grid, Box, CircularProgress,  Modal,  Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { LiaUserEditSolid } from "react-icons/lia";
/* react icons */
import { IoAddCircleOutline } from "react-icons/io5";
import { TbCross } from "react-icons/tb";
import { IoIosClose } from "react-icons/io";

const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 3000 });

export const IndexMemorialSucursal = ({setShowNavbarFooter}) =>{
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { Token, User, Sucursal, Usuario, UserDataMarca, Empresa } = useUserEmpresa();
    const { id, id_qr } = useParams(); // Este es el id_grupo_familiar
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [Memoriales, setMemoriales] = useState([])
    const { sucursal, user, QR} = location.state || {};
    const [openModal, setOpenModal] = useState(false);
    const [openModaledit, setOpenModaledit] = useState(false);
    const [selectedMemorial, setSelectedMemorial] = useState(null); // Estado para el memorial seleccionado
    const [selectedobituario, setSelectedobituario] = useState(null); 
    const [selectedGrupoFamiliar, setSelectedGrupoFamiliar] = useState(QR);
    const [openUserQRModal, setOpenUserQRModal] = useState(false);
    const [openModalvercomentarios, setOpenModalvercomentarios] = useState(false);
    const [openModalEditarmemorial, setOpenModalEditarMemorial] = useState(false);
    const [users, setUser] = useState(null);
    const [pdfGenerated, setPdfGenerated] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const navigate = useNavigate();
  
   
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    useEffect(() => {
      const fetchUserData = async () => {
          if (!QR.id_usuario || !Token) return;

          try {
              const response = await http.get(`${backendURL}/api/getUserByIdespecifica/sucursal/${QR.id_usuario}`, {
                  headers: {
                      Authorization: `Bearer ${Token}`, // Incluye el token en los headers
                  },
              })
              setUser(response.data); // Almacena la información del usuario en el estado
          } catch (error) {
              console.error("Error fetching user data:", error);
          } finally {
              setLoading(false); // Cambia el estado de loading a false
          }
      };

      fetchUserData();
  }, [QR?.id_usuario, Token, backendURL]);
  
const handleAssignUser = (qr) => {
    navigate(`/registro/memorial/${id}/sucursal`, { state: {grupoFamiliar:QR, sucursal, user } });
  };

  const handleCrearMemorial = () => {
    navigate(`/sucursal/${id}/memorial/crear/nuevo`, { state: {QR, sucursal, user } });
  };
  
  // Función para abrir el modal de Usuario QR
  const handleOpenUserQRModal = () => {
    setOpenUserQRModal(true);
  };

  // Función para cerrar el modal de Usuario QR
  const handleCloseUserQRModal = () => {
    setOpenUserQRModal(false);
  };
        /* Accordeon */
        const [expanded, setExpanded] = useState(false);
        const handleAccordionChange = (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };

        const fetchMemoriales = async () => {
          if (!sucursal?.id || !Token) return;
          setLoading(true);
          try {
            const response = await http.get(`${backendURL}/api/getMemorialesPorGrupoFamiliar/sucursal/${id_qr}`, {
              headers: {
                Authorization: `Bearer ${Token}`,
              },
            });
            setMemoriales(response.data);
          } catch (error) {
            console.error('Error al obtener los memoriales', error);
          } finally {
            setLoading(false);
          }
        };
      
        
      
      useEffect(() => {
          fetchMemoriales();
         
        }, [Token, id_qr, backendURL, sucursal.id_empresa]);
      
        // Función para abrir el modal con el memorial seleccionado
        const handleOpenModal = (memorial) => {
          setSelectedMemorial(memorial);
          setSelectedGrupoFamiliar(QR);
          setOpenModal(true);
        };
        const handleCloseModal = () => setOpenModal(false);

        const handleObituarioCreado = () => {
          fetchMemoriales(); // Actualiza la lista de memoriales
          setOpenModal(false); // Cierra el modal
        };
        
         // Función para abrir el modal con el memorial seleccionado
         const handleOpenModaledit = (obituarioId) => {
          setSelectedobituario(obituarioId);
          setSelectedGrupoFamiliar(QR);
          setOpenModaledit(true);
        };
        const handleCloseModaledit = () => setOpenModaledit(false);

         // Función para abrir el modal con el memorial seleccionado
         const handleOpenModalvercomentarios = (MemorialId) => {
         
          setSelectedMemorial(MemorialId);
          setSelectedGrupoFamiliar(QR);
          setOpenModalvercomentarios(true);
        };
        const handleCloseModalvercomentarios = () =>  setOpenModalvercomentarios(false);

        const handleOpenModalEditarMemorial = (MemorialId) => {
         
          setSelectedMemorial(MemorialId);
          setSelectedGrupoFamiliar(QR);
          setOpenModalEditarMemorial(true);
        };
        const handleCloseModalEditarMemorial = () =>  setOpenModalEditarMemorial(false);


        const addTextToPDF = (doc, text, options) => {
          const { font, fontSize, startY, lineHeight, maxWidth, imgWidth } = options;
        
          doc.setFont(font || "helvetica", "bold");
          doc.setFontSize(fontSize || 12);
        
          const lines = doc.splitTextToSize(text, maxWidth || imgWidth);
          lines.forEach((line, index) => {
            doc.text(line, imgWidth / 2, startY + index * lineHeight, { align: "center" });
          });
        };
        
        const handleDownloadPDF = async () => {
          try {
            // URLs
            const qrImageUrl = `${backendURL}/api/descargarqrempresa/${id_qr}`;
            const logoImageUrl = `${backendURL}/api/descargarLogo/${Empresa.id}`;
            const fondoImage = cartacondolencias;
        
            // Función para cargar imágenes
            const loadImage = (url) => {
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.crossOrigin = "Anonymous";
                img.onload = () => resolve(img);
                img.onerror = (err) => reject(err);
              });
            };
        
            // Cargar imágenes
            const logoImage = await loadImage(logoImageUrl);
            const qrImage = await loadImage(qrImageUrl);
            const fondoImg = await loadImage(fondoImage);
        
            const doc = new jsPDF();
        
            // Dimensiones del documento
            const imgWidth = doc.internal.pageSize.getWidth();
            const imgHeight = doc.internal.pageSize.getHeight();
        
            // Añadir imagen de fondo
            doc.addImage(fondoImg.src, "PNG", 0, 0, imgWidth, imgHeight);
        
            // Añadir logo
            const logoSize = 35;
            const logoX = (imgWidth - logoSize) / 2;
            const logoY = 60;
            doc.addImage(logoImage.src, "PNG", logoX, logoY, logoSize, logoSize);
        
            // Añadir texto principal
            addTextToPDF(doc, "Expresamos nuestras sinceras condolencias para la familia y acompañantes en este difícil momento.", {
              font: "helvetica",
              fontSize: 15,
              startY: 40,
              lineHeight: 6,
              maxWidth: 150,
              imgWidth,
            });
        
            // Añadir texto secundario
            addTextToPDF(
              doc,
              `${Empresa.nombre_social} le ofrece un memorial virtual para preservar los bellos momentos en la vida de su ser querido.`,
              {
                font: "helvetica",
                fontSize: 15,
                startY: 100,
                lineHeight: 5,
                maxWidth: 120,
                imgWidth,
              }
            );
        
            // Añadir QR
            const qrSize = 100;
            const qrX = (imgWidth - qrSize) / 2;
            const qrY = 150;
            doc.addImage(qrImage.src, "PNG", qrX, qrY, qrSize, qrSize);
        
            // Añadir texto tercero
            addTextToPDF(doc, "Visita el memorial y deja un  recuerdo o un mensaje de amor.", {
              font: "helvetica",
              fontSize: 13,
              startY: 137,
              lineHeight: 5,
              maxWidth: 80,
              imgWidth,
            });
          
            const pdfBlob = doc.output("blob");
            const pdfBlobUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfBlobUrl);
          // Guardar el PDF
            doc.save(`cartadefuncion_${id_qr}.pdf`);
          } catch (error) {
            console.error("Error al generar el PDF:", error);
          }
        };
       

        const handleOpenVelatorioVirtual = (memorialId) => {
          window.open(`/${Empresa.nombre_empresa}/${Empresa.id}/Velatorio/virtual/${memorialId}`, "_blank");

        };
   

    return(
      <SucursalLayout>
        <Container maxWidth='lg'>

        <Box sx={{
            marginTop:'50px'
        }}>
            <Grid Container>
                <Grid item xs={12}>
               <div className="encabezadoTitulo">
               
                <div className="ContieneTituloNombreEmpresa">
                    <h2 className="TituloEmpresaTipo">Sucursal</h2>
                    <h3>{sucursal?.nombre_sucursal}</h3>
                </div>
                </div> 
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={4}>
                            <div className="TituloGrupoFamiliar">
                            <h3>Grupo Familiar</h3> 
                            <h3>{QR?.nombreFamilia || "No disponible"}</h3>
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="contienenbotonesCrearmemorial">
                          {
                            (QR?.tipoQR === "Memorial" || QR?.tipoQR === "Grupo Familiar") && !QR?.nombreFamilia ? (
                              <div>
                                <button className="botoncrearmemorilaempresa" onClick={handleAssignUser}>Crear QR Familiar</button>
                              </div>
                            ) : (
                              <div></div>
                            )
                          }
                             {(QR?.tipoQR === "Grupo Familiar" || QR?.tipoQR === '') && QR?.nombreFamilia ? (
                                                              <div>
                                                                <button className="botonagregarmemorilaempresa" onClick={handleCrearMemorial}>
                                                                  <IoAddCircleOutline />Agregar un nuevo memorial
                                                                </button>
                                                              </div>
                                                            ) : QR?.tipoQR =="Memorial" && (!QR.cantidadMemorial || QR.cantidadMemorial == 0 ) && QR?.nombreFamilia ? (
                                                              // Si no hay memorial o está vacío y el tipo de memorial es 2, muestra el botón
                                                              <div>
                                                                <button className="botonagregarmemorilaempresa" style={{ marginLeft: '5px' }} onClick={handleCrearMemorial} >
                                                                  <IoAddCircleOutline />Agregar un nuevo memorial
                                                                </button>
                                                              </div>
                                                            ) : (
                                                             <></>
                                                            )}
                                {(QR?.tipoQR === "Memorial" || QR?.tipoQR === '') && QR.nombre_familia ? (
                                 <div style={{
                                  display:"flex",
                                  alignItems:"center",
                                  justifyContent:"center"
                                }}>
                                <TbUserHeart style={{
                                  fontSize:'2.5rem',
                                  margin:'0px 10px',
                                  color:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') ? UserDataMarca.color_1 :'#E1DBE6',
                                }}/> 
                                <div className="textoinformativoempresamemorial" style={{
                                  fontSize:'0.8rem',
                                  fontFamily:'Poppins',
                                  width:'270px',
                                  textAlign:'justify',
                                  paddingRight:'5px'
                                }}>
                                 Este memorial es de carácter personal y ya está asociado con otro memorial.
                                </div>
                                </div>):( <></>)}
                                <div>
                                <button className="botoncrearmemorilaempresa" style={{
                                  marginLeft:'5px'
                                }}
                                onClick={handleOpenUserQRModal}>
                                   Información QR asignado
                                   </button>
                                </div>
                                </div> 
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Box sx={{ marginTop: '50px' }}>
            {loading ? (
        // Mostrar CircularProgress mientras se cargan los datos
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: '200px' }}
        >
          <CircularProgress />
        </Box>
      ) : Memoriales.length === 0 ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 200,
        border: "1px dashed rgba(0, 0, 0, 0.2)",
        borderRadius: 8,
        padding: 2,
        backgroundColor: "#fefefe",
      }}
    >
      <WarningAmberIcon
        sx={{
          fontSize: 40,
          color: "#FF9800",
          marginBottom: 1,
        }}
      />
      <Typography variant="h6" color="textSecondary">
        No hay memorial creado
      </Typography>
    </Box>
  ) : (
    // Si hay memoriales, muestra la lista de memoriales
    Memoriales.map((memorial, index) => (
            <Accordion
              key={memorial.id}
              expanded={expanded === `panel${index}`}
              onChange={handleAccordionChange(`panel${index}`)}
              sx={StyleAcordeon}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{
                  color:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                  ? UserDataMarca.color_1 
                  : '#694B81',
                }}/>}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography sx={StyleTitulo}>Memorial {memorial.nombre}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={4}>
                  {memorial.imagen_perfil &&(
                  <section className="contiene-input-imagen-perfil">
                <label htmlFor="file-input" className="input-subir">
                  <img src={`${backendURL}/imagenes/memoriales/${memorial.carpeta_perfil}/${memorial.imagen_perfil}`} alt="Vista previa de la imagen" />
                </label>
            
              </section>
  )}
                  </Grid>
                  <Grid item xs={6}>
                    <div className="contieneinfomemorial">
                      <ul>
                        <li className="infomemodescripcion">
                        <span> Nombre: </span> <h4>{memorial.nombre}</h4>
                        </li>
                        <li className="infomemodescripcion">
                       <span>rut:</span> <h4>{memorial.rut}</h4> 
                        </li>
                        <li className="infomemodescripcion">
                        <span>Fecha Nacimiento:</span>  
                        <h4>
                            {new Intl.DateTimeFormat('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                .format(new Date(memorial.fecha_nacimiento))}
                        </h4>
                        </li>
                        <li className="infomemodescripcion" style={{
                                      fontFamily:'Poppins'
                                    }}>
                        <span>Fecha Defunción:</span> 
                        <h4>
                            {new Intl.DateTimeFormat('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                .format(new Date(memorial.fecha_defuncion))}
                        </h4>
                        </li>
                        <li className="infomemodescripcion">
                         <span>Servicio:</span> <h4>  {memorial.tipo_servicio ? (
    <ul>
      {memorial.tipo_servicio.split(',').map((servicio, index) => (
        <li key={index}>
          <h4>{servicio.trim()}</h4>
        </li>
      ))}
    </ul>
  ) : (
    <h4>No disponible</h4> // Mensaje en caso de que no haya datos
  )}</h4>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                     <button 
                                         onClick={() => handleOpenModalEditarMemorial(memorial.id)}
                                        style={{ 
                                         padding:'10px 20px',
                                         display:'flex',
                                         alignItems:'center',
                                         justifyContent:'center',
                                         fontFamily:'Poppins',
                                         borderRadius:'50px',
                                         fontWeight:'500',
                                         background:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                         ? UserDataMarca.color_1 
                                         : '#694B81',
                                         }}>
                                          
                                          <LiaUserEditSolid style={{fontSize:'1.5rem', marginRight:'5px'}}/> Editar Memorial
                                          
                                          </button>
                  </Grid>
                </Grid>
                <Box sx={styleBoxVelatorio}>
                                  <div>
                                  <Typography variant="h6" color="textSecondary" sx={{color:' #585857', mb:3}}>
                        Velatorio Virtual
                      </Typography>
                
                      <Typography variant="body1" color="textSecondary" sx={{color:' #585857', mb:3}}>
                      Nuestro Velatorio Virtual es un espacio conmemorativo en línea donde familiares y amigos pueden reunirse para honrar la memoria de sus seres queridos. A través de un muro de condolencias, los asistentes pueden compartir mensajes, recuerdos y palabras de apoyo.
                
                Además, cada imagen que los participantes suban se integrará automáticamente en una galería conmemorativa, creando un homenaje visual que perdurará en el tiempo. Este espacio facilita la interacción y el acompañamiento emocional, permitiendo a todos rendir homenaje sin importar la distancia.
                      </Typography>
                                  </div>
                                   <Box
                                                    sx={{
                                                      display:'flex',
                                                      alignItems:'center',
                                                      justifyContent:'space-between'
                                                    }}>
                                <button
                                      className="botonObituarioEmpresa"
                                      onClick={() => handleOpenVelatorioVirtual(memorial.id)}
                                    >
                                      <TbCross /> Velatorio virtual
                                    </button>
                                    <button
                      className="botonObituarioEmpresa"
                      onClick={() => handleOpenModalvercomentarios(memorial.id)}
                    >
                      <RemoveRedEyeIcon /> Revisar comentarios virtual
                    </button>
                                    </Box>
                                </Box>
                <Grid container sx={{
                  marginTop:'50px'
                }}>
                  <Grid item xs={6} sx={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'flex-start',
                    flexDirection:'column',
                    width:'100%'
                  }}>
                   {memorial.obituario ? (
                    <button
                      className="botonObituarioEmpresa"
                      onClick={() => handleOpenModaledit(memorial.obituario.id)}
                    >
                      <TbCross /> Editar Obituario
                    </button>
                  ) : (
                    <button
                      className="botonObituarioEmpresa"
                      onClick={() => handleOpenModal(memorial)}
                    >
                      <TbCross /> Crear Obituario
                    </button>
                  )}
                  {memorial.obituario && (
                      <>
                      
                      <Box
                        sx={{
                          marginTop: "40px",
                          padding: "0px",
                          borderRadius: "8px",
                          fontWeight:'500'
                        }}
                      >
                        
                        <a href={`/Obituario/${memorial.obituario.id}`} target="_blank" rel="noopener noreferrer" className="vistaobituarioempresa">
                          Ver Obituario Completo
                        </a>
                      </Box>
                       <Box sx={{ marginTop: '20px', width:'100%' }}>
                       <iframe
                         src={`/Obituario/${memorial.obituario.id}`}
                         title="Vista previa del Obituario"
                         width="100%"
                         height="500px"
                         style={{ border: 'none' }}
                       />
                     </Box>
                      
                      </>
              )}
                  </Grid>
                  
                  <Grid item xs={6} sx={{
                   display:'flex',
                   alignItems:'center',
                   justifyContent:'flex-start',
                   flexDirection:'column',
                   width:'100%'
                  }}>
                    
                    <button className="botonObituariodescargarEmpresa" onClick={handleDownloadPDF}><TbCross /> Descargar QR condolencias </button>
                    
                    {pdfGenerated && (
        <div style={{ marginTop: "20px" }}>
          <h3 className="escritopdfmemorial">Vista previa del PDF generado:</h3>
          <iframe
            src={pdfGenerated}
            width="400"
            height="500"
            style={{ border: "1px solid #000" }}
            title="Vista previa del PDF"
          ></iframe>
        </div>
      )}
                  </Grid>
                 
                </Grid>
              </AccordionDetails>
            </Accordion>
           ))
          )}
        </Box>
      </Box>
                 

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: '#ffff',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            height:'500px',
            overflowY:'auto'
          }}
        >
          <Box sx={{
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'flex-end',
            position:'relative'
          }}>
          <button onClick={handleCloseModal} className="botoncloseempresamodalobituario">
          <IoIosClose />
          </button>
           </Box>
            <Box sx={{
            position:'relative'
          }}>
               <CrearObituarioSucursal memorial={selectedMemorial} grupoFamiliar={selectedGrupoFamiliar} sucursal={sucursal} onclose={handleCloseModal}  onObituarioCreado={handleObituarioCreado}/>
            </Box>
        </Box>
      </Modal>
      
      <Modal
        open={openModaledit}
        onClose={handleCloseModaledit}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: '#ffff',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            height:'500px',
            overflowY:'auto'
          }}
        >
          <Box sx={{
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'flex-end'
          }}>
          <button onClick={handleCloseModaledit} className="botoncloseempresamodalobituario">
          <IoIosClose />
          </button>
           </Box>
            <Box>
               <EditarObituarioSucursal obituarios={selectedobituario} grupoFamiliar={selectedGrupoFamiliar} sucursal={sucursal} onclose={handleCloseModaledit}  onObituarioCreado={handleObituarioCreado}/> 
            </Box>
        </Box>
      </Modal>
      <Modal
          open={openUserQRModal}
          onClose={handleCloseUserQRModal} // Cierra el modal al hacer clic fuera
        >
          <Box sx={style}>
          <Box sx={{
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'flex-end',
            borderRadius:'10px'
          }}>
          <button onClick={handleCloseUserQRModal} className="botoncloseempresamodalobituario">
          <IoIosClose />
          </button>
           </Box>
          
            <h2 style={{
              color:'#694B81',
              fontWeight:'600'
            }}>Usuario QR {id_qr}</h2>
        
            <Box>
            {users ? (
                            <div className="contieneinfomemorial">
                                <ul>
                                    <li className="infomemodescripcion">
                                        <span>Nombre:</span> <h4>{users.name} {users.apellido}</h4>
                                    </li>
                                    <li className="infomemodescripcion">
                                        <span>Telefóno:</span> <h4>{users.telefono}</h4>
                                    </li>
                                    <li className="infomemodescripcion">
                                        <span>Correo:</span> <h4>{users.email}</h4>
                                    </li>
                                    <li className="infomemodescripcion">
                                        <span>Genero:</span> <h4>{users.genero}</h4>
                                    </li>
                                    <li className="infomemodescripcion">
                                        <span>Rut:</span> <h4>{users.rut}</h4>
                                    </li>
                                </ul>
                            </div>
                        ) : (
                            <p>No hay información disponible para el usuario.</p>
                        )}
            </Box>
          </Box>
        </Modal>
         <Modal
                open={openModalvercomentarios}
                onClose={handleCloseModalvercomentarios}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: '#ffff',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: 4,
                    height:'600px',
                    overflowY:'auto'
                  }}
                >
                  <Box sx={{
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems:'flex-end'
                  }}>
                  <button onClick={handleCloseModalvercomentarios} className="botoncloseempresamodalobituario">
                  <IoIosClose />
                  </button>
                   </Box>
                    <Box>
                      <VelatorioComentarioSucursal memorial={selectedMemorial}/>
                     
                    </Box>
                </Box>
              </Modal>
              <Modal
                open={openModalEditarmemorial}
                onClose={handleCloseModalEditarMemorial}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: '#ffff',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: 4,
                    height:'600px',
                    overflowY:'auto'
                  }}
                >
                  <Box sx={{
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems:'flex-end'
                  }}>
                  <button onClick={handleCloseModalEditarMemorial} className="botoncloseempresamodalobituario">
                  <IoIosClose />
                  </button>
                   </Box>
                    <Box>
        
                     <EditarMemorialSistemaSucursal 
                      fetchMemoriales={fetchMemoriales} 
                      onclose={handleCloseModalEditarMemorial}
                      memorial={selectedMemorial}/>
                     
                    </Box>
                </Box>
              </Modal>
        </Container>
        </SucursalLayout>
    )
}

const StyleAcordeon = {
    width:"100%",
    background:'#ffff'
}


const StyleTitulo ={
    fontFamily:'Poppins',
    color: '#585857',
    fontWeight:'600'
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  borderRadius:'10px',
  bgcolor: '#ffff',
  boxShadow: 24,
  p: 4,
};

const styleBoxVelatorio = {
  padding:'20px',
  border:'1px solid #585857',
  fontFamily:'Poppins',
  borderRadius:'20px',
  margin:'20px 20px',
  display:'flex',
  alignItems:'center',
  justifyContent:"center",
  flexDirection:'column'
}

