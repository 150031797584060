import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import { useNavigate, useParams } from "react-router-dom";
import EmpresaLayout from "../../../../../../../EmpresaLayout";
/* libreria UI */
import { Container, Box, CircularProgress, Snackbar, Button, LinearProgress} from '@mui/material';
import { styled } from '@mui/material/styles'; 
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';

/* react */
import { BsQrCode } from "react-icons/bs";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlineHistoryEdu } from "react-icons/md";
/* imagenes */
import logomemorialdos from "../../../../../../../Image/generales/grupo-familia-2.png";



export const CrearMemorialHistorico = ({ setShowNavbarFooter }) =>{
     const { Usuario, Token, Empresa, UserDataMarca} = useUserEmpresa();
     const [loading, setLoading] = useState(false);
     const navigate = useNavigate()
     const [message, setMessage] = useState("");
     const [openSnackbar, setOpenSnackbar] = useState(false); 
     const [step, setStep] = useState(1);
     const [groupName, setGroupName] = useState('');
     const [error, setError] = useState('');
     const [isLoading, setIsLoading] = useState(false);
     const[grupoFamiliar, setGrupoFamiliar] = useState([]);
     const backendURL = process.env.REACT_APP_BACKEND_URL; 
     const theme = useTheme();


     useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

     const handleNextStep = () => {
        // Incrementa el paso
        const nextStep = step + 1;
        setStep(nextStep);
    
        // Si el nuevo paso es 2, llama a fetchGrupoFamiliarById
        if (nextStep === 2) {
            fetchGrupoFamiliarById(Usuario.id); // Llama a la función con el id del usuario
        }
    };

    const handleCreateQr = async () => {
        setLoading(true);
        setMessage(""); // Limpiamos el mensaje

        try {
            const response = await axios.post(
                `${backendURL}/api/qr/empresa/memorial/historico`, 
                {
                    id_usuario: Usuario.id, 
                    id_empresa: Empresa.id, 
                    cantidad: 1, 
                    tipo_memorial:"3" 
                },
                {
                    headers: {
                        Authorization: `Bearer ${Token}`, 
                    },
                }
            );

            setMessage("Código QR creado exitosamente.");
            setOpenSnackbar(true); // Abrir el Snackbar

            // Avanzar al siguiente paso después de 3 segundos
            setTimeout(() => {
                handleNextStep(); // Llama a la función para avanzar al siguiente paso
                setOpenSnackbar(false); // Cierra el Snackbar
            }, 3000); // Espera 3000 ms (3 segundos)
        } catch (error) {
            console.error("Error al crear el QR:", error);
            setMessage("Error al crear el código QR. Por favor, intenta nuevamente.");
            setOpenSnackbar(true); // Abrir el Snackbar

            // Cerrar el Snackbar después de 3 segundos
            setTimeout(() => {
                handleNextStep();
                setOpenSnackbar(false);
            }, 3000); // Espera 3000 ms (3 segundos)
        } finally {
            setLoading(false); // Ocultamos el spinner
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    /* editar nombre grupo familiar empresa */
    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
        setError(''); // Limpiar el mensaje de error cuando se comienza a escribir
      };
        
      const fetchGrupoFamiliarById = async (id) => {
        try {
            const response = await axios.get(`${backendURL}/api/qr/Memorial/Historico`, {
                params: { id_usuario: Usuario.id }, // Asegúrate de usar 'params'
                headers: {
                    'Authorization': `Bearer ${Token}`,
                },
            });
    
            // Verifica si la respuesta fue exitosa
            if (response.status >= 200 && response.status < 300) {
                // Obtén el último grupo familiar generado
                const ultimoGrupoFamiliar = response.data[response.data.length - 1];
                setGrupoFamiliar(ultimoGrupoFamiliar);
            } else {
                console.error('Error al obtener el grupo familiar:', response.data.message);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    };

    const handleSaveClick = async (id) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${backendURL}/api/editar/GrupoFamiliar/Memorial/Historico/${grupoFamiliar.id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    nombre_familia: groupName, 
                    id_usuario: id  // Usa el 'id' pasado a la función
                }),
            });
    
            if (response.status >= 200 && response.status < 300) {
                const updatedData = await response.json(); // Procesa la respuesta del servidor
    
                // Llamamos a fetchGrupoFamiliar para obtener el grupo actualizado
                await fetchGrupoFamiliarById(id); // Pasa el id del usuario aquí
    
                // Actualizamos el nombre del grupo familiar con los datos obtenidos
                setGroupName(updatedData.nombre_familia); // Asegúrate de que el nombre sea correcto
    
                // Mostrar mensaje de éxito en el Snackbar
                setMessage("Se ha creado con éxito el nombre del grupo.");
                setOpenSnackbar(true); // Abre el Snackbar
    
                // Mostrar el modal por 3 segundos y luego avanzar al siguiente paso
               
                setTimeout(() => {
                   
                    handleNavigateCreardemo(grupoFamiliar); // Avanzar al siguiente paso
                    setOpenSnackbar(false); // Cierra el Snackbar después de 3 segundos
                }, 3000);
            } else {
                const data = await response.json();
                setError(data.message || 'Error al actualizar el nombre del grupo familiar');
            }
        } catch (error) {
            console.error('Error al actualizar el nombre del grupo familiar:', error);
            setError('Error al actualizar el nombre del grupo familiar');
        } finally {
            setIsLoading(false);
        }
    };
  
  const handleNextPage = async () => {
    if (!groupName.trim()) {
      setError('Por favor, escribe el nombre del grupo familiar');
      return;
    }
     handleSaveClick(); 
  };

/* navegacion */
const handleNavigateCreardemo = (grupoFamiliar) => {
    navigate(`/galeria/video/memorialeshistoricos/${Usuario.id}`, {
      state: { grupoFamiliar },
    });
  };
   
    return(
            <EmpresaLayout>
                <Container maxWidth='lg'>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        {step === 1 && (
                            <div style={{
                                width:'100%',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                                flexDirection:'column'
                            }}>
                            <div className='contiene-imagen-grupo-memorialempresa'>
                            <MdOutlineHistoryEdu style={{
                                fontSize: '5rem', position: 'relative', left: '-4px', color: '#585857'
                            }} />
                              <h1 style={{textAlign:'center', color:'#585857'}}> ¡Bienvenido a la creación del memorial historico! </h1>
                  
                            </div>
                            <StyledButton sx={{background: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                                    ? UserDataMarca.color_1
                                    : '#694B81'}} variant="contained" onClick={handleCreateQr} disabled={loading}>
                                <BsQrCode sx={{fontSize:'2.5rem' }} /> {loading ? <CircularProgress size={24} /> : "Haz clic aquí para crear memorial historico"}
                            </StyledButton>
                            </div>
                        )}
                        {step === 2 && (
                            <> <Box sx={StyleBoxdos}>
    
                            <div className='contiene-imagen-grupo-memorialempresa'>
                            <MdOutlineHistoryEdu style={{
                                fontSize: '5rem', position: 'relative', left: '-4px', color: '#585857'
                            }} />
                              <h1 style={{textAlign:'center', color:'#585857'}}> ¡Bienvenido a la creación del memorial historico! </h1>
                  
                  
                            </div>
                            
                            <div className="instructivo">
                              <section className="tituloinstructivo">
                                <h5 style={{ color:'#585857'}}>Sugerencias para el nombre del Memorial historico:
                                </h5>
                              </section>
                              <ul>
                                <li className="lista">Utiliza el nombre de la persona historica, por ejemplo: <strong>"Violeta Parra"</strong>.</li>
                                
                              </ul>
                            </div>
                            <div className="alertainformacionempresa">
                              <h3>Este nombre será visible en la plataforma, por lo que te recomendamos elegir una combinación que mejor represente la memoria.</h3>
                            </div>
                            <div style={{ width: '700px' }}>
                              <div className="creacion-input-memorial">
                                <div className="creacion-nombre-grupo-familiarempresa">
                                  <label className="titulo-inupt-familia">Nombre del QR del memorial historico</label>
                                  <input
                                   style={{
                                    background:theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                                  }}
                                    name="familia"
                                    className={`input-nombre-familia ${error && 'input-error'}`}
                                    placeholder="Ej: Violeta Parra"
                                    value={groupName}
                                    onChange={handleGroupNameChange}
                                  />
                                  {error && <p className="errormessage"><FiAlertCircle />{error}</p>}
                                </div>
                              </div>
                            </div>
                            <div className="contienen-botones">
                              <div className="boton-registro">
                                <button onClick={handleNextPage} style={{background: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                                    ? UserDataMarca.color_1
                                    : '#694B81'}} disabled={isLoading}>  {isLoading ? <CircularProgress size={24} /> : "Crear"}</button>
                              </div>
                            </div>
                  
                          </Box></>
                        )}
                       
                    </Box>
                </Container>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={openSnackbar}
                    autoHideDuration={6000} // Duración antes de que se cierre automáticamente
                    onClose={handleCloseSnackbar}
                >
                    <Alert 
                        onClose={handleCloseSnackbar} 
                        severity={message.includes("exitosamente") ? "success" : "error"} 
                        variant="filled"
                    >
                        {message}
                        <LinearProgress 
                            variant="indeterminate" // Cambia a indeterminate para animación
                            sx={{ marginTop: '5px', height: '4px', borderRadius: '5px', backgroundColor: 'white' }} // Color blanco
                        />
                    </Alert>
                </Snackbar>
            </EmpresaLayout>
    
    
    )
}

const StyledButton = styled(Button)(() => ({
    marginTop:'10px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-evenly',
    width:'400px',
    textTransform:'capitalize',
    borderRadius: '50px',
    padding: '10px 30px',
    fontFamily: 'Poppins',
    backgroundColor: '#694B81',
    color: 'white',
    '&:hover': {
        backgroundColor: '#8E6BB3', // Color al hacer hover
    },
    transition: 'background-color 0.3s ease', // Suaviza la transición de color
}));

const Alert = styled(MuiAlert)(({  severity }) => ({
    backgroundColor: severity === 'success' ? '#95e892' : '#eebbbb', // Verde claro o rojo claro
    color: '#fff',
    fontFamily:'Poppins',
    fontWeight:'600',
    '& .MuiAlert-icon': {
        display: 'none', // Ocultar el icono si no se necesita
    },
}));

const StyleBoxdos = {
    marginTop: '100px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  }