import React, {useEffect, useState} from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";

/* Material UI */
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    Box,
    Typography,
    Grid,
    CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import StarIcon from '@mui/icons-material/Star';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export const EstadsiticaPorSucursalEmpresa = () =>{
   
    const {Token, Empresa,  UserDataMarca}=useUserEmpresa();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sucursalesData, setSucursalesData] = useState([]);
    const [TopSucursal, setTopSucursal] = useState([]);
    
    useEffect(() => {
        if (!backendURL) {
            console.warn("⚠️ La variable de entorno REACT_APP_BACKEND_URL no está definida.");
            setLoading(false);
            return;
        }

        if (!Empresa?.id) {
            setLoading(false);
            return;
        }

        const fetchGruposFamiliares = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${backendURL}/api/estadistica/empresa/por/sucursal/qr`, {
                    params: { id_empresa: Empresa.id },
                    headers: { Authorization: `Bearer ${Token}` },
                });

               // Ordenar por cantidad de usuarios asignados (descendente)
               const sortedData = response.data.map(sucursal => ({
                ...sucursal,
                grupos_por_admin: sucursal.grupos_por_admin.sort((a, b) => b.total_creados - a.total_creados)
            }));

            setSucursalesData(sortedData);
            } catch (err) {
                console.error("❌ Error en la petición:", err);
                setError(err.response?.data?.message || "Error al obtener los datos");
            } finally {
                setLoading(false);
            }
        };

        fetchGruposFamiliares();
    }, [backendURL, Empresa.id, Token]);

    useEffect(() => {
        if (!backendURL) {
            console.warn("⚠️ La variable de entorno REACT_APP_BACKEND_URL no está definida.");
            setLoading(false);
            return;
        }

        if (!Empresa?.id) {
            setLoading(false);
            return;
        }

        const fetchTopSucursal = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${backendURL}/api/estadistica/empresa/top/ventas/sucursal`, {
                    params: { id_empresa: Empresa.id },
                    headers: { Authorization: `Bearer ${Token}` },
                });


                setTopSucursal(response.data)
              
            } catch (err) {
                console.error("❌ Error en la petición:", err);
                setError(err.response?.data?.message || "Error al obtener los datos");
            } finally {
                setLoading(false);
            }
        };

        fetchTopSucursal();
    }, [backendURL, Empresa.id, Token]);


   
    return(
        <>
         {loading && <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>< CircularProgress/></div>}
        {error && <p style={{ color: "red" }}>{error}</p>}

        {!loading && !error && sucursalesData.length == 0 && (
            <p style={{ textAlign: "center", fontSize: "18px", color: "#666", fontFamily:'Poppins' }}>
                No hay sucursales creadas.
            </p>
        )}

        {!loading && !error && sucursalesData.length > 0 ? (
           
             <Box>
                 <Box sx={{p:2, display:'flex', alignItems:'center', justifyContent:'space-between', flexDirection:'row'}}>
                    <Card sx={{ p:2, width:'500px', height:'auto',background:'#ffff',
                                                border:'1px solid',
                                                }}>
                                                            <CardContent>
                                                                <Box sx={{ flexDirection:'column',position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            
                                                                <Typography variant="h6" sx={{ marginTop: 2,  fontSize:'1rem',mb:'10',  color: "#585857", textAlign:'center' }}>
                                                                Sucursal Líder en Ventas
                                                                </Typography>
                                                                <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                                
                                                                    <CircularProgress
                                                                        variant="determinate"
                                                                        value={100} // Círculo completo
                                                                        size={150} // Tamaño del círculo
                                                                        thickness={4}
                                                                        sx={{ color: "#FFD700" }} // Color del círculo (ajustable)
                                                                    />
                                                                    <Box sx={{ position: "absolute", top: 30, left: "50%", transform: "translateX(-50%)" }}>
                                                                    <EmojiEventsIcon sx={{ fontSize: 40, color: "#FFD700" }} />
                                                                    </Box>
                                                                    <Typography
                                                                        variant="h4"
                                                                        sx={{
                                                                            top:80,
                                                                            fontSize:'1rem',
                                                                            position: "absolute",
                                                                            fontWeight: "600",
                                                                            color: "#585857", // Color del número
                                                                        }}
                                                                    >
                                                                        
                                                                   {TopSucursal?.sucursal_top?.total_grupos_familiar ?? 0}
                                                                    </Typography>
                                                                    </Box>
                                                                    <Box >
                                                                    <Typography variant="h6" sx={{ marginTop: 2, color: "#585857", textAlign:'center' }}>
                                                                    {TopSucursal?.sucursal_top?.nombre_sucursal ?? "No disponible"}
                                                                </Typography>
                                                                        </Box>
                                                                </Box>
                                                                
                                                            </CardContent>
                                                        </Card>
                                                    <Card sx={{ p:2, width:'500px',background:'#ffff',
                                            border:'1px solid',
                                            }}>
                                                        <CardContent>
                                                            <Box sx={{ flexDirection:'column',position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                           
                                                            <Typography variant="h6" sx={{ marginTop: 2, fontSize:'1rem',mb:'10', color: "#585857", textAlign:'center' }}>
                                                            Vendedor Destacado de la Sucursal Top en Ventas
                                                            </Typography>
                                                            <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                            
                                                                <CircularProgress
                                                                    variant="determinate"
                                                                    value={100} // Círculo completo
                                                                    size={150} // Tamaño del círculo
                                                                    thickness={4}
                                                                    sx={{ color: "#FFD700" }} // Color del círculo (ajustable)
                                                                />
                                                                <Box sx={{ position: "absolute", top: 30, left: "50%", transform: "translateX(-50%)" }}>
                                                                <EmojiEventsIcon sx={{ fontSize: 40, color: "#FFD700" }} />
                                                                </Box>
                                                                <Typography
                                                                    variant="h4"
                                                                    sx={{
                                                                        top:80,
                                                                        fontSize:'1rem',
                                                                        position: "absolute",
                                                                        fontWeight: "600",
                                                                        color: "#585857", // Color del número
                                                                    }}
                                                                >
                                                                    
                                                                   {TopSucursal?.admin_top?.total_creados ?? 0} 
                                                                </Typography>
                                                                </Box>
                                                                <Box >
                                                                <Typography variant="h6" sx={{ marginTop: 2, color: "#585857", textAlign:'center' }}>
                                                                {TopSucursal?.admin_top?.nombre ?? "No disponible"} {TopSucursal?.admin_top?.apellido ?? "No disponible"}
                                                            </Typography>
                                                                    </Box>
                                                            </Box>
                                                            
                                                        </CardContent>
                                                    </Card>
                </Box>
                <Box sx={{p:2}}>
             {sucursalesData.map((sucursal, index) => (
                 <Accordion key={index} sx={{background:'#ffff', fontFamily:'Poppins', color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5', }}>
                     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                         <Typography variant="h6">{sucursal.sucursal.nombre_sucursal}</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Grid container spacing={3} justifyContent="center">
                             <Grid item xs={12} sm={6} md={4}>
                                                    <Card sx={{ background:'#ffff',
                                            border:'1px solid',
                                            borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                                                ? UserDataMarca.color_1
                                                : '#E1DBE6'}}>
                                                        <CardContent>
                                                            <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                                <CircularProgress
                                                                    variant="determinate"
                                                                    value={100} // Círculo completo
                                                                    size={150} // Tamaño del círculo
                                                                    thickness={4}
                                                                    sx={{ color: "primary.main" }} // Color del círculo (ajustable)
                                                                />
                            
                                                                <Typography
                                                                    variant="h4"
                                                                    sx={{
                                                                        fontSize:'1rem',
                                                                        position: "absolute",
                                                                        fontWeight: "600",
                                                                        color: "#585857", // Color del número
                                                                    }}
                                                                >
                                                                   {sucursal.total_grupos_familiar}
                                                                </Typography>
                                                                </Box>
                                                                <Box sx={{ marginLeft:'30px'}}>
                                                                <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                                                Total de Códigos QR Memoriales Creado
                                                            </Typography>
                                                                    </Box>
                                                            </Box>
                                                            
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                 <Grid item xs={12} sm={6} md={4}>
                                                                        <Card sx={{ background:'#ffff',
                                                                border:'1px solid',
                                                                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                                                                    ? UserDataMarca.color_1
                                                                    : '#E1DBE6' }}>
                                                                            <CardContent>
                                                                                <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                                                    <CircularProgress
                                                                                        variant="determinate"
                                                                                        value={(sucursal.total_grupos_familiar / sucursal.grupos_disponibles) * 100} // Porcentaje de disponibilidad
                                                                                        size={150} // Tamaño del círculo
                                                                                        thickness={4}
                                                                                        sx={{ color: "green" }} // Color para "disponibles"
                                                                                    />
                                                
                                                                                    <Typography
                                                                                        variant="h4"
                                                                                        sx={{
                                                                                            fontSize:'1rem',
                                                                                            position: "absolute",
                                                                                            fontWeight: "600",
                                                                                            color: "#585857", // Color del número
                                                                                        }}
                                                                                    >
                                                                                        {sucursal.grupos_disponibles}
                                                                                    </Typography>
                                                                                    </Box >
                                                                                    <Box sx={{ marginLeft:'30px' }}>
                                                                                      <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                                                                    Qr Disponibles
                                                                                </Typography>   
                                                                                        </Box>
                                                                                </Box>
                                                                               
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={4} >
                                                                                            <Card sx={{ background:'#ffff',
                                                                                    border:'1px solid',
                                                                                    borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                                                                                        ? UserDataMarca.color_1
                                                                                        : '#E1DBE6' }}>
                                                                                                <CardContent>
                                                                                                    <Box sx={{ position: "relative", display: "inline-flex", flexDirection:'row', justifyContent: "center", alignItems: "center" }}>
                                                                                                      <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                                                                        <CircularProgress
                                                                                                            variant="determinate"
                                                                                                            value={(sucursal.total_grupos_familiar  / sucursal.grupos_con_usuario) * 100} // Porcentaje de en uso
                                                                                                            size={150} // Tamaño del círculo
                                                                                                            thickness={4}
                                                                                                            sx={{ color: "orange" }} // Color para "en uso"
                                                                                                        />
                                                                    
                                                                                                        <Typography
                                                                                                            variant="h4"
                                                                                                            sx={{
                                                                                                                fontSize:'1rem',
                                                                                                                position: "absolute",
                                                                                                                fontWeight: "600",
                                                                                                                color: "#585857", // Color del número
                                                                                                            }}
                                                                                                        >
                                                                                                           {sucursal.grupos_con_usuario}
                                                                                                        </Typography>
                                                                                                        </Box>
                                                                                                        <Box sx={{marginLeft:'30px'}}>
                                                                                                        <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                                                                                        Qr asignados por clientes
                                                                                                    </Typography>
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                   
                                                                                                </CardContent>
                                                                                            </Card>
                                                                                        </Grid>
                        </Grid>
                      
 
                         <DataGrid
                             rows={sucursal.grupos_por_admin.map((admin, idx) => ({
                                 id: idx,
                                 icon: "⭐", // Se usa para la estrella en la primera columna
                                 name: sucursal.usuarios.find(user => user.id == admin.id_admin_sucursal)?.name || "Desconocido",
                                 apellido: sucursal.usuarios.find(user => user.id == admin.id_admin_sucursal)?.apellido || "",
                                 total_creados: admin.total_creados,
                                 disponibles: admin.qr_disponibles,
                                 asignados: sucursal.grupos_con_usuario,
                             }))}
                             columns={[
                                 { field: "icon", headerName: "⭐", width: 50 },
                                 { field: "name", headerName: "Nombre", flex: 150 },
                                 { field: "apellido", headerName: "Apellido", flex: 150 },
                                 { field: "total_creados", headerName: "Grupos Creados", width: 300 },
                                 { field: "disponibles", headerName: "Disponibles", width: 300 },
                                 { field: "asignados", headerName: "QR Asignados", width: 300 },
                             ]}
                             autoHeight
                             disableSelectionOnClick
                             pageSize={5}
                             sx={{

                                marginTop: '50px',
                                fontFamily: 'Poppins',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra del borde
                                borderRadius: '8px', // Borde redondeado (si lo deseas)
                                '& .MuiDataGrid-columnHeaders': {
                                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de texto de la cabecera
                                  fontWeight: '600', // Estilo de fuente de la cabecera
                                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Sombra para la cabecera
                                  borderBottom: '2px solid rgba(0, 0, 0, 0.2)', // Borde inferior de la cabecera
                                },
                                '& .MuiDataGrid-cell': {
                                  color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5',
                                  borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde entre celdas
                                },
                                '& .MuiCheckbox-root': {
                                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de los checkboxes (opcional)
                                },
                                '& .MuiTablePagination-selectLabel':{
                                    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                                    fontFamily:'Poppins'
                                },
                                '& .MuiTablePagination-displayedRows' : {
                                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                                    fontFamily:'Poppins'
                                },
                                '& .MuiInputBase-input':{
                                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                                    fontFamily:'Poppins'
                                },
                                '& .Mui-disabled':{
                                    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                                    fontFamily:'Poppins'
                                },
                                '& .MuiSelect-icon':{
                                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                                    fontFamily:'Poppins'
                                }
                              }}
                         />
                     </AccordionDetails>
                 </Accordion>
             ))}
             </Box>
         </Box>
       ) : (
        !loading && <p>No hay grupos sucursales asociados.</p>
    )}
        
        </>

    )
}