import React, { useEffect } from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import { useLocation} from 'react-router-dom';
import "../../../Style/generales/recetcon.css";

export const Finalgenerarcontraseña = ({setShowNavbarFooter}) => {
   const location = useLocation();
    const { marcas } = location.state || {}; 

    useEffect(() => {
            // Ocultar el navbar y footer al montar el componente
            setShowNavbarFooter(false);
            return () => {
                // Mostrar el navbar y footer al desmontar el componente
                setShowNavbarFooter(true);
            };
        }, [setShowNavbarFooter]);
    return(
        <>
           <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent:'center'
          }}
        >
            <section className="checkcambiocontraseña">
            <FaRegCircleCheck />
            </section>
            <section className="titulocontraseñacorrecta">
                <h1 style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}>Su contraseña ha sido</h1>
                <h1 style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}>cambiada con éxito</h1>
            </section>
            <div className="botonverificarregistro">
            <Link to={marcas?.id_empresa ? `/empresa/${marcas.id_empresa}/login/usuario` : "/"}>
  <button style={{ background: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }}>
    Iniciar Sesión
  </button>
</Link>

            </div>

        </Box>
        </Container>
        
        </>
    )
}