import React from "react";
import { useState, useEffect } from 'react'
import { useLocation, useNavigate, Link } from "react-router-dom";
import "../../../../Style/usuario/obituario.css"
import { Iconos, Iconosverdes, Iconosnaranjos } from "../../../../data/dataicono";
import { useUsers } from "../../../context/userscontexts";

/* elemento */
import axios from 'axios';
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Container, Modal, CircularProgress } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';

/* icono */
import { RxCalendar } from "react-icons/rx";
import { LuClock2 } from "react-icons/lu";
import { FiAlertCircle } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";

/* imagenes data previsualizacion  verde*/
import v25 from "../../../../Image/obituario/verde/v25.png"
import v26 from "../../../../Image/obituario/verde/v26.png"
import v27 from "../../../../Image/obituario/verde/v27.png"


/* imagenes data previsualizacion  naranjo*/
import n22 from "../../../../Image/obituario/naranjo/n22.png"
import n23 from "../../../../Image/obituario/naranjo/n23.png"
import n24 from "../../../../Image/obituario/naranjo/n24.png"


/* imagenes data previsualizacion  morado*/
import icono24 from "../../../../Image/obituario/m24.png";
import icono25 from "../../../../Image/obituario/m25.png";
import icono26 from "../../../../Image/obituario/m26.png";




export const Obituariocreardos = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const grupoFamiliar = location.state.familia;
  const memorialId = location.state.memorial;
  const [logoUrl, setLogoUrl] = useState('');
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [memorialData, setMemorialData] = useState({});
  const [selectedOption, setSelectedOption] = useState('clasicoSmartMemorial');
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [selectedIcon, setSelectedIcon] = React.useState(Iconos[0].value);
  const [direccionVelatorio, setDireccionVelatorio] = useState('');
  const [fechadesdeVelatorio, setFechadesdeVelatorio] = useState('');
  const [fechahastaVelatorio, setFechahastaVelatorio] = useState('');
  const [horaSalida, setHoraSalida] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [fechaFuneral, setFechaFuneral] = useState('');
  const [horaFuneral, setHoraFuneral] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [genero, setGenero] = useState('');
  const [direccion, setDireccion] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [edad, setEdad] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const {userData} = useUsers();
 

  const handleChange = (event) => {
    setSelectedIcon(event.target.value);
  };

  /* memorial */
  const fetchMemorialData = async (id) => {
    try {
      const response = await axios.get(`${backendURL}/api/getMemorialId/${id}`, {
        
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ClientToken.token}`,
        }
      });
      setMemorialData(response.data);
      console.log("lo que llega del memorial", response.data)
      setDireccion(response.data.cementerio);
      const fechaNacimiento = new Date(response.data.fecha_nacimiento);
      const fechaDefuncion = new Date(response.data.fecha_defuncion);
      const calculatedEdad = calcularEdad(fechaNacimiento, fechaDefuncion);

      setEdad(calculatedEdad);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching memorial data:', error);
      setLoading(false);
    }
  };

  const calcularEdad = (fechaNacimiento, fechaDefuncion) => {
    let edad = fechaDefuncion.getFullYear() - fechaNacimiento.getFullYear();
    let meses = fechaDefuncion.getMonth() - fechaNacimiento.getMonth();
    let dias = fechaDefuncion.getDate() - fechaNacimiento.getDate();

    // Ajustar los días si la diferencia es negativa
    if (dias < 0) {
        meses--;  // Reducimos un mes
        const ultimoDiaMesAnterior = new Date(
            fechaDefuncion.getFullYear(),
            fechaDefuncion.getMonth(),
            0
        ).getDate();
        dias += ultimoDiaMesAnterior;  // Sumamos los días restantes del mes anterior
    }

    // Ajustar los meses si la diferencia es negativa
    if (meses < 0) {
        edad--;  // Reducimos un año
        meses += 12;  // Sumamos 12 meses para ajustar
    }

    // Mostrar solo el valor más relevante
    if (edad > 0) {
        return `${edad} ${edad === 1 ? 'año' : 'años'}`;
    } else if (meses > 0) {
        return `${meses} ${meses === 1 ? 'mes' : 'meses'}`;
    } else {
        return `${dias} ${dias === 1 ? 'día' : 'días'}`;
    }
};


  useEffect(() => {
    fetchMemorialData(memorialId);
  }, [memorialId]);

/* editar fondo y cementerio selecion */

const handleSelectChange = (event) => {
  const selectedValue = event.target.value;
  setSelectedOption(selectedValue);
  updatefondo(selectedValue, direccion); // Actualiza fondo y dirección
};

const handleDireccionChange = (event) => {
  const newDireccion = event.target.value;
  setDireccion(newDireccion);

  updatefondo(selectedOption, newDireccion); // Actualiza fondo y dirección
};

const updatefondo = async (selectedOption, newDireccion) => {
  try {
    const response = await axios.post(`${backendURL}/api/updateMemorial/${memorialId}`,
      { fondo: selectedOption, cementerio: newDireccion }, 
      {
        headers: {
          'Authorization': `Bearer ${ClientToken.token}`
        }
      }
    );

    fetchMemorialData(memorialId);
    navigate('/usuariomemoriales', { state: { user: ClientToken, familia: grupoFamiliar } });
  } catch (error) {
    console.error('Error al editar el memorial:', error);
  }
};

  /* funeraria Logo traer */
  useEffect(() => {
    const fetchFuneraria = async () => {
      try {
        if (grupoFamiliar && grupoFamiliar.id_funeraria) {
          const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
          const funerariaData = response.data;
          setLink(funerariaData);
          if (funerariaData.nombre === 'smart memorial') {
            setLogoUrl(null); // No mostrar logo
          } else if (funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
          } else {
            setLogoUrl(null); // No hay logo disponible
          }

        } else {
          console.error('Group family or funerary ID is missing');
        }
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };

    fetchFuneraria();
  }, [grupoFamiliar]);

  /* click */
   // Manejar la selección del género
   const handleGeneroChange = (event) => {
    setGenero(event.target.value);
    setSelectedChips([]); // Reinicia los chips seleccionados al cambiar el género
  };

  // Manejar el clic en el Chip
  const handleChipClick = (label) => {
    setSelectedChips((prev) =>
      prev.includes(label) ? prev.filter((chip) => chip !== label) : [...prev, label]
    );
  };

  // Listas de parentesco según el género
  const parentescoMasculino = [
    'Papá',
    'Hermano',
    'Esposo',
    'Abuelo',
    'Tío',
    'Hijo',
    'Primo',
    'Nieto',
  ];

  const parentescoFemenino = [
    'Madre',
    'Hermana',
    'Esposa',
    'Abuela',
     'Tía',
    'Hija',
    'Prima',
    'Nieta',
   
  ];

  const parentesco = genero === 'Masculino' ? parentescoMasculino : parentescoFemenino;


  /* formulario obituario  */
  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };
  
  const handleAccept = () => {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId };
    navigate('/usuariomemoriales', { state });
}; 


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccessMessage(''); // Limpiar mensajes anteriores
    setErrorMessage('');
    if (!descripcion || !genero || selectedChips.length === 0 || !direccionVelatorio || !fechadesdeVelatorio || !fechahastaVelatorio || !horaSalida || !fechaFuneral || !horaFuneral) {
      setLoading(false);
      setErrorMessage('Todos los campos son obligatorios.');
      return;
  }
    
    const obituarioData = {
      edad:1,
      descripcion: descripcion,
      genero: genero,
      estado: 1,
      parentesco: JSON.stringify(selectedChips),
      direccion: direccionVelatorio,
      fecha_desde: fechadesdeVelatorio,
      fecha_hasta : fechahastaVelatorio,
      hora_salida: horaSalida,
      fecha_funeral: fechaFuneral,
      hora_funeral: horaFuneral,
      icono: selectedIcon, 
      id_memorial:memorialData.id,
       diseno:'2'
    };
  
    try {
      const response = await axios.post(`${backendURL}/api/crearObituario`, obituarioData, {
        headers: {
          'Authorization': `Bearer ${ClientToken.token}`,
          'Content-Type': 'application/json'
        }
      });
      setLoading(false); 
      setSuccessMessage('Obituario creado exitosamente.');
      handleAccept();
      // Redirige a una página después de crear el obituario
    } catch (error) {
      setErrorMessage('Ocurrió un error al crear el obituario. Por favor, inténtalo de nuevo.');
      setLoading(false);
    }
  };

  /* previzualizacion */
  const handleOpenModal = (e) => {
    e.preventDefault(); // Para prevenir el comportamiento por defecto del formulario

    if (!descripcion || !genero || !direccionVelatorio || !fechadesdeVelatorio || !fechahastaVelatorio || !horaSalida || !fechaFuneral || !horaFuneral || !selectedIcon || !selectedOption) {
      setErrorMessage('Por favor, completa todos los campos requeridos.');
      return;
    }
  
    // Si todos los campos están completos, se limpia el mensaje de error y se abre el modal
    setErrorMessage('');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};

const getDayOfWeek = (dateString) => {
  const date = new Date(dateString + 'T00:00:00'); 
  const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
  return days[date.getDay()];
};

const styles = {
  titleStyle: {
    color: (() => {
      switch (memorialData?.fondo) {
        case 'naturalezaAustral':
          return '#68A354';
        case 'atardecerVerano':
          return '#E28939';
        default:
          return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
      }
    })(),
  },
  fondomensajeStyle: {
    background: (() => {
      switch (memorialData?.fondo) {
        case 'naturalezaAustral':
          return '#68A354';
        case 'atardecerVerano':
          return '#E28939';
        default:
          return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
      }
    })(),
  },
  fondosStyle: {
    background: (() => {
      switch (memorialData?.fondo) {
        case 'naturalezaAustral':
          return '#F3F8F1';
        case 'atardecerVerano':
          return '#FDF5EF';
        default:
          return (userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 :'#F0EDF3';
      }
    })(),
  },
  titulooscuroStyle: {
    color: (() => {
      switch (memorialData?.fondo) {
        case 'naturalezaAustral':
          return '#27632D';
        case 'atardecerVerano':
          return '#A04424';
        default:
          return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#D26999';
      }
    })(),
  },
  texto: {
    color: "#fff"
  },
  selectedIconStyle: {

    cursor: "pointer",
    width: '40px',
    height: '40px',
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: (() => {
      switch (memorialData?.fondo) {
        case 'naturalezaAustral':
          return '#68A354';
        case 'atardecerVerano':
          return '#E28939';
        default:
          return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
      }
    })(),
  },
  iconStyle: {
    cursor: "pointer",
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    background: 'transparent',  // Aquí está el error corregido
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: (() => {
      switch (memorialData?.fondo) {
        case 'naturalezaAustral':
          return '#68A354';
        case 'atardecerVerano':
          return '#E28939';
        default:
          return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
      }
    })(),
  },
  
};

const capitalizeFirstLetter = (string) => {
  if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const getImageSrc = (fondo) => {
  switch (fondo) {
    case 'naturalezaAustral':
      return v25;
    case 'atardecerVerano':
      return n22;
    default:
      return icono24
  }
};

const getImageizquierdo = (fondo) => {
  switch (fondo) {
    case 'naturalezaAustral':
      return v26;
    case 'atardecerVerano':
      return n23;
    default:
      return icono25
  }
};
const getImagederecho = (fondo) => {
  switch (fondo) {
    case 'naturalezaAustral':
      return v27;
    case 'atardecerVerano':
      return n24;
    default:
      return icono26
  }
};

const getIconoSeleccionado = (fondo, value) => {
  switch (fondo) {
    case 'naturalezaAustral':
      return Iconosverdes.find(icono => icono.value === value);
    case 'atardecerVerano':
      return Iconosnaranjos.find(icono => icono.value === value);
    default:
      return Iconos.find(icono => icono.value === value);
  }
};

const iconoSeleccionado = getIconoSeleccionado(memorialData?.fondo, selectedIcon);
const imageSrc = getImageSrc(memorialData?.fondo);
const imageizquierdo = getImageizquierdo(memorialData?.fondo);
const imagederecho = getImagederecho(memorialData?.fondo);
  return (
    <section className="contienenpaginainiciousuario" >
      {logoUrl && (
        <Link to={link.web} className="logofunerariacrearmemorial" target="black">

          <img
            src={`${backendURL}/${logoUrl}`}
            alt="Logo de la funeraria"
            className="logofun"

          />
        </Link>
      )}
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent: 'center',
            alignItems: 'center'

          }}
        >
          <section className="titulopaginacrearobituario">
            <h1 className="tituloobituariocrear" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Crear obituario</h1>
            <span className="informacionobituariocrear">Se mostrará una ventana emergente con</span>
            <span className="informacionobituariocrear">la información al ingresar en el memorial</span>
            <span className="informacionobituariocrear">de su ser querido</span>
          </section>
          <section className="formulariocrearobituario">
            <form onSubmit={handleSubmit}>
              <section className="seccionform">
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Aviso del desceso</h3>
                <div className="inputformobituario">
                  <textarea
                  style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                    placeholder="Escriba información para quienes deseen asistir al velatorio y/o funeral..."
                    className="inputdescriopcionformcrearobituario"
                    onChange={handleInputChange(setDescripcion)} />
                </div>
                
                <div className="inputformgenero">
                    <label htmlFor='genero'>Género</label>
                    <div className='contieneinputgenero'> < StyledSelect
                      sx={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81', textAlign:"left"}}
                      name='genero'
                      id='genero'
                      value={genero}
                      onChange={handleGeneroChange}
                      className="input-select-registro"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value="">
                        <em>Seleccionar género...</em>
                      </MenuItem>
                      <MenuItem value={"Masculino"}>Masculino</MenuItem>
                      <MenuItem value={"Femenino"}>Femenino</MenuItem>
                    
                    </ StyledSelect>
                  </div>  
                  </div>
              </section>
             {genero && ( 
              <section className="seccionform">
                <h3 className="subtituloformcrearobiturio">Titulo de parentesco</h3>
                <span className="infosuvtitulo">Puedes seleccionar varias opciones</span>
                
                <div className="contienechip">
                         {parentesco.map((label) => (
              <Chip
                key={label}
                label={label}
                onClick={() => handleChipClick(label)}
                sx={{
                  margin: '10px',
                  fontSize: '0.8rem',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  backgroundColor: selectedChips.includes(label)
                  ? (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81'
                  : (userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 : '#E1DBE6',
                color: selectedChips.includes(label)
                  ? 'white'
                  : (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81',
                '&:hover': {
                  backgroundColor: selectedChips.includes(label)
                    ? (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81'
                    : (userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 : '#E1DBE6',
                },
                }}
              />
            ))}
 </div>
  
                </section>)}
              <section  className="seccionform">
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Velatorio</h3>
                <div className="inputformobituario">
                  <label htmlFor="direccion" className="labelfromobituario"> Dirección del lugar</label>
                  <input style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} type="text" id="direccion" name="direccion"  className="inputcreaobituario" onChange={handleInputChange(setDireccionVelatorio)}/>
                </div>
               
                <Grid container>
                  <Grid item xs={12}> 
                     <label htmlFor="direccion" className="labelfromobituario">
                       Fecha
                     </label>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="inputcrearobituario">
                    <div className="contieneninputfechaobituariocrear">
                    <input  type="date" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}   id="fecha" name="fecha" className="inputcreaobituario" onChange={handleInputChange(setFechadesdeVelatorio)} />
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                    </div>
                  </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="inputcrearobituario">
                    <div className="contieneninputfechaobituariocrear">
                    <input  type="date" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}  id="fecha" name="fecha" className="inputcreaobituario" onChange={handleInputChange(setFechahastaVelatorio)}/>
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                    </div>
                  </div>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section  className="seccionform">  
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Cortejo fúnebre</h3>
                <div>
                  <label htmlFor="direccion" className="labelfromobituario"> Hora de salida </label>
                  <div className="inputcrearobituario">
                      <div className="contienenrelojcrearobituario">
                        <input  className="inputcreahorariodosobituario"
                        style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                          placeholder="Desde.."
                          type="time"
                          name="hora"
                          onChange={handleInputChange(setHoraSalida)}
                        />
                        <div className="relojiobituariocono">
                          <LuClock2 className="iconoeventoobituariocalendario"
                          style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                        </div>
                      </div>
                    </div>
                </div>

              </section>
              <section  className="seccionform">
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Funeral</h3>
                <div className="inputformobituario">
                  <label htmlFor="direccion" className="labelfromobituario"> Dirección del lugar</label>
                  <  input type="text" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} id="direccion" name="direccion" className="inputcrearformobituario" value={direccion} 
            onChange={handleDireccionChange}  />
                </div>

                <Grid container>

                  <Grid item xs={6}>
                    <div >
                      <label htmlFor="direccion" className="labelfromobituario"> Fecha</label>
                      <div className="contieneninputfechaobituariodoscrear">
                    <input  style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} type="date"   id="fecha" name="fecha" className="inputcreaobituario" onChange={handleInputChange(setFechaFuneral)}/>
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario"
                      style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                    </div>
                  </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div  >
                      <label htmlFor="direccion" className="labelfromobituario"> Hora</label>
                      <div className="inputcrearobituario">
                      <div className="contienenrelojcrearobituario">
                        <input  className="inputcreahorarioobituario"
                        style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                          placeholder="Desde.."
                          type="time"
                          name="hora"
                          onChange={handleInputChange(setHoraFuneral)}
                        />
                        <div className="relojiobituariocono">
                          <LuClock2 className="iconoeventoobituariocalendario"
                          style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                        </div>
                      </div>
                    </div>
                    </div>
                  </Grid>
                </Grid>

              </section>

              <section  className="seccionform">
                <div className="contieneiconoobitiario">
                <h3 className="subtituloformcrearobiturio" 
                style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}> Seleccione un icono</h3>
                <SelectItem
                 className="seleciondefondofinal"
                 IconComponent={KeyboardArrowDownIcon}   
                 value={selectedIcon}
                 onChange={handleChange}
                 displayEmpty
                 inputProps={{ 'aria-label': 'Seleccione un icono' }}
                 renderValue={(selected) => {
                   const selectedIconData = Iconos.find(icono => icono.value === selected);
                   return selectedIconData ? (
                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                       <img
                         src={selectedIconData.src}
                         alt="Icon Preview"
                         style={{ width: '32px', height: '32px', objectFit: 'contain' }}
                       />
                     </div>
                   ) : (
                     <span>Seleccione un icono</span>
                   );
                 }}
               >
          {Iconos.map((icono) => (
        
        <CustomMenu key={icono.value} value={icono.value}>
          <img
            src={icono.src}
            alt={icono.alt}
            style={{ width: '40px', height: '40px', objectFit: 'contain', marginRight: '10px' }}
          />
        </CustomMenu>
  
      ))}
         
      </SelectItem>
                </div>
        
              <section>
                <div className="contieneiconoobitiario">
                <h3 className="subtituloformcrearobiturio" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}> 
                  Fondo
                </h3>
         
         
        
            <SelectItemdos
              className="seleciondefondofinal"
              value={selectedOption}
              onChange={handleSelectChange}
              IconComponent={KeyboardArrowDownIcon}
            >
            
              <StyledMenuItem value="atardecerVerano">
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  <ListItemIcon>
                    <div style={{ backgroundColor: '#E28939', borderRadius: '50%', width: 20, height: 20,  }} />
                  </ListItemIcon>
                  <ListItemText primary="Atardecer en verano" sx={{marginLeft:"-10px",width: '200px', fontFamily: "Poppins", fontSize: "0.7rem", '& .MuiTypography-root': { fontSize: "0.8rem", fontFamily: "Poppins" }, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />
                </div>
              </StyledMenuItem>
              <StyledMenuItem value="naturalezaAustral">
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  <ListItemIcon>
                    <div style={{ backgroundColor: '#68A354', borderRadius: '50%', width: 20, height: 20,  }} />
                  </ListItemIcon>
                  <ListItemText primary="Naturaleza austral" sx={{marginLeft:"-10px", width: '200px', fontFamily: "Poppins", fontSize: "0.7rem", '& .MuiTypography-root': { fontSize: "0.8rem", fontFamily: "Poppins" }, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />
                </div>
              </StyledMenuItem>
              <StyledMenuItem value="clasicoSmartMemorial">
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  <ListItemIcon>
                    <div style={{ backgroundColor:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', borderRadius: '50%', width: 20, height: 20,  }} />
                  </ListItemIcon>
                  <ListItemText primary={userData ? 'Color empresa' : 'Clásico Smart Memorial'} sx={{marginLeft:"-10px", width: '200px', fontFamily: "Poppins", fontSize: "0.7rem", '& .MuiTypography-root': { fontSize: "0.8rem", fontFamily: "Poppins" }, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />

                </div>
              </StyledMenuItem>
             
            </SelectItemdos>
            </div>
          </section>
              

              </section>

              <section className="alertainformacion">
                 <div className="iconoalertaobit"><FiAlertCircle  /></div> 
                 <div className="informacionalertaobituario">
                  <span>El Obituario se mostrará durante los siguientes </span>
                  <span className="bold">7 días desde la fecha de creación</span>
                  </div>
              </section>
              <section className="buttonovituario">
              {successMessage && <Alert severity="success">{successMessage}</Alert>}
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <button className="previsualizacion-obituario"  onClick={handleOpenModal} 
                style={{background:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>
                  Previzualizar
                </button>
              </section>

            </form>
          </section>

        </Box>
      </Container>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxHeight: '90vh',  // Ajuste de la altura máxima
            backgroundColor: (() => {
              switch (memorialData.fondo) {
                case 'naturalezaAustral':
                  return '#F3F8F1';
                case 'atardecerVerano':
                  return '#FDF5EF';
                default:
                  return (userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 :'#F0EDF3';
              }
            })(),
            border: '0px solid #000',
            display: "flex",
            flexDirection: "column",
            overflowY: 'auto',  // Scroll si el contenido supera la altura
            padding: 2,
            '@media (max-width: 700px)': {
              width: '80%',
            }
        }}>
          
           

          {iconoSeleccionado && (
    <Box sx={{ 
      width: "100%", 
      textAlign: 'center', 
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column" 
    }}>
      <Box sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
      }}> 
        <IoIosClose  
          className="botoncloseobituarioprev" 
          onClick={handleCloseModal} 
          style={styles.titleStyle}
        />
      </Box>
      
      <img
        className="iconoselecionform"
        key={iconoSeleccionado.value}
        src={iconoSeleccionado.src}
        alt="smart memorial" 
        title="smart memorial"
      />
      
      <div className="tituloobituarioprev">
        <h4 style={styles.titleStyle}>Obituario</h4>
      </div>
      
      <img 
        src={imageSrc} 
        className="subrayadouno" 
        alt="smart memorial" 
        title="smart memorial"
      />
    </Box>
  )}

    <Box sx={{
      width:"100%",
      display:"flex",
      alignItems:"center"
      ,justifyContent:"center"
      ,flexDirection:"column",
      marginTop:"20px",
      marginBottom:"30px"
    
    }}>
      <span className="informacionfallecimientoparteuno"> A la edad de <p style={styles.titleStyle}>{edad}</p> el día <p style={styles.titleStyle}>{formatDate(memorialData.fecha_defuncion)}</p> </span>
      <span  className="informacionfallecimientoparteuno">Lamentamos informar  el fallecimiento de:</span>
    </Box>
         
    <Box sx={{
      width:"100%",
      display:"flex",
      alignItems:"center"
      ,justifyContent:"center"
      ,flexDirection:"column",
      position:"relative"
    }}>

    <div className="imagenprevizualizarobituario"  style={{
  borderColor: (() => {
    switch (memorialData.fondo) {
      case 'naturalezaAustral':
                  return '#F3F8F1';
                case 'atardecerVerano':
                  return '#FDF5EF';
                default:
                  return (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :'#F0EDF3';
    }
  })(),}} >
                {memorialData && (

                  <img src={`${backendURL}/imagenes/memoriales/${memorialData.carpeta_perfil}/${memorialData.imagen_perfil}`} alt="smart memorial" />

                )}
              </div>
    <div className="cuadradoprevizualizacionobituario" style={{ backgroundColor: (() => {
    switch (memorialData.fondo) {
      case 'naturalezaAustral':
        return '#E1EDDD';
      case 'atardecerVerano':
        return '#F9E7D7';
      default:
        return (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :'#E1DBE6';
    }
  })() }}>

      <div className="titulonombreobituarioprev"> <h3 style={styles.titulooscuroStyle}  >{memorialData.nombre}</h3></div>
      <div className="fechaobituariopersonaprev" style={styles.titleStyle}> {formatDate(memorialData.fecha_nacimiento)} • {formatDate(memorialData.fecha_defuncion)} </div>
   
   <div className="descripcionobituarioprev"> <span>{descripcion}</span> </div>

   <div className="parentescoovitprev"><span> Fue {selectedChips.join(', ')} quien despediremos en compañía de su familia y amigos.</span></div>
    </div>

    </Box>
    <Box sx={{
      marginTop:"-50px"
    }}>
                <section className="infovelarotioobprev">
                <div className="tituloinfoobtprev">
                   <img src={imageizquierdo} className="cerrarizqobprev" alt="smart memorial" title="smart memorial"/> 
                   <h2 style={styles.titleStyle}>Velatorio</h2>
                    <img src={imagederecho} className="cerrarderqobprev" alt="smart memorial" title="smart memorial"/> </div>
                <div className="estructurafechaobprev"> 
                  <div className="titulofechaobprev" style={styles.titulooscuroStyle}>Fecha</div>
                  <div className="infodosfechaobprev" > <div className="contienefechaprevo"> <span> {getDayOfWeek(fechadesdeVelatorio)}</span> <p>{formatDate(fechadesdeVelatorio)}</p></div> • <div className="contienefechaprevo" ><span>{getDayOfWeek(fechahastaVelatorio)}</span> <p>{formatDate(fechahastaVelatorio)}</p></div></div>
                </div>
                <div className="estructurafechaobprev"> 
                  <div className="titulofechaobprev" style={styles.titulooscuroStyle}>Dirección</div>
                  <div className="infodosobprev">{direccionVelatorio}</div>
                </div>

                </section>
                <section className="infovelarotioobprev">
                <div className="tituloinfoobtprev">
                   <img src={imageizquierdo} className="cerrarizqobprev" alt="smart memorial" title="smart memorial"/>
                    <h2 style={styles.titleStyle}>Cortejo fúnebre</h2>
                     <img src={imagederecho} className="cerrarderqobprev" alt="smart memorial" title="smart memorial"/>
                      </div>
                      <div className="estructurafechaobprev"> 
                  <div className="titulofechaobprev" style={styles.titulooscuroStyle}>Hora</div>
                  <div className="infodosfechaobprev" > {horaSalida} hrs.</div>
                </div>
                </section>
                <section className="infovelarotioobprev">
                <div className="tituloinfoobtprev">
                   <img src={imageizquierdo} className="cerrarizqobprev" alt="smart memorial" title="smart memorial"/>
                    <h2 style={styles.titleStyle}>Funeral</h2>
                     <img src={imagederecho} className="cerrarderqobprev" alt="smart memorial" title="smart memorial"/>
                      </div>
                      <div className="estructurafechaobprev"> 
                      <Grid Container sx={{ display:"flex", width:"100%"}}>
                        <Grid item xs={6} sx={{ display:"flex", width:"100%", alignItems:"center", justifyContent:"center", position:"relative", left:"-37px"}}>
                          <div className="estructurafechaobprev"> 
                  <div className="titulofechaobprev" style={styles.titulooscuroStyle}>Fecha</div>
                  <div className="infodosfechaobprev" >  <div className="contienefechaprevo"> <span> {getDayOfWeek(fechaFuneral)}</span> <p>{formatDate(fechaFuneral)}</p></div> </div>
                </div></Grid>
                        <Grid item xs={6} sx={{ display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}}>
                          <div className="estructurafechaobprev"> 
                  <div className="titulofechaobprev" style={styles.titulooscuroStyle}>Hora</div>
                  <div className="infodosfechaobprev" > {horaFuneral} hrs.</div>
                </div></Grid>
                      </Grid>
                     </div>
                     <div className="estructurafechaobprev">
                     <div className="titulofechaobprev" style={styles.titulooscuroStyle}>Dirección</div>
                     <div className="infodosobprev" >{direccionVelatorio} </div>


                     </div>
                </section>

                <section className="infovelarotioobprev">
 <button className="previsualizacion-obituario" onClick={handleSubmit} style={{
  border: (() => {
    switch (memorialData.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81';
    }
  })(),
  backgroundColor: (() => {
    switch (memorialData.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81';
    }
  })()
}} >
  {loading ? <CircularProgress size={24} /> : 'Aceptar y guardar'}
          </button>
               
                </section>
    </Box>
        
         

{successMessage && <Alert severity="success">{successMessage}</Alert>}

{errorMessage && <Alert severity="error">{errorMessage}</Alert>}
         
        </Box>
      </Modal>
    </section>
  )
}




/* diseño material iu elemento */

const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
  color: "#585857",
  fontFamily: "Poppins",
  fontSize: "0.8rem",
  '& .MuiTypography-root': {
    fontSize: "0.8rem",
  }

}))

const IconMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px',
  minHeight: 'auto',
  borderRadius: '4px',
  margin: '2px',
  
}));

const SelectItem = styled(Select)(({ theme }) => ({
  width:'40%',
  marginLeft:'10px',
  borderRadius:'50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent:'center',
  
}));

// Estilo personalizado para el contenedor del menú
const CustomMenu = styled(MenuItem)(({ theme }) => ({
  
}));

const SelectItemdos = styled(Select)(({ theme }) => ({
  width:'70%',
  
  borderRadius:'50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent:'center',

 display: 'flex',
  fontSize: "0.8rem",
  alignItems: 'flex-start',
  color: '#585857',
  flexDirection:'column',
 fontFamily: 'Poppins',
  '& .MuiTypography-root': { // Estilos para MuiTypography-root
  fontSize: '0.8rem', // Aquí estableces el tamaño de fuente deseado
  }

  
}));


const StyledSelect = styled(Select)(({ theme, error }) => ({
  padding: '1px 13px',
  color:'#694B81',
  position:'relative',
  fontFamily: "Poppins",
  width:'95%',
  height:'55px',
  fontSize:'0.8rem',
  borderRadius: '50px',
  border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F0EDF3', // Color del borde por defecto
    },
    '&:hover fieldset': {
      borderColor: '#585857', // Color del borde al pasar el mouse
    },
    '&.Mui-focused fieldset': {
      borderColor: '#585857', // Color del borde en foco
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#585857', // Color del borde en foco
    borderWidth: '2px', // Ancho del borde en foco
  },
  '@media (max-width: 310px)': {
    fontSize:'0.7rem',
    width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
    maxWidth: '96%',
  },
  
}));