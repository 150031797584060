import React, {useEffect, useState} from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";

/* material ui */
import { Card,CardContent, Box, Typography, Grid, CircularProgress} from '@mui/material';


export const QrEmpresaFuncion = () => {
    const {Token, Empresa,  UserDataMarca}=useUserEmpresa();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [gruposFamiliares, setGruposFamiliares] = useState([]);
    const [TotalQr, setTotalQr] = useState(0);
    const [totalDisponibles, setTotalDisponibles] = useState(0);
    const [totalEnUso, setTotalEnUso] = useState(0);
    const [totalGrupo, setTotalGrupo] = useState(0);
    const [totalIndividual, setTotalIndividual] = useState(0);
    const [totalAmbos, setTotalAmbos] = useState(0);
    const [TotalQrEmpresa, setTotalQrEmpresa] = useState(0);
    const [totalHistorico, setTotalHistorico] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!backendURL) {
            console.warn("⚠️ La variable de entorno REACT_APP_BACKEND_URL no está definida.");
            setLoading(false);
            return;
        }

        if (!Empresa?.id) {
            setLoading(false);
            return;
        }

        const fetchGruposFamiliares = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${backendURL}/api/estadistica/empresa/qr`, {
                    params: { id_empresa: Empresa.id },
                    headers: { Authorization: `Bearer ${Token}` },
                });

                setGruposFamiliares(response.data.grupos_familiares);
                setTotalQr(response.data.total_generados);
                setTotalDisponibles(response.data.total_disponibles);  // Nuevos valores
                setTotalEnUso(response.data.total_en_uso); 
                setTotalGrupo(response.data.tipo_grupo_familiar);
                setTotalIndividual(response.data.tipo_individual);
                setTotalAmbos(response.data.tipo_ambos);
                setTotalQrEmpresa(response.data.tipo_qr_empresa);
                setTotalHistorico(response.data.tipo_historicos);
            } catch (err) {
                console.error("❌ Error en la petición:", err);
                setError(err.response?.data?.message || "Error al obtener los datos");
            } finally {
                setLoading(false);
            }
        };

        fetchGruposFamiliares();
    }, [backendURL, Empresa.id, Token]);

   


    return(

        <>
        <h2 style={{color:'#585857', fontFamily:'Poppins', fontWeight:'600'}}> Información de la empresa</h2>
        {loading && <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>< CircularProgress/></div>}
            {error && <p style={{ color: "red" }}>{error}</p>}

            {!loading && !error && gruposFamiliares.length > 0 ? (
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Grid container spacing={3} justifyContent="center">
                    {/* Total QR */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Card sx={{ background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6'}}>
                            <CardContent>
                                <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={100} // Círculo completo
                                        size={150} // Tamaño del círculo
                                        thickness={4}
                                        sx={{ color: "primary.main" }} // Color del círculo (ajustable)
                                    />

                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize:'1rem',
                                            position: "absolute",
                                            fontWeight: "600",
                                            color: "#585857", // Color del número
                                        }}
                                    >
                                        {TotalQr}
                                    </Typography>
                                    </Box>
                                    <Box sx={{ marginLeft:'30px'}}>
                                    <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                    Total de Códigos QR Memoriales Creado
                                </Typography>
                                        </Box>
                                </Box>
                                
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Códigos QR disponibles */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Card sx={{ background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6' }}>
                            <CardContent>
                                <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={(totalDisponibles / TotalQr) * 100} // Porcentaje de disponibilidad
                                        size={150} // Tamaño del círculo
                                        thickness={4}
                                        sx={{ color: "green" }} // Color para "disponibles"
                                    />

                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize:'1rem',
                                            position: "absolute",
                                            fontWeight: "600",
                                            color: "#585857", // Color del número
                                        }}
                                    >
                                        {totalDisponibles}
                                    </Typography>
                                    </Box >
                                    <Box sx={{ marginLeft:'30px' }}>
                                      <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                    Qr Disponibles
                                </Typography>   
                                        </Box>
                                </Box>
                               
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Códigos QR en uso */}
                    <Grid item xs={12} sm={6} md={4} >
                        <Card sx={{ background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6' }}>
                            <CardContent>
                                <Box sx={{ position: "relative", display: "inline-flex", flexDirection:'row', justifyContent: "center", alignItems: "center" }}>
                                  <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={(totalEnUso / TotalQr) * 100} // Porcentaje de en uso
                                        size={150} // Tamaño del círculo
                                        thickness={4}
                                        sx={{ color: "orange" }} // Color para "en uso"
                                    />

                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize:'1rem',
                                            position: "absolute",
                                            fontWeight: "600",
                                            color: "#585857", // Color del número
                                        }}
                                    >
                                        {totalEnUso}
                                    </Typography>
                                    </Box>
                                    <Box sx={{marginLeft:'30px'}}>
                                    <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                    Qr asignados por clientes
                                </Typography>
                                    </Box>
                                </Box>
                               
                            </CardContent>
                        </Card>
                    </Grid>
                    {totalGrupo !== 0 && (
                    <Grid item xs={12} sm={6} md={4} >
                        <Card sx={{ background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6' }}>
                            <CardContent>
                                <Box sx={{ position: "relative", display: "inline-flex", flexDirection:'row', justifyContent: "center", alignItems: "center" }}>
                                  <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={(totalEnUso / TotalQr) * 100} // Porcentaje de en uso
                                        size={150} // Tamaño del círculo
                                        thickness={4}
                                        sx={{ color: "orange" }} // Color para "en uso"
                                    />

                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize:'1rem',
                                            position: "absolute",
                                            fontWeight: "600",
                                            color: "#585857", // Color del número
                                        }}
                                    >
                                        {totalGrupo}
                                    </Typography>
                                    </Box>
                                    <Box sx={{marginLeft:'30px'}}>
                                    <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                    Qr asignados por Grupo Familiar
                                </Typography>
                                    </Box>
                                </Box>
                               
                            </CardContent>
                        </Card>
                    </Grid>
                    )}
                    {totalIndividual !== 0 && (
                    <Grid item xs={12} sm={6} md={4} >
                        <Card sx={{ background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6' }}>
                            <CardContent>
                                <Box sx={{ position: "relative", display: "inline-flex", flexDirection:'row', justifyContent: "center", alignItems: "center" }}>
                                  <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={(totalEnUso / TotalQr) * 100} // Porcentaje de en uso
                                        size={150} // Tamaño del círculo
                                        thickness={4}
                                        sx={{ color: "orange" }} // Color para "en uso"
                                    />

                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize:'1rem',
                                            position: "absolute",
                                            fontWeight: "600",
                                            color: "#585857", // Color del número
                                        }}
                                    >
                                        {totalIndividual}
                                    </Typography>
                                    </Box>
                                    <Box sx={{marginLeft:'30px'}}>
                                    <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                    Qr asignados por memorial
                                </Typography>
                                    </Box>
                                </Box>
                               
                            </CardContent>
                        </Card>
                    </Grid>)}
                    {totalAmbos !== 0 && (
                    <Grid item xs={12} sm={6} md={4} >
                        <Card sx={{ background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6' }}>
                            <CardContent>
                                <Box sx={{ position: "relative", display: "inline-flex", flexDirection:'row', justifyContent: "center", alignItems: "center" }}>
                                  <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={(totalEnUso / TotalQr) * 100} // Porcentaje de en uso
                                        size={150} // Tamaño del círculo
                                        thickness={4}
                                        sx={{ color: "orange" }} // Color para "en uso"
                                    />

                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize:'1rem',
                                            position: "absolute",
                                            fontWeight: "600",
                                            color: "#585857", // Color del número
                                        }}
                                    >
                                        {totalAmbos}
                                    </Typography>
                                    </Box>
                                    <Box sx={{marginLeft:'30px'}}>
                                    <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                    Qr asignados por Grupo familiar y memorial
                                </Typography>
                                    </Box>
                                </Box>
                               
                            </CardContent>
                        </Card>
                    </Grid>)}
                    {TotalQrEmpresa !== 0 && (
                    <Grid item xs={12} sm={6} md={4} >
                        <Card sx={{ background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6' }}>
                            <CardContent>
                                <Box sx={{ position: "relative", display: "inline-flex", flexDirection:'row', justifyContent: "center", alignItems: "center" }}>
                                  <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={(totalEnUso / TotalQr) * 100} // Porcentaje de en uso
                                        size={150} // Tamaño del círculo
                                        thickness={4}
                                        sx={{ color: "orange" }} // Color para "en uso"
                                    />

                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize:'1rem',
                                            position: "absolute",
                                            fontWeight: "600",
                                            color: "#585857", // Color del número
                                        }}
                                    >
                                        {TotalQrEmpresa}
                                    </Typography>
                                    </Box>
                                    <Box sx={{marginLeft:'30px'}}>
                                    <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                    Qr asignados por QR Empresa {Empresa.nombre_empresa}
                                </Typography>
                                    </Box>
                                </Box>
                               
                            </CardContent>
                        </Card>
                    </Grid>
                    )}
                    {totalHistorico !== 0 && (
                    <Grid item xs={12} sm={6} md={4} >
                        <Card sx={{ background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6' }}>
                            <CardContent>
                                <Box sx={{ position: "relative", display: "inline-flex", flexDirection:'row', justifyContent: "center", alignItems: "center" }}>
                                  <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={(totalEnUso / totalHistorico) * 100} // Porcentaje de en uso
                                        size={150} // Tamaño del círculo
                                        thickness={4}
                                        sx={{ color: "orange" }} // Color para "en uso"
                                    />

                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize:'1rem',
                                            position: "absolute",
                                            fontWeight: "600",
                                            color: "#585857", // Color del número
                                        }}
                                    >
                                        {totalHistorico}
                                    </Typography>
                                    </Box>
                                    <Box sx={{marginLeft:'30px'}}>
                                    <Typography variant="h6" sx={{ marginTop: 2, color: "#585857" }}>
                                    Qr asignados por Memoriales Historicos
                                </Typography>
                                    </Box>
                                </Box>
                               
                            </CardContent>
                        </Card>
                    </Grid>
                    )}
                </Grid> 
              </Box>
            ) : (
                !loading && <p>No hay grupos familiares asociados.</p>
            )}
        
        </>
    )
}