import React, { useState, useEffect } from "react"
import axios from "axios";
import {  Box, CircularProgress, Autocomplete,  TextField, Button, Modal,Typography} from '@mui/material';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
export const OtorgarBotonQrAdministrador = () => {
     const {Token, UserDataMarca, Sucursal, Usuario, Empresa} = useUserEmpresa()
     const [selectedQR, setSelectedQR] = useState(null);
     const [qrOptions, setQrOptions] = useState([]);
     const backendURL = process.env.REACT_APP_BACKEND_URL;
     const [error, setError] = useState('');
     const [successAlertOpen, setSuccessAlertOpen] = useState(false);
     const [errorAlertOpen, setErrorAlertOpen] = useState(false);
     const [enviandoAlert, setenviandoAlert] = useState(false);
     const [alertMessage, setAlertMessage] = useState('');

     useEffect(() => {
      fetchGrupos();
  }, [Empresa?.id, Token]); // Agregado Token como dependencia
  
  const fetchGrupos = async () => {
      if (!Empresa || !Empresa.id || !Token) return;
  
      try {
          const response = await axios.get(`${backendURL}/api/grupo-familiar/empresa/${Empresa.id}`, {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Token}`,
              },
          });
          const qrIds = response.data.map(grupo => ({
              label: `${grupo.id}`
          }));
  
          setQrOptions(qrIds);
      } catch (error) {
          console.error("Error al obtener los grupos familiares:", error);
      }
  };

     const handleAsignar = async () => {
        if (!selectedQR) return;
        setenviandoAlert(true);
            
        try {
            const response = await axios.post(
                `${backendURL}/api/grupo-familiar/asignar-qr/${selectedQR.label}`,
                {
                    id_sucursal: Sucursal.id,
                    id_admin_sucursal: Usuario.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Token}`,
                    },
                }
            );
    
            setenviandoAlert(false);
            setSuccessAlertOpen(true);
            setAlertMessage('Qr asignado al administrador de la sucursal con exito.');
            await fetchGrupos();
        } catch (error) {
            console.error("Error al asignar el QR:", error);
            setError("Error al asignar el QR:", error);
            setAlertMessage('Hubo un error al asignar el QR');
            setenviandoAlert(false)           
           
        }
    };


    return(
        <>
         <Typography variant="h5" sx={{ padding:'0px 20px',marginBottom: "20px", fontFamily:'Poppins', fontSize:'1rem', fontWeight:'600',  color:' #585857' }}>
         Asignación de QR Vendido
                    </Typography>
                   
      <Typography variant="body1" paragraph sx={{ padding:'0px 20px', fontFamily:'Poppins', fontSize:'1rem', fontWeight:'500',  color:' #585857' }}>
      

      "Utiliza esta herramienta para registrar la venta de un QR. Es útil cuando el QR se ha entregado sin haber registrado previamente al cliente y  se realizo la venta."

      </Typography>

      <Box sx={{
        background:(UserDataMarca?.color_3 && UserDataMarca.color_3 !== 'undefined') 
                    ? UserDataMarca.color_3 
                    : '#ffff',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    flexDirection:'row',
                    p:2,
                    mb:10

      }}>
      <Autocomplete
       sx={{
        p:2,
        width:'250px',
        "& .MuiInputBase-root": { color: "#585857" }, // Cambia el color del texto dentro del input
        "& .MuiOutlinedInput-root": {
          "& fieldset": { borderColor: "#585857" }, // Cambia el color del borde
          "&:hover fieldset": { borderColor: "#424242" }, // Color al pasar el mouse
          "&.Mui-focused fieldset": { borderColor: "#212121" }, // Color cuando está seleccionado
        },
      }}
      options={qrOptions}
      getOptionLabel={(option) => option.label}
      onChange={(_, newValue) => setSelectedQR(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Seleccionar QR"
          variant="outlined"
          fullWidth
          sx={{ my: 2, "& .MuiInputLabel-root": { color: "#585857" } }} // Cambia el color del label
        />
      )}
    />
            <Button

                onClick={handleAsignar}
                disabled={!selectedQR}
                sx={{
                    p:2,
                    width:'300px',
                    fontFamily:'Poppins',
                    textTransform:'capitalize',
                    color: "#ffffff", // Color del texto del botón
                    backgroundColor: "#585857", // Color de fondo
                    "&:hover": { backgroundColor: "#424242" }, // Color al pasar el mouse
                    "&:disabled": { backgroundColor: "#bdbdbd", color: "#ffffff" }, // Color cuando está deshabilitado
                  }}
            >
                Asignar QR a {Usuario.name} {Usuario.apellido} de {Sucursal.nombre_sucursal}
            </Button>
      </Box>
      
                <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '350px',
                    height: 'auto',
                    bgcolor: '#ffff',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: '10px',
                    '@media (max-width: 500px)': {
                      width: '90%',
                    },
                  }}
                >
                  <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
                    <IoClose />
                  </section>
                  <section style={{ textAlign: 'center' }}>
                    <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
                    <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
                      {alertMessage}
                    </p>
                  </section>
                </Box>
              </Modal>
        
              {/* Popup de error */}
              <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '350px',
                    height: 'auto',
                    bgcolor: '#ffff',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: '10px',
                    '@media (max-width: 500px)': {
                      width: '90%',
                    },
                  }}
                >
                  <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
                    <IoClose />
                  </section>
                  <section style={{ textAlign: 'center' }}>
                    <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
                    <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                      {alertMessage}, {error}
                    </p>
                  </section>
                </Box>
              </Modal>
        
              {/* Popup de carga */}
              <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '350px',
                    height: 'auto',
                    bgcolor: '#ffff',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: '10px',
                    '@media (max-width: 500px)': {
                      width: '90%',
                    },
                  }}
                >
                  <section style={{ textAlign: 'center', padding: '1rem' }}>
                    <CircularProgress style={{ color: '#694B81' }} />
                    <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                      Procesando solicitud...
                    </p>
                  </section>
                </Box>
              </Modal>
        </>
    )
}