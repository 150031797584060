import React from "react";
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Container, CircularProgress } from "@mui/material";
import axios from 'axios';
import Box from "@mui/material/Box";
import { AiOutlineCheckCircle } from "react-icons/ai";
import "../../../../Style/usuario/final.css"
import { BsFillShareFill } from "react-icons/bs";
import { FaRegEye } from 'react-icons/fa';
import { CgClose } from "react-icons/cg";
import { useUsers } from "../../../context/userscontexts";

export const Finalcrearmemorial = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const ClientToken = location.state.user; // Obtener la información del usuario desde las props
    const grupoFamiliar = location.state.familia;
    const memorialId = location.state.memorial;
    const [link, setLink] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true); 
    const [nombreEmpresa, setNombreEmpresa] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const {userData} =useUsers();

    useEffect(() => {
        const fetchFuneraria = async () => {
            try {
                if (grupoFamiliar && grupoFamiliar.id_funeraria) {
                    const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
                    const funerariaData = response.data;
                    setLink(funerariaData);
                    if (funerariaData.nombre === 'smart memorial') {
                        setLogoUrl(null); // No mostrar logo
                      } else if (funerariaData.logo) {
                      setLogoUrl(funerariaData.logo);
                      } else {
                        setLogoUrl(null); // No hay logo disponible
                      }
                  
                } else {
                    console.error('Group family or funerary ID is missing');
                }
            } catch (error) {
                console.error('Error fetching funeraria data:', error);
            }
        };

        fetchFuneraria();
    }, [grupoFamiliar]);
    
    useEffect(() => {
        const fetchNombreEmpresa = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/usuarioEmpresacliente/traer/informacion/${userData.id_empresa}`, {
                    headers: {
                        'Authorization': `Bearer ${ClientToken.token}`,
                    },
                });
                setNombreEmpresa(response.data.empresa.nombre_empresa);
            } catch (error) {
                console.error('Error fetching nombre_empresa:', error);
            } finally {
                setIsLoading(false); // Finalizar la carga
            }
        };
    
        fetchNombreEmpresa();
    }, [backendURL, ClientToken, userData]);
    

    const downloadQR = async (grupoFamiliar) => {
       
        try {
            const response = await axios.post(`${backendURL}/api/downloadQrGeneral`, {
                qr: grupoFamiliar.qr,
                id: grupoFamiliar.id
            }, {
                responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
            });
    
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = grupoFamiliar.qr; // Nombre del archivo
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    };

    const handleClick = () => {
        window.open(`/familiar/group/${grupoFamiliar.id}`, '_blank', 'noopener');
    };
    const handleClickinicio = () => {
        const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId };
        navigate('/usuariosmart', { state });
    };

    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Compartir Memorial',
                    text: 'Conoce nuestro memorial',
                    url: `${window.location.origin}/familiar/group/${grupoFamiliar.id}`
                });
            } else {
                throw new Error('La función de compartir no está soportada en este navegador');
            }
        } catch (error) {
            console.error('Error al compartir:', error.message);
            // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
        }
    };

    const downloadQRempresa = async (grupoFamiliar) => {
        try {
            // Construye la ruta con el nombre de la empresa y el QR
            const nombreempresa = nombreEmpresa;
            const qrFileName = grupoFamiliar.qr;
    
            const response = await axios.post(`${backendURL}/api/descargaQrEmpresa`, {
                qr: qrFileName,
                id: grupoFamiliar.id,
                nombre_empresa: nombreempresa // Enviar el nombre de la empresa al backend
            }, {
                responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
            });
    
            if (response.status === 200) {
                // Crear URL de descarga para el archivo recibido
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${qrFileName}`; // Nombre del archivo de descarga
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error en la respuesta:', response.status);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    };

    if (isLoading) {
        return  <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Pantalla completa
          flexDirection: 'column',
        }}
      >
        <CircularProgress
          size={100}
          thickness={4}
          sx={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }}
        />
      </Box>;
      }
    

    return(
        <section className="contienenpaginainiciousuario" >
        {logoUrl && (
              <Link to={link.web} className="logofunerariacrearmemorial" target="black"> 
            
             <img
               src={`${backendURL}/${logoUrl}`}
               alt="Logo de la funeraria"
               className="logofun"
    
             />
           </Link>
            )}
        <Container  component="main" maxWidth="xs">

        {userData?.color_2 ? (
            <Box
            marca empresa
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <section className="closeqr">
                <button className="closesr" onClick={handleClickinicio}><CgClose style={{
color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',

}} /></button>
            </section>

            <section className="tituloprinicpalgenerarqr">

<AiOutlineCheckCircle />
<h1 style={{
color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',

}}>Su memorial ha sido</h1>
<h1 style={{
color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',

}}>creado con éxito</h1>

            </section>
            <section className="vistaqr">
                <div className="titulovistaqr">
                    <h2 style={{
color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',

}}>Comparta el memorial con</h2>
                    <h2 style={{
color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',

}}>sus seres queridos</h2>
                </div>
                <div className="mantienevistaqr">
                <div className="contand"></div>
                <div className="contdos"></div>
                <div className="trescont"></div>
                <div className="cuatroqr"></div>
                    
                    <div className="cardqrimagen">
                        
                    {grupoFamiliar&&(    
                    <img src={`${backendURL}/imagenes/qr/empresa/${nombreEmpresa}/${grupoFamiliar.qr}`} className="imagenqrmemorial" alt="smart memorial"/>
                   )} </div>
                </div>
            </section>
            <section className="botonesqr">
                <button className="descargar" style={{
background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',

}} onClick={() => downloadQRempresa(grupoFamiliar)}>
                    Descargar memorial
                </button>
                <button className="compartir"  onClick={handleShare} style={{
color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',

}}>
                <BsFillShareFill /> Compartir memorial
                </button>
                
                <button className="vermemorial" onClick={handleClick} style={{
color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
background:(userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 :  '#E1DBE6'
}}>
                    <FaRegEye/> Ver memorial
                </button>
            </section>


            </Box>
        ) : (
    
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <section className="closeqr">
                                        <button className="closesr" onClick={handleClickinicio}><CgClose style={{
   color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    
  }} /></button>
                                    </section>

                                    <section className="tituloprinicpalgenerarqr">

<AiOutlineCheckCircle />
<h1 style={{
   color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    
  }}>Su memorial ha sido</h1>
<h1 style={{
   color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    
  }}>creado con éxito</h1>

                                    </section>
                                    <section className="vistaqr">
                                        <div className="titulovistaqr">
                                            <h2 style={{
   color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    
  }}>Comparta el memorial con</h2>
                                            <h2 style={{
   color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    
  }}>sus seres queridos</h2>
                                        </div>
                                        <div className="mantienevistaqr">
                                        <div className="contand"></div>
                                        <div className="contdos"></div>
                                        <div className="trescont"></div>
                                        <div className="cuatroqr"></div>
                                            
                                            <div className="cardqrimagen">
                                            {grupoFamiliar&&(    
                                            <img src={`${backendURL}/imagenes/qr/${grupoFamiliar.qr}`} className="imagenqrmemorial" alt="smart memorial"/>
                                           )} </div>
                                        </div>
                                    </section>
                                    <section className="botonesqr">
                                        <button className="descargar" style={{
   background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    
  }} onClick={() => downloadQR(grupoFamiliar)}>
                                            Descargar memorial
                                        </button>
                                        <button className="compartir"  onClick={handleShare} style={{
   color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    
  }}>
                                        <BsFillShareFill /> Compartir memorial
                                        </button>
                                        
                                        <button className="vermemorial" onClick={handleClick} style={{
   color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    background:(userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 :  '#E1DBE6'
  }}>
                                            <FaRegEye/> Ver memorial
                                        </button>
                                    </section>


                                    </Box>
                                    
  )}
                                    </Container>
        
        </section>
    )
}