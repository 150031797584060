import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import { Navbar } from "../../nav-fotter/navbar";
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import { IoIosArrowBack } from "react-icons/io";
import { FiAlertCircle } from "react-icons/fi";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import "../../../Style/generales/recetcon.css";
import { Nuevacontraseña } from "../../Log/contraseña/nuevacontraseña";

export const RestaurarContraseñaEmpresa = ({setShowNavbarFooter}) =>{
    const { id } = useParams();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
     const [marcas, setMarcas] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        // Ocultar el navbar y footer al montar el componente
        setShowNavbarFooter(false);
        return () => {
            // Mostrar el navbar y footer al desmontar el componente
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

     useEffect(() => {
            const fetchGrupoFamiliar = async () => {
                try {
                    setLoading(true);
                    const empresaResponse = await axios.get(`${backendURL}/api/marcaempresa/especifica/${id}`, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    setMarcas(empresaResponse.data.data[0]);
                } catch (error) {
                    console.error('Error fetching grupo familiar:', error);
                    navigate('/Registro');
                } finally {
                    setLoading(false);
                }
            };
    
            fetchGrupoFamiliar();
        }, [id, backendURL, navigate]);

    const handleNextPage = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${backendURL}/api/resetPassword`, { email });

            

            if (response.status === 200 && response.data.message) {
                setError("");
                setCurrentPage(currentPage + 1);
            } else {
                setError("Correo no corresponde o no existe");
            }
        } catch (error) {
            setError("Correo no corresponde o no existe");
        } finally {
            setLoading(false);
        }
    };

    const handleInputFocus = () => {
        setError("");
    };

    

        const StyleFondo={
            backgroundImage: `url(${marcas?.fondo_memorial ? `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}` : ''})`,
              backgroundSize: 'cover',           // Asegurar que cubra toda la sección
              backgroundPosition: 'center',      // Centrar la imagen
              width: '100%',
              height: 'auto',                   // Ajustar la altura de la sección
              
          }

    return(
        
         <section className="contienenpaginainiciousuario" style={StyleFondo}>
                {marcas ? (
                    <Navbar
                    marcas={marcas}
                    color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
                    color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
                    logomarca={`${backendURL}/${marcas?.logo}`}
                    />
                  ) : (
                    <Navbar
                    />
                  )}
                   <Container component="main" maxWidth="xs" sx={{height:'90vh'}}>
                              <Box
                                  sx={{
                                      marginTop: 2,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'stretch',
                                  }}
                              >
                                 
                                              <Box>
                                                  <Link to="/">
                                                      <IoIosArrowBack className="arrowatras"  style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }} />
                                                  </Link>
                                              </Box>
                  
                                              <Box>
                                                  <section className="titulorecuperarcontraseña" >
                                                      <h1  style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}>Recuperar contraseña</h1>
                                                  </section>
                                                  
                                                   <section className={`pagina-${currentPage}`}>
                                      {currentPage === 1 && (
                                          <>
                                                  <form onSubmit={handleNextPage} style={{width:"100%"}}>
                                                      <section className="verificacioncorreorecuperacion">
                                                          <div className="contieneverificar">
                                                              <label className="tituloinputverificar" htmlFor="email">Ingrese su correo electrónico</label>
                                                              <input
                                                                  type="text"
                                                                  className={`inuptverificarcorreo ${error ? 'error' : ''}`}
                                                                  value={email}
                                                                  onChange={(e) => setEmail(e.target.value)}
                                                                  onFocus={handleInputFocus}
                                                              />
                                                              <div className="errorgenerado">
                                                                  {error && <span className="errorverificarmessage"> <FiAlertCircle className='iconserror' />{error}</span>}
                                                              </div>
                                                          </div>
                                                      </section>
                                                      <section className="contienebottonesrecet">
                                                          <button type="submit"  style={{background:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}>Confirmar</button>
                                                      </section>
                                                  </form>   </>
                                      )}
                                      {currentPage === 2 && (
                                          <Nuevacontraseña email={email} marcas={marcas} />
                                      )}
                                  </section>
                                              </Box>
                                       
                              </Box>
                              <Modal
                                  open={loading}
                                  aria-labelledby="loading-modal-title"
                                  aria-describedby="loading-modal-description"
                                  style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                  }}
                              >
                                  <CircularProgress />
                              </Modal>
                          </Container>
        
        </section>
    )
}