import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate,} from "react-router-dom";
import EmpresaLayout from "../../../../../../../EmpresaLayout";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import { IndexEditarMemorialHistorico } from "./IndexEditarMemorialesHistoricos";
/* libreria UI */
import { Box, Button, Avatar, Card, CardContent, Grid,  Fade, IconButton, CircularProgress, Typography } from '@mui/material';
import { Close } from "@mui/icons-material";
import { AddCircleOutline, Map } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber"

/* react icons */
import { MdOutlineHistoryEdu } from "react-icons/md";



export const IndexMemorialHistorico = ({ setShowNavbarFooter }) => {
    const {Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const [hoveredCard, setHoveredCard] = useState(null);
    const [selectedMemorial, setSelectedMemorial] = useState(null);
    const [memoriales, setMemoriales] = useState([]);
    const [loadingMemoriales, setLoadingMemoriales] = useState(true);
    const navigate = useNavigate()
    const backendURL = process.env.REACT_APP_BACKEND_URL;
   

    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    useEffect(() => {
        if (!Empresa?.id) return; // Evita llamar la API si no hay id_empresa
    
        axios
        .get(`${backendURL}/api/qr/todos/MemorialesHistoricos/${Empresa.id}`, {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          })
          .then((response) => {
            setMemoriales(response.data);
            setLoadingMemoriales(false);
          })
          .catch((error) => {
            console.error("Error al obtener los memoriales históricos:", error);
            setLoadingMemoriales(false);
          });
      }, [Empresa, backendURL]);


     

      const handleVerMemorial = (qr) => {
        const url = `/empresa/memorial/historico/${Empresa.nombre_empresa}/${Empresa.id}/${qr.id}`;
        window.open(url, "_blank"); // "_blank" abre en nueva pestaña
    };

    const handleCrearMemorial = () => {
        navigate(`/crear/memorialeshistoricos/${Usuario.id}`);
       
    };
  
        const handleCreateMemorialForFamily = (grupoFamiliar) => {
            navigate(`/galeria/video/memorialeshistoricos/${Usuario.id}`, {
              state: { grupoFamiliar }
            });
          };
      
    /* const handleVisitarMapa = () => {
        console.log("Visitar mapa de memoriales");
    }; */

    const handleUpdateMemorial = (updatedMemorial) => {
        setSelectedMemorial(updatedMemorial);
      
    };

    const formatDateToDMY = (dateString) => {
      if (!dateString) return "";
      const parts = dateString.split("-");
      if (parts.length !== 3) return dateString;
      // parts[0] = año, parts[1] = mes, parts[2] = día
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    };
   
    /* descarga */
    const downloadQR = async (memorial) => {
      try {
          const response = await axios.post(`${backendURL}/api/qr/empresa/download/demo`, {
              qr: memorial.qr,
              id: memorial.id,
              id_empresa: Empresa.id
          }, {
              responseType: 'blob' // Especifica que la respuesta es un archivo
          });
  
          if (response.status === 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const a = document.createElement('a');
              a.href = url;
              a.download = `${memorial.nombre_familia}_QR.png`; // Asigna un nombre válido
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
          } else {
              console.error('Error en la descarga:', response.status);
          }
      } catch (error) {
          console.error('Error al descargar QR:', error.response?.data || error);
      }
  };
    return (
        <EmpresaLayout >
            <>
                <Box sx={StyleBox}>
                    <Box sx={StyleBoxPrincipal}>
                        <Box sx={StyleImagenBoxtitulo}>
                            <MdOutlineHistoryEdu style={{
                                fontSize: '5rem', position: 'relative', left: '-4px',  color:'#585857',
                            }} />
                            <div className="ContieneInfoModalMemoriaCrear" style={{
                                marginTop: '10px'
                            }}>
                                <h3 className="ModalTituloMemorialCrear" style={{
                                    fontSize: '1.5rem', color:'#585857',
                                }}>Memoriales Historicos</h3>
                                <h3 className="ModalTituloMemorialCrear" style={{
                                    fontSize: '1.5rem',  color:'#585857',
                                }}> {Empresa?.id_tipo_empresa === "1" ? "Cementerio" : Empresa?.id_tipo_empresa === "2" ? "Funeraria" : ""} {Empresa?.nombre_empresa}</h3>
                            </div>
                        </Box>
                        <Box sx={botoneraStyle}>
                            <Button
                                variant="contained"
                                sx={{
                                    ...botonStyle,
                                    backgroundColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                                        ? UserDataMarca.color_1
                                        : '#694B81',
                                    '&:hover': {
                                        backgroundColor: UserDataMarca?.color_2 && UserDataMarca.color_2 !== "undefined"
                                            ? UserDataMarca.color_2
                                            : '#694B81',
                                    }
                                }}
                                onClick={handleCrearMemorial}
                                startIcon={<AddCircleOutline />}
                            >
                                Crear Memorial
                            </Button>

                            {/* <Button
                                variant="contained"
                                sx={{
                                    ...botonStyle,
                                    backgroundColor: "#68A354",
                                    '&:hover': { backgroundColor: "#27632D", color: '#ffff' }
                                }}
                                onClick={handleVisitarMapa}
                                startIcon={<Map />}
                            >
                                Ver Mapa
                            </Button> */}


                        </Box>


                    </Box>
                    <Box sx={StyleImagenBox} >
                        <Grid container spacing={1} sx={{ marginTop: 4, width:'100%',flexWrap:'nowrap' }}>
                            
                            <Grid item  xs={6}  sx={{padding:'20px', marginLeft:'20px'}}>
                              <Grid  container spacing={3}>
                              {loadingMemoriales ? (
                  <CircularProgress />
                ) : memoriales.length === 0 ? (
                    <Box
                    width='100%'
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      height: 200,
                      border: "1px dashed rgba(0, 0, 0, 0.2)",
                      borderRadius: 8,
                      padding: 2,
                      backgroundColor: "#fefefe",
                    }}
                  >
                    <WarningAmberIcon
                      sx={{
                        fontSize: 40,
                        color: "#FF9800",
                        marginBottom: 1,
                      }}
                    />
                    <Typography variant="h6" color="textSecondary">
                      No hay memoriales historicos disponibles
                    </Typography>
                  </Box>
                 ) : (
                    <Grid container spacing={3}>
                      {memoriales.map((memorial) => {
                        // Si la propiedad 'memorial' es un array vacío, no existe memorial
                        const hasMemorial = memorial.memorial && memorial.memorial.length > 0;
  
                        return (
                          <Grid item xs={12} sm={6} md={6} key={memorial.id}>
                            <Card
                              sx={{
                                ...cardStyle,
                                position: "relative", // Para posicionar los botones
                                overflow: "hidden", // Evita desbordamiento
                              }}
                              onMouseEnter={() => setHoveredCard(memorial.id)}
                              onMouseLeave={() => setHoveredCard(null)}
                            >
                            {memorial.memorial &&(
                              <Avatar  src={
                                hasMemorial
                                  ? `${backendURL}/imagenes/memoriales/${memorial.memorial[0].carpeta_perfil}/${memorial.memorial[0].imagen_perfil}`
                                  : ""
                              } alt={memorial.memorial.nombre} sx={avatarStyle} />
                              )}
                              
                              <CardContent sx={{ textAlign: "center" }}>
                                <Typography variant="h6">{memorial.nombre_familia}</Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: "30px", color:'#ffff', fontFamily:'Poppins', fontWeight:'500' }}>
                                {hasMemorial
                                  ? `${formatDateToDMY(memorial.memorial[0].fecha_nacimiento)} • ${formatDateToDMY(memorial.memorial[0].fecha_defuncion)}`
                                  : "Sin fechas"}
                                </Typography>
                              </CardContent>
                              {/* Botones con animación */}
                              <Fade in={hoveredCard === memorial.id} timeout={300}>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    bottom: 10,
                                    left: 0,
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: 1,
                                    opacity: hoveredCard === memorial.id ? 1 : 0,
                                  }}
                                >
                                  {hasMemorial ? (
                                    <>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          backgroundColor: "#68A354",
                                          textTransform: "capitalize",
                                          padding: "8px 15px",
                                          borderRadius: "50px",
                                          fontFamily: "Poppins",
                                          color: "#ffff",
                                          fontSize:'0.7rem',
                                          "&:hover": { backgroundColor: "#27632D" },
                                        }}
                                        onClick={() => handleVerMemorial(memorial)}
                                      >
                                         Memorial
                                      </Button>
                                      
                                      <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          backgroundColor: "#8DB9E4",
                                          textTransform: "capitalize",
                                          padding: "8px 15px",
                                          borderRadius: "50px",
                                          fontFamily: "Poppins",
                                          fontSize:'0.7rem',
                                          color: "#ffff",
                                          "&:hover": { backgroundColor: "#4C7EC1" },
                                        }}
                                        onClick={() => downloadQR(memorial)} 
                                      >
                                        Descargar Qr
                                      </Button>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          backgroundColor: "#FFA726",
                                          textTransform: "capitalize",
                                          padding: "8px 20px",
                                          borderRadius: "50px",
                                          fontFamily: "Poppins",
                                          color: "#ffff",
                                          fontSize:'0.7rem',
                                          "&:hover": { backgroundColor: "#EF6C00" },
                                        }}
                                        onClick={() => handleUpdateMemorial(memorial)}
                                      >
                                        Editar
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#FFA726",
                                        textTransform: "capitalize",
                                        padding: "10px 20px",
                                        borderRadius: "50px",
                                        fontFamily: "Poppins",
                                        color: "#ffff",
                                        "&:hover": { backgroundColor: "#EF6C00" },
                                      }}
                                      onClick={() => handleCreateMemorialForFamily(memorial)}
                                    >
                                      Crear Memorial
                                    </Button>
                                  )}
                                </Box>
                              </Fade>
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}</Grid> 
                            </Grid>
                            <Grid item  xs={6}>
                            <Fade in={!!selectedMemorial} timeout={500}>
        <Box sx={{ 
            padding: 2, 
            border: "1px solid #ccc", 
            borderRadius: 2, 
            position: "relative" 
        }}>
            {/* Botón de cerrar */}
            <IconButton 
                sx={{ 
                    position: "absolute", 
                    top: 8, 
                    right: 8 
                }} 
                onClick={() => setSelectedMemorial(null)}
            >
                <Close  sx={{
                     color: '#585857'
                }}/>
            </IconButton>

            {selectedMemorial && (
                <>
                  <IndexEditarMemorialHistorico 
                  selectedMemorial={selectedMemorial}
                  onUpdateMemorial={handleUpdateMemorial}
                  />
                </>
            )}
        </Box>
    </Fade>
                            </Grid>
                        </Grid>
                    </Box>


                </Box>
            </>
        </EmpresaLayout>

    )
}

const StyleImagenBox = {
    width: '100%',
    Height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',

}
const StyleImagenBoxtitulo = {
    width: '50%',
    Height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',

}

const StyleBox = {
    width: '100%',
    Height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '20px'
}
const StyleBoxPrincipal = {
    width: '100%',
    Height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
}



const botoneraStyle = {
    position: "relative",
    right: "0px",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
};

const botonStyle = {
    backgroundColor: "#694B81",
    color: "#fff",
    padding: "10px 20px",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "30px",
    textTransform: "none",
    transition: "all 0.3s ease-in-out",
    '&:hover': {
        backgroundColor: "#563A67",
        transform: "scale(1.05)",
    },
}

const cardStyle = {
    padding: '20px',
    Cursor:'pointer',
    borderRadius:'10px',
    textAlign: 'center',
    transition: "transform 0.3s ease-in-out",
    '&:hover': {
        transform: "scale(1.05)"
    }
};

const avatarStyle = {
    width: 200,
    height: 200,
    margin: "auto",
    border: "3px solid #ddd",
    transition: "box-shadow 0.3s ease-in-out",
    '&:hover': {
        boxShadow: "0px 0px 15px rgba(0,0,0,0.3)"
    }
};

const textStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'1rem',
    fontWeight:'600'
}
