import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { ImSearch } from "react-icons/im";
import { RxCalendar } from "react-icons/rx";

export const EditarMemorialSistema = ({ memorial, onclose, fetchMemoriales }) => {
    const { Token, userDataMarca } = useUserEmpresa();
    const [loading, setLoading] = useState(false);
    const [imagenPreview, setImagenPreview] = useState(null);
    const [memorialData, setMemorialData] = useState({
        id: '',
        nombre: '',
        rut: '',
        fecha_nacimiento: '', // Valor inicial vacío o cualquier otro valor válido
        fecha_defuncion: '',
        cementerio: '',
        carpeta_perfil: '',
        imagen_perfil: '',
    });

    const backendURL = process.env.REACT_APP_BACKEND_URL;


    /* memorial */
    const fetchMemorialData = async (memorial) => {
        try {
            const response = await axios.get(`${backendURL}/api/getMemorialHistorico/${memorial}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Token}`,
                }
            });
            setMemorialData(response.data);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching memorial data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMemorialData(memorial);
    }, []);

    const handleMemorialUpdate = async (fieldName, value) => {

        try {
            // Ignorar la actualización si el campo es 'video'
            // Construir formData para enviar al backend
            const formData = new FormData();

            // Copiar todos los campos actuales al formData
            Object.entries(memorialData).forEach(([key, val]) => {
                formData.append(key, val);
            });

            // Si el campo es una fecha, formatearlo correctamente
            if (fieldName === 'fecha_nacimiento' || fieldName === 'fecha_defuncion') {
                const date = new Date(value);
                const formattedDate = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;
                formData.set(fieldName, formattedDate);
            } else if (fieldName === 'imagen_perfil') {
                // Si el campo es imagen_perfil, adjuntar el file al formData
                formData.set(fieldName, value);
            } else {
                // De lo contrario, adjuntar el nuevo valor al formData
                formData.set(fieldName, value);
            }

            // Enviar la solicitud al backend
            const response = await axios.post(`${backendURL}/api/memorialEditarEmpresa/${memorialData.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Actualizar el memorialData con la respuesta del servidor (opcional)
            setMemorialData(prevState => ({ ...prevState, [fieldName]: value }));

            fetchMemoriales();
        } catch (error) {
            console.error('Error al actualizar el memorial:', error);
        }
    };

    // Manejar cambios en los campos del formulario
    const handleFieldChange = (fieldName, value) => {
        setMemorialData(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    /* imagen selecion perfil */

    useEffect(() => {
        if (memorialData.imagen_perfil) {
            setImagenPreview(`${backendURL}/imagenes/memoriales/${memorialData.carpeta_perfil}/${memorialData.imagen_perfil}`);
        }
    }, [memorialData.imagen_perfil]);

    const handleFotoPerfilChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                setImagenPreview(reader.result);
                handleMemorialUpdate('imagen_perfil', file);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <h1 style={{ color:'#585857',  fontFamily:'Poppins', fontSize:'1rem', fontWeight:'600' }}>Editar Memorial {memorialData.nombre}</h1>


            <section className="contiene-input-imagen-perfil">
                <label htmlFor="file-input" className="input-subir" style={{
                    border: `7px solid ${(userDataMarca?.color_3 && userDataMarca.color_3 !== 'undefined') ? userDataMarca.color_3 : '#F0EDF3'}`
                }}>
                    <input id="file-input" name="imagen_perfil" type="file" onChange={handleFotoPerfilChange}
                    />
                    {imagenPreview && <img src={imagenPreview} alt="Vista previa de la imagen" />}
                    <div className="cambiar-foto" style={{ color: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined') ? userDataMarca.color_2 : '#694B81' }}> <span className="cambiarfotoedit"><p>Cambiar</p><p>foto</p> </span></div>
                </label>
            </section>
            <section>
                <div className="inputregistorform">
                    <label>Nombre del fallecido</label>
                    <div className="inputnombreeditfallecido" style={{ width: '100%' }}>
                        <input
                            style={{
                                color: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined')
                                    ? userDataMarca.color_2 : '#694B81',
                                width: '100%'
                            }}
                            className="inputeditregistro "
                            name="nombre"
                            value={memorialData.nombre}
                            onChange={(e) => handleFieldChange('nombre', e.target.value)}
                            onBlur={(e) => handleMemorialUpdate('nombre', e.target.value)}
                            id="name" />
                    </div>
                </div>
                <div className="inputregistorform" >
                    <label>Rut del fallecido</label>
                    <div className="inputnombreeditfallecido" style={{ width: '100%' }}>
                        <input
                            style={{ width: '100%', color: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined') ? userDataMarca.color_2 : '#694B81' }}
                            className="inputeditregistro "
                            name="rut"
                            value={memorialData.rut}
                            onChange={(e) => handleFieldChange('rut', e.target.value)}
                            onBlur={(e) => handleMemorialUpdate('rut', e.target.value)}
                            id="rut" />
                    </div>
                </div>
                <div className="inputregistorform">
                    <label htmlFor="editfecha">Fecha de Nacimiento</label>
                    <div className="contienencalendarioperfil">
                        <input
                            style={{ color: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined') ? userDataMarca.color_2 : '#694B81' }}
                            type="date"
                            className="inputeditregistro "
                            name='fecha_nacimiento'
                            value={memorialData.fecha_nacimiento}
                            onChange={(e) => handleFieldChange('fecha_nacimiento', e.target.value)}
                            onBlur={(e) => handleMemorialUpdate('fecha_nacimiento', e.target.value)}
                        />
                        <div className="calendarioicono">
                            <RxCalendar className="iconoperfilcalendario"
                                style={{ color: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined') ? userDataMarca.color_2 : '#694B81' }} />
                        </div>
                    </div>

                </div>
                <div className="inputregistorform">
                    <label htmlFor="editfecha">Fecha de Fallecimiento</label>
                    <div className="contienencalendarioperfil" style={{ width: '100%' }}>
                        <input
                            type="date"
                            style={{ width: '100%', color: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined') ? userDataMarca.color_2 : '#694B81' }}
                            className="inputeditregistro "
                            name='fecha_defuncion'
                            value={memorialData.fecha_defuncion}
                            onChange={(e) => handleFieldChange('fecha_defuncion', e.target.value)}
                            onBlur={(e) => handleMemorialUpdate('fecha_defuncion', e.target.value)}
                        />
                        <div className="calendarioicono">
                            <RxCalendar className="iconoperfilcalendario"
                                style={{ color: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined') ? userDataMarca.color_2 : '#694B81' }} />
                        </div>
                    </div>

                </div>

                <div className="inputregistorform">
                    <label>Cementerio (opcional)</label>
                    <div className="inputeditcementerio" style={{ width: '100%' }}>

                        <div>
                            <input
                                type='text'
                                style={{ width: '100%', color: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined') ? userDataMarca.color_2 : '#694B81' }}
                                className="inputeditregistro "
                                name="cementerio"
                                id="cementerio"
                                placeholder="Buscar cementerios"
                                value={memorialData.cementerio}
                                onChange={(e) => handleFieldChange('cementerio', e.target.value)}
                                onBlur={(e) => handleMemorialUpdate('cementerio', e.target.value)}
                            />
                            <span className="buscadoredit" style={{ right: '1px' }}>
                                <ImSearch style={{ color: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined') ? userDataMarca.color_2 : '#694B81' }} />
                            </span>

                        </div>
                    </div>

                </div>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="botonguardarmensaje" onClick={onclose} style={{ background: (userDataMarca?.color_2 && userDataMarca.color_2 !== 'undefined') ? userDataMarca.color_2 : '#694B81' }}>Guardar cambios </div>
                </div>

            </section>
        </>
    )
}