import React, { useState, useEffect } from "react"
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../../../../../Style/AdministradorSuperEmpresa/adminmemorialempresapagina.css"
import Skeleton from '@mui/material/Skeleton';
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
/* libreria UI */
import { Grid, Box, CircularProgress, Button, Typography } from "@mui/material";
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { BsFillBookmarkHeartFill } from "react-icons/bs";
// Configuración de axios-rate-limit
const https = rateLimit(axios.create(), {
    maxRequests: 2, // Número máximo de solicitudes permitidas
    perMilliseconds: 1000, // Tiempo en milisegundos
});



export const CalendarioMemorialsucursal = () => {
    const {Token, UserDataMarca, Sucursal, Usuario, Empresa} = useUserEmpresa()
    const [memoriales, setMemoriales] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredMemoriales, setFilteredMemoriales] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        fetchMemoriales();
    }, [Sucursal]);

    const fetchMemoriales = async () => {
        if (!Empresa?.id || !Token) return;
        setLoading(true);
        try {
            const response = await https.get(`${backendURL}/api/sucursal/empresa/${Empresa.id}/grupos-familiares`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Token}`,
                },
            });
    
            // Filtrar los grupos familiares por id_sucursal y id_admin_sucursal
            const gruposFiltrados = response.data.filter(grupo => 
                grupo.id_sucursal == Sucursal.id && grupo.id_admin_sucursal == Usuario.id
            );
    
            // Extraer los memoriales de los grupos familiares filtrados
            const memorialesFiltrados = gruposFiltrados.flatMap(grupo => grupo.memorial || []);
    
            // Actualizar el estado con los memoriales filtrados
            setMemoriales(memorialesFiltrados);
        } catch (error) {
            console.error("Error al obtener los memoriales", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        filterMemorialesByDate(date);
    };


     // Obtener las fechas de memoriales creados
  const memorialDates = memoriales.map(memorial => new Date(memorial.created_at).toDateString());
  const isMemorialDay = (date) => {
    return memorialDates.includes(date.toDateString());
  };
    const filterMemorialesByDate = (date) => {
        const selectedDateStr = date.toISOString().split("T")[0]; // Formatear la fecha seleccionada
        const filtered = memoriales.filter((memorial) =>
            memorial.created_at.startsWith(selectedDateStr)
        );
        setFilteredMemoriales(filtered);
    };

    if (loading) {
        return(
          <>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{
            marginTop:'30px', flexDirection:'column'
          }}  >
           
          <Skeleton variant="rectangular" width="80%" height="300px" />  
          <Skeleton variant="rectangular" width="80%" height="200px" sx={{
            marginTop:'10px'
          }} />  
      </Box>
      
      
       </>)
      }

    return (
        <Box sx={{ width:'100%', marginTop: "50px", textAlign: "center", display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}>
            <Typography variant="h5" sx={{ marginBottom: "20px", fontFamily:'Poppins', fontSize:'1rem', fontWeight:'600',  color:' #585857' }}>
                Calendario de Memoriales {Sucursal?.nombre_sucursal}
            </Typography>

            <Calendar
             onChange={handleDateChange}
              value={selectedDate} 
               tileClassName={({ date }) => isMemorialDay(date) ? 'memorial-day' : null}
                width={'100%'}
                style={{
                    background:(UserDataMarca?.color_3 && UserDataMarca.color_3 !== 'undefined') 
                    ? UserDataMarca.color_3 
                    : '#ffff',
                }} />

            {loading ? (
                <CircularProgress />
            ) : (
                <Box sx={{ marginTop: "20px",
                 border:'1px solid #F0EDF3 ',
                  width:'90%',
                  borderColor: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                  ? UserDataMarca.color_1 
                  : '#694B81',
                   borderRadius:'10px' }}>
                    <Box sx={{
                        display:'flex', 
                        alignItems:'center', 
                        justifyContent:'center',
                        borderBottom:'1px solid',
                        borderColor: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                        ? UserDataMarca.color_1 
                        : '#694B81',
                    
                    }}>
                        <Typography style={{
                            color:'#694B81',
                            fontSize:'1rem',
                            fontWeight:'600',
                            margin:'10px',
                            color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                        ? UserDataMarca.color_1 
                        : '#694B81',
                        }}>Memoriales</Typography>
                    </Box>
                    {filteredMemoriales.length > 0 ? (
                        <Grid container spacing={2} sx={{ marginTop: "10px", marginBottom:'10px' ,height:'150px',
                            overflowY:'auto' }}>
                            {filteredMemoriales.map((memorial) => (
                                <Grid item xs={12} key={memorial.id}>
                                    <Grid container>
                                        <Grid item xs={7} sx={{
                                            display:'flex',
                                            alignItems:'center'
                                            ,justifyContent:'flex-start'
                                        }}> 
                                        <div className="iconomemoempressa" >
                                                <BsFillBookmarkHeartFill style={{
                                                    fontSize:'2rem', color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                                    ? UserDataMarca.color_1 
                                                    : '#694B81',
                                                }} />
                                            </div>
                                            <span className="nombrememorialempresa">{memorial.nombre}</span> </Grid>
                                        <Grid item xs={5}>
                                        <a
                                            href={`/familiar/memorial/${memorial.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                        <button
                                        className="butonvermemorial"
                                        style={{
                                            background:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                            ? UserDataMarca.color_1 
                                            : '#694B81',
                                        }}
                                        >
                                       Ver Memorial
                                    </button></a>
                                    </Grid>
                                    </Grid>
                                   
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <div className="errorinfomemoempresa">
                             <div className="iconomemoempressa">
                             <Diversity2Icon   sx={{
                                   
                                   fontSize: '2rem',
                                   
                                   margin: '0px',
                                   color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                       ? UserDataMarca.color_1 
                       : '#694B81',
                               }} />
                            </div>
                            <Typography
                                sx={{
                                   
                                    fontSize: '0.8rem',
                                    fontWeight: '500',
                                    margin: '0px',
                                    color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                        ? UserDataMarca.color_1 
                        : '#694B81',
                                }}
                            >
                                No se encontraron memoriales para esta fecha.
                            </Typography>
                        </div>
                       
                    )}
                </Box>
            )}
        </Box>
    );
};