import React, { useState, useEffect } from "react";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import axios from "axios";
import {
  Grid,
  CircularProgress,
  Modal,
  Box,
  Table,
  Alert, Snackbar,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Fade,
} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';

export const EnviarEncuesta = ({ encuestaId, nombreEncuesta }) => {
  const { Token, UserDataMarca, Empresa } = useUserEmpresa();
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [usuariosAgrupados, setUsuariosAgrupados] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customMessage, setCustomMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const filteredUsuarios =
  usuariosAgrupados?.[selectedYear]?.[String(selectedMonth).padStart(2, "0")] || [];
 
  /* enviarmodal */
  const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
  const [openModalenviar, setOpenModalenviar] = useState(false);
  const [asunto, setAsunto] = useState('editar titulo informativo ');
  const [messagePart0, setMessagePart0] = useState('información para editar (Aquí va  eslogan de tu empresa)');
  const [messagePart1, setMessagePart1] = useState('información para editar parrafo uno');
  const [messagePart2, setMessagePart2] = useState(
    'información para editar parrafo dos'
  );
  const [messagePart3, setMessagePart3] = useState(
     `(información para editar parrafo 3) "Hemos realizado una encuesta "${nombreEncuesta || ""}" para que pueda responder" `
  );
  const [messagePart4, setMessagePart4] = useState(
    'Con cariño (despedida final editar)'
  );

  const [openModalenviarprev, setOpenModalenviarPrev] = useState(false);

  const handleCloseModalprev = () => setOpenModalenviarPrev(false);
 
  const handlePrevisualizar = () => {
    const previewMessage = `
      Asunto: ${asunto}
      Para: ${grupoSeleccionado.map(user => user.email).join(', ')}
      Mensaje:
      ${messagePart0}
      ${messagePart1}
      ${messagePart2}
      ${messagePart3}
      ${messagePart4}
    `;
    
    setOpenModalenviarPrev(true); // Abrir el modal para previsualización
  };
 

  // Obtener usuarios por empresa
  useEffect(() => {
    if (Empresa?.id && Token) {
      const fetchUsuarios = async () => {
        try {
          const { data } = await axios.get(
            `${backendURL}/api/usuariosgetempresatraer/${Empresa.id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Token}`,
              },
            }
          );
        
          setUsuariosAgrupados(data.data);
        } catch (error) {
          console.error("Error al cargar los usuarios:", error);
        }
      };
      fetchUsuarios();
    }
  }, [Empresa?.id, Token]);

  const groupUsuarios = (usuarios, size) => {
    const groups = [];
    for (let i = 0; i < usuarios.length; i += size) {
      groups.push(usuarios.slice(i, i + size));
    }
    return groups;
  };

  const usuarioGroups = groupUsuarios(filteredUsuarios, 10);




  const handleEnviarWhatsapp = async (grupo) => {
    if (!selectedYear || !selectedMonth) {
      showSnackbar("Debe seleccionar un año y un mes.", "warning");
      return;
    }

    if (grupo.length === 0) {
      showSnackbar("No hay usuarios disponibles para este grupo.", "info");
      return;
    }

    if (!customMessage.trim()) {
      showSnackbar("El mensaje personalizado está vacío.", "warning");
      return;
    }

    // Para cada usuario del grupo, se genera un mensaje de WhatsApp y se envía
    for (const usuario of grupo) {
      // Construir el enlace de WhatsApp
      const encuestaUrl = `${window.location.origin}/encuesta/${Empresa.id}/${encuestaId}/${usuario.id}`;
      const mensaje = `Hola ${usuario.name} ${usuario.apellido}, ${customMessage} ${encuestaUrl}`;
      const mensajeEncoded = encodeURIComponent(mensaje);

      // Limpiar el número de teléfono y verificar que tenga la longitud correcta
      const cleanedNumber = usuario.telefono.replace(/\D/g, "");
      if (cleanedNumber.length < 10) {
        console.error(`Número de teléfono inválido para ${usuario.name}`);
        showSnackbar(`Número de teléfono inválido para ${usuario.name}`, "error");
        continue;
      }

      const whatsappLink = `https://wa.me/${cleanedNumber}?text=${mensajeEncoded}`;

      try {
        // Abrir el enlace de WhatsApp en una nueva pestaña
        window.open(whatsappLink, "_blank");
        showSnackbar(`Encuesta enviada a ${usuario.name} ${usuario.apellido} por WhatsApp.`, "success");
      } catch (error) {
        console.error("Error al enviar WhatsApp:", error);
        showSnackbar(`Error al enviar WhatsApp a ${usuario.name}.`, "error");
      }
    }
  };

  const openModal = (usuarioId) => {
    const selectedUser = usuariosAgrupados[selectedYear]?.[selectedMonth]?.find(
      (usuario) => usuario.id === usuarioId
    );
    setSelectedUser(selectedUser);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCustomMessage("");
    setSelectedUser(null);
  };


  const handleOpenModal = (grupo) => {
    setGrupoSeleccionado(grupo); // Almacena el grupo seleccionado
    setOpenModalenviar(true); // Abre el modal
  };

  const handleCloseModal = () => {
    setGrupoSeleccionado(null); // Limpia el grupo seleccionado
    setOpenModalenviar(false);
  };
  

  const handleEnviarCorreo = async (grupoSeleccionado) => {
    setLoading(true);
    // Obtener los IDs de los usuarios en el grupo
    const idsUsuarios = grupoSeleccionado.map((usuario) => usuario.id);

    // Enviar correo por cada grupo de IDs de usuarios
   
      try {
        // Crear un objeto FormData para enviar los datos
        const formData = new FormData();
      
        formData.append("id_empresa", Empresa.id);
        formData.append("id_encuesta", encuestaId);
        formData.append("asunto", asunto);
        formData.append("mensaje0", messagePart0);
        formData.append("mensaje1", messagePart1 || ""); // Si es opcional, enviar una cadena vacía
        formData.append("mensaje2", messagePart2 || "");
        formData.append("mensaje3", messagePart3 || "");
        formData.append("mensaje4", messagePart4 || "");

       

        idsUsuarios.forEach((id, index) => {
          formData.append(`ids_usuarios[${index}]`, id);
        });
    

        const { data } = await axios.post(`${backendURL}/api/envio/encuesta`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        });

        showSnackbar(data.message || "Correos enviados exitosamente.", "success");
        setSnackbarMessage(data.message || "Correos enviados exitosamente.", "success");
        handleCloseModal()
        setLoading(false);
      } catch (error) {
        console.error("Error al enviar correos:", error);
        setLoading(false);
        const errorMessage =
          error.response?.data?.message || "Ocurrió un error al enviar los correos.";
        setSnackbarMessage(errorMessage, "error");
        showSnackbar(errorMessage, "error");
      }
    
  };

  const showSnackbar = (message, severity = "info") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="subtitle1" sx={{ color: "#585857", fontWeight: '600', fontSize: '0.9rem', }}>
        Importante:
      </Typography>
      <Typography variant="body2" sx={{ color: "#585857", fontSize: '0.8rem' }}>
        Para evitar que los correos sean considerados como spam, es recomendable que no se envíen más de 10 correos electrónicos a la vez.
      </Typography>
      <Typography variant="body2" sx={{ color: "#585857", mb: 10, fontSize: '0.8rem' }}>
        Si necesitas enviar el mismo mensaje a más de 10 personas, te sugerimos que dividas los envíos en varios grupos pequeños.
      </Typography>
      <Grid container spacing={8}>
        {/* Selección de año y mes */}
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle1" gutterBottom sx={{ color: "#585857" }}>
            Seleccione el año:
          </Typography>
          <FormControl fullWidth>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              displayEmpty
              sx={{
                color: "#585857",
                border: "1px solid #585857",
              }}
            >
              <MenuItem value="" disabled>
                Año
              </MenuItem>
              {[2024, 2025].map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, color: "#585857" }}>
            Seleccione el mes:
          </Typography>
          <FormControl fullWidth>
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              displayEmpty
              sx={{
                color: "#585857",
                border: "1px solid #585857",
              }}
            >
              <MenuItem value="" disabled>
                Mes
              </MenuItem>
              {[...Array(12)].map((_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {new Date(0, index).toLocaleString("es", { month: "long" })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Tabla de usuarios */}
        <Grid item xs={12} md={9}>
          <Typography variant="subtitle1" sx={{ color: "#585857" }}>
            Usuarios
          </Typography>
          {usuarioGroups.length > 0 ? (
            usuarioGroups.map((grupo, index) => (
              <Box key={index} sx={{ mb: 4 }}>

                <TableContainer sx={{ height: '300px', overflowY: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: "#585857" }}>whatssap</TableCell>
                        <TableCell sx={{ color: "#585857" }}>Nombre</TableCell>
                        <TableCell sx={{ color: "#585857" }}>Correo Electrónico</TableCell>
                        <TableCell sx={{ color: "#585857" }}>Teléfono</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {grupo.map((usuario) => (
                        <TableRow key={usuario.id}>
                          <TableCell sx={{ color: "#585857", fontFamily: 'Poppins', fontSize: '0.8rem' }}>
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => openModal(usuario.id)} // Enviar WhatsApp a todo el grupo
                              sx={{ fontFamily: 'Poppins', fontSize: '0.7rem', textTransform: 'capitalize', color: '#ffff' }}
                            >
                              <WhatsAppIcon sx={{ mr: 1 }} />
                              Enviar WhatsApp
                            </Button>
                          </TableCell>
                          <TableCell sx={{ color: "#585857", fontSize: '0.7rem' }}>{usuario.name} {usuario.apellido}</TableCell>
                          <TableCell sx={{ color: "#585857", fontSize: '0.7rem' }}>{usuario.email}</TableCell>
                          <TableCell sx={{ color: "#585857", fontSize: '0.7rem' }}>{usuario.telefono}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ textAlign: "right", mt: 2, mr: 10 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(grupo)} 
                    sx={{ fontFamily: 'Poppins', fontSize: '0.9rem', textTransform: 'capitalize', color: '#ffff' }}
                  >
                    <EmailIcon sx={{ mr: 1 }} />
                    Enviar Correos
                  </Button>


                </Box>
              </Box>
            ))
          ) : (
            <Box sx={{ mt: 4, textAlign: "center" }}>
              <Typography variant="subtitle1" sx={{ color: "#585857" }}>
                No hay clientes disponibles para este mes.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "#ffff",
            border: "1px solid #000",
            boxShadow: 24,
            borderRadius: '10px',
            p: 4,
          }}
        >
          <Typography variant="h6" sx={{ color: "#585857", textAlign: 'center' }}>
            Escribe el mensaje personalizado para enviar la encuesta por WhatsApp
          </Typography>
          <textarea
            rows={4}
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
            style={{
              width: "100%",
              marginTop: "10px",
              padding: "10px",
              fontSize: "16px",
              fontFamily: 'Poppins',
              color: "#585857",
              background: '#ffff',
            }}
            placeholder="Escribe tu mensaje aquí"
          />
          <Box sx={{ textAlign: "right", marginTop: "10px" }}>
            <Button
              sx={{ textTransform: "capitalize", color: '#ffff' }}
              variant="contained"
              color="success"
              onClick={() => {
                handleEnviarWhatsapp([selectedUser]); // Enviar WhatsApp a un solo usuario
                closeModal();
              }}
              disabled={!customMessage.trim()}
            >
              Enviar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={loading}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000} // Duración en milisegundos
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* enviar correo */}
      <Modal
        open={openModalenviar}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: '90%',
            height:'95%',
            overflowY:'auto',
            bgcolor: "#ffff",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display:"flex",
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column'
          }}
        >
          <div
          style={{
            
            position:'relative',
            top:'20px',
            borderRadius: '10px',
            height: 'auto',
            marginBottom:'150px'
            
          }}
          >
             

          </div>
          
              <div
                style={{
                  padding:'20px',
                  width: '85%',
                  position:'relative',
                  top:'20px',
                  borderRadius: '10px',
                  height: 'auto',
                  border: '1px solid #585857',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                }}
              >
               
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#f9f9f9',
                  }}
                >
                  <InputLabel
                    sx={{
                      color: '#585857',
                      marginLeft: '20px',
                      padding: '10px',
                      fontWeight: '500',
                    }}
                  >
                    Nuevo Mensaje
                  </InputLabel>
                  <Button>
                    <CloseIcon onClick={handleCloseModal} style={{
                      color:UserDataMarca?.color_1 || '#585857'
                    }}/>
                  </Button>
                </div>
                <div
                  style={{
                    borderBottom: '1px solid #585857',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: '100%', // Hace que el div ocupe todo el ancho disponible
                  }}
                >
                  <InputLabel
                    sx={{
                      color: '#585857',
                      marginLeft: '20px',
                      fontWeight: '500',
                      marginRight: '10px', // Opcional para agregar un espacio entre el label y el campo
                    }}
                  >
                    Asunto
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    value={asunto}
                    onChange={(e) => setAsunto(e.target.value)}
                    InputProps={{
                      sx: {
                        color: '#585857',
                        border: 'none', // Elimina el borde
                      },
                    }}
                    sx={{
                      width: '80%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none', // Elimina el borde del TextField
                        },
                      },
                    }}
                  />
                </div>

                <div
  style={{
    width: '100%',
    borderBottom: '1px solid #585857',
    display: 'flex',
    flexDirection: 'column', // Cambiado
    alignItems: 'flex-start',
    padding: '0px 2px',
  }}
>
  <InputLabel
    sx={{
      color: '#585857',
      marginLeft: '20px',
      padding: '10px 0px',
      fontWeight: '500',
    }}
  >
    Para
  </InputLabel>
  <div
    style={{
      flexWrap: 'wrap',
      width: '100%',
      height:'80px',
      overflowY:'auto',
      padding: '10px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start', // Cambiado
    }}
  >

      {grupoSeleccionado &&
        grupoSeleccionado.map((usuario, index) => (
          <div
           className="encuestacorreo"
            key={index}
            style={{
              flexWrap: 'wrap',
              width:'30%',
              height:'50px',
              display: 'flex',
              flexDirection:'row',
              alignItems: 'center',
              justifyContent:'center',
              marginTop: '0px',
              marginBottom: '5px', // Añadido
              borderRadius: '8px',
              backgroundColor: '#f9f9f9',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <span style={{ marginRight: '8px', color: '#585857' }}>
              {usuario.email}
            </span>
          </div>
        ))}
  
  </div>
</div>
                <InputLabel
                  sx={{
                    color: '#585857',
                    marginLeft: '20px',
                    fontWeight: '500',
                  }}
                >
                  Mensaje
                </InputLabel>
                {/* Mostrar los correos agregados */}

                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                  <Box sx={{
                    width: '120px'
                  }}>
                    {UserDataMarca?.logo ? (
                      <img src={`${backendURL}/${UserDataMarca.logo}`} width='100%' />
                    ) : <>
                    </>}
                  </Box>

                  <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '30px'
                  }}>
                    <textarea
                      value={messagePart0}
                      onChange={(e) => setMessagePart0(e.target.value)}
                      rows={messagePart0.split('\n').length} // Ajusta el número de filas según las líneas
                      style={{
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        background: '#ffff',
                        width: '86%', // Ancho completo
                        color: '#585857', // Color del texto
                        height: '100%', // Altura dinámica
                        resize: 'none', // Deshabilita el ajuste manual del tamaño
                        fontSize: '0.8rem', // Tamaño de fuente
                        outline: 'none', // Elimina el borde al enfocar
                        border: '1px solid #585857'

                      }}
                    />
                  </Box>
                  <Box sx={{
                    width: '85%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'start',
                    marginBottom: '15px'
                  }}>
                    <Typography sx={{
                      fontFamily: 'Poppins',
                      color: '#585857', // Color del texto
                      fontSize: '0.8rem',
                    }}>
                      Estimado/a [nombre del destinario]
                    </Typography>
                  </Box>
                  <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <textarea
                      value={messagePart1}
                      onChange={(e) => setMessagePart1(e.target.value)}
                      rows={messagePart1.split('\n').length} // Ajusta el número de filas según las líneas
                      style={{
                        textAlign: 'justify',
                        fontFamily: 'Poppins',
                        background: '#ffff',
                        width: '86%', // Ancho completo
                        color: '#585857', // Color del texto
                        height: '60px', // Altura dinámica
                        overflowY: 'auto',
                        resize: 'none', // Deshabilita el ajuste manual del tamaño
                        fontSize: '0.8rem', // Tamaño de fuente
                        outline: 'none', // Elimina el borde al enfocar
                         border: '1px solid #585857',
                         marginBottom:'20px'

                      }}
                    />
                  </Box>
                 
                  <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <textarea
                      value={messagePart2}
                      onChange={(e) => setMessagePart2(e.target.value)}
                      rows={messagePart2.split('\n').length} // Ajusta el número de filas según las líneas
                      style={{
                        textAlign: 'justify',
                        fontFamily: 'Poppins',
                        background: '#ffff',
                        width: '86%', // Ancho completo
                        color: '#585857', // Color del texto
                        height: '90px', // Altura dinámica
                        overflowY: 'auto',
                        resize: 'none', // Deshabilita el ajuste manual del tamaño
                        fontSize: '0.8rem', // Tamaño de fuente
                        outline: 'none', // Elimina el borde al enfocar
                         border: '1px solid #585857',
                        marginBottom:'20px'

                      }}
                    />
                  </Box>

                  <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <textarea
                      value={messagePart3}
                      onChange={(e) => setMessagePart3(e.target.value)}
                      rows={messagePart3.split('\n').length} // Ajusta el número de filas según las líneas
                      style={{
                        textAlign: 'justify',
                        fontFamily: 'Poppins',
                        background: '#ffff',
                        width: '86%', // Ancho completo
                        color: '#585857', // Color del texto
                        height: '90px', // Altura dinámica
                        overflowY: 'auto',
                        resize: 'none', // Deshabilita el ajuste manual del tamaño
                        fontSize: '0.8rem', // Tamaño de fuente
                        outline: 'none', // Elimina el borde al enfocar
                         border: '1px solid #585857',
                         marginBottom:'20px'

                      }}
                    />
                  </Box>
                  <Box sx={{
                    width: '85%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'start',
                    flexDirection: 'column'

                  }}>
                    <textarea
                      value={messagePart4}
                      onChange={(e) => setMessagePart4(e.target.value)}
                      rows={messagePart4.split('\n').length} // Ajusta el número de filas según las líneas
                      style={{
                        textAlign: 'justify',
                        fontFamily: 'Poppins',
                        background: '#ffff',
                        width: '100%', // Ancho completo
                        color: '#585857', // Color del texto
                        height: 'auto',
                        overflowY: 'auto',
                        resize: 'none', // Deshabilita el ajuste manual del tamaño
                        fontSize: '0.8rem', // Tamaño de fuente
                        outline: 'none', // Elimina el borde al enfocar
                         border: '1px solid #585857',
                         marginBottom:'20px'

                      }}
                    />
                    <Typography sx={{
                      fontFamily: 'Poppins',
                      color: '#585857', // Color del texto
                      fontSize: '0.8rem'
                    }}>
                      {Empresa.nombre_empresa}
                    </Typography>
                  </Box>

                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', mt: 4, p: 1,}}>
                  {/* Botón de enviar correo */}
                  <Button
                    variant="contained"
                    
                    onClick={handlePrevisualizar}
                    
                    sx={{
                      backgroundColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined' ? UserDataMarca.color_1 : '#694B81',
                      textTransform: 'capitalize',
                      color: '#ffff',
                      position:'relative',
                      right:'20px'
                     
                    }}
                  >
                   Previsualización
                  </Button>
                  <Button
                    variant="contained"
                    
                    onClick={() => handleEnviarCorreo(grupoSeleccionado)}
                    
                    sx={{
                      backgroundColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined' ? UserDataMarca.color_1 : '#694B81',
                      textTransform: 'capitalize',
                      color: '#ffff',
                     
                    }}
                  >
                   {loading ? "Enviando..." : "Enviar Correo Electrónico"}
                  </Button>

                </Box>
              </div>
              <div>

              </div>
            
        </Box>
      </Modal>
      <Modal
        open={openModalenviarprev}
        onClose={handleCloseModalprev}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            height: 'auto',
            overflowY: 'auto',
            bgcolor: '#fff',
            border: '2px solid #000',
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6" id="modal-title">Previsualización del Correo</Typography>
          <Box sx={{ mt: 2 }}>
            <Box
            sx={{
              width:1000,
              background: UserDataMarca?.color_3 && UserDataMarca.color_3 !== 'undefined' ? UserDataMarca.color_3 : '#694B81',
              p:3,
              display:'flex',
              alignItems:'center',
              justifyContent:'center'
            }}>
              <Box
              sx={{
                background:'#ffff',
                width:700,
                borderRadius:'5px'
                
              }}
              
              >
                <div style={{
                  width:'100%',
                  background: UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined' ? UserDataMarca.color_1 : '#694B81',
                  height:'20px',
                   borderRadius:'5px 5px 0px 0px'
               }} >
                  
                </div> 
                <div  
           style={{
            height:'10px',
          
            background: `linear-gradient(to right, ${UserDataMarca?.color_2 || '#ff6d00'}, ${UserDataMarca?.color_3 || '#ff0080'}, ${UserDataMarca?.color_4 || '#ff6d00'})`,
          }}></div>
                <div
                style={{
                  padding:'20px',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  flexDirection:'column'
                }}>
                    <Box sx={{
                    width: '120px'
                  }}>
                    {UserDataMarca?.logo ? (
                      <img src={`${backendURL}/${UserDataMarca.logo}`} width='100%' />
                    ) : <>
                    </>}
                    
                   
                  </Box>
                    <Typography variant="body1" sx={{  color: UserDataMarca?.color_2 || '#ff6d00' }}>
                        {messagePart0} 
                       </Typography>
                </div>
                <div
                style={{
                  padding:'20px',
                  display:'flex',
                  alignItems:'stretch',
                  flexDirection:'column'
                }}>
                     <Typography sx={{
                      fontFamily: 'Poppins',
                      color: '#585857', // Color del texto
                      fontSize: '0.8rem',
                      mb:2,
                      color: UserDataMarca?.color_2 || '#ff6d00' 
                    }}>
                      Estimado/a [nombre del destinario]
                    </Typography>
                <Typography variant="body1" sx={{  color: UserDataMarca?.color_2 || '#ff6d00',textAlign:'justify' }}>
                        {messagePart1} 
                       </Typography>
                       <Typography variant="body1" sx={{  color: UserDataMarca?.color_2 || '#ff6d00', textAlign:'justify' }}>
                        {messagePart2} 
                       </Typography>
                </div>
                <div
                style={{
                  width:'100%',
                  background: UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined' ? UserDataMarca.color_1 : '#694B81',
                  padding:'20px',
                  display:'flex',
                  alignItems:'stretch',
                  flexDirection:'column'
                }}>
                    <Typography variant="body1" sx={{ mb:3, color: '#ffff', textAlign:'center' }}>
                        {messagePart3} 
                       </Typography>
                       <Typography variant="body1" sx={{  color: '#ffff', textAlign:'justify' }}>
                        {messagePart4} 
                       </Typography>
                       <Typography sx={{
                      fontFamily: 'Poppins',
                      color: '#ffff', // Color del texto
                      fontSize: '0.8rem'
                    }}>
                      {Empresa.nombre_empresa}
                    </Typography>
                    <Box sx={{ mt: 4, display: 'flex', justifyContent:'center', width: '100%' }}>
            <Button
              variant="outlined"
              onClick={handleCloseModalprev}
              sx={{
                color: '#ffff',
                borderColor: '#ffff',
                textTransform: 'capitalize',
                borderRadius:'50px',
                padding:'10px 30px'
              }}
            >
              Haz clic aquí
            </Button>
            
          </Box>
                  </div>
              </Box>
             
              
            </Box>
          
          </Box>

          <Box sx={{ mt: 4, display: 'flex', justifyContent:'flex-end', width: '100%' }}>
            <Button
              variant="outlined"
              onClick={handleCloseModalprev}
              sx={{
                color: '#694B81',
                borderColor: '#694B81',
                textTransform: 'capitalize',
              }}
            >
              Cerrar
            </Button>
            
          </Box>
        </Box>
      </Modal>

    </Box>
  );
};