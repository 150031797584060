import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from 'jspdf';
import rateLimit from 'axios-rate-limit';
import { useNavigate, useParams, useLocation} from "react-router-dom";
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import SucursalLayout from "../../../../../SucursalLayout"


/* material ui */
import { DataGrid } from '@mui/x-data-grid';
import { Container, Grid, Box, CircularProgress, Typography, Select ,MenuItem} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
/* react icons */
import { IoIosClose } from "react-icons/io";
import { BsFillHouseAddFill } from "react-icons/bs";
import { BsFillHouseCheckFill } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { TbFaceIdError } from "react-icons/tb";
import { TbUserPlus } from "react-icons/tb";
import { TbUserHeart } from "react-icons/tb";
import { TbUser } from "react-icons/tb";
/* IMAGENES  */
import QrBlanco from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/qrblanco.png"
import fondo from "../../../../../Image/generales/carta.png";

// Configuración de axios-rate-limit
const http = rateLimit(axios.create(), {
    maxRequests: 2, // Número máximo de solicitudes permitidas
    perMilliseconds: 1000, // Tiempo en milisegundos
});

export const QrHistorialsucursalAdmi = ({setShowNavbarFooter}) => {
    const { id } = useParams(); // El ID de la empresa
    const { Token, Empresa, Sucursal, Usuario, UserDataMarca } = useUserEmpresa();
    const [loading, setLoading] = useState(true);
    const [codigos, setCodigos] = useState([]);
    const [marcas, setMarcas] = useState([])
    const [gruposFamiliares, setGruposFamiliares] = useState([]);
    const [error, setError] = useState(null);
    const location = useLocation();
    const { sucursal, user } = location.state || {};
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [searchQR, setSearchQR] = useState('');
    const [searchUser, setSearchUser] = useState('');
    const [searchRut, setSearchRut] = useState('');
    const [selectedSucursal, setSelectedSucursal] = useState('');
    const [selectedAgente, setSelectedAgente] = useState('');
    const handleQRChange = (e) => setSearchQR(e.target.value);
    const handleUserChange = (e) => setSearchUser(e.target.value);
    const handleRutChange = (e) => setSearchRut(e.target.value);
    const handleSucursalChange = (e) => setSelectedSucursal(e.target.value);
    const handleAgenteChange = (e) => setSelectedAgente(e.target.value);
    const [gruposfamilias, setGrupofamilias] = useState([]); 
    const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [suscripcionFiltro, setSuscripcionFiltro] = useState('');

    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
               
                await fetchGruposFamiliares();
                await fetchCodigos();
                await fetchMarcas();
                await  fetchCodigosqr();
            } catch (error) {
                console.error("Error al cargar los datos:", error);
            } finally {
                setLoading(false);
            }
        };
  
        if (Sucursal && Empresa.id) {
            fetchData();
        }
    }, [Sucursal, Token]);
  
    
  
    const fetchGruposFamiliares = async () => {
        const response = await http.get(`${backendURL}/api/sucursal/empresa/${Empresa.id}/grupos-familiares`, {
            headers: {
                Authorization: `Bearer ${Token}`,
            },
        });
         // Filtrar los grupos familiares para que solo queden aquellos cuyo id_sucursal coincida con el id de la sucursal actual
        

         // Actualizar el estado con los datos filtrados
         setGruposFamiliares(response.data);
    };

    const fetchMarcas = async () => {
        if (Empresa.id) {
            const response = await http.get(`${backendURL}/api/marcaempresa/sucursal/${Empresa.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Token}`,
                },
            });
            const marcaData = response.data.data[0];
            if (marcaData) {
                setMarcas(marcaData);
            }
        }
    };
  
    const fetchCodigos = async () => {
        try {
            const response = await http.get(`${backendURL}/api/getcodigosSucursal`, {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            });
            setCodigos(response.data);
        } catch (error) {
            console.error("Error al obtener los códigos:", error);
        }
    };

   const fetchCodigosqr = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/getAllGruposFamiliaresSucursal`, {
                  headers: { Authorization: `Bearer ${Token}` },
                });
                setGrupofamilias(response.data);
               
            } catch (error) {
                console.error("Error al obtener los códigos:", error);
            } 
        };
        



    const filteredGruposFamiliares = gruposFamiliares.filter((qr) => {
        // Filtros para los diferentes campos
        const matchesQR = searchQR ? qr.id.toString().includes(searchQR) || qr.nombre_familia?.toLowerCase().includes(searchQR.toLowerCase()) : true;
        const matchesUser = searchUser ? `${qr.usuario?.name} ${qr.usuario?.apellido}`.toLowerCase().includes(searchUser.toLowerCase()) : true;
        const matchesRut = searchRut ? String(qr.usuario?.rut || '').includes(searchRut) : true;
        const matchesSucursal = selectedSucursal ? qr.sucursal?.nombre_sucursal === selectedSucursal : true;
        const matchesAgente = selectedAgente ? qr.sucursal?.agente === selectedAgente : true;
    
        return matchesQR && matchesUser && matchesRut && matchesSucursal && matchesAgente;
    });
        
        
    const handleAssignUser = (qr) => {
        navigate(`/registro/${Usuario.id}/usuario/sucursal`, { state: { QR: qr, sucursal:Sucursal, user:Usuario, Token } });
      };
      
          const handleUserqr = (qr) => {
            navigate(`/sucursal/${Usuario.id}/qr/memorial/${qr.id}`, { state: { QR: qr, sucursal:Sucursal, user:Usuario, Token } });
          };
    
        /* descargas QR Unico*/
       
        const downloadQR = async (qr) => {
          try {
              const response = await axios.post(`${backendURL}/api/download/qr/empresa`, {
                  qr: qr.qr,
                  id: qr.id,
                  id_empresa: qr.id_empresa
              }, {
                  responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
              });
      
              if (response.status === 200) {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = qr.qr; // Nombre del archivo
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
              } else {
                  console.error('Error:', response.status);
              }
          } catch (error) {
              console.error('Error al enviar la solicitud:', error);
          }
      };
     /* descarga carta marca */
     const handleDownloadPDF = async (codigoId) => {
        try {
            const grupoFamiliar = gruposfamilias.find(grupo => grupo.id === codigoId);
    
            if (!grupoFamiliar || !grupoFamiliar.qr) {
                console.error("Código QR no encontrado para el grupo familiar:", codigoId);
                return;
            }
    
            const qrImageUrl = `${backendURL}/api/descargarqrempresa/${codigoId}`;
            const cartaImageUrl = `${backendURL}/api/descargarCarta/${Empresa.id}`; // Usa el ID correcto aquí
            const grupoFamiliares = gruposFamiliaresConSuscripcion.find(grupo => grupo.id === codigoId);
    
            if (!grupoFamiliares || !grupoFamiliares.codigo) {
                console.error("Código no encontrado para el grupo familiar con suscripción:", codigoId);
                return;
            }
    
            const loadImage = (url) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = url;
                    img.crossOrigin = "Anonymous";
                    img.onload = () => resolve(img);
                    img.onerror = (err) => reject(err);
                });
            };
    
            const qrImage = await loadImage(qrImageUrl);
            const fondoImage = await loadImage(fondo);
            let cartaImage;
    
            // Intenta cargar la imagen de la carta
            try {
                cartaImage = await loadImage(cartaImageUrl);
            } catch (error) {
                console.warn("No se pudo cargar la imagen de la carta. Usando el fondo predeterminado.");
                cartaImage = null; // Establece cartaImage a null si no se puede cargar
            }
    
            const canvas = document.createElement('canvas');
            canvas.width = qrImage.width;
            canvas.height = qrImage.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(qrImage, 0, 0, qrImage.width, qrImage.height);
            const qrDataURL = canvas.toDataURL('image/png');
    
            const doc = new jsPDF();
    
            // Usa la imagen de la carta como fondo si existe
            const imgWidth = doc.internal.pageSize.getWidth();
            const imgHeight = doc.internal.pageSize.getHeight();
            if (cartaImage) {
                // Si hay imagen de carta, usarla como fondo
                doc.addImage(cartaImage.src, 'PNG', 0, 0, imgWidth, imgHeight);
            } else {
                // Si no, usar el fondo específico
                doc.addImage(fondoImage.src, 'PNG', 0, 0, imgWidth, imgHeight);
            }
    
            // Define el tamaño y la posición del QR
            const qrSize = 44;
            const qrX = (imgWidth - qrSize) / 2; // Centra el QR horizontalmente
            const qrY = 140; // Ajusta esta posición según tus necesidades
            doc.addImage(qrDataURL, 'PNG', qrX, qrY, qrSize, qrSize);
    
            const idText = ` ${grupoFamiliar.id}`; // Formato para el ID
    
            // Establece un tamaño de fuente más pequeño
            const originalFontSize = doc.internal.getFontSize(); // Guarda el tamaño de fuente original
            doc.setFontSize(10); // Establece el tamaño de fuente a 10 (ajusta este valor según tus necesidades)
    
            const idTextWidth = doc.getStringUnitWidth(idText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const idTextX = (imgWidth - idTextWidth) / 2; // Centra el ID horizontalmente
            const idTextY = qrY + qrSize + 5; // Ajusta esta posición según tus necesidades
            doc.text(idText, idTextX, idTextY);
    
            // Restaura el tamaño de fuente original
            doc.setFontSize(originalFontSize);
    
            // Agrega el código de texto debajo del ID
            const codeText = grupoFamiliares.codigo; // Código de grupo familiar
            const codeTextWidth = doc.getStringUnitWidth(codeText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const codeTextX = (imgWidth - codeTextWidth) / 2; // Centra el código horizontalmente
            const codeTextY = idTextY + 32; // Ajusta esta posición según tus necesidades
            doc.text(codeText, codeTextX, codeTextY);
    
            doc.save(`codigo_${grupoFamiliar.id}.pdf`);
        } catch (error) {
            console.error('Error al generar el PDF:', error);
        }
    };

    const gruposFamiliaresConSuscripcion = gruposfamilias.map((grupo) => {
        const suscripcion = codigos.find(codigo => codigo.id_grupo_familiar == grupo.id);
        return {
            id: grupo.id,
            id_empresa: grupo.id_empresa,
            id_sucursal: grupo.id_sucursal,
            id_usuario: grupo.id_usuario,
            nombre_familia: grupo.nombre_familia,
            estado_suscripcion: suscripcion ? suscripcion.estado : null,
            codigo: suscripcion ? suscripcion.codigo : null,
            fecha_inicio: suscripcion ? suscripcion.fecha_inicio : null,
            fecha_final: suscripcion ? suscripcion.fecha_final : null,
            qr: grupo.qr,
            tipo_memorial: grupo.tipo_memorial,
            created_at: grupo.created_at,
            updated_at: grupo.updated_at
        };
    });
    
    // Filtrar los datos según los filtros seleccionados
    const filteredData = grupoSeleccionado
        ? gruposFamiliaresConSuscripcion.filter(row => row.id == grupoSeleccionado.id)
        : gruposFamiliaresConSuscripcion.filter((row) => {
            return (estadoFiltro === '' || row.estado_suscripcion === estadoFiltro) &&
                (suscripcionFiltro === '' || row.suscripcion === suscripcionFiltro);
        });
    
        const handleEstadoChange = async (userId, isChecked) => {
            const newStatus = isChecked ? 'ACTIVO' : 'INACTIVO';
        
            try {
                const response = await axios.get(`${backendURL}/api/estadosuscripcion/${userId}/${newStatus}`, {
                    headers: {
                        Authorization: `Bearer ${Token}`
                    }
                });
                await fetchGruposFamiliares()
            } catch (error) {
                console.error('Error al actualizar el estado:', error.response ? error.response.data : error.message);
            }
        };

        const columns = [
            { field: 'id', headerName: 'QR', width: 100 },
          
            { field: 'nombreFamilia', headerName: 'Nombre Familia', width: 200 },
            { field: 'tipoQR', headerName: 'Tipo QR', width: 150 },
            { field: 'cantidadMemorial', headerName: 'Cantidad Memorial', width: 150 },
            { field: 'sucursal', headerName: 'Sucursal', width: 200 },
            { field: 'usuario', headerName: 'Usuario', width: 200 },
            { field: 'rut', headerName: 'Rut', width: 150 },
            { field: 'email', headerName: 'Email', width: 200 },
            { field: 'telefono', headerName: 'Teléfono', width: 150 },
            {
                field: 'estado',
                headerName: 'Estado',
                width: 150,
                renderCell: (params) => (
                  params.row.id_usuario ? (
                    <div className="switchqr">
                      <IOSSwitch
                        checked={params.row.estadoUsuario == 1} // Verifica si el estadoUsuario es 1
                        onChange={(event) => handleEstadoChange(params.row.idUsuario, event.target.checked)} // Cambia el estado cuando el switch se mueve
                      />
                    </div>
                  ) : (
                    <p>No asignado</p> // Si no hay idUsuario, puedes mostrar algo por defecto
                  )
                ),
              },
            {
            field: 'accionesUsuario',
            headerName: 'Acciones Usuario',
            width: 350,
            renderCell: (params) => (
                params.row.id_usuario ? (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <button className="BotonPerfil" onClick={() => handleUserqr(params.row)}>
                    <TbUser /> Perfil
                    </button>
                    {params.row.nombreFamilia && (
                    <a href={`/familiar/group/${params.row.id}`} target="_blank" rel="noopener noreferrer">
                        <button className="BotonMemorial">
                        <TbUserHeart /> Memorial
                        </button>
                    </a>
                    )}
                    
                </div>
                ) : (
                <button onClick={() => handleAssignUser(params.row)} className="botonAsignar">
                    <TbUserPlus /> Asignar Usuario
                </button>
                )
            ),
            },
            {
            field: 'accionesQR',
            headerName: 'Acciones QR',
            width: 300,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                <button className="BotonQrdescarga" onClick={() => downloadQR(params.row)}>
                    Descargar QR
                </button>
                <button className="cartadescargar" onClick={() => handleDownloadPDF(params.row.id)}>
                    Descargar Carta
                </button>
                </div>
            ),
            }, 
             { field: 'qr', headerName: 'QR imagen', width: 100 }
        ];
        
        const rows = filteredGruposFamiliares.map((qr) => ({
            id: qr.id,
            id_admin_sucursal:qr.id_admin_sucursal,
            id_empresa:qr.id_empresa,
            id_sucursal:qr.id_sucursal,
            nombreFamilia: qr.nombre_familia,
            tipoQR: qr.tipo_memorial == 1
            ? 'Grupo Familiar'
            : qr.tipo_memorial == 2
            ? 'Memorial'
            : qr.tipo_memorial == 3
            ? 'Histórico'
            : qr.tipo_memorial == 4
            ? 'Empresa'
            : '-',
            cantidadMemorial: qr.memorial ? qr.memorial.length : 0,
            sucursal: qr.sucursal ? qr.sucursal.nombre_sucursal : `Empresa ${Empresa?.nombre_empresa}`,
            usuario: qr.usuario ? `${qr.usuario.name} ${qr.usuario.apellido}` : 'No hay usuario asignado',
            rut: qr.usuario?.rut || 'No hay usuario asignado',
            email: qr.usuario?.email || 'No hay usuario asignado',
            name: qr.usuario?.name || 'No hay usuario asignado',
            apellido: qr.usuario?.apellido || 'No hay usuario asignado',
            telefono: qr.usuario?.telefono || 'No hay usuario asignado',
            id_usuario: qr.id_usuario,
            estadoUsuario: qr.usuario?.estado,
            qr: qr.qr,
        }));
  

    
    return (
        <SucursalLayout>
                <Container maxWidth="lg">

                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" sx={{ flexDirection:'column'}}>
                            <Skeleton variant="text" width="80%" height="100px" />
                            <Skeleton variant="rectangular" width="80%" height="400px" />
                        </Box>
                    ) : (
                        <Box sx={{
                            width:'100%',
                            marginTop:'50px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            flexDirection:'column',
                            marginBottom:'100px'

                        }}>
                            <Box sx={{
                            width:'100%'}}>
                                <Box
                                sx={{
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'flex-start'
                                }}>
                                <div style={{
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center'
                                }}>
                                <IoSearch style={{
                                    color:'#694B81',
                                    fontSize:'1.3rem'
                                }}/> 
                                </div> 
                                <h4 style={{
                                    color:'#585857',
                                    fontWeight:'600'
                                }}>QR Buscador Sucursal {Sucursal.nombre_sucursal}</h4>
                                </Box>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <div className="buscadorlupaqr">
                                            <input
                                                className="inputregistroqrempresa"
                                                type="text"
                                                placeholder="Buscar código QR o nombre familia"
                                                value={searchQR}
                                                onChange={handleQRChange}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="buscadorlupaqr">
                                            <input
                                                className="inputregistroqrempresa"
                                                type="text"
                                                placeholder="Buscar usuario"
                                                value={searchUser}
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="buscadorlupaqr">
                                            <input
                                                className="inputregistroqrempresa"
                                                type="text"
                                                placeholder="Buscar Rut"
                                                value={searchRut}
                                                onChange={handleRutChange}
                                            />
                                        </div>
                                    </Grid>
                                
                                    
                                </Grid>
                            
                            </Box>
                            <Grid container spacing={2}>
                            <div style={{ height: 400, width: '100%' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              checkboxSelection
              disableSelectionOnClick
              sx={{

                marginTop: '50px',
                fontFamily: 'Poppins',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra del borde
                borderRadius: '8px', // Borde redondeado (si lo deseas)
                '& .MuiDataGrid-columnHeaders': {
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de texto de la cabecera
                  fontWeight: '600', // Estilo de fuente de la cabecera
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Sombra para la cabecera
                  borderBottom: '2px solid rgba(0, 0, 0, 0.2)', // Borde inferior de la cabecera
                },
                '& .MuiDataGrid-cell': {
                  color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5',
                  borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde entre celdas
                },
                '& .MuiCheckbox-root': {
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de los checkboxes (opcional)
                },
                '& .MuiTablePagination-selectLabel':{
                    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },'& .MuiTablePagination-displayedRows' : {
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .MuiInputBase-input':{
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .Mui-disabled':{
                    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .MuiSelect-icon':{
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                }
              }}
            />
          )}
        </div>
        </Grid>
                        </Box>
                    )}
                </Container>
        </SucursalLayout>
    );
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
  
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color:'#694B81',
    position:'relative',
    left:'0.5%',
    fontFamily: "Poppins",
    width:'100%',
    height:'40px',
    fontSize:'0.8rem',
    borderRadius: '50px',
    border: ` ${error ? '1px solid #D26999' : '3px solid #F0EDF3'}`, // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize:'0.7rem',
      width: '100%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '100%',
    },
}));