import React, { useEffect, useState } from "react";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import { useParams, useLocation } from "react-router-dom";
import EmpresaLayout from "../../../../../../../EmpresaLayout";
import { CrearMemorialHistoricoFinal } from "./IndexCreacionMemorialHistorico";
import { CrearGaleriaMemorialHistorico } from "./CrearGaleriaMemorialHistorico";
import { UbicacionMemorialHistorico } from "./IndexUbicacionMemorialHistorico";

/* libreria UI */
import { Container} from '@mui/material';





export const ComponenetesCreacionMemorialHistorico =({setShowNavbarFooter}) =>{
  
    const { id } = useParams();
    const location = useLocation();
    const { grupoFamiliar} = location.state || {};
    const [step, setStep] = useState(1);
    const [memorialId, setMemorialId] = useState(null);



   
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

   
    const handleNextStep = (id) => {
        setMemorialId(id); // Guardar el memorialId
        setStep(prevStep => prevStep + 1); // Avanzar al siguiente paso
    };

  

  

    return(
        <EmpresaLayout>
        <Container maxWidth='lg' sx={{fontFamily:'Poppins'}}>
            
            <Container maxWidth='md'>
           {step === 1 && (
                <>
                <CrearMemorialHistoricoFinal
                    id={id}
                    grupoFamiliar={grupoFamiliar}
                    onNextStep={handleNextStep}
                />
                </>

            )}
             {step === 2 && (
                <>
                <CrearGaleriaMemorialHistorico
                    id={id}
                    grupoFamiliar={grupoFamiliar}          
                    memorialId={memorialId}
                    onNextStep={handleNextStep}
                />
                </>
                
            )}
           {step === 3 && (
                <>
                     <UbicacionMemorialHistorico
                    id={id}
                    grupoFamiliar={grupoFamiliar}
                    memorialId={memorialId}
                    onNextStep={handleNextStep}
                />
                </>
            )} 
                
            </Container>
        
        
        </Container>
        </EmpresaLayout>
    )
}