import React, {useEffect, useState} from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";

/* Material UI */
import {
    
    Card,
    CardContent,
    Box,
    Typography,
    Grid,
    CircularProgress
} from '@mui/material';
/* Material-UI Charts */
import { BarChart } from '@mui/x-charts';

export const EstadisticaPorFecha = () => {

        const {Token, Empresa,  UserDataMarca}=useUserEmpresa();
        const backendURL = process.env.REACT_APP_BACKEND_URL;
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
        const [fechasQr, setFechasQr] = useState({}); 

        useEffect(() => {
                if (!backendURL) {
                    console.warn("⚠️ La variable de entorno REACT_APP_BACKEND_URL no está definida.");
                    setLoading(false);
                    return;
                }
        
                if (!Empresa?.id) {
                    setLoading(false);
                    return;
                }
        
                const fetchGruposFamiliares = async () => {
                    setLoading(true);
                    setError(null);
        
                    try {
                        const response = await axios.get(`${backendURL}/api/estadistica/empresa/fechas`, {
                            params: { id_empresa: Empresa.id },
                            headers: { Authorization: `Bearer ${Token}` },
                        });
        
                    
        
                        setFechasQr(response.data || {});
                    } catch (err) {
                        console.error("❌ Error en la petición:", err);
                        setError(err.response?.data?.message || "Error al obtener los datos");
                    } finally {
                        setLoading(false);
                    }
                };
        
                fetchGruposFamiliares();
            }, [backendURL, Empresa.id, Token]);

            if (loading) return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
    if (error) return <Typography color="error">{error}</Typography>;

    // ✅ Asegurar que `fechasQr` tenga siempre una estructura válida
    const totalVentas = fechasQr?.total_qr_por_mes_empresa ?? {}; 
    const sucursales = fechasQr?.sucursales ?? []; 

    if (sucursales.length === 0) return <Typography>No hay datos disponibles.</Typography>;

    const meses = Object.keys(totalVentas).sort((a, b) => a - b);

    const series = sucursales.length > 0 
    ? sucursales.map(sucursal => ({
        label: sucursal.sucursal?.nombre || "Sucursal Desconocida",
        data: meses.map(mes => sucursal.total_qr_por_mes?.[mes] ?? 0) 
    }))
    : [];

    const defaultColor = '#3f51b5';
const totalEmpresaColor = (UserDataMarca?.color_3 && /^#([0-9A-F]{3}){1,2}$/i.test(UserDataMarca.color_3)) 
    ? UserDataMarca.color_3 
    : defaultColor;

    series.push({
        label: "Total Empresa",
        data: meses.map(mes => totalVentas[mes] ?? 0), 
        color: totalEmpresaColor
    });
        
    return(
        <>
       
        <Box sx={{background: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',}}>
            <Typography variant="h5" textAlign="center" marginBottom={2}>
                Ventas por Mes y Sucursal
            </Typography>

            <BarChart
                xAxis={[
                    {
                        scaleType: 'band',
                        data: meses.map(m => `Mes ${m}`)
                    }
                ]}
                series={series}
                height={400}
            />
        </Box>
        </>
    )
}