import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../../../../Style/AdministradorSuperEmpresa/LoginEmpresas.css"
import { useUserEmpresa } from "../../../context/UsuarioEmpresa";

/* Material UI */
import { Container,  Box, Grid, CircularProgress,  Typography, Modal, Button } from '@mui/material';
import { borderRadius, styled } from "@mui/system";


/* Iconos React */
import { FiAlertCircle } from 'react-icons/fi';
import { TbLock } from "react-icons/tb";
import { TbLockOpen } from "react-icons/tb";
import { IoAlertCircleOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

/* imagenes */
import Logo from "../../../../Image/generales/movil-smart-memorial-header.png"
import BackgroundImage from "../../../../Image/inicio/incio.jpg";

export const LoginPageEmpresas = () =>{
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailError, setEmailError] = useState('');
  const [empresaInactiva, setEmpresaInactiva] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setAdminUserEmpresaToken, setUserInformationMarca,setUserInformationSucursal, setUserInformationUsuario, setUserInformationEmpresa} = useUserEmpresa();
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');
  
    if (!validateEmail(email)) {
      setEmailError('El correo electrónico no es válido');
      setLoading(false);
      return;
    } else {
      setEmailError('');
    }
  
    try {
      const response = await fetch(`${backendURL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        setPasswordError('El correo o contraseña están incorrectos');
        setLoading(false);
        return;
      }
  
      const data = await response.json();
      const { token, user, marca_empresa, empresa, sucursal } = data; // Desestructurar para obtener el token y el usuario
     
      if (user.estado == 0) {
        setEmpresaInactiva(true); // Mostrar el modal
        setLoading(false);
        return;
      }
     
      // Guardar el token y el usuario en sessionStorage y contexto
      sessionStorage.setItem('Token', token);
      sessionStorage.setItem('User', user);
      setUserInformationMarca(marca_empresa); 
      setAdminUserEmpresaToken(token, user);
      setUserInformationUsuario(user);
      setUserInformationEmpresa(empresa);
      setUserInformationSucursal(sucursal);
     
  
      // Navegar según el rol
      if (user.id_rol == 3) {
        navigate(`/Inicio-Empresa/${user.id}`);
      } else if (user.id_rol == 4) {
        navigate(`/Inicio-Sucursal/${user.id}`);
      }
  
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Comprueba si el usuario ya ha iniciado sesión al cargar el componente
    const token = sessionStorage.getItem("userToken");
    if (token) {
      const tokenParts = token.split('.');
      if (tokenParts.length == 3) {
        try {
          const user = JSON.parse(atob(tokenParts[1])); // Intenta decodificar el payload
          // Verifica que el usuario tenga la propiedad 'id_rol'
          if (user.id_rol) {
            if (user.id_rol == 3) {
              navigate(`/Inicio-Empresa/${user.id}`);
            } else if (user.id_rol == 4) {
              navigate(`/Inicio-Sucursal/${user.id}`);
            } else {
              navigate("/Inicio-Sesion-Empresas"); // Redirigir si no es un rol reconocido
            }
          } else {
            console.error('El token no contiene el rol del usuario.');
           
            navigate("/Inicio-Sesion-Empresas");
          }
        } catch (error) {
          console.error('Error al decodificar el token:', error);
          navigate("/Inicio-Sesion-Empresas");
        }
      } else {
        console.error('Token no es válido');
        navigate("/Inicio-Sesion-Empresas");
      }
    }
  }, [navigate]);


  const validateEmail = (email) => {
    // Expresión regular para validar correo electrónico
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  
  
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:'10px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    p: 4,
  };
    return(
        <>
         <section  style={{display:'flex', alignItems:'center', justifyContent:'center',backgroundImage: `url(${BackgroundImage})`, height:'100vh'}}>
        
<form className="formempresaingreso" onSubmit={handleSubmit}>

   
                        <section className="logoselecionempresa" style={{
                          width:'200px'
                        }}>
                            <img src={Logo} className='logosmartmemorialiniciosecionempresa' alt="smart memorial" title="smart memorial" />
                        </section>
                        <section className="TituloPaginaLoginEmpresa">
                            <h1 className="Bienvenido">Bienvenido a</h1>
                            <h1 className="TituloDosEmpresa"> Smart Memorial Empresa</h1>
                        </section>
                        

  <div className="flex-column">
    <label>Email </label>
  </div>
  <div className="inputFormEmpresapaginaingreso">
    <svg
    style={{fill:'#ffff'}}
      height="20"
      viewBox="0 0 32 32"
      width="20"
      
    >
      <g id="Layer_3" data-name="Layer 3">
        <path
          d="m30.853 13.87a15 15 0 0 0 -29.729 4.082 15.1 15.1 0 0 0 12.876 12.918 15.6 15.6 0 0 0 2.016.13 14.85 14.85 0 0 0 7.715-2.145 1 1 0 1 0 -1.031-1.711 13.007 13.007 0 1 1 5.458-6.529 2.149 2.149 0 0 1 -4.158-.759v-10.856a1 1 0 0 0 -2 0v1.726a8 8 0 1 0 .2 10.325 4.135 4.135 0 0 0 7.83.274 15.2 15.2 0 0 0 .823-7.455zm-14.853 8.13a6 6 0 1 1 6-6 6.006 6.006 0 0 1 -6 6z"
        ></path>
      </g>
    </svg>
    <input 
         required
         id="email"
         name="email"
         autoComplete="email"
         className="inputloginempresa" 
         placeholder="ingrese email" />
  </div>

  <div className="flex-column">
    <label>Password </label>
  </div>
  <div className="inputFormEmpresapaginaingreso" >
  <button
  type="button"
  onClick={() => setShowPassword(!showPassword)}
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    background: "transparent",
    border: "0px",
    padding: "0px",
  }}
>
  {showPassword ? (
    <TbLockOpen style={{ fontSize: "1.5rem", color: "#ffff" }} />
  ) : (
    <TbLock style={{ fontSize: "1.5rem", color: "#ffff" }} />
  )}
</button>
    <input 
      required
      name="password"
      id="password"
      autoComplete="current-password"
      type={showPassword ? "text" : "password"} 
      className="inputloginempresa" 
      placeholder="Ingrese contraseña" />
  </div>
  <div className="contiene-error-mensaje" style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', left:'0'}}>  {passwordError && <span className="errormessagelogin" style={{color:'#ffff'}}><FiAlertCircle className='icon-error' />{passwordError}</span>} </div>
 <div className="cotienenbutonloginempresa">
  <button  type='submit'className="buttoningresoempresasubmit"> {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Iniciar Sesión'}</button>
</div>

  
</form>

         
           
         </section>
        
         <Modal open={empresaInactiva} onClose={() => setEmpresaInactiva(false)}>
      <Box sx={style}>
        <div style={{display:'flex', alignItems:'flex-end', width:'100%'}} > 
          <Button onClick={() => setEmpresaInactiva(false)} sx={{  fontFamily:'Poppins', textTransform:'capitalize',  justifyContent:'flex-end',  }}  >
          <IoClose style={{ fontSize:'2rem', color:'#585857 '}} />
        </Button>
        </div>
        <div>
        <IoAlertCircleOutline style={{ fontSize:'3rem', color:'#FFC107 '}} />
        </div>
        <Typography variant="h6" component="h2" sx={{fontFamily:'Poppins', color:'#585857', textAlign:'center'}}>
          Usuario inactivo
        </Typography>
        <Typography   sx={{fontFamily:'Poppins', mt: 2,mb:2,color:'#585857',  textAlign:'center' }}>
          Tu empresa se encuentra inactiva. Por favor, contacta con el
          administrador para más información.
        </Typography>
       
      </Box>
    </Modal>
        
        </>
    )
}
