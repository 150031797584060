import React from "react";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import { QrEmpresaFuncion } from "./QrEmpresasFuncionEstadistica";
import { EstadsiticaPorSucursalEmpresa } from "./QrPorSucursalEmpresaEstadistica";
import { EstadisticaPorFecha } from "./EstadisticaPorFecha";
/* material ui */
import { Container, Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  

export const IndexEstructuraEstadistica = () => {
    const {Empresa, UserDataMarca} = useUserEmpresa();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
        <Container maxWidth='xxl'>
      <Box sx={{ background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6',  width: '100%' }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{background:'#ffff',
                border:'1px solid',
                borderColor: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                    ? UserDataMarca.color_1
                    : '#E1DBE6', 
            }}
          >
            <Tab 
            label={Empresa.nombre_empresa}
             {...a11yProps(0)}
             sx={{color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== "undefined"
                ? UserDataMarca.color_2
                : '#E1DBE6', fontFamily:'Poppins', textTransform:'capitalize', fontWeight:'600'}} />
            <Tab 
            label="Sucursales" 
            {...a11yProps(1)}
            sx={{color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== "undefined"
                ? UserDataMarca.color_2
                : '#E1DBE6',  fontFamily:'Poppins', textTransform:'capitalize', fontWeight:'600' }} />
            <Tab 
            label="Qr" 
            {...a11yProps(2)}
            sx={{color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== "undefined"
                ? UserDataMarca.color_2
                : '#E1DBE6',  fontFamily:'Poppins', textTransform:'capitalize', fontWeight:'600' }} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <QrEmpresaFuncion/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <EstadsiticaPorSucursalEmpresa/>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <EstadisticaPorFecha/>
        </TabPanel>
      </Box>
      </Container>
    )
}

