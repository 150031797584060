import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from 'jspdf';
import rateLimit from 'axios-rate-limit';
import { useNavigate} from "react-router-dom";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import EmpresaLayout from "../../../../../../EmpresaLayout"
/* style */
import "../../../../../../Style/AdministradorSuperEmpresa/iconosadminempresa.css"
/* material ui */
import { DataGrid } from '@mui/x-data-grid'; 
import { Container,Modal, Grid, Box, CircularProgress,  Avatar,  Select ,MenuItem,  IconButton, AlertTitle, Alert} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
/* react icons */
import { IoSearch } from "react-icons/io5";
import { TbUserPlus } from "react-icons/tb";
import { TbUserHeart } from "react-icons/tb";
import { TbUser } from "react-icons/tb";
import { FaTrashCan } from "react-icons/fa6";
/* IMAGENES  */
import fondo from "../../../../../../Image/generales/carta.png";

// Configuración de axios-rate-limit
const http = rateLimit(axios.create(), {
    maxRequests: 2, // Número máximo de solicitudes permitidas
    perMilliseconds: 1000, // Tiempo en milisegundos
});

export const QrHistorialEmpresaAdmi = ({setShowNavbarFooter}) => {

    const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(true);
    const [codigos, setCodigos] = useState([]);
    const [qrData, setQrData] = useState([]);
    const [marcas, setMarcas] = useState([])
    const [gruposFamiliares, setGruposFamiliares] = useState([]);
    const navigate = useNavigate()
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [searchQR, setSearchQR] = useState('');
    const [searchUser, setSearchUser] = useState('');
    const [searchRut, setSearchRut] = useState('');
    const [selectedSucursal, setSelectedSucursal] = useState('');
    const [selectedAgente, setSelectedAgente] = useState('');
    const handleQRChange = (e) => setSearchQR(e.target.value);
    const handleUserChange = (e) => setSearchUser(e.target.value);
    const handleRutChange = (e) => setSearchRut(e.target.value);
    const handleSucursalChange = (e) => setSelectedSucursal(e.target.value);
    const [gruposfamilias, setGrupofamilias] = useState([]); 
    const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [suscripcionFiltro, setSuscripcionFiltro] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [processingModal, setProcessingModal] = useState(false);
    const [alertType, setAlertType] = useState("info");
    const [openEliminar, setOpenEliminar] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
  

    const handleOpenModal = () => setIsOpen(true);
    const handleCloseModal = () => setIsOpen(false);

    /* eliminar codigo QR */
    const [idQr, setIdQr] = useState(null); 

    const handleOpenEliminar = (qrId) => {
      setIdQr(qrId);
      setOpenEliminar(true);
  };
     /* Cerrar el modal */
     const handleCloseEliminar = () => {
      setOpenEliminar(false);
      setIdQr(null);
  };
    
    /* Eliminar código QR */
    const handleEliminarQrEmpresa = async (id) => {
      if (!id) {
          console.error("No hay ID de QR para eliminar");
          return;
      }
    
      setProcessingModal(true); 
      try {
          const response = await axios.post(`${backendURL}/api/qreliminar/empresa`, 
              { id: id }, // Enviar ID en el body
              {
                  headers: {
                      Authorization: `Bearer ${Token}`,
                  },
              }
          );
          setLoadings(true)
          setProcessingModal(false);
          setSuccessMessage("Qr eliminado con exito.");
          setAlertType("success");
          setIsOpen(true);
          await fetchGruposFamiliares();
          
         
  
          // Aquí puedes actualizar la lista de QR tras la eliminación
          // setQrList(prevList => prevList.filter(qr => qr.id !== idQr));
  
      }catch (error) {
        setProcessingModal(false);
 
  setErrorMessage("Error al eliminar el qr");
  setAlertType("error");
  setIsOpen(true);
        console.error('Error al generar el PDF:', error);
    }
    setLoadings(false)
      handleCloseEliminar();
  };
    
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await fetchQrData();
                await fetchGruposFamiliares();
                await fetchMarcas();
                await fetchCodigos();
            } catch (error) {
                console.error("Error al cargar los datos:", error);
            } finally {
                setLoading(false);
            }
        };
  
        if (Empresa && Empresa?.id) {
            fetchData();
        }
    }, [Empresa, Token]);
  
    const fetchQrData = async () => {
        const response = await http.get(`${backendURL}/api/qr/empresa/admi/${Empresa.id}`, {
            headers: {
                Authorization: `Bearer ${Token}`,
            },
        });
        setQrData(response.data.data);
    };
  
    const fetchGruposFamiliares = async () => {
      if (Empresa?.id) {
        const response = await http.get(`${backendURL}/api/empresa/grupos/familiares/index/${Empresa.id}`, {
            headers: {
                Authorization: `Bearer ${Token}`,
            },
        });
        setGruposFamiliares(response.data);
         }
    };

    const fetchMarcas = async () => {
        if (Empresa.id) {
            const response = await http.get(`${backendURL}/api/todo/marca/empresa/index/${Empresa.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Token}`,
                },
            });
            const marcaData = response.data.data[0];
            if (marcaData) {
                setMarcas(marcaData);
            }
        }
    };
  
    const fetchCodigos = async () => {
        try {
            const response = await http.get(`${backendURL}/api/todos/codigos/empresa`, {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            });
            setCodigos(response.data);
        } catch (error) {
            console.error("Error al obtener los códigos:", error);
        }
    };
  
   

      useEffect(() => {
        // Simular la carga de datos
        const fetchData = async () => {
            setLoading(true);
            setLoadings(true)
            // Aquí va tu lógica de fetch para obtener los datos
            await fetchGruposFamiliares();
            setLoading(false);
            setLoadings(false)
        };
    
        fetchData();
    }, []);

  

    const filteredGruposFamiliares = gruposFamiliares.filter((qr) => {
        // Filtros para los diferentes campos
        const matchesQR = searchQR ? qr.id.toString().includes(searchQR) || qr.nombre_familia?.toLowerCase().includes(searchQR.toLowerCase()) : true;
        const matchesUser = searchUser ? `${qr.usuario?.name} ${qr.usuario?.apellido}`.toLowerCase().includes(searchUser.toLowerCase()) : true;
        const matchesRut = searchRut ? String(qr.usuario?.rut || '').includes(searchRut) : true;
        const matchesSucursal = selectedSucursal ? qr.sucursal?.nombre_sucursal === selectedSucursal : true;
        const matchesAgente = selectedAgente ? qr.sucursal?.agente === selectedAgente : true;
    
        return matchesQR && matchesUser && matchesRut && matchesSucursal && matchesAgente;
    });
        
        
        const handleAssignUser = (qr) => {
            navigate(`/registro/${Usuario.id}/usuario/empresa`, { state: { QR: qr, Empresa, Usuario, Token } });
          };
      
          const handleUserqr = (qr) => {
            navigate(`/empresa/${Usuario.id}/qr/memorial/${qr.id}`, { state: { QR: qr, Empresa, Usuario, Token } });
          };
    
        /* descargas QR Unico*/
        const fetchCodigosqr = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/get/todos/GruposFamiliaresempresas`, {
                  headers: { Authorization: `Bearer ${Token}` },
                });
                setGrupofamilias(response.data);
               
            } catch (error) {
                console.error("Error al obtener los códigos:", error);
            } finally {
                setLoading(false);
            }
        };
        useEffect(() => {
            fetchCodigosqr();
        }, [backendURL, Token])

        const downloadQR = async (qr) => {
          try {
              const response = await axios.post(`${backendURL}/api/download/qr/empresa`, {
                  qr: qr.qr,
                  id: qr.id,
                  id_empresa: Empresa.id
              }, {
                  responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
              });
      
              if (response.status === 200) {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = qr.qr; // Nombre del archivo
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
              } else {
                  console.error('Error:', response.status);
              }
          } catch (error) {
              console.error('Error al enviar la solicitud:', error);
          }
      };
     /* descarga carta marca */
     const handleDownloadPDF = async (codigoId) => {
        setLoading(true);
        setProcessingModal(true); 
        try {
            const grupoFamiliar = gruposfamilias.find(grupo => grupo.id === codigoId);
    
            if (!grupoFamiliar || !grupoFamiliar.qr) {
                setProcessingModal(false);
                setLoading(false);
                setErrorMessage("Código QR no encontrado para el grupo familiar:", codigoId);
                setAlertType("error");
                setIsOpen(true);
                
                return;
            }
    
            const qrImageUrl = `${backendURL}/api/descargarqrempresa/${codigoId}`;
            const cartaImageUrl = `${backendURL}/api/descargarCarta/${Empresa.id}`; // Usa el ID correcto aquí
            const grupoFamiliares = gruposFamiliaresConSuscripcion.find(grupo => grupo.id === codigoId);
    
            if (!grupoFamiliares || !grupoFamiliares.codigo) {
                setProcessingModal(false);
                setLoading(false);
                setErrorMessage("Código no encontrado para el grupo familiar con suscripción:", codigoId);
                setAlertType("error");
                setIsOpen(true);
                
                return;
            }
    
            const loadImage = (url) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = url;
                    img.crossOrigin = "Anonymous";
                    img.onload = () => resolve(img);
                    img.onerror = (err) => reject(err);
                });
            };
    
            const qrImage = await loadImage(qrImageUrl);
            const fondoImage = await loadImage(fondo);
            let cartaImage;
    
            // Intenta cargar la imagen de la carta
            try {
                cartaImage = await loadImage(cartaImageUrl);
            } catch (error) {
                console.warn("No se pudo cargar la imagen de la carta. Usando el fondo predeterminado.");
                cartaImage = null; // Establece cartaImage a null si no se puede cargar
            }
    
            const canvas = document.createElement('canvas');
            canvas.width = qrImage.width;
            canvas.height = qrImage.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(qrImage, 0, 0, qrImage.width, qrImage.height);
            const qrDataURL = canvas.toDataURL('image/png');
    
            const doc = new jsPDF();
    
            // Usa la imagen de la carta como fondo si existe
            const imgWidth = doc.internal.pageSize.getWidth();
            const imgHeight = doc.internal.pageSize.getHeight();
            if (cartaImage) {
                // Si hay imagen de carta, usarla como fondo
                doc.addImage(cartaImage.src, 'PNG', 0, 0, imgWidth, imgHeight);
            } else {
                // Si no, usar el fondo específico
                doc.addImage(fondoImage.src, 'PNG', 0, 0, imgWidth, imgHeight);
            }
    
            // Define el tamaño y la posición del QR
            const qrSize = 44;
            const qrX = (imgWidth - qrSize) / 2; // Centra el QR horizontalmente
            const qrY = 140; // Ajusta esta posición según tus necesidades
            doc.addImage(qrDataURL, 'PNG', qrX, qrY, qrSize, qrSize);
    
            const idText = ` ${grupoFamiliar.id}`; // Formato para el ID
    
            // Establece un tamaño de fuente más pequeño
            const originalFontSize = doc.internal.getFontSize(); // Guarda el tamaño de fuente original
            doc.setFontSize(10); // Establece el tamaño de fuente a 10 (ajusta este valor según tus necesidades)
    
            const idTextWidth = doc.getStringUnitWidth(idText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const idTextX = (imgWidth - idTextWidth) / 2; // Centra el ID horizontalmente
            const idTextY = qrY + qrSize + 5; // Ajusta esta posición según tus necesidades
            doc.text(idText, idTextX, idTextY);
    
            // Restaura el tamaño de fuente original
            doc.setFontSize(originalFontSize);
    
            // Agrega el código de texto debajo del ID
            const codeText = grupoFamiliares.codigo; // Código de grupo familiar
            const codeTextWidth = doc.getStringUnitWidth(codeText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const codeTextX = (imgWidth - codeTextWidth) / 2; // Centra el código horizontalmente
            const codeTextY = idTextY + 32; // Ajusta esta posición según tus necesidades
            doc.text(codeText, codeTextX, codeTextY);
            setLoading(false);
            setProcessingModal(false);
            setSuccessMessage("Carta creado con exito.");
            setAlertType("success");
            setIsOpen(true);
            doc.save(`codigo_${grupoFamiliar.id}.pdf`);
        } catch (error) {
            setProcessingModal(false);
      setLoading(false);
      setErrorMessage("Error al generar el PDF");
      setAlertType("error");
      setIsOpen(true);
            console.error('Error al generar el PDF:', error);
        }
    };

    const gruposFamiliaresConSuscripcion = gruposfamilias.map((grupo) => {
        const suscripcion = codigos.find(codigo => codigo.id_grupo_familiar == grupo.id);
        return {
            id: grupo.id,
            id_empresa: grupo.id_empresa,
            id_sucursal: grupo.id_sucursal,
            id_usuario: grupo.id_usuario,
            nombre_familia: grupo.nombre_familia,
            estado_suscripcion: suscripcion ? suscripcion.estado : null,
            codigo: suscripcion ? suscripcion.codigo : null,
            fecha_inicio: suscripcion ? suscripcion.fecha_inicio : null,
            fecha_final: suscripcion ? suscripcion.fecha_final : null,
            qr: grupo.qr,
            tipo_memorial: grupo.tipo_memorial,
            created_at: grupo.created_at,
            updated_at: grupo.updated_at
        };
    });
    
    // Filtrar los datos según los filtros seleccionados
    const filteredData = grupoSeleccionado
        ? gruposFamiliaresConSuscripcion.filter(row => row.id == grupoSeleccionado.id)
        : gruposFamiliaresConSuscripcion.filter((row) => {
            return (estadoFiltro === '' || row.estado_suscripcion === estadoFiltro) &&
                (suscripcionFiltro === '' || row.suscripcion === suscripcionFiltro);
        });
    
        const handleEstadoChange = async (userId, isChecked) => {
            const newStatus = isChecked ? 'ACTIVO' : 'INACTIVO';
        
            try {
                const response = await axios.get(`${backendURL}/api/estadosuscripcion/${userId}/${newStatus}`, {
                    headers: {
                        Authorization: `Bearer ${Token}`
                    }
                });
                await fetchGruposFamiliares()
            } catch (error) {
                console.error('Error al actualizar el estado:', error.response ? error.response.data : error.message);
            }
        };
    
    
        const columns = [
            { field: 'id', headerName: 'QR', width: 70 },
          
            { field: 'nombreFamilia', headerName: 'Nombre Familia', width: 200 },
            { field: 'tipoQR', headerName: 'Tipo QR', width: 150 },
            { field: 'cantidadMemorial', headerName: 'Cantidad Memorial', width: 150 },
            { field: 'sucursal', headerName: 'Sucursal', width: 200 },
            { field: 'usuario', headerName: 'Usuario', width: 200 },
            { field: 'rut', headerName: 'Rut', width: 150 },
            { field: 'email', headerName: 'Email', width: 200 },
            { field: 'telefono', headerName: 'Teléfono', width: 150 },
            {
                field: 'estado',
                headerName: 'Estado',
                width: 100,
                renderCell: (params) => (
                  params.row.idUsuario ? (
                    <div className="switchqr">
                      <IOSSwitch
                        checked={params.row.estadoUsuario == 1} // Verifica si el estadoUsuario es 1
                        onChange={(event) => handleEstadoChange(params.row.idUsuario, event.target.checked)} // Cambia el estado cuando el switch se mueve
                      />
                    </div>
                  ) : (
                    <p>No asignado</p> // Si no hay idUsuario, puedes mostrar algo por defecto
                  )
                ),
              },
            {
            field: 'accionesUsuario',
            headerName: 'Acciones Usuario',
            width: 350,
            renderCell: (params) => (
                params.row.idUsuario ? (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <button className="BotonPerfil" onClick={() => handleUserqr(params.row)}>
                    <TbUser /> Perfil
                    </button>
                    {params.row.nombreFamilia && (
                    <a href={`/familiar/group/${params.row.id}`} target="_blank" rel="noopener noreferrer">
                        <button className="BotonMemorial">
                        <TbUserHeart /> Memorial
                        </button>
                    </a>
                    )}
                    
                </div>
                ) : (
                <button onClick={() => handleAssignUser(params.row)} className="botonAsignar">
                    <TbUserPlus /> Asignar Usuario
                </button>
                )
            ),
            },
            {
            field: 'accionesQR',
            headerName: 'Acciones QR',
            width: 320,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                <button className="BotonQrdescarga" onClick={() => downloadQR(params.row)}>
                    Descargar QR
                </button>
                <button className="cartadescargar" onClick={() => handleDownloadPDF(params.row.id)}>
                    Descargar Carta
                </button>
                </div>
            ),
            }, 
            { field: 'Eliminar QR', headerName: 'Eliminar QR', width: 150,renderCell: (params) => (
              <div style={{ display: 'flex', gap: '8px' }}>
             <button className="Eliminar-boton-qr-empresa" onClick={() => handleOpenEliminar(params.row)}>
                <svg className="eliminarqrempresa-svgIcon" viewBox="0 0 448 512">
                                  <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                                </svg>
              </button>
              </div>
          ), },
             { field: 'qr', headerName: 'QR imagen', width: 50 }
        ];
        
        const rows = filteredGruposFamiliares.map((qr) => ({
            id: qr.id,
           
            nombreFamilia: qr.nombre_familia || 'No hay memorial asignado',
            tipoQR: qr.tipo_memorial == 1
            ? 'Grupo Familiar'
            : qr.tipo_memorial == 2
            ? 'Memorial'
            : qr.tipo_memorial == 3
            ? 'Histórico'
            : qr.tipo_memorial == 4
            ? 'Empresa'
            : '-',
            cantidadMemorial: qr.memorial ? qr.memorial.length : 0,
            sucursal: qr.sucursal ? qr.sucursal.nombre_sucursal : `Empresa ${Empresa?.nombre_empresa}`,
            usuario: qr.usuario ? `${qr.usuario.name} ${qr.usuario.apellido}` : 'No hay usuario asignado',
            rut: qr.usuario?.rut || 'No hay usuario asignado',
            email: qr.usuario?.email || 'No hay usuario asignado',
            telefono: qr.usuario?.telefono || 'No hay usuario asignado',
            idUsuario: qr.id_usuario,
            nombreFamilia: qr.nombre_familia,
            estadoUsuario: qr.usuario?.estado,

            qr: qr.qr,
        }));
  
        if (loading) {
          return (
              <Box
    
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="10vh"
                  sx={{ flexDirection: 'column', position: 'relative', height: '200px', width: '100%' }}
              >
                  {/* Circular Progress grande */}
                  <CircularProgress 
                      size={100} 
                      thickness={3} 
                      sx={{ color: 'primary.main' }} 
                  />
                  
                  {/* Avatar centrado */}
                  {Empresa?.logo && (
                      <Avatar
                          src={`${backendURL}/${Empresa.logo}`}
                          alt="Logo Empresa"
                          sx={{
                              width: 40, // Ajusta el tamaño del avatar
                              height: 40,
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)', // Para centrar
                          }}
                      />
                  )}
              </Box>
          );
      }
    return (
        <EmpresaLayout>
        <Container maxWidth="lg">

            {loadings ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" sx={{ flexDirection:'column'}}>
                    <Skeleton variant="text" width="80%" height="100px" />
                    <Skeleton variant="rectangular" width="80%" height="400px" />
                </Box>
            ) : (
                <Box sx={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    flexDirection:'column',
                    marginBottom:'100px'

                }}>
                    <Box sx={{
                    width:'100%'}}>
                        <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'flex-start'
                        }}>
                        <div style={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center'
                        }}>
                         <IoSearch style={{
                            color:'#694B81',
                            fontSize:'1.3rem'
                         }}/> 
                        </div> 
                        <h4 style={{
                            color:'#585857',
                            fontWeight:'500',
                            fontSize:'1rem',
                            fontFamily:'Poppins',
                        }}>QR Buscador {Empresa?.id_tipo_empresa == 1 ? 'Cementerio' : 'Funeraria'} {Empresa?.nombre_empresa}</h4>
                        </Box>
                        <Grid container>
                            <Grid item xs={3}>
                                <div className="buscadorlupaqr">
                                    <input
                                        className="inputregistroqrempresa"
                                        type="text"
                                        placeholder="Buscar código QR o nombre familia"
                                        value={searchQR}
                                        onChange={handleQRChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="buscadorlupaqr">
                                    <input
                                        className="inputregistroqrempresa"
                                        type="text"
                                        placeholder="Buscar usuario"
                                        value={searchUser}
                                        onChange={handleUserChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="buscadorlupaqr">
                                    <input
                                        className="inputregistroqrempresa"
                                        type="text"
                                        placeholder="Buscar Rut"
                                        value={searchRut}
                                        onChange={handleRutChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <StyledSelect
                                    name="sucursal"
                                    value={selectedSucursal}
                                    onChange={handleSucursalChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                    
                                        fontFamily:'Poppins'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Sucursales</em>
                                    </MenuItem>
                                    {/* Mapear las sucursales únicas */}
                                    {[...new Set(gruposFamiliares.map(qr => qr.sucursal?.nombre_sucursal))].map((sucursal) => (
                                        sucursal && <MenuItem key={sucursal} value={sucursal}>{sucursal}</MenuItem>
                                    ))}
                                </StyledSelect>
                            </Grid>
                            
                        </Grid>
                    
                    </Box>
                    <Grid container spacing={2}>
        <div style={{ height: 400, width: '100%' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              checkboxSelection
              disableSelectionOnClick
              sx={{

                marginTop: '50px',
                fontFamily: 'Poppins',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra del borde
                borderRadius: '8px', // Borde redondeado (si lo deseas)
                '& .MuiDataGrid-columnHeaders': {
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de texto de la cabecera
                  fontWeight: '600', // Estilo de fuente de la cabecera
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Sombra para la cabecera
                  borderBottom: '2px solid rgba(0, 0, 0, 0.2)', // Borde inferior de la cabecera
                },
                '& .MuiDataGrid-cell': {
                  color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5',
                  borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde entre celdas
                },
                '& .MuiCheckbox-root': {
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de los checkboxes (opcional)
                },
                '& .MuiTablePagination-selectLabel':{
                    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .MuiTablePagination-displayedRows' : {
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .MuiInputBase-input':{
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .Mui-disabled':{
                    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .MuiSelect-icon':{
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                }
              }}
            />
          )}
        </div>
      </Grid>
                </Box>
            )}

            {/* procesos */}
    <AlertaModal
        alertType={alertType}
        successMessage={successMessage}
        errorMessage={errorMessage}
        isOpen={isOpen}
        handleClose={handleCloseModal}
      />

<Modal
    open={processingModal}
    onClose={() => setProcessingModal(false)}
    aria-labelledby="processing-modal"
    aria-describedby="processing-description"
>
    <Box sx={{ ...modalStyle }}>
    <h3 id="processing-modal" style={{margin:'0'}} >Procesando solicitud...</h3>
    <CircularProgress color="primary" />
    </Box>
</Modal>

<Modal
    open={openEliminar} onClose={handleCloseEliminar}
    aria-labelledby="processing-modal"
    aria-describedby="processing-description"
>
    <Box sx={{ ...modalStyle }}>
      <div className="TituloEliminarQrEmpresa">
      <FaTrashCan />
      </div>
    <h3 id="processing-modal" style={{margin:'0', fontWeight:'500',color:' #585857', textAlign:'center', fontSize:'1rem', marginBottom:'10px'}} >¿Estás seguro de eliminar éste código QR {idQr?.id} {idQr?.nombreFamilia}?</h3>
    <h3 id="processing-modal" style={{margin:'0', fontWeight:'600',color:'rgb(97, 30, 30)', textAlign:'center', fontSize:'1rem', marginBottom:'10px'}} >Aviso importante</h3>
    
    <h4 id="processing-modal" style={{margin:'0', fontWeight:'500',color:' #585857', textAlign:"justify", fontSize:'0.9rem'}} > Si confirmas la eliminación de éste código QR {idQr?.id} {idQr?.nombreFamilia}, también se eliminará el usuario registrado, los memoriales asociados, la galería y los comentarios. Esta acción es irreversible y la información no podrá recuperarse. Asegúrate de que esta decisión es definitiva</h4>
    <div style={{ display: 'flex', gap: '8px', marginTop:'20px' }}>
                <button className="BotonQrdescarga" onClick={()=> handleEliminarQrEmpresa(idQr?.id)} style={{background:(UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',}}>
                    Eliminar QR
                </button>
                <button className="cartadescargar" onClick={handleCloseEliminar}  style={{background:(UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#585857',}} >
                    Cancelar
                </button>
                </div>
   
    </Box>
</Modal>
        </Container>
        </EmpresaLayout>
    );
};



/* alerta */
const AlertaModal = ({ alertType, successMessage, errorMessage, isOpen, handleClose }) => {
    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-modal-title"
        aria-describedby="alert-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, 0)',
            zIndex: 1000,
            width: '80%',
            maxWidth: '600px',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 0,
            borderRadius: 2,
            fontFamily:'Poppins'
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {alertType === 'success' && successMessage && (
            <Alert severity="success">
              <AlertTitle sx={{
                fontFamily:'Poppins'
              }}>Éxito</AlertTitle>
              {successMessage}
            </Alert>
          )}
          {alertType === 'warning' && errorMessage && (
            <Alert severity="warning" sx={{
              fontFamily:'Poppins'
            }}>
              <AlertTitle sx={{
                fontFamily:'Poppins'
              }}>Advertencia</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          {alertType === 'error' && errorMessage && (
            <Alert severity="error" sx={{
              fontFamily:'Poppins'
            }}>
              <AlertTitle sx={{
                fontFamily:'Poppins'
              }}>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}
        </Box>
      </Modal>
    );
  };
  

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
  
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color:'#694B81',
    position:'relative',
    left:'0.5%',
    fontFamily: "Poppins",
    width:'100%',
    height:'40px',
    fontSize:'0.8rem',
    borderRadius: '50px',
    border: ` ${error ? '1px solid #D26999' : '3px solid #F0EDF3'}`, // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize:'0.7rem',
      width: '100%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '100%',
    },
}));

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#ffff',
    boxShadow: 24,
    p: 4,
    borderRadius:'10px',
    fontFamily:'poppins',
    fontSize:'1rem',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color:'#694B81',
    flexDirection:'column'
};