import React, { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useNavigate, useParams, Link } from "react-router-dom";
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
/* libreria UI */
import { Container, Grid, Box, CircularProgress, Typography, Card, CardContent, Avatar} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 3000 });

export const MemorialDemoEmpresa = () => {
  const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [qrExists, setQrExists] = useState(false);
    const [grupoFamiliar, setGrupoFamiliar] = useState([]) // Estado para manejar si existe un código QR
    const [isHovered, setIsHovered] = useState(false);
    const [hovered, setHovered] = useState(null);
    const StyleBtonAdd = {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      background: '#ffff',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: '#F0EDF3',
      padding: '15px 20px',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // Sombreado inicial
      transition: 'box-shadow 0.3s ease, transform 0.3s ease', // Transición suave
  };
 
  const handleMouseEnter = (id) => {
    setHovered(id);
  };
  
  const handleMouseLeave = () => {
    setHovered(null);
  };
  // Estilo cuando el cursor está encima
  const StyleBtonAddHover = {
      ...StyleBtonAdd, // Mantiene los estilos base
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Sombreado más intenso
      transform: 'scale(1.05)', // Pequeño efecto de zoom
  };
   
    useEffect(() => {
      // Hacer la solicitud GET al backend para verificar si hay QR disponibles
      const fetchQrStatus = async () => {
          if (!Token || !Usuario.id || !id) return; // Verifica que Token, user.id e id estén presentes

          setLoading(true);
          try {
              const response = await http.get(`${backendURL}/api/qr/empresa/demo/${Usuario.id}`, {
                  params: { id_usuario: Usuario.id },
                  headers: {
                      Authorization: `Bearer ${Token}`, // Incluye el token si es necesario
                  },
              });
          
              // Verificar si la respuesta es exitosa y si hay memoriales
              if (response.status === 200 && response.data.length > 0) {
                // Filtrar solo los memoriales con tipo_memorial === 3
                const memorialesFiltrados = response.data.filter(memorial => memorial.tipo_memorial === "4");
                
                setGrupoFamiliar(memorialesFiltrados); // Guardar memoriales filtrados en el estado
                
                setQrExists(memorialesFiltrados.length > 0); // Memoriales encontrados si hay al menos uno
            } else {
                setQrExists(false); // No hay memoriales
            }
          } catch (error) {
              console.error("Error al verificar el estado del QR", error);
          } finally {
              setLoading(false); // Asegúrate de que se detenga la carga en caso de éxito o error
          }
      };

      fetchQrStatus();
  }, [Token, id, Usuario?.id]);


  // Función para navegar a la página de crear memorial
  const handleNavigateCreardemo = () => {
    navigate(`/crear/demo/empresa/${Usuario.id}`, {
      state: { Usuario, Empresa },
    });
  };

  // Función para navegar a la página de ver QR de la empresa
  const handleNavigateVerQr = (grupoFamiliar) => {
    navigate(`/ver/demo/memorial/empresa/${Usuario.id}`, {
      state: { user: Usuario, empresa: Empresa, grupoFamiliar },
    });
  };

  if (loading) {
    return (<Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh" sx={{ flexDirection:'column'}}>
      <Skeleton variant="text" width="80%" height="100px" />
      <Skeleton variant="rectangular" width="80%" height="100px" />
  </Box>) // Muestra un spinner mientras se cargan los datos
  }

  
    return(
      <Container maxWidth='xxl'>
        <Box>
          <div>
          <button onClick={handleNavigateCreardemo} 
          style={isHovered ? StyleBtonAddHover : StyleBtonAdd}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
        <div > 
          <AddIcon sx={{
           fontSize:'2rem',
          color:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
          ? UserDataMarca.color_1 
          : '#694B81',
        }}/>
          <QrCodeIcon sx={{
            fontSize:'2rem',
          color:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
          ? UserDataMarca.color_1 
          : '#694B81',
        }}/></div>
          <Typography variant="h6" sx={{
            fontFamily:'Poppins',
            marginLeft:'5px',
            fontWeight:'500',
            fontSize:'1rem',
          color:'#585857',
        }}>Nuevo código QR</Typography>
        </button>
        </div>

          <Box sx={StyleBoxBlanco}>
          <Box>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontWeight: "500",
              fontSize: "1.3rem",
              color: "#585857",
              marginBottom: "30px",
            }}
          >
            QR sucursales
          </Typography>
        </Box>

        {loading ? (
          // Indicador de carga
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </Box>
          ) : qrExists ? (
            // Si existen QR creados
            <Grid container>
            {/* Lista de grupos familiares */}
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                {Array.isArray(grupoFamiliar) &&
                  grupoFamiliar.map((grupo) => (
                    <Grid
                      item
                      xs={3}
                      key={grupo.id}
                      onMouseEnter={() => handleMouseEnter(grupo.id)} // Detectar cuando el cursor está sobre el grupo
                      onMouseLeave={handleMouseLeave} // Detectar cuando el cursor sale del grupo
                    >
                      <Card
                        sx={{
                          background: "#ffff",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                        onClick={() => handleNavigateVerQr(grupo)} // Obtén memoriales del grupo
                      >
                        <CardContent
                          sx={{
                            background: "#ffff",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontFamily: "Poppins",
                              marginLeft: "5px",
                              fontWeight: "500",
                              fontSize: "1rem",
                              color: "#585857",
                            }}
                          >
                            {grupo.nombre_familia}
                          </Typography>
                          <Box>
                            <ArrowForwardIosIcon
                              sx={{
                                color:
                                  UserDataMarca?.color_1 &&
                                  UserDataMarca.color_1 !== "undefined"
                                    ? UserDataMarca.color_1
                                    : "#694B81",
                              }}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          
            {/* Lista de memoriales */}
            <Grid item xs={12} md={3}>
              <Grid container>
                {grupoFamiliar.map((grupo) => (
                  <Grid item xs={12} key={grupo.id}>
                    <Card sx={{ p: 0, background: "#ffff","&:last-child": {
                            paddingBottom: 0, // Elimina el padding aplicado por Material-UI
                          }, }}>
                      <CardContent
                        sx={{
                          padding: "0px",
                          "&:last-child": {
                            paddingBottom: 0, // Elimina el padding aplicado por Material-UI
                          },
                        }}
                      >
                        {hovered === grupo.id && grupo.memorial && grupo.memorial.length > 0 && (
                         
                            <img
                              src={`${backendURL}/imagenes/memoriales/${grupo.memorial[0].carpeta_perfil}/${grupo.memorial[0].imagen_perfil}`}
                              alt={`Imagen del memorial de ${grupo.nombre_familia}`}
                              style={{
                                maxWidth: "100%", // Ajusta el tamaño de la imagen
                                maxHeight: "100%", // Asegura que la imagen no sea demasiado grande
                                objectFit: "contain",
                              }}
                            />
                         
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          ) : (
            // Mensaje si no hay QR creados
            <Box>No hay QR creados</Box>
          )}
        </Box>
          </Box>
      </Container>
    )
}


const StyleBox ={
    width:'90%',
    border:'1px solid #F0EDF3',
    marginTop:'30px',
    borderRadius:'10px',
    p:2,
    background:'#F0EDF3',
   
  }
   
  const StyleBoxBlanco ={
    width:'95%',
    border:'1px solid #FFFF',
    margin:'0px',
    borderRadius:'10px',
    p:2,
    background:'#FFFF',
    margin:'10px 0px',
    display:'flex',
    alingItems:'center',
    justifyContent:'Center',
    flexDirection:'column'
  }

  