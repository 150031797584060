import React, {useState, useEffect} from "react";
import axios from "axios";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import PropTypes from 'prop-types';
import { Grid, Modal, Box, CircularProgress, Typography} from '@mui/material';
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
/* react icons */
import { IoClose } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { BsTrash3Fill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";


export const  VelatorioComentarioSucursal = ({memorial}) => {
    const {Token, UserDataMarca} = useUserEmpresa();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [commentToDelete, setCommentToDelete] = useState(null);
    const [openModals, setOpenModals] = useState(false);
    const [loading, setLoading] = useState(true); 
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const[enviandoAlertprogress, setenviandoAlertprogress] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [comentarios, setComentarios] = useState([]);
    const [progress, setProgress] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
      const [popupMessage, setPopupMessage] = useState('');
  
   
      const fetchComentarios = async () => {
                  try {
                      const response = await axios.get(`${backendURL}/api/get/comentariosmemorial/${memorial}`);
                      
                      
                      // Ordena los comentarios por fecha de manera descendente
                      const sortedComentarios = response.data.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
                      
                      setComentarios(sortedComentarios);
                    
                  } catch (error) {
                      console.error('Error al obtener comentarios:', error);
                  }
              };
              
              // Llama a esta función en el useEffect para obtener comentarios al cargar la página
              useEffect(() => {
                  fetchComentarios();
              }, [memorial]);
    
    const handleAceptar = async (id) => {
        setLoading(false);
        try {
          setLoading(false);
          const response = await axios.post(
            `${backendURL}/api/comentarioStateSucursal`,
            {
              id: id.id,
              visible: 1
            },
            {
              headers: {
                Authorization: `Bearer ${Token}`
              }
            }
          );
    
        
          if (response.status === 200) {
            handleOpenPopup('Comentario aceptado con éxito.');
            handleCloseModal();

            // Espera a que fetchComentarios termine antes de continuar
            await fetchComentarios(); 
        }
        } catch (error) {
          console.error('Error al cambiar el estado del comentario:', error);
          handleOpenPopup('Error al aceptar el comentario, vuelva a intentar.');
        }
        finally {
          setLoading(false);
          
        }
      };
    const handleDeleteComment = async (id) => {
        try {
          setenviandoAlert(true);
          setLoading(false);
          await axios.post(
            `${backendURL}/api/comentarioDeleteSucursal`,
            { id: id },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Token}`
              }
            }
          );
          setenviandoAlert(false);
          setSuccessAlertOpen(true);
          setAlertMessage('Su mensaje fue eliminado con éxito.')
          setLoading(true);
          handleCloseModals();
          const updatedComentarios = comentarios.filter(comentario => comentario.id !== id);
          setComentarios(updatedComentarios);
    
         
       
        } catch (error) {
          setErrorAlertOpen(true);
          setAlertMessage('Error al eliminar el mensaje, vuelva a intentar.');
        }
        finally{
          setenviandoAlert(false);
     }
    
     // Simulación de tiempo de carga
     setTimeout(() => {
         handleCloseModal();
     }, 3000);
     // Simulación de tiempo de carga
     setTimeout(() => {
         setSuccessAlertOpen(false);
         setErrorAlertOpen(false);
     }, 2000);
      };
    

      const handleOpenPopup = (message) => {
        setPopupMessage(message);
        setShowPopup(true);
    
        setTimeout(() => {
          setShowPopup(false);
          setPopupMessage('');
        }, 2000); 
      };

      const handleCloseModal = () => {
        setenviandoAlert(false);
        setenviandoAlertprogress(false);
      };
  
     const handleOpenModalsnew = (comentario) => {
    setCommentToDelete({ id: comentario.id, nombre: comentario.creador });
    setOpenModals(true);
};
    
    const handleCloseModals = () => {
        setOpenModals(false);
        setCommentToDelete(null);
      };
    const capitalizeFirstLetter = (string) => {
        if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
    return(
        <>
        <h1 style={{color:'#585857', fontFamily:'Poppins', fontWeight:'600'}}> Comentarios del memorial</h1>
        <section className="editcomentarios">
  {comentarios && comentarios.length > 0 ? (
    comentarios.filter(comentario => comentario.visible !== '1').length > 0 ? (
      comentarios
        .filter(comentario => comentario.visible !== '1')
        .slice()
        .reverse()
        .map((comentario, index) => {
          return (
            <div style={{ width: "100%" }} key={index}>
              <div className="cardmensajeeditaparicion">
                <div style={{ width: '100%', display: "flex", alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                  <div className="basuraeditcomt" style={{ marginRight: '20px' }}>
                    <div onClick={() => handleAceptar(comentario)}>
                      Aceptar <FaCheck />
                    </div>
                  </div>
                  <div className="basuraeditcomt">
                    <div onClick={() => handleOpenModalsnew(comentario)}>
                      Eliminar <BsTrash3Fill />
                    </div>
                  </div>
                </div>
                <div className="toocomt">
                  <div className="titulogeneradoeditcoment">
                    <h1>{capitalizeFirstLetter(comentario.titulo)}</h1>
                  </div>
                  <div className="comentarioeditgeneradocomt">
                    <p>
                      {comentario.mensaje ? (
                        <DangerouslySetHTMLContent html={capitalizeFirstLetter(comentario.mensaje)} />
                      ) : (
                        <span></span>
                      )}
                    </p>
                  </div>
                  <div className="quienteditlorealizohora">
                    <p>{comentario.creador}</p> •
                    <p>{new Date(comentario.fecha).toLocaleDateString('es-ES')}</p>
                  </div>
                  <div className="imagencomentario">
                    {comentario.imagen && (
                      <img
                        src={`${backendURL}/${comentario.imagen}`}
                        alt="smart memorial"
                        className="imagencomentariofinal"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
    ) : (
      // 📌 Muestra el mensaje si no hay comentarios visibles !== '1'
      <Grid item xs={12} className="no-galeria" sx={{ width: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            height: 200,
            border: "1px dashed rgba(0, 0, 0, 0.2)",
            borderRadius: 8,
            padding: 2,
            backgroundColor: "#fefefe",
          }}
        >
          <WarningAmberIcon
            sx={{
              fontSize: 40,
              color: "#FF9800",
              marginBottom: 1,
            }}
          />
          <Typography variant="h6" color="textSecondary">
            No hay nuevos comentarios
          </Typography>
        </Box>
      </Grid>
    )
  ) : (
    // 📌 Muestra el mensaje si no hay comentarios en absoluto
    <Grid item xs={12} className="no-galeria" sx={{ width: '100%' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '100%',
          height: 200,
          border: "1px dashed rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          padding: 2,
          backgroundColor: "#fefefe",
        }}
      >
        <WarningAmberIcon
          sx={{
            fontSize: 40,
            color: "#FF9800",
            marginBottom: 1,
          }}
        />
        <Typography variant="h6" color="textSecondary">
          No hay comentarios disponibles
        </Typography>
      </Box>
    </Grid>
  )}
</section>
        
            <Modal
                open={openModals}
                onClose={handleCloseModals}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius: "10px", '@media (max-width: 500px)': {
            width:'80%', p:2
          } }}>
                  <div className="tituloeliminarmensaje">
                    <h2 id="modal-title">¿Desea eliminar este mensaje de {capitalizeFirstLetter(commentToDelete?.nombre)}?</h2></div>
                  <div className="contienetodoslosbotonesediteliminar"> <button className="noeli" onClick={handleCloseModals}>No eliminar</button>
                    <button className="sieli" onClick={() =>handleDeleteComment(commentToDelete?.id)} >Si eliminar</button></div>
                </Box>
              </Modal>
               {/* Popup de carga */}
                    <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '350px',
                          height: 'auto',
                          bgcolor: '#ffff',
                          boxShadow: 24,
                          p: 1,
                          borderRadius: '10px',
                          '@media (max-width: 500px)': {
                            width: '90%',
                          },
                        }}
                      >
                        <section style={{ textAlign: 'center', padding: '1rem' }}>
                          <CircularProgress style={{ color: '#694B81' }} />
                          <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                            Procesando solicitud...
                          </p>
                        </section>
                      </Box>
                    </Modal>
                    <Modal open={enviandoAlertprogress} onClose={() => setenviandoAlertprogress(false)}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '350px',
                    height: 'auto',
                    bgcolor: '#ffff',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: '10px',
                    '@media (max-width: 500px)': {
                      width: '90%',
                    },
                  }}
                >
                  <section style={{ textAlign: 'center', padding: '1rem' }}>
                    <CircularProgressWithLabel value={progress} style={{ color: '#694B81' }} />
                    <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                      Procesando solicitud...
                    </p>
                  </section>
                </Box>
              </Modal>
               <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '350px',
                          height: 'auto',
                          bgcolor: '#ffff',
                          boxShadow: 24,
                          p: 1,
                          borderRadius: '10px',
                          '@media (max-width: 500px)': {
                            width: '90%',
                          },
                        }}
                      >
                        <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
                          <IoClose />
                        </section>
                        <section style={{ textAlign: 'center' }}>
                          <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
                          <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
                            {alertMessage}
                          </p>
                        </section>
                      </Box>
                    </Modal>
                    <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '350px',
                                height: 'auto',
                                bgcolor: '#ffff',
                                boxShadow: 24,
                                p: 1,
                                borderRadius: '10px',
                                '@media (max-width: 500px)': {
                                  width: '90%',
                                },
                              }}
                            >
                              <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
                                <IoClose />
                              </section>
                              <section style={{ textAlign: 'center' }}>
                                <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
                                <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                                  {alertMessage}
                                </p>
                              </section>
                            </Box>
                          </Modal>
                           {showPopup && (
                                    <div className="popupmodal">
                                      <div className="popupmensaje">
                                      <CircularProgress sx={{color: (UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined') ? UserDataMarca.color_2 :  '#694B81'}} />
                                        <p style={{ fontFamily:"Poppins", color: (UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined') ? UserDataMarca.color_2 :  '#694B81', fontSize:"0.8rem", fontWeight:"600", marginTop: '20px'}}>Espere un momento</p>
                                        <p className="mensajefinalpop">{popupMessage}</p>
                                        
                                      </div>
                                    </div>
                                  )}
                    
        </>
    )
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel() {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}

